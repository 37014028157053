import React from "react";
import { makeStyles, Typography, Button } from "@material-ui/core";
import authApi from "../api/auth-api";

const GoogleAuth = () => {
  const useStyles = makeStyles(() => ({
    root: {
      backgroundColor: "#9DC199",
      color: "#fff",
      fontSize: "16px",
      lineHeight: "19px",
      height: "40px",
      marginBottom: "34px",
      boxShadow: "none",
      textTransform: "none",
      transition: "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",

      "& svg": {
        fill: "#fff",
        marginRight: "15px",
      },

      "&:hover": {
        backgroundColor: "#6D9D68",
        boxShadow: "none",
      },
    },
  }));

  const handleGoogleAuthBtnClick = () => {
    authApi.getGoogleAuthUrl().then((res) => {
      window.location.replace(res.data.google_auth_url);
    });
  };

  return (
    <Button
      variant="contained"
      fullWidth
      className={useStyles().root}
      onClick={handleGoogleAuthBtnClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="#000000"
        viewBox="0 0 24 24"
        width="24px"
        height="24px"
      >
        <path d="M12.545,10.239v3.821h5.445c-0.712,2.315-2.647,3.972-5.445,3.972c-3.332,0-6.033-2.701-6.033-6.032s2.701-6.032,6.033-6.032c1.498,0,2.866,0.549,3.921,1.453l2.814-2.814C17.503,2.988,15.139,2,12.545,2C7.021,2,2.543,6.477,2.543,12s4.478,10,10.002,10c8.396,0,10.249-7.85,9.426-11.748L12.545,10.239z" />
      </svg>
      <Typography>Continue with Google</Typography>
    </Button>
  );
};

export default GoogleAuth;
