/* 
    Just for development and debug
    Will be remove in future
*/

import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import library from "./library";

const WidgetClientDemo = () => {
  const companyUuid = useSelector((store) => store.company.uuid);
  const location = useLocation();

  const user = location.search
    ? JSON.parse(
        `{"${decodeURI(location.search.substring(1))
          .replace(/"/g, '\\"')
          .replace(/&/g, '","')
          .replace(/=/g, '":"')}"}`
      )
    : undefined;

  return (
    <div style={{ backgroundColor: "bisque", height: "150vh" }}>
      {library(companyUuid, user)}
    </div>
  );
};

export default WidgetClientDemo;
