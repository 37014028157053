import React from "react";
import {
  makeStyles,
  Grid,
  Button,
  Dialog,
  List,
  ListItem,
  Collapse,
  ListItemText,
  Typography,
  IconButton,
  Select,
  Tooltip,
} from "@material-ui/core";
import {
  ExpandLess,
  ExpandMore,
  ErrorOutlineOutlined,
  Close,
} from "@material-ui/icons";
import PropTypes from "prop-types";

import QuestionsList from "./QuestionsList";

const ChooseTemplate = (props) => {
  const { getActiveTemplate } = props;

  const templates = {
    Product: {
      "Feature Feedback": [
        {
          type: "NPS_Score",
          text:
            "How likely are you to recommend <product> to a friend or colleague?",
          options: {
            lowScoreText: "Not At All Likely",
            highScoreText: "Extremely Likely",
          },
        },
        {
          type: "multipleChoice",
          text:
            "What is one thing <product> doesn’t offer that you wish it did?",
          options: {
            optionsList: ["Answer 1", "Answer 2", "Answer 3"],
          },
        },
        {
          type: "thankYouNote",
          text:
            "Thanks for providing us this feedback. It helps us make a better product for you",
        },
      ],
      Onboarding: [
        {
          type: "rating",
          text:
            "On a scale of 1 to 5, how would you rate your onboarding experience?",
          options: {
            lowScoreText: "Not So Great",
            highScoreText: "Great",
            starsNumber: 5,
          },
        },
        {
          type: "openQuestion",
          text:
            "What, if anything, was the biggest obstacle during your onboarding experience?",
        },
        {
          type: "thankYouNote",
          text:
            "Thanks for providing us this feedback. It helps us make a better product for you",
        },
      ],
    },
    Design: {
      "UMUX Lite": [
        {
          type: "rating",
          text: "This website/product’s capabilities meet my requirements",
          options: {
            lowScoreText: "Strongly disagree",
            highScoreText: "Strongly agree",
            starsNumber: 5,
          },
        },

        {
          type: "rating",
          text: "This website/product is easy to use",
          options: {
            lowScoreText: "Strongly disagree",
            highScoreText: "Strongly agree",
            starsNumber: 5,
          },
        },
        {
          type: "thankYouNote",
          text:
            "Thanks for providing us this feedback. It helps us make a better product for you",
        },
      ],
      Findability: [
        {
          type: "yesNo",
          text:
            "Are information and instructions clear and concise on this product/website?",
        },
        {
          type: "rating",
          text:
            "I was able to get from point a to point b in this website/product without much distraction?",
          options: {
            lowScoreText: "Strongly disagree",
            highScoreText: "Strongly agree",
            starsNumber: 5,
          },
        },
      ],
    },
    "Customer Success": {
      "Customer Satisfaction": [
        {
          type: "NPS_Score",
          text:
            "How likely are you to recommend <product> to a friend or colleague?",
          options: {
            lowScoreText: "Not At All Likely",
            highScoreText: "Extremely Likely",
          },
        },
        {
          type: "openQuestion",
          text:
            "Is there anything Prove can do to make your experience better?",
        },
        {
          type: "thankYouNote",
          text:
            "Thanks for providing us this feedback. It helps us make a better experience for you",
        },
      ],
      Achievement: [
        {
          type: "rating",
          text: "Rate your experience so far using our product",
          options: {
            lowScoreText: "Strongly disagree",
            highScoreText: "Strongly agree",
            starsNumber: 5,
          },
        },
        {
          type: "openQuestion",
          text: "What were your goals when signing up for this product?",
        },
        {
          type: "yesNo",
          text: "Did you achieve your goals?",
        },
      ],
    },
  };

  const [state, setState] = React.useState({
    modalOpen: false,
    choseTemplate: [
      Object.keys(templates)[0],
      Object.keys(templates[Object.keys(templates)[0]])[0],
    ],
    [`${Object.keys(templates)[0]}Open`]: true,
  });
  const useStyles = makeStyles((theme) => ({
    button: {
      fontSize: "16px",
      lineHeight: "25px",
      padding: "6px 16px",
      borderColor: theme.palette.primary.main,
      letterSpacing: "0.3px",
    },
    modal: {
      "& .MuiDialog-paperScrollPaper": {
        maxWidth: "unset",
        height: "75%",
        overflow: "auto",
        paddingTop: "0",

        "&::-webkit-scrollbar-track": {
          webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
          backgroundColor: "transparent",
        },
        "&::-webkit-scrollbar": {
          width: "4px",
          height: "4px",
          backgroundColor: "transparent",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#96BD92",
          border: "2px solid #96BD92",
        },

        "& > .MuiGrid-container": {
          height: "100%",
          width: "829px",
          position: "relative",

          [theme.breakpoints.down("sm")]: {
            width: "auto",
          },
        },
      },

      "& aside": {
        backgroundColor: "#212121",
        color: "#fff",
        width: "205px",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        overflow: "auto",
        // height: "calc(100% - 79px)",

        // TODO: Create Template
        height: "100%",
        zIndex: "1",

        [theme.breakpoints.down("sm")]: {
          display: "none",
        },

        "&::-webkit-scrollbar-track": {
          webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
          backgroundColor: "transparent",
        },
        "&::-webkit-scrollbar": {
          width: "4px",
          height: "4px",
          backgroundColor: "transparent",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#96BD92",
          border: "2px solid #96BD92",
        },

        "& > h6": {
          color: theme.palette.primary.main,
          padding: "25px 16px 18px",
        },

        "& ul": {
          paddingBottom: "17px",

          "& > .MuiGrid-root": {
            borderTop: "1px solid #424242",
            padding: "4px 0",

            "&:last-child": {
              borderBottom: "1px solid #424242",
            },

            "& > .MuiListItem-gutters": {
              paddingRight: "8px",
            },

            "& > .MuiCollapse-container": {
              "&:last-child": {
                paddingBottom: "3px",
              },

              "& .MuiListItem-button": {
                padding: "4px 16px 4px 32px",
              },
            },
          },
        },
      },

      "& section": {
        display: "flex",
        flexDirection: "column",
        width: "624px",
        minWidth: "624px",
        position: "relative",
        overflow: "auto",
        padding: "35px 47px 30px",
        height: "calc(100% - 77px)",

        "& .QuestionsList": {
          padding: "20px 50px 18px 21px",
        },

        [theme.breakpoints.down("sm")]: {
          width: "100%",
          minWidth: "unset",
          padding: "35px 50px 30px 20px",

          "& .QuestionsList": {
            paddingRight: "10px",

            "&__visibility": {
              right: "-47px",
            },
          },
        },

        "&::-webkit-scrollbar-track": {
          webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
          backgroundColor: "transparent",
        },
        "&::-webkit-scrollbar": {
          width: "4px",
          height: "4px",
          backgroundColor: "transparent",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#96BD92",
          border: "2px solid #96BD92",
        },

        "& > .MuiGrid-root:nth-child(2)": {
          display: "none",
          marginBottom: "15px",

          [theme.breakpoints.down("sm")]: {
            display: "flex",
            alignItems: "flex-end",
            flex: "none",
          },

          [theme.breakpoints.down("xs")]: {
            flexDirection: "column",
            alignItems: "flex-start",
          },

          "& > .MuiGrid-root": {
            "& > p": {
              fontWeight: "600",
              fontSize: "10px",
              lineHeight: "11px",
              letterSpacing: "1px",
              textTransform: "uppercase",
              color: "#B3B3B3",
              marginBottom: "10px",
            },

            "& .MuiOutlinedInput-input": {
              padding: "10px 12px",
            },

            "& .MuiOutlinedInput-root:not(.Mui-disabled):hover fieldset": {
              borderColor: "#96BD92",
            },
          },

          "& > button": {
            marginLeft: "30px",
            color: "#fff",
            boxShadow: "none",

            [theme.breakpoints.down("xs")]: {
              marginLeft: "0",
              marginTop: "15px",
            },
          },
        },

        "& > button": {
          position: "absolute",
          top: "4px",
          right: "3px",

          "& svg": {
            fill: "rgba(136, 136, 136, 0.54)",
          },
        },

        "& .ChooseTemplate__header": {
          marginBottom: "19px",

          "& svg": {
            marginLeft: "9px",
            color: "rgba(136, 136, 136, 0.54)",
          },
        },

        "& > p": {
          color: theme.palette.primary.main,
          marginBottom: "16px",
        },
      },

      "& .ChooseTemplate__footer": {
        position: "absolute",
        bottom: "0",

        "& > .MuiGrid-root": {
          "&:first-child": {
            background: "#000",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
            bottom: "0",
            width: "205px",

            // TODO: Create Template
            backgroundColor: "#212121",
            height: "79px",

            [theme.breakpoints.down("sm")]: {
              display: "none",
            },

            "& button": {
              fontSize: "16px",
              lineHeight: "25px",
              fontWeight: "600",
              padding: "6px 16px",
              letterSpacing: "0.3px",

              // TODO: Create Template
              display: "none",
            },
          },

          "&:last-child": {
            borderTop: "1px solid #E0E0E0",
            padding: "16px 20px 18px 0",
            display: "flex",
            bottom: "0",
            width: "624px",
            background: "#fff",

            [theme.breakpoints.down("sm")]: {
              width: "100%",
            },

            "& button": {
              color: "#fff",
              boxShadow: "none",
              padding: "8px 22px",
              letterSpacing: "0.3px",
              marginLeft: "auto",

              "& span": {
                fontSize: "15px",
                lineHeight: "26px",
              },
            },
          },
        },
      },
    },
  }));

  const handleModalOpen = () => {
    setState({ ...state, modalOpen: !state.modalOpen });
  };

  const handleGroupOpen = (group, event) => {
    setState({ ...state, [`${group}Open`]: !state[`${group}Open`] });

    event.target.closest(".MuiGrid-root").scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  const handleChooseTemplate = (group, subGroup) => {
    setState({ ...state, choseTemplate: [group, subGroup] });
  };

  return (
    <Grid container>
      <Button
        className={useStyles().button}
        variant="outlined"
        color="primary"
        onClick={handleModalOpen}
      >
        Choose Template
      </Button>
      <Dialog
        className={useStyles().modal}
        onClose={handleModalOpen}
        open={state.modalOpen}
      >
        <Grid container wrap="nowrap">
          <aside>
            <Typography variant="h6">Choose Pack</Typography>
            <List>
              {Object.keys(templates).map((group) => (
                <Grid key={group}>
                  <ListItem
                    button
                    onClick={(event) => handleGroupOpen(group, event)}
                  >
                    <ListItemText primary={group} />
                    {state[`${group}Open`] ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  {Object.keys(templates[group]).map((subGroup) => (
                    <Collapse
                      in={state[`${group}Open`]}
                      timeout="auto"
                      unmountOnExit
                      key={subGroup}
                    >
                      <List component="div" disablePadding>
                        <ListItem
                          button
                          onClick={() => handleChooseTemplate(group, subGroup)}
                        >
                          <ListItemText>
                            <Typography variant="body2">{subGroup}</Typography>
                          </ListItemText>
                        </ListItem>
                      </List>
                    </Collapse>
                  ))}
                </Grid>
              ))}
            </List>
          </aside>
          <section>
            <IconButton onClick={handleModalOpen}>
              <Close />
            </IconButton>
            <Grid>
              <Grid>
                <Typography>Choose Pack</Typography>
                <Select
                  native
                  variant="outlined"
                  onChange={(event) =>
                    handleChooseTemplate(
                      Object.keys(templates)[event.target.value.split(",")[0]],
                      Object.keys(
                        templates[
                          Object.keys(templates)[
                            event.target.value.split(",")[0]
                          ]
                        ]
                      )[event.target.value.split(",")[1]]
                    )}
                >
                  {Object.keys(templates).map((group, groupKey) => (
                    <optgroup key={group} label={group}>
                      {Object.keys(templates[group]).map(
                        (subGroup, subGroupKey) => (
                          <option
                            key={subGroup}
                            value={[groupKey, subGroupKey]}
                          >
                            {subGroup}
                          </option>
                        )
                      )}
                    </optgroup>
                  ))}
                </Select>
              </Grid>
              <Button variant="contained" color="primary">
                Create Your Own
              </Button>
            </Grid>
            {templates[state.choseTemplate[0]] && (
              <>
                <Grid
                  container
                  alignItems="center"
                  className="ChooseTemplate__header"
                >
                  <Typography variant="h5">{state.choseTemplate[1]}</Typography>
                  <Tooltip title="Some tooltip" arrow enterTouchDelay={0}>
                    <ErrorOutlineOutlined />
                  </Tooltip>
                </Grid>
                <Typography variant="body1">
                  {`Pack Question (${
                    templates[state.choseTemplate[0]][state.choseTemplate[1]]
                      .length
                  }) :`}
                </Typography>
                <QuestionsList
                  questions={
                    templates[state.choseTemplate[0]][state.choseTemplate[1]]
                  }
                />
              </>
            )}
          </section>
          <Grid container className="ChooseTemplate__footer">
            <Grid>
              <Button variant="outlined" color="primary">
                Create Your Own
              </Button>
            </Grid>
            <Grid>
              <Button
                variant="contained"
                color="primary"
                disabled={!templates[state.choseTemplate[0]]}
                onClick={() => {
                  handleModalOpen();
                  getActiveTemplate(
                    templates[state.choseTemplate[0]][state.choseTemplate[1]]
                  );
                }}
              >
                Choose Pack
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    </Grid>
  );
};

ChooseTemplate.propTypes = {
  getActiveTemplate: PropTypes.func.isRequired,
};

export default ChooseTemplate;
