import React from "react";
import {
  makeStyles,
  Grid,
  Stepper,
  Step,
  StepLabel,
  Typography,
  TextField,
  Button,
  Divider,
  Tooltip,
  FormControlLabel,
  Checkbox,
  Radio,
  MenuItem,
  IconButton,
  FormControl,
  OutlinedInput,
  InputAdornment,
  ClickAwayListener,
  Backdrop,
  CircularProgress,
  Dialog,
} from "@material-ui/core";
import {
  ErrorOutlineOutlined,
  ChevronLeft,
  ErrorOutline,
  DeleteOutlineOutlined,
  Add,
  FileCopyOutlined,
  Close,
} from "@material-ui/icons";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import campaignsApi from "../api/campaigns-api";
import Logo from "../components/Logo";
import QuestionsList from "../components/QuestionsList";
import ChooseTemplate from "../components/ChooseTemplate";
import Widget from "../components/Widget";
import AlertMessage from "../components/AlertMessage";

const CampaignEdit = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const userId = useSelector((store) => store.user.id);
  const userRole = useSelector((store) => store.user.role);
  const company = useSelector((store) => store.company);

  const [state, setState] = React.useState({
    hasCampaign: false,
    step: 1,
    title: "Create your feedback campaign",
    isCampaign: false,
    backdrop: false,
    alertMsg: null,
    campaignsList: [],
    isCampaignNameExist: false,
    campaignId: null,
    campaign: "",
    addQuestionAnchor: null,
    questions: [],
    demoWidget: 0,
    color: "#96BD92",
    segment: "allUsers", // TODO: Segments - Will be empty value
    status: "",
    statisticFinishedCount: 0,
    firstCampaign: true,
    frequency: {
      type: "showOnce",
      responders: false,
      timeType: "days",
      timeValue: 10,
    },
    recurringTimeActive: false,
    position: "bottom-left",
    branding: true,
    triggers: [
      {
        id: 0,
        time: {
          value: "10",
          type: "sec",
        },
        additional: {
          type: "pageVisit",
          rule: "contains",
          value: company.url,
          manually:
            "_prove('showForm', '25b2d230-9a2f-11ea-84dc-eda24a64b77d');", // TODO: Manually - Will be later
        },
      },
    ],
    confirmUpdateModal: false,
  });

  const useStyles = makeStyles((theme) => ({
    stepper: {
      position: "absolute",
      left: "50%",
      transform: "translateX(-50%)",
      top: "22px",
      zIndex: "1",
      display: state.hasCampaign ? "block" : "none",

      [theme.breakpoints.down("sm")]: {
        left: "15px",
        transform: "none",
        top: "95px",
      },

      [theme.breakpoints.down(360)]: {
        left: "-20px",
      },

      "& .MuiPaper-root": {
        backgroundColor: "transparent",

        "& .MuiStepLabel-label": {
          marginTop: "-51px",
          fontWeight: "600",
          fontSize: "10px",
          lineHeight: "11px",
        },

        "& .MuiStepIcon-root": {
          width: "20px",
          height: "20px",
          zIndex: "1",

          "& .MuiStepIcon-text": {
            fill: "#fff",
          },
        },

        "& .MuiStepConnector-alternativeLabel": {
          left: "-50%",
          right: "50%",
          top: "8px",

          "& .MuiStepConnector-lineHorizontal": {
            borderTopWidth: "4px",
            borderColor: "#D5E5D3",
          },
        },

        "& .MuiStep-alternativeLabel": {
          "&:first-child": {
            "& .MuiStepLabel-label, .MuiStepIcon-root": {
              color: state.step === 1 ? theme.palette.primary.main : "#D5E5D3",
            },
          },

          "&:nth-child(2)": {
            "& .MuiStepLabel-label": {
              color:
                state.step === 2
                  ? theme.palette.primary.main
                  : state.step === 3
                  ? "#D5E5D3"
                  : "rgba(136, 136, 136, 0.54)",
            },
            "& .MuiStepIcon-root": {
              color: state.step === 2 ? theme.palette.primary.main : "#D5E5D3",
            },
          },

          "&:nth-child(3)": {
            "& .MuiStepLabel-label": {
              color:
                state.step === 3
                  ? theme.palette.primary.main
                  : "rgba(136, 136, 136, 0.54)",
            },
            "& .MuiStepIcon-root": {
              color: state.step === 3 ? theme.palette.primary.main : "#D5E5D3",
            },
          },
        },
      },
    },
    form: {
      display: state.hasCampaign ? "flex" : "none",
      flexDirection: "column",
      justifyContent: "space-between",
      minHeight: "100vh",

      "& form": {
        paddingTop: "29px",
        paddingLeft: "115px",

        [theme.breakpoints.down(1100)]: {
          paddingLeft: "70px",
        },

        [theme.breakpoints.down("sm")]: {
          paddingLeft: "45px",
          paddingRight: "45px",
        },

        [theme.breakpoints.down(400)]: {
          paddingLeft: "14px",
          paddingRight: "14px",
        },

        "& > a": {
          display: "block",
        },

        "& > button": {
          fontSize: "16px",
          fontWeight: "600",
          lineHeight: "25px",
          marginTop: "24px",
          marginBottom: "-30px",
          paddingLeft: "0",
          marginLeft: "-7px",

          [theme.breakpoints.down("sm")]: {
            marginTop: "90px",
          },

          "& svg": {
            width: "18px",
            height: "18px",
            marginRight: "9px",
          },
        },

        "& > h5": {
          marginTop: "39px",

          [theme.breakpoints.down("sm")]: {
            marginTop: state.step === 1 ? "100px" : "50px",
          },
        },

        "& .CampaignEdit": {
          "&__step_1": {
            paddingRight: "50px",
            "& > .MuiTextField-root": {
              width: "100%",

              [theme.breakpoints.down("xs")]: {
                width: "calc(100% + 50px)",
              },

              "& label": {
                color: "rgba(136, 136, 136, 0.54)",

                "&.Mui-focused": {
                  color: theme.palette.primary.main,
                },
              },

              "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                borderBottomColor: theme.palette.primary.main,
                borderBottomWidth: "1px",
              },

              "& > p.MuiFormHelperText-root.Mui-error": {
                position: "absolute",
                bottom: "-23px",
              },
            },

            "&__header": {
              marginTop: "26px",

              "& .MuiGrid-container": {
                width: "auto",

                "& svg": {
                  marginLeft: "9px",
                  marginTop: "3px",
                  color: "rgba(136, 136, 136, 0.54)",
                },

                "& button": {
                  [theme.breakpoints.down(460)]: {
                    marginTop: "20px",
                  },
                },
              },
            },

            "&__list": {
              width: "calc(100% + 50px)",

              "& > .MuiGrid-root:first-child": {
                marginTop: "16px",
                overflowY: "auto",
                maxHeight: "calc(100vh - 425px)",

                [theme.breakpoints.down("sm")]: {
                  maxHeight: "unset",
                },

                "&::-webkit-scrollbar-track": {
                  webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
                  backgroundColor: "transparent",
                },
                "&::-webkit-scrollbar": {
                  width: "4px",
                  height: "4px",
                  backgroundColor: "transparent",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#96BD92",
                  border: "2px solid #96BD92",
                },
              },
            },

            "& .QuestionsList": {
              backgroundColor: "unset",
              transition:
                "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
              paddingRight: "40px",

              "&:hover": {
                backgroundColor: "#FAFAFA",

                "& .QuestionsList": {
                  "&__add": {
                    opacity: "1",
                  },
                },
              },

              "&__main": {
                width: "100%",

                "& p:last-child": {
                  whiteSpace: "unset!important",
                  width: "unset",
                },
              },

              "&__visibility": {
                display: "none",
              },

              "&__actions": {
                position: "absolute",
                top: "2px",
                right: "4px",
                transform: "unset",
                display: "block",
                transition: "opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",

                "& svg": {
                  color: "rgba(136, 136, 136, 0.54)",
                },
              },

              "&__add": {
                display: "block",
                left: "50%",
                transform: "translateX(-50%)",
                opacity: "0",
                transition: "opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
              },
            },
          },
          "&__step_2": {
            marginTop: "38px",
            paddingBottom: "30px",
            maxHeight: "calc(100vh - 300px)",
            overflowY: "auto",
            overflowX: "hidden",

            [theme.breakpoints.down("sm")]: {
              maxHeight: "unset",
            },

            "&::-webkit-scrollbar-track": {
              webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
              backgroundColor: "transparent",
            },
            "&::-webkit-scrollbar": {
              width: "4px",
              height: "4px",
              backgroundColor: "transparent",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#96BD92",
              border: "2px solid #96BD92",
            },

            "& p": {
              fontWeight: " 600",
              fontSize: " 10px",
              lineHeight: " 11px",
              display: " flex",
              alignItems: " center",
              textAlign: " center",
              letterSpacing: " 1px",
              color: "#B3B3B3",
              marginTop: "38px",
              marginBottom: "8px",

              "& svg": {
                marginLeft: "6px",
                fill: "rgba(136, 136, 136, 0.54)",
              },
            },

            "& > p:first-child": {
              marginTop: "0",
            },

            "& > p:nth-child(3)": {
              marginTop: "30px",
            },

            "& .MuiSelect-select, & .MuiInputBase-input": {
              padding: "10px 12px",
            },

            "& .MuiOutlinedInput-root:hover fieldset": {
              borderColor: "#96BD92",
            },

            "& > .MuiFormControl-root": {
              width: "100%",
              maxWidth: "266px",

              "& label:not(.MuiInputLabel-shrink)": {
                top: "-9px",
              },

              "&:nth-child(2)": {
                "& legend span, & > label": {
                  display: state.segment ? "none" : "block",
                },
              },
            },

            "&__triggers": {
              maxWidth: "90%",

              [theme.breakpoints.down("sm")]: {
                maxWidth: "100%",
              },

              "&__item": {
                [theme.breakpoints.down(505)]: {
                  width: "100%",
                },

                "& > .MuiGrid-root": {
                  width: "calc(100% - 50px)",

                  [theme.breakpoints.down(505)]: {
                    width: "auto",
                  },
                },

                "&__time": {
                  marginBottom: "10px",

                  "& > p": {
                    fontSize: "16px",
                    lineHeight: "19px",
                    color: "rgba(0, 0, 0, 0.87)",
                    fontWeight: "400",
                    margin: "0 19px 0 0",
                    letterSpacing: "normal",
                  },

                  "& .MuiFormControl-root": {
                    "&:nth-child(2)": {
                      width: "53px",
                      marginRight: "10px",

                      [theme.breakpoints.down(505)]: {
                        width: "calc(100% - 211px)",
                      },
                    },
                    "&:nth-child(3)": {
                      width: "75px",
                    },
                  },
                },
                "&__additional": {
                  display: "flex",

                  [theme.breakpoints.down(505)]: {
                    display: "block",
                  },

                  // TODO: Manually - Remove in future
                  "& > p": {
                    color: "rgba(0, 0, 0, 0.87)",
                    margin: "0 19px 0 0",
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "19px",
                    letterSpacing: "normal",
                    display: "inline-flex",
                    height: "39px",
                  },

                  "& .MuiFormControl-root": {
                    marginRight: "10px",

                    "&:first-child": {
                      width: "115px",
                      [theme.breakpoints.down(505)]: {
                        width: "127px",
                      },
                    },
                    "&:not(.CampaignEdit__step_2__triggers__item__additional__manually):nth-child(2)": {
                      width: "105px",
                      [theme.breakpoints.down(505)]: {
                        width: "calc(100% - 94px)",
                        marginRight: "0",
                      },
                    },
                    "&:nth-child(3)": {
                      flex: "1",

                      [theme.breakpoints.down(505)]: {
                        marginTop: "10px",
                        width: "100%",
                      },
                    },
                  },

                  "&__manually": {
                    flex: "1",

                    [theme.breakpoints.down(505)]: {
                      width: "126px",
                      marginRight: "0!important",
                    },

                    "& svg": {
                      width: "15px",
                      height: "15px",
                    },

                    "& .MuiSnackbar-root": {
                      position: "absolute",
                      left: "unset",
                      right: "0",
                      whiteSpace: "nowrap",
                      top: "100%",
                      transform: "translateY(5px)",
                      padding: "15px",

                      [theme.breakpoints.down("sm")]: {
                        top: "100%",
                        left: "0",
                        transform: "translateY(10px)",
                        width: "215px",
                      },

                      [theme.breakpoints.down(505)]: {
                        left: "-137px",
                      },

                      "& p": {
                        margin: "0",
                        color: "#fff",
                        fontSize: "14px",
                        lineHeight: "20px",
                        letterSpacing: "normal",
                        fontWeight: "500",
                      },
                    },
                  },
                },
                "& > button": {
                  color: "rgba(136, 136, 136, 0.54)",
                  height: "48px",

                  [theme.breakpoints.down(505)]: {
                    margin: "auto",
                    marginTop: "10px",
                  },
                },
                "& > hr": {
                  width: "100%",
                  margin: "30px 0",

                  [theme.breakpoints.down(505)]: {
                    margin: "10px 0 30px 0",
                  },
                },
              },
              "&__add": {
                position: "relative",
                margin: "45px 0 30px 0",
                maxWidth: "90%",

                [theme.breakpoints.down("xs")]: {
                  maxWidth: "100%",
                },

                [theme.breakpoints.down(505)]: {
                  width: "100%",
                  marginTop: "30px",
                },

                "& button": {
                  position: "absolute",
                  top: "-22px",
                  left: "50%",
                  transform: "translateX(-50%)",

                  "& svg": {
                    borderRadius: "24px",
                    cursor: "pointer",
                    transition: "background 250ms",
                    width: "20px",
                    height: "20px",
                    backgroundColor: theme.palette.primary.main,
                    color: "#fff",

                    "&:hover": {
                      backgroundColor: theme.palette.primary.dark,
                    },

                    "& path": {
                      transform: "scale(0.6) translate(8px, 8px)",
                      fill: "#fff",
                    },
                  },
                },

                "& > p": {
                  display: "block",
                  textAlign: "center",
                  marginTop: "16px",
                },
              },
            },

            "&__frequency": {
              "& > p": {
                marginTop: "0",
              },

              "& .MuiFormControl-root": {
                width: "100%",
                maxWidth: "160px",
                marginRight: "10px",

                "&:nth-child(2)": {
                  width: "53px",
                },

                "&:nth-child(3)": {
                  width: "90px",
                  marginRight: "13px",

                  [theme.breakpoints.down(450)]: {
                    width: "160px",
                    marginTop: "20px",
                  },

                  [theme.breakpoints.down(425)]: {
                    width: "90px",
                  },

                  [theme.breakpoints.down(400)]: {
                    width: "160px",
                  },
                },
              },

              "& label.MuiFormControlLabel-root": {
                [theme.breakpoints.down(450)]: {
                  marginTop: "20px",
                },

                "& svg": {
                  width: "20px",
                  height: "20px",
                },

                "& span": {
                  fontSize: "14px",
                  lineHeight: "20px",
                },
              },
            },
          },
          "&__step_3": {
            paddingBottom: "30px",
            maxHeight: "calc(100vh - 255px)",
            overflowY: "auto",

            [theme.breakpoints.down("sm")]: {
              maxHeight: "unset",
            },

            "&::-webkit-scrollbar-track": {
              webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
              backgroundColor: "transparent",
            },
            "&::-webkit-scrollbar": {
              width: "4px",
              height: "4px",
              backgroundColor: "transparent",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#96BD92",
              border: "2px solid #96BD92",
            },

            "& > p": {
              fontWeight: "600",
              fontSize: "10px",
              lineHeight: "11px",
              letterSpacing: "1px",
              color: "#B3B3B3",

              "&:first-child": {
                marginTop: "43px",
                marginBottom: "5px",
              },

              "&:nth-child(4)": {
                fontWeight: "normal",
                fontSize: "16px",
                lineHeight: "19px",
                letterSpacing: "0.15px",
                color: "#000000",
                margin: "16px 0 26px",
              },
            },

            "& .MuiOutlinedInput-root:hover fieldset": {
              borderColor: "#96BD92",
            },

            "&__colorPicker": {
              marginTop: "5px",
              marginBottom: "38px",

              "& p": {
                fontSize: "16px",
                lineHeight: "19px",
                letterSpacing: "0.15px",
                color: "#000000",
                marginRight: "21px",

                [theme.breakpoints.down(1200)]: {
                  width: "100%",
                  margin: "15px 0",
                },
              },

              "& .MuiFormControl-root:nth-child(2)": {
                marginRight: "22px",

                "& input": {
                  width: "46px",
                  height: "46px",
                  padding: "0",
                  webkitAppearance: "none",
                  borderRadius: "4px",

                  "&::-webkit-color-swatch-wrapper": {
                    padding: "0",
                    borderRadius: "4px",
                  },
                  "&::-webkit-color-swatch": {
                    border: "none",
                    borderRadius: "4px",
                  },
                },
                "& .MuiInput-underline:before, & .MuiInput-underline:after": {
                  display: "none",
                },
              },

              "& .MuiFormControl-root:nth-child(3)": {
                width: "137px",

                "& input": {
                  padding: "11px 12px",
                },
              },
            },

            "&__position": {
              maxWidth: "528px",
              marginBottom: "36px",
              paddingRight: "50px",

              [theme.breakpoints.down(490)]: {
                paddingRight: "0",
              },

              "& > .MuiGrid-root": {
                width: "180px",
                height: "46px",
                display: "flex",
                flexWrap: "nowrap",
                alignItems: "center",
                border: "2px solid rgba(0, 0, 0, 0.08)",

                [theme.breakpoints.down(490)]: {
                  width: "50%",
                  marginRight: "0!important",
                },

                "&:first-child": {
                  marginRight: "calc(100% - 360px)",
                  marginBottom: "40px",
                  borderBottom: "none",
                  borderRight: "none",
                  borderRadius: "8px 0",

                  borderColor:
                    state.position === "top-left"
                      ? theme.palette.primary.main
                      : "rgba(0, 0, 0, 0.08)",

                  "& .MuiFormControlLabel-label": {
                    color:
                      state.position === "top-left"
                        ? theme.palette.primary.main
                        : "rgba(136, 136, 136, 0.54)",
                  },
                },

                "&:nth-child(2)": {
                  borderBottom: "none",
                  borderLeft: "none",
                  borderRadius: "0 8px",
                  marginBottom: "40px",
                  justifyContent: "flex-end",

                  borderColor:
                    state.position === "top-right"
                      ? theme.palette.primary.main
                      : "rgba(0, 0, 0, 0.08)",

                  "& .MuiFormControlLabel-label": {
                    color:
                      state.position === "top-right"
                        ? theme.palette.primary.main
                        : "rgba(136, 136, 136, 0.54)",
                  },

                  "& .MuiFormControlLabel-root": {
                    flexDirection: "row-reverse",
                  },
                },

                "&:nth-child(3)": {
                  marginRight: "calc(100% - 360px)",
                  borderTop: "none",
                  borderRight: "none",
                  borderRadius: "0 8px",

                  borderColor:
                    state.position === "bottom-left"
                      ? theme.palette.primary.main
                      : "rgba(0, 0, 0, 0.08)",

                  "& .MuiFormControlLabel-label": {
                    color:
                      state.position === "bottom-left"
                        ? theme.palette.primary.main
                        : "rgba(136, 136, 136, 0.54)",
                  },
                },

                "&:nth-child(4)": {
                  borderTop: "none",
                  borderLeft: "none",
                  borderRadius: "8px 0",
                  borderColor:
                    state.position === "bottom-right"
                      ? theme.palette.primary.main
                      : "rgba(0, 0, 0, 0.08)",
                  justifyContent: "flex-end",

                  "& .MuiFormControlLabel-label": {
                    color:
                      state.position === "bottom-right"
                        ? theme.palette.primary.main
                        : "rgba(136, 136, 136, 0.54)",
                  },

                  "& .MuiFormControlLabel-root": {
                    flexDirection: "row-reverse",
                  },
                },

                "& .MuiFormControlLabel-root": {
                  margin: "0",
                },

                "& .MuiRadio-root:not(.Mui-checked)": {
                  color: "rgba(136, 136, 136, 0.54)",
                },
              },
            },

            "& > .MuiFormControl-root:last-child": {
              width: "260px",
              marginTop: "12px",

              "& .MuiSelect-select": {
                padding: "11px 12px",
              },
            },
          },
        },
      },

      "& > .MuiGrid-root:last-child": {
        "& hr": {
          width: "100%",
        },

        "& button": {
          marginTop: "16px",
          marginBottom: "18px",
          marginRight: "20px",
          padding: "8px 22px",
          fontSize: "15px",
          lineHeight: "24px",

          "&:nth-child(2)": {
            marginLeft: "20px",
            marginRight: "auto",
          },

          "&:last-child": {
            color: "#fff",
            boxShadow: "none",
          },
        },
      },

      "& .MuiSnackbar-anchorOriginBottomCenter": {
        bottom: "90px",
        zIndex: "100",

        [theme.breakpoints.up("md")]: {
          left: "unset",
          right: "50%",
          transform: "translateX(-20px)",
        },
      },
    },
    confirmUpdateModal: {
      "& .MuiDialog-paper": {
        padding: "15px 20px 20px",
        overflow: "auto",

        "& > button:first-child": {
          top: "5px",
          right: "5px",
          position: "absolute",
        },

        "& > h6": {
          marginRight: "50px",
          marginBottom: "10px",
        },

        "& > p": {
          marginBottom: "20px",
        },

        "& > button:last-child": {
          color: "#fff",
          boxShadow: "none",
        },
      },
    },
    demo: {
      backgroundColor: "#F9F9F9",
      minHeight: "100vh",
      display: state.hasCampaign ? "flex" : "none",
      justifyContent: "center",
      alignItems: "center",
      position: "relative",

      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
  }));

  React.useEffect(() => {
    if (userRole === "viewer") {
      history.push("/");
    }
    let hasCampaign = false;
    campaignsApi.listCampaigns(userId).then((response) => {
      if (location.pathname.split("/campaigns/")[1] === "new") {
        setState({
          ...state,
          hasCampaign: true,
          campaignsList: response.data.map((campaign) => campaign.name),
          firstCampaign: response.data.length === 0,
        });
      } else {
        response.data.map((campaign) => {
          if (
            +location.pathname.match("/campaigns/(.*)/edit")[1] === campaign.id
          )
            hasCampaign = true;
          return campaign;
        });

        if (hasCampaign) {
          campaignsApi
            .fetchCampaign(location.pathname.match("/campaigns/(.*)/edit")[1])
            .then((res) => {
              const triggers = [...res.data.triggers];
              triggers.forEach((el, id) => {
                el.id = id; // eslint-disable-line
              });

              const questions = [...res.data.questions].sort(
                (a, b) => a.position - b.position
              );
              setState({
                ...state,
                hasCampaign,
                campaignsList: response.data
                  .map((campaign) => campaign.name)
                  .filter((name) => name !== res.data.name),
                campaignId: res.data.id,
                isCampaign: true,
                status: res.data.status,
                statisticFinishedCount: res.data.finished_count,
                campaign: res.data.name,
                questions,
                firstCampaign: response.data.length === 0,
                color: res.data.color,
                segment: res.data.segment,
                frequency: {
                  type: res.data.frequency.type,
                  responders: res.data.frequency.responders,
                  timeType: res.data.frequency.timeType,
                  timeValue: res.data.frequency.timeValue,
                },
                position: res.data.position,
                branding: res.data.branding,
                triggers,
              });
            });
        } else {
          history.push("/");
        }
      }
    });
  }, []); // eslint-disable-line

  const getActiveTemplate = (template) => {
    setState({ ...state, questions: [...state.questions, ...template] });
  };

  const getNewQuestions = (newQuestions, activeDemoWidget) => {
    setState({
      ...state,
      questions: newQuestions,
      demoWidget: activeDemoWidget || state.demoWidget,
    });
  };

  const getActiveWidget = (position) => {
    setState({ ...state, demoWidget: position });
  };

  const handleWidgetClose = () => {
    setState({ ...state, demoWidget: -1 });
  };

  const handleConfirmUpdateModal = () => {
    setState({ ...state, confirmUpdateModal: !state.confirmUpdateModal });
  };

  const handleCampaignChange = (event) => {
    const val = event.target.value;
    let newState = {};
    let newChanged = false;

    if (val.length === 0 && state.isCampaign) {
      newState = { ...newState, isCampaign: false };
      newChanged = true;
    }
    if (val.length > 0 && !state.isCampaign) {
      newState = { ...newState, isCampaign: true };
      newChanged = true;
    }
    if (state.campaignsList.includes(val)) {
      newState = { ...newState, isCampaignNameExist: true };
      newChanged = true;
    }
    if (
      !state.campaignsList.includes(val) &&
      state.isCampaignNameExist === true
    ) {
      newState = { ...newState, isCampaignNameExist: false };
      newChanged = true;
    }

    if (newChanged) {
      setState({ ...state, ...newState });
    }
  };

  const campaignPayload = {
    user_id: userId,
    campaignId: state.campaignId,
    name: state.campaign,
    color: state.color,
    questions: state.questions,
    segment: state.segment,
    triggers: state.triggers,
    position: state.position,
    branding: state.branding,
    frequency: {
      type: state.frequency.type,
      responders: state.frequency.responders,
      timeType: state.frequency.timeType,
      timeValue: state.frequency.timeValue,
    },
  };

  const handleSaveAsDraft = () => {
    setState({ ...state, backdrop: true });

    const data = {
      ...campaignPayload,
      status: "draft",
    };

    if (state.campaignId) {
      campaignsApi
        .updateCampaign(data)
        .then((response) => {
          setState({
            ...state,
            backdrop: false,
            alertMsg: "Draft campaign has been updated",
            questions: response.data.questions,
            status: "draft",
          });
        })
        .catch((error) => {
          console.log(error);
          setState({ ...state, backdrop: false });
        });
    } else {
      campaignsApi
        .createCampaign(data)
        .then((response) => {
          setState({
            ...state,
            campaignId: response.data.id,
            backdrop: false,
            alertMsg: "Draft campaign has been created",
            questions: response.data.questions,
            status: "draft",
          });
        })
        .catch((error) => {
          setState({ ...state, backdrop: false });
          console.log(error);
        });
    }
  };

  const handlePublish = () => {
    setState({ ...state, backdrop: true });

    const data = {
      ...campaignPayload,
      status: "live",
    };

    if (state.campaignId) {
      campaignsApi
        .updateCampaign(data)
        .then(() => {
          window.location.replace(
            company.snippetInstalled ? "/campaigns" : "/install"
          );
        })
        .catch((error) => {
          console.log(error);
          setState({ ...state, backdrop: false });
        });
    } else {
      campaignsApi
        .createCampaign(data)
        .then(() => {
          window.location.replace(
            company.snippetInstalled ? "/campaigns" : "/install"
          );
        })
        .catch((error) => {
          console.log(error);
          setState({ ...state, backdrop: false });
        });
    }
  };

  const handleStep = (direction) => {
    if (direction === "next" && state.step === 3) {
      if (location.pathname.split("/campaigns/")[1] === "new") {
        handlePublish();
      } else if (
        state.status === "draft" ||
        state.statisticFinishedCount === 0
      ) {
        const status = state.status
          ? state.status === "draft"
            ? "live"
            : state.status
          : "live";

        campaignsApi
          .updateCampaign({
            ...campaignPayload,
            status,
          })
          .then(() => {
            dispatch({
              type: "COMPANY",
              payload: {
                ...company,
                campaigns:
                  company.campaigns +
                  (state.status === "draft" && status === "live" ? 1 : 0),
              },
            });

            if (company.snippetInstalled) {
              history.push({
                pathname: "/campaigns",
                campaignsEdited: true,
              });
            } else {
              history.push("/install");
            }
          });
      } else {
        handleConfirmUpdateModal();
      }
      return;
    }

    const step = direction === "next" ? state.step + 1 : state.step - 1;
    const title =
      direction === "next"
        ? state.step === 1
          ? `Choose triggers for ${state.campaign}`
          : "Finalize look & feel"
        : state.step === 2
        ? "Create your feedback campaign"
        : `Choose triggers for ${state.campaign}`;

    setState({
      ...state,
      step,
      title,
    });
  };

  const handleRecurringTime = (event) => {
    if (
      !Number.isNaN(Number(event.target.value)) &&
      !event.target.value.includes(".")
    ) {
      setState({
        ...state,
        frequency: { ...state.frequency, timeValue: event.target.value },
        recurringTimeActive: true,
      });
    }
  };

  const handleTriggers = (id, type, value) => (event) => {
    if (
      type === "time" &&
      value === "value" &&
      (Number.isNaN(Number(event.target.value)) ||
        event.target.value.includes("."))
    )
      return;

    const updatedList = [...state.triggers];
    updatedList.find((trigger) => trigger.id === id)[type][value] =
      event.target.value;

    setState({ ...state, triggers: updatedList });
  };

  const handleBlurTriggerTime = (id) => (event) => {
    if (event.target.value === "") {
      const updatedList = [...state.triggers];
      updatedList.find((trigger) => trigger.id === id).time.value = 0;
      setState({ ...state, triggers: updatedList });
    }
  };

  const handleCopyToClipboard = (id) => (event) => {
    const input = event.target.closest(".MuiInputAdornment-positionEnd")
      .previousElementSibling;

    input.select();
    document.execCommand("copy");
    input.blur();

    if (window.getSelection) {
      if (window.getSelection().empty) {
        // Chrome
        window.getSelection().empty();
      } else if (window.getSelection().removeAllRanges) {
        // Firefox
        window.getSelection().removeAllRanges();
      }
    } else if (document.selection) {
      // IE?
      document.selection.empty();
    }

    setState({ ...state, [`copiedToClipboard-${id}`]: true });
  };

  const handleAddTrigger = () => {
    setState({
      ...state,
      triggers: [
        ...state.triggers,
        {
          id: state.triggers.length,
          time: {
            value: "10",
            type: "sec",
          },
          additional: {
            type: "pageVisit",
            rule: "contains",
            value: company.url,
            manually:
              "_prove('showForm', '25b2d230-9a2f-11ea-84dc-eda24a64b77d');",
          },
        },
      ],
    });
  };

  const handleRemoveTrigger = (id) => {
    const updatedList = [...state.triggers];

    updatedList.splice(id, 1);
    updatedList.forEach((trigger) => {
      if (trigger.id >= id) trigger.id -= 1;
    });

    setState({ ...state, triggers: updatedList });
  };

  const handleUpdateWithResetStatistics = () => {
    setState({ ...state, confirmUpdateModal: false, backdrop: true });

    campaignsApi
      .updateCampaign({
        ...campaignPayload,
        status: state.status,
      })
      .then(() => {
        campaignsApi.campaignReset(campaignPayload.campaignId).then(() => {
          if (company.snippetInstalled) {
            history.push({
              pathname: "/campaigns",
              campaignsEdited: true,
            });
          } else {
            history.push("/install");
          }
        });
      });
  };

  return (
    <Grid container style={{ position: "relative" }}>
      {/* Stepper */}
      <Grid item className={useStyles().stepper}>
        <Stepper activeStep={state.step} nonLinear alternativeLabel>
          <Step>
            <StepLabel>ADD QUESTIONS</StepLabel>
          </Step>
          <Step>
            <StepLabel>CHOOSE TRIGGERS</StepLabel>
          </Step>
          <Step>
            <StepLabel>
              LOOK &
              <br />
              FEEL
            </StepLabel>
          </Step>
        </Stepper>
      </Grid>

      {/* Form */}
      <Grid item xs={12} md={6} className={useStyles().form}>
        <form>
          <Logo />

          {state.step !== 1 && (
            <Button onClick={() => handleStep("prev")}>
              <ChevronLeft />
              Back
            </Button>
          )}
          <Typography variant="h5">{state.title}</Typography>

          {/* Form - 1 step */}
          {state.step === 1 && (
            <Grid className="CampaignEdit__step_1">
              <TextField
                error={state.isCampaignNameExist}
                type="campaign"
                label="Name your survey..."
                onChange={handleCampaignChange}
                key={state.campaign}
                defaultValue={state.campaign}
                onBlur={(event) =>
                  setState({ ...state, campaign: event.target.value })}
                onKeyDown={(event) => {
                  if (event.key === "Enter") event.target.blur();
                }}
                helperText={
                  state.isCampaignNameExist
                    ? "This company name is already exist"
                    : ""
                }
              />
              <Grid
                className="CampaignEdit__step_1__header"
                container
                justify="space-between"
                alignItems="center"
              >
                <Grid container alignItems="center">
                  <Typography variant="h6">Add Questions:</Typography>
                  <Tooltip
                    title="Adding feedback questions to a campaign will display them to the user to answer."
                    interactive
                    arrow
                    enterTouchDelay={0}
                  >
                    <ErrorOutlineOutlined />
                  </Tooltip>
                </Grid>
                <ChooseTemplate getActiveTemplate={getActiveTemplate} />
              </Grid>
              <Grid className="CampaignEdit__step_1__list">
                <QuestionsList
                  questions={state.questions}
                  getNewQuestions={getNewQuestions}
                  getActiveWidget={getActiveWidget}
                  demoWidget={state.demoWidget}
                />
              </Grid>
            </Grid>
          )}

          {/* Form - 2 step */}
          {state.step === 2 && (
            <Grid className="CampaignEdit__step_2">
              {/* TODO: Segments - this functionality will be later
              <Typography>
                AUDIENCE
                <Tooltip title="Some tooltip" arrow enterTouchDelay={0}>
                  <ErrorOutline />
                </Tooltip>
              </Typography>
              <TextField
                select
                label="Choose Segment..."
                variant="outlined"
                value={state.segment}
                onChange={(event) =>
                  setState({ ...state, segment: event.target.value })
                }
                SelectProps={{
                  MenuProps: { disablePortal: true, disableScrollLock: true },
                }}
              >
                <MenuItem value="allUsers">All Users</MenuItem>
                <MenuItem value="savedSegment">Saved Segment</MenuItem>
              </TextField> */}
              <Typography>
                TRIGGERS
                <Tooltip
                  title="Campaign triggers are events that will trigger a campaign and display its questions to the user."
                  interactive
                  arrow
                  enterTouchDelay={0}
                >
                  <ErrorOutline />
                </Tooltip>
              </Typography>
              <Grid className="CampaignEdit__step_2__triggers">
                {state.triggers.map((trigger, id) => (
                  <Grid
                    className="CampaignEdit__step_2__triggers__item"
                    key={trigger.id}
                    container
                    alignItems="center"
                  >
                    <Grid>
                      <Grid
                        container
                        className="CampaignEdit__step_2__triggers__item__time"
                      >
                        <Typography>Time on page: </Typography>
                        <TextField
                          variant="outlined"
                          value={trigger.time.value}
                          onChange={handleTriggers(trigger.id, "time", "value")}
                          onClick={(event) => event.target.select()}
                          onBlur={handleBlurTriggerTime(trigger.id)}
                        />
                        <TextField
                          select
                          variant="outlined"
                          value={trigger.time.type}
                          onChange={handleTriggers(trigger.id, "time", "type")}
                          SelectProps={{
                            MenuProps: {
                              disablePortal: true,
                              disableScrollLock: true,
                            },
                          }}
                        >
                          <MenuItem value="sec">Sec</MenuItem>
                          <MenuItem value="min">Min</MenuItem>
                        </TextField>
                      </Grid>
                      <Grid className="CampaignEdit__step_2__triggers__item__additional">
                        {/* TODO: Manually - Will be later */}
                        {false ? (
                          <TextField
                            select
                            variant="outlined"
                            value={trigger.additional.type}
                            onChange={handleTriggers(
                              trigger.id,
                              "additional",
                              "type"
                            )}
                            SelectProps={{
                              MenuProps: {
                                disablePortal: true,
                                disableScrollLock: true,
                              },
                            }}
                          >
                            <MenuItem value="pageVisit">Page Visit</MenuItem>
                            <MenuItem value="manually">Manually</MenuItem>
                          </TextField>
                        ) : (
                          <Typography>Page Visit:</Typography>
                        )}
                        {trigger.additional.type === "pageVisit" && (
                          <>
                            <TextField
                              select
                              variant="outlined"
                              value={trigger.additional.rule}
                              onChange={handleTriggers(
                                trigger.id,
                                "additional",
                                "rule"
                              )}
                              SelectProps={{
                                MenuProps: {
                                  disablePortal: true,
                                  disableScrollLock: true,
                                },
                              }}
                            >
                              <MenuItem value="equals">Equals</MenuItem>
                              <MenuItem value="contains">Contains</MenuItem>
                            </TextField>
                            <TextField
                              value={trigger.additional.value}
                              placeholder="www.prove.com"
                              onChange={handleTriggers(
                                trigger.id,
                                "additional",
                                "value"
                              )}
                              error={!trigger.additional.value}
                              variant="outlined"
                            />
                          </>
                        )}
                        {trigger.additional.type === "manually" && (
                          <FormControl
                            className="CampaignEdit__step_2__triggers__item__additional__manually"
                            variant="outlined"
                          >
                            <OutlinedInput
                              value={trigger.additional.manually}
                              readOnly
                              endAdornment={
                                <InputAdornment
                                  position="end"
                                  onClick={handleCopyToClipboard(trigger.id)}
                                >
                                  <IconButton>
                                    <FileCopyOutlined />
                                  </IconButton>
                                </InputAdornment>
                              }
                            />
                            {state[`copiedToClipboard-${trigger.id}`] && (
                              <ClickAwayListener
                                onClickAway={() =>
                                  setState({
                                    ...state,
                                    [`copiedToClipboard-${trigger.id}`]: false,
                                  })}
                              >
                                <Grid>
                                  <AlertMessage
                                    type="success"
                                    message="Code copied to clipboard"
                                  />
                                </Grid>
                              </ClickAwayListener>
                            )}
                          </FormControl>
                        )}
                      </Grid>
                    </Grid>
                    {state.triggers.length - 1 !== 0 && (
                      <IconButton
                        onClick={() => handleRemoveTrigger(trigger.id)}
                      >
                        <DeleteOutlineOutlined />
                      </IconButton>
                    )}

                    {state.triggers.length - 1 !== id && <Divider />}
                  </Grid>
                ))}
              </Grid>
              <Grid className="CampaignEdit__step_2__triggers__add">
                <Divider />
                <IconButton color="primary" onClick={handleAddTrigger}>
                  <Add />
                </IconButton>
                <Typography>ADD CONDITION</Typography>
              </Grid>
              <Grid className="CampaignEdit__step_2__frequency">
                <Typography>
                  FREQUENCY
                  <Tooltip
                    title="Campaign frequency controls when a campaign is displayed to a user and if that campaign is shown to responders again."
                    interactive
                    arrow
                    enterTouchDelay={0}
                  >
                    <ErrorOutline />
                  </Tooltip>
                </Typography>
                <Grid container>
                  <TextField
                    select
                    variant="outlined"
                    value={state.frequency.type}
                    onChange={(event) =>
                      setState({
                        ...state,
                        frequency: {
                          ...state.frequency,
                          type: event.target.value,
                        },
                      })
                    }
                    SelectProps={{
                      MenuProps: {
                        disablePortal: true,
                        disableScrollLock: true,
                      },
                    }}
                  >
                    <MenuItem value="showOnce">Show Once</MenuItem>
                    <MenuItem value="recurring">Recurring</MenuItem>
                  </TextField>
                  {state.frequency.type === "recurring" && (
                    <>
                      <TextField
                        variant="outlined"
                        value={state.frequency.timeValue}
                        onChange={handleRecurringTime}
                        onBlur={(event) => {
                          if (event.target.value === "")
                            setState({
                              ...state,
                              frequency: {
                                ...state.frequency,
                                timeValue: event.target.value,
                              },
                            });
                        }}
                        onFocus={() =>
                          !state.recurringTimeActive
                            ? setState({
                                ...state,
                                frequency: {
                                  ...state.frequency,
                                  timeValue: "",
                                },
                              })
                            : null
                        }
                      />
                      <TextField
                        select
                        variant="outlined"
                        value={state.frequency.timeType}
                        onChange={(event) =>
                          setState({
                            ...state,
                            frequency: {
                              ...state.frequency,
                              timeType: event.target.value,
                            },
                          })
                        }
                        SelectProps={{
                          MenuProps: {
                            disablePortal: true,
                            disableScrollLock: true,
                          },
                        }}
                      >
                        <MenuItem value="days">Days</MenuItem>
                        <MenuItem value="weeks">Weeks</MenuItem>
                      </TextField>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={state.frequency.responders}
                            onChange={() =>
                              setState({
                                ...state,
                                frequency: {
                                  ...state.frequency,
                                  responders: !state.frequency.responders,
                                },
                              })}
                          />
                        }
                        label="Include Responders"
                      />
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          )}

          {/* Form - 3 step */}
          {state.step === 3 && (
            <Grid className="CampaignEdit__step_3">
              <Typography>PRIMARY COLOR</Typography>
              <Grid
                container
                alignItems="center"
                className="CampaignEdit__step_3__colorPicker"
              >
                <Typography>Choose your primary brand color:</Typography>
                <TextField
                  type="color"
                  defaultValue={state.color}
                  onBlur={(event) =>
                    setState({ ...state, color: event.target.value })
                  }
                />
                <TextField value={state.color} variant="outlined" />
              </Grid>
              <Typography>POSITION</Typography>
              <Typography>Where would you like this widget placed?</Typography>
              <Grid container className="CampaignEdit__step_3__position">
                <Grid item>
                  <FormControlLabel
                    control={(
                      <Radio
                        color="primary"
                        checked={state.position === "top-left"}
                        onChange={() =>
                          setState({ ...state, position: "top-left" })
                        }
                      />
                    )}
                    label="Top Left"
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Radio
                        color="primary"
                        checked={state.position === "top-right"}
                        onChange={() =>
                          setState({ ...state, position: "top-right" })}
                      />
                    }
                    label="Top Right"
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Radio
                        color="primary"
                        checked={state.position === "bottom-left"}
                        onChange={() =>
                          setState({ ...state, position: "bottom-left" })}
                      />
                    }
                    label="Bottom Left"
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Radio
                        color="primary"
                        checked={state.position === "bottom-right"}
                        onChange={() =>
                          setState({ ...state, position: "bottom-right" })}
                      />
                    }
                    label="Bottom Right"
                  />
                </Grid>
              </Grid>
              <Typography>BRANDING</Typography>
              <TextField
                select
                variant="outlined"
                value={state.branding}
                onChange={(event) =>
                  setState({
                    ...state,
                    branding: event.target.value,
                    alertMsg:
                      !event.target.value && company.plan.type === "freemium"
                        ? "You can`t remove branding in freemium plan."
                        : false,
                  })}
                SelectProps={{
                  MenuProps: { disablePortal: true, disableScrollLock: true },
                }}
              >
                <MenuItem value>Keep Branding</MenuItem>
                <MenuItem value={false}>Without Branding</MenuItem>
              </TextField>
            </Grid>
          )}
        </form>
        {state.alertMsg && (
          <ClickAwayListener
            onClickAway={() =>
              setState({
                ...state,
                alertMsg: false,
              })
            }
          >
            <Grid>
              <AlertMessage
                type={`${
                  state.alertMsg ===
                  "You can`t remove branding in freemium plan."
                    ? "error"
                    : "success"
                }`}
                upgradePlanMsg={
                  state.alertMsg ===
                  "You can`t remove branding in freemium plan."
                }
                message={state.alertMsg}
              />
            </Grid>
          </ClickAwayListener>
        )}
        <Grid container justify="flex-end">
          <Divider />
          <Button
            variant="outlined"
            onClick={() =>
              history.push(state.firstCampaign ? "/dashboard" : "/campaigns")}
          >
            {state.firstCampaign ? "Skip To Dashboard" : "Cancel"}
          </Button>

          {!state.isCampaign || !state.questions.length ? (
            <>
              {(location.pathname.split("/campaigns/")[1] === "new" ||
                state.status === "draft") && (
                <Tooltip
                  title="You need to add survey name and at least one question"
                  arrow
                  enterTouchDelay={0}
                  interactive
                >
                  <Typography>
                    <Button variant="contained" disabled>
                      Save As Draft
                    </Button>
                  </Typography>
                </Tooltip>
              )}
              <Tooltip
                title="You need to add survey name and at least one question"
                arrow
                enterTouchDelay={0}
                interactive
              >
                <Typography>
                  <Button variant="contained" color="primary" disabled>
                    Continue
                  </Button>
                </Typography>
              </Tooltip>
            </>
          ) : !state.segment && state.step === 2 ? (
            <>
              {(location.pathname.split("/campaigns/")[1] === "new" ||
                state.status === "draft") && (
                <Button variant="outlined" onClick={handleSaveAsDraft}>
                  Save As Draft
                </Button>
              )}
              <Tooltip
                title="You need to choose Segment"
                enterTouchDelay={0}
                arrow
              >
                <Typography>
                  <Button variant="contained" color="primary" disabled>
                    Continue
                  </Button>
                </Typography>
              </Tooltip>
            </>
          ) : state.step === 1 &&
            state.questions
              .map((survey) => {
                if (
                  survey.type === "multipleChoice" &&
                  survey.options.optionsList.length === 0
                )
                  return false;
                return true;
              })
              .includes(false) ? (
                <>
              {(location.pathname.split("/campaigns/")[1] === "new" ||
                state.status === "draft") && (
                <Button variant="outlined" onClick={handleSaveAsDraft}>
                  Save As Draft
                </Button>
              )}
              <Tooltip
                    title="You need to add at least 1 Multiple Choice option"
                    enterTouchDelay={0}
                    arrow
              >
                    <Typography>
                  <Button variant="contained" color="primary" disabled>
                        Continue
                  </Button>
                </Typography>
                  </Tooltip>
            </>
          ) : state.step === 1 &&
            state.questions
              .map((survey) => {
                if (survey.type === "cta" && survey.options.linkUrl === "")
                  return false;
                return true;
              })
              .includes(false) ? (
                <>
              {(location.pathname.split("/campaigns/")[1] === "new" ||
                state.status === "draft") && (
                <Button variant="outlined" onClick={handleSaveAsDraft}>
                  Save As Draft
                </Button>
              )}
              <Tooltip
                    title="You need to add link url for CTA type"
                    enterTouchDelay={0}
                    arrow
              >
                    <Typography>
                  <Button variant="contained" color="primary" disabled>
                        Continue
                  </Button>
                </Typography>
                  </Tooltip>
            </>
          ) : (
            <>
              {(location.pathname.split("/campaigns/")[1] === "new" ||
                state.status === "draft") && (
                <Button
                  variant="outlined"
                  onClick={handleSaveAsDraft}
                  disabled={
                    (state.step === 1 && state.isCampaignNameExist) ||
                    (state.step === 2 &&
                      !state.triggers.every((trigger) => {
                        if (trigger.additional.type === "pageVisit") {
                          return trigger.additional.value;
                        }
                        return true;
                      }))
                  }
                >
                  Save As Draft
                </Button>
              )}
              {state.step === 3 &&
              !state.branding &&
              company.plan.type === "freemium" ? (
                <Tooltip
                  title="You can`t remove branding in freemium plan"
                  enterTouchDelay={0}
                  arrow
                >
                  <Typography>
                    <Button variant="contained" color="primary" disabled>
                      {location.pathname.split("/campaigns/")[1] !== "new" &&
                      state.status !== "draft" &&
                      state.step === 3
                        ? "Update"
                        : "Continue"}
                    </Button>
                  </Typography>
                </Tooltip>
              ) : state.step === 1 &&
                state.questions
                  .map((survey) => {
                    if (
                      survey.type === "multipleChoice" &&
                      survey.options.optionsList.length !==
                        new Set(
                          survey.options.optionsList.map((el) => el.value)
                        ).size
                    )
                      return false;
                    return true;
                  })
                  .includes(false) ? (
                    <>
                  <Tooltip
                        title="The value for the Multiple Choose option should be unique"
                        enterTouchDelay={0}
                        arrow
                  >
                        <Typography>
                      <Button variant="contained" color="primary" disabled>
                            Continue
                      </Button>
                    </Typography>
                      </Tooltip>
                </>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleStep("next")}
                  disabled={
                    (state.step === 1 && state.isCampaignNameExist) ||
                    (state.step === 2 &&
                      !state.triggers.every((trigger) => {
                        if (trigger.additional.type === "pageVisit") {
                          return trigger.additional.value;
                        }
                        return true;
                      }))
                  }
                >
                  {location.pathname.split("/campaigns/")[1] !== "new" &&
                  state.status !== "draft" &&
                  state.step === 3
                    ? "Update"
                    : "Continue"}
                </Button>
              )}
            </>
          )}
        </Grid>
      </Grid>
      <Dialog
        open={state.confirmUpdateModal}
        onClose={handleConfirmUpdateModal}
        className={useStyles().confirmUpdateModal}
      >
        <IconButton onClick={handleConfirmUpdateModal}>
          <Close />
        </IconButton>
        <Typography variant="h6">Update Campaign</Typography>
        <Typography>
          After updating the company, all statistics will be lost.
          <br />
          Would you like to continue?
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={handleUpdateWithResetStatistics}
        >
          Update
        </Button>
      </Dialog>

      {/* Demo */}
      <Grid item xs={6} className={useStyles().demo}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="210"
          height="327"
          viewBox="0 0 210 327"
          fill="none"
        >
          <rect
            y="72"
            width="210"
            height="210"
            fill="black"
            fillOpacity="0.04"
          />
          <rect
            y="315"
            width="210"
            height="12"
            rx="4"
            fill="black"
            fillOpacity="0.04"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M105 40C116.046 40 125 31.0457 125 20C125 8.9543 116.046 0 105 0C93.9543 0 85 8.9543 85 20C85 31.0457 93.9543 40 105 40Z"
            fill="black"
            fillOpacity="0.04"
          />
        </svg>
        {state.questions[state.demoWidget] && (
          <Widget
            position={state.position}
            question={state.questions[state.demoWidget]}
            widgetClose={handleWidgetClose}
            color={state.color}
            branding={state.branding}
          />
        )}
      </Grid>
      <Backdrop
        open={state.backdrop || !state.hasCampaign}
        style={{
          zIndex: 1,
          backgroundColor: state.hasCampaign ? "rgba(0, 0, 0, 0.5)" : "#fff",
        }}
      >
        <CircularProgress color="primary" />
      </Backdrop>
    </Grid>
  );
};

export default CampaignEdit;
