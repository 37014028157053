import React from "react";
import { makeStyles, Backdrop, CircularProgress } from "@material-ui/core";
import { useHistory } from "react-router-dom";

import authApi from "../api/auth-api";
import campaignsApi from "../api/campaigns-api";

const GoogleReturnPage = () => {
  const history = useHistory();

  const useStyles = makeStyles(() => ({
    root: {
      backgroundColor: "#fff",
    },
  }));

  React.useEffect(() => {
    const code = new URL(window.location.href).searchParams.get("code");

    authApi
      .authenticateWithGoogle(code)
      .then((res) => {
        if (res.profileNotCompleted) {
          history.push({
            pathname: "/",
            profileNotCompleted: true,
            userId: res.userId,
          });
        } else if (res.response && res.response.status === 422) {
          history.push({
            pathname: "/",
            userDeactivated: true,
            userId: res.userId,
          });
        } else {
          authApi.me().then((response) => {
            if (response.data && response.data.id) {
              if (
                response.data.company.snipped_installed ||
                response.data.role === "viewer"
              ) {
                window.location.replace("/");
              } else {
                campaignsApi
                  .listCampaigns(response.data.id)
                  .then((campaigns) => {
                    if (
                      campaigns.data.some((survey) => survey.status === "live")
                    ) {
                      window.location.replace("/install");
                    } else {
                      window.location.replace("/campaigns/new");
                    }
                  });
              }
            } else {
              authApi.logout();
            }
          });
        }
      })
      .catch(() => {
        authApi.me().then((response) => {
          if (response.data && response.data.id) {
            if (
              response.data.company.snipped_installed ||
              response.data.role === "viewer"
            ) {
              window.location.replace("/");
            } else {
              campaignsApi.listCampaigns(response.data.id).then((campaigns) => {
                if (campaigns.data.some((survey) => survey.status === "live")) {
                  window.location.replace("/install");
                } else {
                  window.location.replace("/campaigns/new");
                }
              });
            }
          } else {
            authApi.logout();
          }
        });
      });
  });

  return (
    <Backdrop open className={useStyles().root}>
      <CircularProgress color="primary" />
    </Backdrop>
  );
};

export default GoogleReturnPage;
