import React from "react";
import {
  makeStyles,
  Button,
  List,
  ListItem,
  Grid,
  Typography,
  SwipeableDrawer,
  IconButton,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { EmojiObjects, Menu as MenuIcon } from "@material-ui/icons";
import { useSelector } from "react-redux";

import ChoosePaymentPlan from "./ChoosePaymentPlan";

const Menu = (props) => {
  const { activePage } = props;

  const [state, setState] = React.useState({
    open: false,
    mobileView: window.innerWidth < 960,
  });

  const userRole = useSelector((store) => store.user.role);
  const company = useSelector((store) => store.company);

  const useStyles = makeStyles((theme) => ({
    root: {
      position: state.mobileView ? "static" : "sticky",
      left: "0",
      top: "0",
      minHeight: "100vh",
      width: state.mobileView ? "145px" : "120px",
      padding: "30px 0 17px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",

      [theme.breakpoints.down("sm")]: {
        overflowY: "auto",
      },

      "&::-webkit-scrollbar-track": {
        webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        backgroundColor: "transparent",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        height: "4px",
        backgroundColor: "transparent",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#96BD92",
        border: "2px solid #96BD92",
      },

      "& .Menu__main": {
        "& > a:first-child": {
          display: "block",
          margin: "auto",
          width: "28px",

          "& svg": {
            display: "block",
            marginBottom: "35px",
          },
        },

        "& > a, & >.MuiGrid-root": {
          "&:nth-child(2) button": {
            color: "#fff",
            display: "inline-flex",
            borderRadius: "24px",
            fontWeight: "500",
            fontSize: "15px",
            lineHeight: "26px",
            letterSpacing: "0.3px",
            width: "124px",
            height: "36px",
            position: "relative",
            left: "8px",
            boxShadow: "none",
            backgroundColor: theme.palette.primary.main,
            textDecoration: "none",
            transition:
              "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",

            "&:hover": {
              boxShadow: "none",
              backgroundColor: "#6D9D68",
            },
          },
        },

        "& > ul": {
          marginTop: "45px",
          padding: "0",

          "& > li": {
            margin: "34px 0",
            padding: "0",

            "&:hover": {
              "&.Menu__active": {
                color: theme.palette.primary.dark,
                borderColor: theme.palette.primary.dark,

                "& a": {
                  color: theme.palette.primary.dark,
                },
              },

              "& a": {
                color: theme.palette.primary.main,
              },
            },

            "&.Menu__active": {
              color: theme.palette.primary.main,
              borderLeft: `4px solid ${theme.palette.primary.main}`,
              transition: "border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",

              "& a": {
                fontWeight: "600",
                lineHeight: "20px",
                letterSpacing: "0.15px",
                paddingLeft: "14px",
              },
            },

            "& a, & p:first-child": {
              fontWeight: "500",
              fontSize: "14px",
              lineHeight: "21px",
              letterSpacing: "0.1px",
              color: "inherit",
              padding: "0 18px",
              width: "100%",
              transition: "color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
            },

            "& p:first-child": {
              color: "rgba(136, 136, 136, 0.54)",
            },

            "& p:last-child": {
              fontWeight: "600",
              fontSize: "12px",
              lineHeight: "22px",
              letterSpacing: "0.4px",
              background: "rgba(0, 0, 0, 0.12)",
              borderRadius: "16px",
              color: "#FFFFFF",
              position: "absolute",
              right: "-12px",
              top: "20px",
              padding: "0 8px",

              [theme.breakpoints.down("sm")]: {
                right: "5px",
              },
            },
          },
        },
      },

      "& .Menu__upgrade": {
        background: "rgba(150, 189, 146, 0.17)",
        borderRadius: "4px",
        width: "84px",
        margin: "0 auto",
        position: "relative",
        padding: "18px 0 9px",

        "&__icon": {
          margin: "auto",
          position: "absolute",
          top: "-20px",
          left: "50%",
          transform: "translateX(-50%)",
          background: "#fff",
          border: "4px solid rgba(150, 189, 146, 0.17)",
          borderRadius: "50%",
          padding: "2px 3px 0px",

          "& svg": {
            fill: "rgba(150, 189, 146, 0.47)",
          },
        },

        "& > p": {
          fontSize: "12px",
          lineHeight: "16px",
          letterSpacing: "0.15px",
          color: "rgba(0, 0, 0, 0.38)",
          textAlign: "center",
          marginBottom: "5px",
        },

        "& > a": {
          fontWeight: "600",
          fontSize: "12px",
          lineHeight: "16px",
          letterSpacing: "0.15px",
          textDecorationLine: "underline",
          color: theme.palette.primary.main,
          display: "block",
          textAlign: "center",
          transition: "color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",

          "&:hover": {
            color: theme.palette.primary.dark,
          },
        },
      },
    },
    hamburgerMenu: {
      position: "fixed",
      zIndex: "3",
      top: "11px",
      left: "35px",

      [theme.breakpoints.down("sm")]: {
        left: "5px",
      },
    },
  }));

  const style = useStyles();

  const updateWidthOnResize = () => {
    setState({ ...state, mobileView: window.innerWidth < 960 });
  };

  React.useEffect(() => {
    window.addEventListener("resize", updateWidthOnResize);
    return () => window.removeEventListener("resize", updateWidthOnResize);
  }, []); // eslint-disable-line

  const MenuContent = () => (
    <Grid className={style.root}>
      <Grid className="Menu__main">
        <Link to="/">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="34"
            viewBox="0 0 28 34"
            fill="none"
          >
            <path
              d="M5.64402 0.577786H2.57315C2.37368 0.577786 2.33164 0.859128 2.5224 0.917431C4.1925 1.42787 5.3794 2.90927 5.51334 4.65049L5.64402 6.34933V0.577786Z"
              fill="black"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.73298 3.86674C9.73298 2.0503 8.26047 0.577786 6.44403 0.577786H5.64402V6.34933V25.3973H9.82187V18.4892H9.95521C10.7256 19.6744 11.7775 20.593 13.1108 21.2448C14.4442 21.8967 15.8664 22.2226 17.3776 22.2226C19.0072 22.2226 20.4739 21.9412 21.7777 21.3782C23.0814 20.7856 24.1925 19.9856 25.111 18.9781C26.0296 17.9707 26.7407 16.7855 27.2444 15.4225C27.7481 14.0595 28 12.6076 28 11.0669C28 9.5261 27.7481 8.08904 27.2444 6.75568C26.7407 5.39269 26.0148 4.20749 25.0666 3.20006C24.1481 2.19263 23.0369 1.40743 21.7332 0.844459C20.4591 0.281486 19.0369 0 17.4665 0C15.7183 0 14.1775 0.370378 12.8442 1.11113C11.5404 1.82226 10.5478 2.74079 9.86632 3.86674H9.73298ZM23.2443 8.22238C23.5407 9.14091 23.6888 10.0891 23.6888 11.0669C23.6888 12.0447 23.5407 12.9928 23.2443 13.9114C22.948 14.8299 22.5036 15.6447 21.911 16.3559C21.3184 17.067 20.5776 17.6448 19.6887 18.0892C18.8295 18.5041 17.822 18.7115 16.6664 18.7115C15.5998 18.7115 14.622 18.5041 13.7331 18.0892C12.8738 17.6744 12.133 17.1262 11.5108 16.4448C10.8886 15.7336 10.3997 14.9188 10.0441 14.0003C9.71817 13.0817 9.5552 12.1187 9.5552 11.1113C9.5552 10.1039 9.71817 9.14091 10.0441 8.22238C10.3997 7.30384 10.8886 6.48901 11.5108 5.77789C12.133 5.06676 12.8738 4.50379 13.7331 4.08896C14.622 3.67414 15.5998 3.46673 16.6664 3.46673C17.822 3.46673 18.8295 3.67414 19.6887 4.08896C20.5776 4.50379 21.3184 5.06676 21.911 5.77789C22.5036 6.48901 22.948 7.30384 23.2443 8.22238Z"
              fill="black"
            />
            <path
              d="M9.82187 25.3973H5.64402L5.67135 25.4246C6.82065 26.5739 8.6878 26.5616 9.82187 25.3973Z"
              fill="black"
            />
            <path
              d="M15.5205 26.1028L15.5205 30.0128L14.4011 30.8489C12.3556 32.3768 10.0837 33.1464 7.81019 33.1575C5.50333 33.1687 3.19478 32.3992 1.11934 30.8489L-6.69281e-05 30.0128L-6.67572e-05 26.1028C2.29304 28.4745 5.05303 29.6531 7.81019 29.6387C10.534 29.6244 13.2551 28.4458 15.5205 26.1028Z"
              fill="black"
            />
          </svg>
        </Link>
        {userRole !== "viewer" && (
          <Link to="/campaigns/new">
            <Button variant="contained" color="primary">
              + Campaign
            </Button>
          </Link>
        )}
        <List>
          <ListItem
            className={activePage === "Dashboard" ? "Menu__active" : ""}
          >
            <Link to="/dashboard">Dashboard</Link>
          </ListItem>
          <ListItem
            className={activePage === "Campaigns" ? "Menu__active" : ""}
          >
            <Link to="/campaigns">Campaigns</Link>
          </ListItem>
          <ListItem className={activePage === "Team" ? "Menu__active" : ""}>
            <Link to="/settings/manage-users">Team</Link>
          </ListItem>
          <ListItem>
            <Typography>Segments</Typography>
            <Typography>Coming soon...</Typography>
          </ListItem>
          <ListItem>
            <Typography>Integrations</Typography>
            <Typography>Coming soon...</Typography>
          </ListItem>
        </List>
      </Grid>
      {company.plan.type === "freemium" && (
        <Grid className="Menu__upgrade">
          <Grid className="Menu__upgrade__icon">
            <EmojiObjects />
          </Grid>
          <Typography>This is a free account.</Typography>
          <ChoosePaymentPlan btnText="Upgrade" />
        </Grid>
      )}
    </Grid>
  );

  return (
    <aside>
      {state.mobileView ? (
        <>
          <IconButton
            className={style.hamburgerMenu}
            onClick={() => setState({ ...state, open: true })}
          >
            <MenuIcon />
          </IconButton>
          <SwipeableDrawer
            open={state.open}
            onClose={() => setState({ ...state, open: false })}
            onOpen={() => null}
          >
            <MenuContent />
          </SwipeableDrawer>
        </>
      ) : (
        <MenuContent />
      )}
    </aside>
  );
};

Menu.propTypes = {
  activePage: PropTypes.string.isRequired,
};

export default Menu;
