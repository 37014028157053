import React from "react";
import { makeStyles, Grid, Typography } from "@material-ui/core";
import InstallSnippet from "./InstallSnippet";

const Installation = () => {
  const useStyles = makeStyles((theme) => ({
    root: {
      padding: "36px 88px 40px 42px",
      width: "calc(100% - 228px)",
      maxWidth: "770px",

      [theme.breakpoints.down("sm")]: {
        width: "calc(100% - 63px)",
        padding: "36px 20px 40px",
      },

      "& > p": {
        "&:nth-child(2)": {
          letterSpacing: "0.15px",
          color: "rgba(136, 136, 136, 0.54)",
          marginBottom: "20px",
        },
      },

      "& div.InstallSnippet, & div.MuiTabs-root": {
        [theme.breakpoints.down("xs")]: {
          marginLeft: "0",
          width: "100%",
        },

        "&__copy p": {
          [theme.breakpoints.down("xs")]: {
            display: "none",
          },
        },
      },
    },
  }));

  return (
    <Grid className={useStyles().root}>
      <Typography variant="body1">Installation</Typography>
      <Typography variant="body2">
        Choose which way you’d like to install Prove on your website or
        application
      </Typography>

      <InstallSnippet />
    </Grid>
  );
};

export default Installation;
