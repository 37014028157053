import React from "react";
import {
  makeStyles,
  Typography,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";
import PropTypes from "prop-types";

const FormSelector = (props) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      marginBottom: "30px",

      "&:nth-child(3)": {
        marginBottom: "11px",
      },

      "& .MuiSelect-selectMenu": {
        padding: "11px 12px",
        fontSize: "15px",

        [theme.breakpoints.down("xs")]: {
          fontSize: "11px",
        },

        "& span": {
          color: "rgba(136, 136, 136, 0.54)",
        },
      },

      "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.primary.main,
      },

      "& > p": {
        fontWeight: "600",
        marginBottom: "7px",
      },
    },
    popUp: {
      "& li": {
        [theme.breakpoints.down("xs")]: {
          fontSize: "11px",
          minHeight: "40px",
        },
      },
    },
  }));

  const { title, list, handleInput, type } = props;

  const [value, setValue] = React.useState("");

  const handleChange = (event) => {
    setValue(event.target.value);
    handleInput(type, event.target.value);
  };

  return (
    <FormControl variant="outlined" className={useStyles().root}>
      <Typography variant="body2">{title}</Typography>
      <Select
        onChange={handleChange}
        value={value}
        displayEmpty
        MenuProps={{ className: useStyles().popUp }}
        required
        inputProps={{ "aria-label": "Without label" }}
        renderValue={(selected) => {
          if (selected.length === 0) {
            return <span>Placeholder</span>;
          }
          return selected;
        }}
      >
        {list.map((el) => (
          <MenuItem value={el} key={el}>
            {el}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

FormSelector.propTypes = {
  title: PropTypes.string.isRequired,
  list: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleInput: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

export default FormSelector;
