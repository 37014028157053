import React from "react";
import { Backdrop, CircularProgress } from "@material-ui/core";

const Spinner = () => {
  return (
    <Backdrop
      open
      style={{
        backgroundColor: "#fff",
      }}
    >
      <CircularProgress color="primary" />
    </Backdrop>
  );
};

export default Spinner;
