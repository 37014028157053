import React from "react";
import { makeStyles, Snackbar, Grid } from "@material-ui/core";
import {
  ErrorOutline,
  InfoOutlined,
  CheckCircleOutline,
} from "@material-ui/icons/";
import PropTypes from "prop-types";

import ChoosePaymentPlan from "./ChoosePaymentPlan";

const AlertMessage = (props) => {
  const { message, type, upgradePlanMsg } = props;

  let color;
  let icon;

  switch (type) {
    case "error":
      color = "#F44336";
      icon = <ErrorOutline />;
      break;
    case "info":
      color = "#2196f3";
      icon = <InfoOutlined />;
      break;
    case "success":
      color = "#96BD92";
      icon = <CheckCircleOutline />;
      break;

    default:
      color = "#2196f3";
      icon = <InfoOutlined />;
      break;
  }

  const useStyles = makeStyles({
    root: {
      backgroundColor: color,
      color: "#fff",
      padding: "6px 16px",
      borderRadius: "4px",

      "& svg": {
        marginRight: "12px",
      },

      "& > .MuiGrid-root": {
        "& > .MuiGrid-root": {
          display: "inline-block",
        },

        "& span": {
          marginRight: upgradePlanMsg ? "2px" : "0",
        },

        "& span, & > .MuiGrid-root > button": {
          fontSize: "14px",
          lineHeight: "20px",
          fontWeight: "500",
        },

        "& > .MuiGrid-root > button, & > .MuiGrid-root > button:hover": {
          color: "#fff",
          padding: "0",
        },
      },
    },
  });

  return (
    <Snackbar open key="up" className={useStyles().root}>
      <>
        {icon}
        <Grid>
          <span>{message}</span>
          {upgradePlanMsg && <ChoosePaymentPlan btnText="Upgrade" />}
        </Grid>
      </>
    </Snackbar>
  );
};

AlertMessage.propTypes = {
  message: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  upgradePlanMsg: PropTypes.bool,
};

AlertMessage.defaultProps = {
  upgradePlanMsg: false,
};

export default AlertMessage;
