import React from "react";
import {
  makeStyles,
  TextField,
  Grid,
  Typography,
  Button,
  Divider,
  Tabs,
  Tab,
  MenuItem,
  IconButton,
  Backdrop,
  CircularProgress,
  Dialog,
  Menu as MaterialMenu,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableSortLabel,
  TableCell,
  FormControlLabel,
  Checkbox,
  Tooltip,
  ButtonGroup,
} from "@material-ui/core";
import {
  LibraryAddCheck,
  HowToVote,
  Stars,
  ErrorOutline,
  SentimentVerySatisfied,
  TagFaces,
  SentimentSatisfied,
  Tonality,
  FormatAlignLeft,
  NearMe,
  Visibility,
  Close,
  Pause,
  AccountCircle,
  Archive,
  Star,
  SentimentVeryDissatisfied,
  Speed,
} from "@material-ui/icons";
import { Link, useLocation, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import Pagination from "@material-ui/lab/Pagination";

import campaignsApi from "../api/campaigns-api";
import Menu from "../components/Menu";
import Header from "../components/Header";
import ChoosePaymentPlan from "../components/ChoosePaymentPlan";

const Campaign = () => {
  const [state, setState] = React.useState({
    activeTab: "overview",
    timeFilter: "allTime",
    survey: {
      questions: [],
      name: "",
      date: "",
      overlimitied_responses_count: 0,
    },
    campaignStats: {
      impressions: { count: 0, growth: 0 },
      started: { count: 0, growth: 0 },
      finished: { count: 0, growth: 0 },
      response_rate: { percentage: 0, growth: 0 },
    },
    totalImpressionsIncrease: 15.34,
    usersStartedFeedbackCampaignIncrease: 15.34,
    completedFeedbackCampaignsIncrease: 15.34,
    campaignRessponseRate: 0,
    campaignRessponseRateIncrease: 15.34,
    questions: [],
    backdrop: false,
    openQuestionCurrentItem: {},
    isOpenQuestionModal: false,
    openQuestionModalList: [],
    optionMenuAnchor: null,
    archiveConfirmModal: false,
    activeColumn: 4,
    widgetColor: "",
    widgetBackgroundColor: "",
    responses: [],
    responsesTolal: 0,
    responsesPage: 1,
    responsesOrder: "date",
    responsesDirection: "desc",
    unlimitedModal: false,
    direction: {
      responseCount: "desc",
      sentiment: "desc",
      date: "desc",
    },
    responseDetailsModalOpen: false,
    responseDetailsModalData: {},
  });

  const location = useLocation();
  const history = useHistory();
  const userRole = useSelector((store) => store.user.role);

  React.useEffect(() => {
    setState({ ...state, backdrop: true });
    campaignsApi
      .fetchCampaign(+location.pathname.split("/campaigns/")[1])
      .then((campaign) => {
        const questions = [...campaign.data.questions]
          .sort((a, b) => a.position - b.position)
          .filter(
            (question) =>
              question.type !== "cta" && question.type !== "thankYouNote"
          );

        campaignsApi
          .fetchCampaignStatistics(campaign.data.id, state.timeFilter)
          .then((statistics) => {
            const questionWithStats = questions.map((question) => ({
              ...question,
              ...statistics.data.questions.find(
                (statisticItem) => statisticItem.question_id === question.id
              ),
            }));

            campaignsApi
              .fetchResponses(
                campaign.data.id,
                state.timeFilter,
                state.responsesPage,
                state.responsesOrder,
                state.responsesDirection
              )
              .then((responses) => {
                setState({
                  ...state,
                  survey: campaign.data,
                  questions: questionWithStats,
                  responses: responses.data,
                  responsesTolal: responses.headers.total,
                  widgetColor: campaign.data.color,
                  campaignStats: statistics.data.stats,
                  widgetBackgroundColor: `rgb(${campaign.data.color
                    .substr(1)
                    .match(/../g)
                    .map((chunk) => +`0x${chunk}`)}, 0.47)`,
                  backdrop: false,
                });
              });
          });
      });
  }, [state.timeFilter]); // eslint-disable-line

  React.useEffect(() => {
    if (!state.survey.id) return;
    setState({ ...state, backdrop: true });
    campaignsApi
      .fetchResponses(
        state.survey.id,
        state.timeFilter,
        state.responsesPage,
        state.responsesOrder,
        state.responsesDirection
      )
      .then((responses) => {
        setState({
          ...state,
          responses: responses.data,
          backdrop: false,
        });
      });
  }, [state.responsesPage, state.responsesOrder, state.responsesDirection]); // eslint-disable-line

  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: "#F9F9F9",
      minHeight: "100vh",
      width: "calc(100% - 120px)",

      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },

      "& .Campaign": {
        margin: "6px 52px 45px 50px",

        [theme.breakpoints.down("sm")]: {
          margin: "6px 20px 45px",
        },

        "&__header": {
          "&__info": {
            "&__status": {
              marginBottom: "6px",

              "& > h6": {
                color: "#000",
                letterSpacing: "0.15px",
                marginRight: "19px",

                [theme.breakpoints.down("sm")]: {
                  marginBottom: "10px",
                },
              },

              "& > button": {
                padding: 0,
                cursor:
                  state.survey.status === "archived" || userRole === "viewer"
                    ? "initial"
                    : "pointer",

                "&:hover": {
                  backgroundColor: "unset",
                },

                "& > span": {
                  "& > svg:first-child": {
                    marginRight: "7px",
                    fill:
                      state.survey.status === "live"
                        ? "#2A5F24"
                        : state.survey.status === "paused"
                        ? "#EADA9D"
                        : "rgba(136, 136, 136, 0.54)",
                  },
                  "& > p": {
                    color:
                      state.survey.status === "live"
                        ? "#2A5F24"
                        : state.survey.status === "paused"
                        ? "#EADA9D"
                        : "#757575",
                    fontWeight: "600",
                    fontSize: "10px",
                    lineHeight: "11px",
                    letterSpacing: "1px",
                    marginRight: "12px",
                    textTransform: "uppercase",
                  },
                },
              },
            },
            "&__description": {
              "& > p": {
                fontWeight: "500",
                fontSize: "12px",
                lineHeight: "11px",
                color: "rgba(0, 0, 0, 0.38)",
                marginRight: "12px",

                "&:nth-last-child(2)": {
                  marginLeft: "11px",
                },

                "&:last-child": {
                  marginLeft: "8px",
                  textTransform: "capitalize",

                  [theme.breakpoints.down("sm")]: {
                    marginLeft: "0",
                    marginTop: "5px",
                  },
                },
              },
              "& > svg": {
                width: "18px",
                height: "18px",
                fill: "#E0E0E0",
                marginRight: "10px",
              },
            },
          },

          "& > a": {
            [theme.breakpoints.down("sm")]: {
              width: "100%",
              marginTop: "20px",
            },

            "& button": {
              color: "#fff",
              boxShadow: "none",
              padding: "3px 16px",
              fontSize: "16px",
              letterSpacing: "0.3px",
              lineHeight: "34px",

              "&:hover": {
                boxShadow: "none",
              },
            },
          },
        },

        "&__stats": {
          backgroundColor: "#FFFFFF",
          marginTop: "10px",
          padding: "16px 29px 21px",

          "&__title": {
            fontSize: "16px",
            letterSpacing: "0.15px",
            lineHeight: "24px",
            color: "rgba(0, 0, 0, 0.87)",
            marginBottom: "10px",

            "& svg": {
              fill: "rgba(136, 136, 136, 0.54)",
              marginLeft: "10px",
            },
          },

          "&__main": {
            "&__item": {
              [theme.breakpoints.down("md")]: {
                width: "calc(50% - 41px)",
              },

              [theme.breakpoints.down("sm")]: {
                width: "100%",
                marginBottom: "10px",
              },

              "&:last-child": {
                marginRight: "0",
                width: "auto",
              },

              "&__count": {
                display: "flex",
                alignItems: "flex-end",

                [theme.breakpoints.down("md")]: {
                  marginTop: "20px",
                },

                [theme.breakpoints.down("sm")]: {
                  alignItems: "flex-start",
                  flexDirection: "column",
                },

                "& p": {
                  "&:first-child": {
                    fontWeight: "500",
                    fontSize: "48px",
                    lineHeight: "46px",
                    color: "rgba(0, 0, 0, 0.87)",
                  },
                  "&:last-child": {
                    fontSize: "13px",
                    lineHeight: "24px",
                    letterSpacing: "0.073125px",
                    color: "#96BD92",
                  },
                },
              },
              "& > p": {
                fontWeight: "500",
                fontSize: "12px",
                lineHeight: "11px",
                color: "rgba(0, 0, 0, 0.38)",
                marginTop: "5px",
              },
            },

            "&__rate": {
              display: "flex",
              alignItems: "center",

              [theme.breakpoints.down("md")]: {
                width: "calc(50% - 41px)",
              },

              [theme.breakpoints.down("sm")]: {
                width: "100%",
              },

              "& > svg": {
                backgroundColor: "#4CAF50",
                fill: "#fff",
                borderRadius: "4px",
                marginRight: "11px",
              },

              "& .Campaign__stats__main__item__count p:first-child": {
                color: "#4CAF50",
              },
            },

            "& > hr": {
              margin: "0 20px",
              height: "46px",

              [theme.breakpoints.down("md")]: {
                alignSelf: "center",
              },

              [theme.breakpoints.down("sm")]: {
                display: "none",
              },

              "&:nth-child(4)": {
                [theme.breakpoints.down("md")]: {
                  display: "none",
                },
              },
            },
          },
        },

        "&__alert": {
          marginTop: "12px",
          marginBottom: "12px",
          background: "rgba(150, 189, 146, 0.1)",
          borderRadius: "4px",
          alignItems: "center",
          justifyContent: "center",
          padding: "11px 30px",

          [theme.breakpoints.down(1150)]: {
            justifyContent: "space-around",
          },

          [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
            alignItems: "flex-start",
            padding: "18px 30px",
          },

          "& > svg": {
            width: "40px",
            height: "45px",
            marginRight: "20px",
            fill: "#6A8F66",
          },

          "& > .MuiGrid-root": {
            color: "#6A8F66",
            marginRight: "10%",

            [theme.breakpoints.down(1150)]: {
              marginRight: "0",
            },

            [theme.breakpoints.down("sm")]: {
              marginBottom: "10px",
              maxWidth: "100%",
            },
          },

          "& > button": {
            fontSize: "16px",
            lineHeight: "25px",
            padding: "6px 16px",
          },
        },

        "&__tabs": {
          marginTop: "30px",

          "&__header": {
            [theme.breakpoints.down("xs")]: {
              marginBottom: "10px",
            },

            "& .MuiTabs-flexContainer > button": {
              minHeight: "32px",
              textTransform: "initial",
              color: "rgba(136, 136, 136, 0.54)",
              opacity: "1",
              fontSize: "18px",
              lineHeight: "32px",
              letterSpacing: "0.15px",
              minWidth: "unset",
              fontWeight: "normal",

              "&:first-child": {
                marginRight: "16px",
              },

              "&.Mui-selected": {
                color: "#000",
              },
            },

            "& .MuiTabs-indicator": {
              top: "36px",
              backgroundColor: "transparent",
              display: "flex",
              justifyContent: "center",

              "& > div": {
                width: "20px",
                backgroundColor: theme.palette.primary.main,
                height: "2px",
              },
            },

            "& > .MuiFormControl-root": {
              marginLeft: "26px",

              [theme.breakpoints.down("xs")]: {
                marginLeft: "7px",
              },

              "& .MuiSelect-select": {
                padding: "10px 15px 10px 12px",
                width: "170px",
                boxSizing: "border-box",
              },

              "& .MuiOutlinedInput-root:hover fieldset": {
                borderColor: "#96BD92",
              },
            },

            "& > .MuiTabs-root": {
              [theme.breakpoints.down("xs")]: {
                width: "100%",
              },
            },
          },

          "&__overview": {
            "&__question": {
              width: "calc(50% - 15px)",
              backgroundColor: "#fff",
              padding: "7px 7px 0px 30px",
              marginBottom: "30px",
              border: "1px solid #F7F7F7",
              borderRadius: "4px",

              [theme.breakpoints.down("md")]: {
                width: "100%",
                padding: "10px 14px",
              },

              "&__header": {
                "&__info": {
                  display: "flex",
                  alignItems: "center",
                  padding: "7px 0",

                  "&__logo": {
                    color: "#fff",
                    borderRadius: "16px",
                    paddingRight: "8px",
                    paddingLeft: "4px",
                    fontSize: "12px",
                    lineHeight: "22px",
                    display: "flex",
                    alignItems: "center",
                    fontWeight: "600",
                    marginRight: "9px",
                    alignSelf: "flex-start",
                    width: "42px",

                    "& svg": {
                      width: "15px",
                      height: "15px",
                      marginRight: "8px",
                      marginBottom: "1px",
                    },
                  },

                  "&__type": {
                    fontSize: "10px",
                    lineHeight: "11px",
                    fontWeight: "600",
                    color: "#B3B3B3",
                    letterSpacing: "1px",
                  },
                },

                "& > button": {
                  color: "#BBBCBA",
                  padding: "6px",
                },
              },

              "&__title": {
                fontWeight: "500",
                fontSize: "16px",
                lineHeight: "24px",
                letterSpacing: "0.15px",
                color: "rgba(0, 0, 0, 0.87)",
                maxWidth: "403px",
                marginBottom: "16px",
                marginRight: "23px",
              },

              "&__main": {
                "&__NPS_Score": {
                  "&__info": {
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    paddingRight: "23px",

                    [theme.breakpoints.down("xs")]: {
                      flexDirection: "column",
                      paddingRight: "0",
                    },

                    "&__score": {
                      "& > .MuiGrid-root": {
                        display: "flex",

                        "& > svg": {
                          marginLeft: "6px",
                          fill: "rgba(136, 136, 136, 0.54)",
                        },
                      },

                      "& > h1": {
                        letterSpacing: "-1.5px",
                        color: "#BE134D",
                        fontWeight: "400",
                      },
                    },

                    "&__statistic": {
                      "& > .MuiGrid-root": {
                        display: "flex",
                        justifyContent: "space-between",
                        width: "236px",
                        alignItems: "center",
                        marginBottom: "3px",

                        [theme.breakpoints.down("md")]: {
                          width: "200px",
                        },

                        [theme.breakpoints.down("sm")]: {
                          width: "236px",
                        },

                        [theme.breakpoints.down("xs")]: {
                          width: "100%",
                          paddingRight: "10px",
                        },

                        "& > p > span": {
                          textTransform: "capitalize",
                        },

                        "& > h4": {
                          fontWeight: "600",
                          color: "#4CAF50",
                        },

                        "& strong": {
                          fontWeight: "500",
                        },

                        "&:nth-child(2)": {
                          "& > h4": {
                            color: "#B3B3B3",
                          },
                        },

                        "&:nth-child(3)": {
                          "& > h4": {
                            color: "#E31B0C",
                          },
                        },
                      },
                    },
                  },

                  "&__chart": {
                    height: "55px",
                    marginTop: "19px",
                    display: "flex",
                    paddingRight: "22px",
                    marginBottom: "30px",

                    [theme.breakpoints.down("sm")]: {
                      marginBottom: "20px",
                    },

                    [theme.breakpoints.down("xs")]: {
                      paddingRight: "0",
                    },

                    "& > .MuiGrid-root": {
                      opacity: "0.5",
                      height: "100%",

                      "&:first-child": {
                        backgroundColor: "#E31B0C",
                        borderTopLeftRadius: "5px",
                        borderBottomLeftRadius: "5px",
                      },
                      "&:nth-child(2)": {
                        backgroundColor: "#B2B2B2",
                      },
                      "&:nth-child(3)": {
                        backgroundColor: "#4CAF50",
                        borderTopRightRadius: "5px",
                        borderBottomRightRadius: "5px",
                      },
                    },
                  },
                },

                "&__multipleChoice": {
                  paddingRight: "22px",
                  paddingBottom: "10px",

                  [theme.breakpoints.down("sm")]: {
                    paddingBottom: "0",
                  },

                  [theme.breakpoints.down("xs")]: {
                    paddingRight: "0",
                  },

                  "&__answer": {
                    "& > p": {
                      maxWidth: "calc(100% - 45px)",

                      "& > strong": {
                        fontWeight: "500",

                        [theme.breakpoints.down("xs")]: {
                          display: "block",
                        },
                      },
                    },

                    "&__chart": {
                      display: "flex",
                      marginBottom: "15px",
                      marginTop: "3px",

                      "& > .MuiGrid-root": {
                        width: "100%",
                        height: "24px",
                        backgroundColor: "rgba(234, 218, 157, 0.2)",
                        marginRight: "10px",
                        borderRadius: "5px",

                        "& > .MuiGrid-root": {
                          height: "100%",
                          borderRadius: "5px",
                          backgroundColor: "rgba(234, 218, 157, 0.5)",
                        },
                      },

                      "& > p": {
                        fontSize: "12px",
                        lineHeight: "22px",
                        color: "#EADA9D",
                        width: "35px",
                        fontWeight: "600",
                        letterSpacing: "0.4px",
                      },
                    },
                  },
                },

                "&__rating": {
                  display: "flex",
                  justifyContent: "space-between",
                  paddingRight: "17px",
                  paddingBottom: "20px",
                  overflowX: "auto",

                  "&::-webkit-scrollbar-track": {
                    webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
                    backgroundColor: "transparent",
                  },
                  "&::-webkit-scrollbar": {
                    width: "4px",
                    height: "4px",
                    backgroundColor: "transparent",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#96BD92",
                    border: "2px solid #96BD92",
                  },

                  "&__answer": {
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginRight: "20px",
                    width: "100%",

                    "& > p": {
                      "&:first-child": {
                        fontWeight: "600",
                        fontSize: "12px",
                        lineHeight: "22px",
                        letterSpacing: "0.4px",
                        color: "#F06191",
                        marginBottom: "2px",
                      },

                      "&:nth-child(2)": {
                        fontSize: "9px",
                        lineHeight: "11px",
                        color: "rgba(0, 0, 0, 0.38)",
                        marginBottom: "2px",
                        height: "22px",
                      },
                    },

                    "& > .MuiGrid-root": {
                      display: "flex",
                      flexDirection: "column-reverse",
                      height: "180px",
                      width: "100%",
                      borderRadius: "5px",
                      backgroundColor: "rgba(240,97,145,0.2)",
                      marginBottom: "15px",
                      minWidth: "35px",

                      "& > .MuiGrid-root": {
                        width: "100%",
                        backgroundColor: "rgba(240,97,145,0.5)",
                        borderBottomRightRadius: "5px",
                        borderBottomLeftRadius: "5px",
                      },
                    },
                  },
                },

                "&__yesNo": {
                  marginBottom: "35px",
                  paddingRight: "22px",
                  marginTop: "35px",

                  "& > .MuiGrid-root > p": {
                    textTransform: "capitalize",

                    "& strong": {
                      fontWeight: "500",
                    },
                  },

                  "&__chart": {
                    marginTop: "3px",
                    marginBottom: "25px",
                    display: "flex",
                    alignItems: "center",

                    "& > .MuiGrid-root": {
                      height: "32px",
                      backgroundColor: "rgba(109,157,104, 0.2)",
                      borderRadius: "5px",
                      width: "100%",
                      marginRight: "8px",

                      "& > .MuiGrid-root": {
                        height: "100%",
                        backgroundColor: "rgba(109,157,104, 0.5)",
                        borderRadius: "5px",
                      },
                    },

                    "& > p": {
                      fontWeight: "600",
                      fontSize: "12px",
                      lineHeight: "22px",
                      letterSpacing: "0.4px",
                      color: "#6D9D68",
                      width: "35px",
                    },
                  },
                },

                "&__openQuestion": {
                  paddingRight: "30px",
                  paddingBottom: "25px",

                  [theme.breakpoints.down("sm")]: {
                    paddingRight: "0",
                  },

                  "& .alice-carousel": {
                    margin: "80px 0",

                    "& > div:first-child": {
                      width: "calc(100% - 80px)",
                      margin: "auto",
                      cursor: "grab",
                      userSelect: "none",

                      [theme.breakpoints.down("sm")]: {
                        width: "calc(100% - 50px)",
                      },

                      "& li > h6": {
                        letterSpacing: "0.15px",
                        color: "#2196F3",
                        overflowWrap: "break-word",
                      },
                    },

                    "& > div:not(:first-child)": {
                      position: "absolute",
                      top: "50%",
                      transform: "translateY(-50%)",
                      width: "auto",
                      padding: "0",

                      "& p": {
                        padding: "0",

                        "& span": {
                          display: "block",
                          height: "20px",
                          width: "12px",
                        },
                      },
                    },

                    "& > .alice-carousel__prev-btn": {
                      left: 0,

                      "& span:after": {
                        content: `url("data:image/svg+xml,%3Csvg width='12' height='20' viewBox='0 0 12 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.67 1.86998L9.9 0.0999756L0 9.99998L9.9 19.9L11.67 18.13L3.54 9.99998L11.67 1.86998Z' fill='%23888888' fill-opacity='0.54'/%3E%3C/svg%3E")`,
                      },
                    },

                    "& > .alice-carousel__next-btn": {
                      right: 0,

                      "& span:after": {
                        content: `url("data:image/svg+xml,%3Csvg width='13' height='20' viewBox='0 0 13 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.880005 2.12L8.76 10L0.880005 17.88L3 20L13 10L3 0L0.880005 2.12Z' fill='%23888888' fill-opacity='0.54'/%3E%3C/svg%3E%0A")`,
                      },
                    },
                  },

                  "&__label": {
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",

                    "& > p": {
                      color: "#9E9E9E",
                      fontWeight: "500",
                      fontSize: "12px",
                      lineHeight: "11px",

                      "& > span": {
                        color: "rgba(0, 0, 0, 0.87)",
                      },
                    },

                    "&__view": {
                      padding: 0,
                      color: "#2196F3",

                      "&:hover": {
                        backgroundColor: "unset",
                      },

                      "& svg": {
                        fill: "#2196F3",
                        marginRight: "7px",
                      },

                      "& p": {
                        fontWeight: "500",
                        fontSize: "12px",
                        lineHeight: "11px",
                      },
                    },
                  },
                },
              },
            },
          },

          "&__responses": {
            overflowX: "auto",

            "&::-webkit-scrollbar-track": {
              webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
              backgroundColor: "#fff",
            },
            "&::-webkit-scrollbar": {
              width: "4px",
              height: "4px",
              backgroundColor: "#fff",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#96BD92",
              border: "2px solid #96BD92",
            },

            "&__table": {
              minWidth: "700px",
              background: "#FFFFFF",
              border: "1px solid #F6F6F6",
              borderBottom: "none",
              borderRadius: "4px",
              paddingBottom: "20px",

              "& > table": {
                "& > thead tr th": {
                  border: "none",
                  textAlign: "right",

                  "&:first-child p": {
                    color: "rgba(136, 136, 136, 0.54)",
                    fontSize: "10px",
                    fontWeight: "600",
                    lineHeight: "11px",
                    letterSpacing: "1px",
                    textTransform: "uppercase",
                    paddingLeft: "16px",
                    textAlign: "left",
                  },

                  "&:last-child": {
                    paddingRight: "45px",
                  },

                  [`&:nth-child(${state.activeColumn}) span`]: {
                    color: theme.palette.primary.main,

                    "& .MuiSvgIcon-root": {
                      fill: theme.palette.primary.main,
                    },
                  },

                  "& span": {
                    color: "rgba(136, 136, 136, 0.54)",
                    fontSize: "10px",
                    fontWeight: "600",
                    lineHeight: "11px",
                    letterSpacing: "1px",
                    textTransform: "uppercase",

                    "&:hover": {
                      color: theme.palette.primary.main,

                      "& .MuiSvgIcon-root": {
                        fill: theme.palette.primary.main,
                        opacity: 1,
                      },
                    },

                    "& .MuiSvgIcon-root": {
                      fill: "rgba(136, 136, 136, 0.54)",
                      opacity: "1",
                      marginLeft: "7px",
                      marginRight: "0",
                    },
                  },
                },

                "& > tbody > tr": {
                  cursor: "pointer",

                  "&.Campaign__tabs__responses__table__unlimited": {
                    filter: "blur(10px)",

                    "& > td:nth-child(3) > .Campaign__tabs__responses": {
                      "&_positive, &_negative, &_neutral": {
                        backgroundColor: "#a9a9a9",
                      },
                    },
                  },

                  "& > td": {
                    border: "none",
                    padding: "5px 16px",
                    minHeight: "58px",
                    textAlign: "right",

                    "&:first-child": {
                      display: "flex",
                      alignItems: "center",
                    },

                    "&:last-child": {
                      paddingRight: "45px",
                    },

                    "&:nth-child(2)": {
                      textAlign: "right",
                      paddingRight: "30px",
                    },

                    "&:nth-child(3)": {
                      "& > .MuiGrid-root": {
                        display: "inline-flex",
                        alignItems: "center",
                        borderRadius: "16px",
                        fontWeight: "600",
                        fontSize: "12px",
                        lineHeight: "22px",
                        letterSpacing: "0.4px",
                        color: "#FFFFFF",
                        textTransform: "capitalize",
                        padding: "0 8px 0 6px",

                        "& > svg": {
                          width: "16px",
                          height: "16px",
                          marginRight: "7px",
                        },

                        "&.Campaign__tabs__responses": {
                          "&_positive": {
                            backgroundColor: "#4CAF50",
                          },
                          "&_neutral": {
                            backgroundColor: "#FF9800",
                          },
                          "&_negative": {
                            backgroundColor: "#E31B0C",
                          },
                        },
                      },
                    },

                    "& > svg": {
                      fill: "rgba(136, 136, 136, 0.54)",
                      marginLeft: "16px",
                      marginRight: "11px",
                    },

                    "& > .MuiGrid-root > p": {
                      textAlign: "left",

                      "&:first-child": {},

                      "&:last-child": {
                        fontSize: "12px",
                        lineHeight: "11px",
                        color: "rgba(0, 0, 0, 0.38)",
                      },
                    },
                  },
                },
              },

              "& > nav": {
                width: "100%",
                backgroundColor: "#fff",
                padding: "20px 0",

                "& > ul": {
                  display: "flex",
                  justifyContent: "center",

                  "& > li > button.Mui-selected": {
                    color: "#fff",
                  },
                },
              },

              "& > p": {
                marginLeft: "32px",
              },
            },
          },
        },
      },

      "& > .MuiBackdrop-root": {
        zIndex: 9,
      },
    },
    openQuestionModal: {
      "& .MuiDialog-paper": {
        padding: "15px 20px 20px",
        overflow: "auto",

        "&::-webkit-scrollbar-track": {
          webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
          backgroundColor: "transparent",
        },
        "&::-webkit-scrollbar": {
          width: "4px",
          height: "4px",
          backgroundColor: "transparent",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#96BD92",
          border: "2px solid #96BD92",
        },

        "& > .MuiGrid-container": {
          height: "100%",
          width: "829px",
          position: "relative",
        },

        "& > button": {
          top: "5px",
          right: "5px",
          position: "absolute",
        },

        "& > h6": {
          marginRight: "50px",
          marginBottom: "10px",
        },

        "& > p": {
          marginBottom: "20px",
        },
      },
    },
    archiveConfirmModal: {
      "& .MuiDialog-paper": {
        padding: "16px 24px 14px",

        "& > p": {
          "&:first-child": {
            fontWeight: "500",
            fontSize: "20px",
            lineHeight: "32px",
            letterSpacing: "0.15px",
            color: "rgba(0, 0, 0, 0.87)",
            marginBottom: "24px",
          },

          "&:nth-child(2)": {
            letterSpacing: "0.15px",
            color: "rgba(136, 136, 136, 0.54)",
            marginBottom: "22px",
          },
        },

        "& > .MuiGrid-root": {
          display: "flex",
          justifyContent: "flex-end",

          "& button": {
            fontWeight: "600",
            fontSize: "16px",
            lineHeight: "25px",
            color: "#96BD92",

            "&:first-child": {
              marginRight: "15px",
            },
          },
        },
      },
    },
    responseDetailsModal: {
      "& .MuiDialog-paper": {
        width: "766px",
        maxWidth: "unset",
        border: "1px solid #E0E0E0",
        borderRadius: "4px",
        overflow: "auto",

        "&::-webkit-scrollbar-track": {
          webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
          backgroundColor: "transparent",
        },

        "&::-webkit-scrollbar": {
          width: "4px",
          height: "4px",
          backgroundColor: "transparent",
        },

        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#96BD92",
          border: "2px solid #96BD92",
        },

        "& > .MuiGrid-root": {
          minWidth: "450px",
          position: "relative",

          "& > button": {
            top: "5px",
            right: "5px",
            position: "absolute",
            padding: "8px",

            "& svg": {
              fill: "rgba(136, 136, 136, 0.54)",
            },
          },

          "& > .MuiGrid-root": {
            "&:nth-child(2)": {
              display: "flex",
              alignItems: "center",
              padding: "25px 30px",
              borderBottom: "1px solid #E0E0E0",

              "& > svg": {
                fill: "rgba(136, 136, 136, 0.54)",
                marginRight: "13px",
              },

              "& > .MuiGrid-root": {
                display: "inline-flex",
                alignItems: "center",
                borderRadius: "16px",
                fontWeight: "600",
                fontSize: "12px",
                lineHeight: "22px",
                letterSpacing: "0.4px",
                color: "#FFFFFF",
                textTransform: "capitalize",
                padding: "0 8px 0 6px",
                marginLeft: "28px",
                marginRight: "35px",

                "&.ResponseDetailsModal": {
                  "&_positive": {
                    backgroundColor: "#4CAF50",
                  },
                  "&_neutral": {
                    backgroundColor: "#FF9800",
                  },
                  "&_negative": {
                    backgroundColor: "#E31B0C",
                  },
                },

                "& > svg": {
                  width: "16px",
                  height: "16px",
                  marginRight: "7px",
                },
              },

              "& > p:last-child": {
                letterSpacing: "0.15px",
                color: "rgba(0, 0, 0, 0.38)",
              },
            },
            "&:nth-child(3)": {
              width: "348px",
              margin: "auto",
              paddingBottom: "50px",

              "& > .MuiGrid-root": {
                width: "296px",
                marginLeft: "auto",
              },

              "& > p": {
                width: "296px",
                fontWeight: "500",
                fontSize: "14px",
                lineHeight: "21px",
                letterSpacing: "0.1px",
                color: "#000000",
                marginLeft: "auto",
                marginBottom: "10px",
              },

              "& .Campaign__response-details": {
                "&__title": {
                  display: "flex",
                  alignItems: "center",
                  marginTop: "45px",
                  marginBottom: "15px",
                  marginLeft: "0",

                  "& > .MuiGrid-root": {
                    color: "#fff",
                    borderRadius: "16px",
                    paddingRight: "8px",
                    paddingLeft: "4px",
                    fontSize: "12px",
                    lineHeight: "22px",
                    display: "flex",
                    alignItems: "center",
                    fontWeight: "600",
                    marginRight: "9px",
                    alignSelf: "flex-start",

                    "& svg": {
                      width: "15px",
                      height: "15px",
                      marginRight: "7px",
                      marginBottom: "1px",
                    },
                  },

                  "& > p": {
                    fontSize: "10px",
                    lineHeight: "11px",
                    fontWeight: "600",
                    color: "#B3B3B3",
                    letterSpacing: "1px",
                  },
                },

                "&__NPS_Score": {
                  "& > .MuiGrid-root": {
                    "&:first-child": {
                      "& > button": {
                        width: "26px",
                        height: "26px",
                        marginRight: "4px",
                        minWidth: "unset",
                        padding: "0",
                        cursor: "initial",

                        "&:last-child": {
                          marginRight: "0",
                        },

                        "&.Campaign__response-details__NPS_Score__answer": {
                          borderColor: state.widgetColor,
                          backgroundColor: state.widgetBackgroundColor,
                          color: state.widgetColor,
                        },
                      },
                    },
                    "&:last-child": {
                      marginTop: "11px",

                      "& > p": {
                        fontWeight: "500",
                        fontSize: "10px",
                        lineHeight: "11px",
                        color: "#616161",
                      },
                    },
                  },
                },

                "&__multipleChoice label": {
                  marginBottom: "-3px",
                  cursor: "initial",

                  "& > span": {
                    cursor: "initial",

                    "&:first-child": {
                      color: "rgba(136, 136, 136, 0.54)",

                      "&:hover": {
                        backgroundColor: state.widgetBackgroundColor.replaceAll(
                          ", 0.47)",
                          ", 0.09)"
                        ),
                      },
                    },

                    "&:last-child": {
                      color: "color: rgba(0, 0, 0, 0.87)",
                    },
                  },

                  "&.Campaign__response-details__multipleChoice__answer > span": {
                    "&:first-child, &:last-child": {
                      color: state.widgetColor,
                    },
                  },
                },

                "&__rating": {
                  "& > .MuiButtonGroup-root": {
                    marginBottom: "9px",

                    "& > button": {
                      width: "0",
                      minWidth: "unset",
                      flex: "1 1 0",
                      borderColor: state.widgetColor,
                      cursor: "initial",

                      "& svg": {
                        fill: state.widgetColor,
                      },

                      "&.Campaign__response-details__rating__answer": {
                        backgroundColor: state.widgetBackgroundColor,
                      },
                    },
                  },

                  "& > .MuiGrid-root > p": {
                    fontWeight: "500",
                    fontSize: "10px",
                    lineHeight: "11px",
                    color: "#616161",
                  },
                },

                "&__openQuestion > p": {
                  color: state.widgetColor,
                },
              },
            },
          },
        },
      },
    },
  }));

  const getQuestionConfig = (type) => {
    switch (type) {
      case "NPS_Score": {
        return {
          color: "#BE134D",
          title: "NPS SURVEY",
          img: <TagFaces />,
        };
      }

      case "multipleChoice": {
        return {
          color: "#EADA9D",
          title: "MULTIPLE CHOICE",
          img: <LibraryAddCheck />,
        };
      }

      case "thankYouNote": {
        return {
          color: "#F1DF38",
          title: "THANK YOU NOTE",
          img: <HowToVote />,
        };
      }

      case "rating": {
        return {
          color: "#F06191",
          title: "RATING",
          img: <Stars />,
        };
      }

      case "yesNo": {
        return {
          color: "#6D9D68",
          title: "YES/NO",
          img: <Tonality />,
        };
      }

      case "openQuestion": {
        return {
          color: "#2196F3",
          title: "OPEN QUESTION",
          img: <FormatAlignLeft />,
        };
      }

      case "cta": {
        return {
          color: "#C77700",
          title: "CTA",
          img: <NearMe />,
        };
      }

      default:
        return {};
    }
  };

  const handleOpenQuestionModal = (title, answers) => {
    setState({
      ...state,
      isOpenQuestionModal: !state.isOpenQuestionModal,
      openQuestionModalTitle: title || state.openQuestionModalTitle,
      openQuestionModalList: answers || state.openQuestionModalList,
    });
  };

  const handleOptionMenu = (event) => {
    setState({ ...state, optionMenuAnchor: event.currentTarget });
  };

  const handleStatus = (status) => {
    setState({
      ...state,
      optionMenuAnchor: null,
      backdrop: true,
      archiveConfirmModal: false,
    });
    campaignsApi
      .updateCampaign({
        ...state.survey,
        status,
        campaignId: state.survey.id,
      })
      .then((res) => {
        setState({
          ...state,
          survey: res.data,
          backdrop: false,
          optionMenuAnchor: null,
          archiveConfirmModal: false,
        });
      });
  };

  const handleSort = (value) => {
    setState({
      ...state,
      responsesOrder: value,
      responsesDirection: state.responsesDirection === "asc" ? "desc" : "asc",
      activeColumn:
        value === "responseCount" ? 2 : value === "sentiment" ? 3 : 4,
      direction: {
        ...state.direction,
        [value]: state.direction[value] === "asc" ? "desc" : "asc",
      },
    });
  };

  return (
    <Grid container>
      <Menu activePage="Campaigns" />
      <Grid className={useStyles().root}>
        <Header />
        <Grid className="Campaign">
          <Grid
            container
            alignItems="center"
            justify="space-between"
            className="Campaign__header"
          >
            <Grid className="Campaign__header__info">
              <Grid
                container
                alignItems="center"
                className="Campaign__header__info__status"
              >
                <Typography variant="h6">{state.survey.name}</Typography>
                <Button
                  onClick={(event) => {
                    if (state.survey.status !== "archived")
                      handleOptionMenu(event);
                  }}
                  disableRipple
                >
                  {state.survey.status === "live" ? (
                    <svg
                      width="20"
                      height="15"
                      viewBox="0 0 20 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.1563 0.386547C16.8076 0.0378178 16.2537 0.0378178 15.905 0.386547C15.5563 0.735276 15.5563 1.28914 15.905 1.63787C19.023 4.75592 19.023 9.82274 15.905 12.9408C15.5563 13.2895 15.5563 13.8434 15.905 14.1921C16.0691 14.3562 16.2947 14.4588 16.5204 14.4588C16.746 14.4588 16.9717 14.3767 17.1358 14.1921C20.9513 10.3766 20.9513 4.18154 17.1563 0.386547Z"
                        fill="#2A5F24"
                      />
                      <path
                        d="M17.1563 0.386547C16.8076 0.0378178 16.2537 0.0378178 15.905 0.386547C15.5563 0.735276 15.5563 1.28914 15.905 1.63787C19.023 4.75592 19.023 9.82274 15.905 12.9408C15.5563 13.2895 15.5563 13.8434 15.905 14.1921C16.0691 14.3562 16.2947 14.4588 16.5204 14.4588C16.746 14.4588 16.9717 14.3767 17.1358 14.1921C20.9513 10.3766 20.9513 4.18154 17.1563 0.386547Z"
                        fill="white"
                        fillOpacity="0.3"
                      />
                      <path
                        d="M14.223 3.29945C13.8742 2.95072 13.3204 2.95072 12.9716 3.29945C12.6229 3.64818 12.6229 4.20204 12.9716 4.55077C14.4691 6.04825 14.4896 8.50987 12.9716 10.0074C12.6229 10.3561 12.6229 10.9099 12.9716 11.2587C13.1357 11.4228 13.3614 11.5253 13.587 11.5253C13.8127 11.5253 14.0383 11.4433 14.2024 11.2587C16.4384 9.06373 16.4179 5.49439 14.223 3.29945Z"
                        fill="#2A5F24"
                      />
                      <path
                        d="M14.223 3.29945C13.8742 2.95072 13.3204 2.95072 12.9716 3.29945C12.6229 3.64818 12.6229 4.20204 12.9716 4.55077C14.4691 6.04825 14.4896 8.50987 12.9716 10.0074C12.6229 10.3561 12.6229 10.9099 12.9716 11.2587C13.1357 11.4228 13.3614 11.5253 13.587 11.5253C13.8127 11.5253 14.0383 11.4433 14.2024 11.2587C16.4384 9.06373 16.4179 5.49439 14.223 3.29945Z"
                        fill="white"
                        fillOpacity="0.3"
                      />
                      <path
                        d="M4.62259 1.63787C4.97132 1.28914 4.97132 0.735276 4.62259 0.386547C4.27386 0.0378178 3.72 0.0378178 3.37127 0.386547C-0.423723 4.18154 -0.423723 10.3766 3.37127 14.1716C3.53538 14.3357 3.76102 14.4383 3.98667 14.4383C4.21232 14.4383 4.43797 14.3562 4.60208 14.1716C4.9508 13.8229 4.9508 13.269 4.60208 12.9203C1.50454 9.82274 1.50454 4.75591 4.62259 1.63787Z"
                        fill="#2A5F24"
                      />
                      <path
                        d="M4.62259 1.63787C4.97132 1.28914 4.97132 0.735276 4.62259 0.386547C4.27386 0.0378178 3.72 0.0378178 3.37127 0.386547C-0.423723 4.18154 -0.423723 10.3766 3.37127 14.1716C3.53538 14.3357 3.76102 14.4383 3.98667 14.4383C4.21232 14.4383 4.43797 14.3562 4.60208 14.1716C4.9508 13.8229 4.9508 13.269 4.60208 12.9203C1.50454 9.82274 1.50454 4.75591 4.62259 1.63787Z"
                        fill="white"
                        fillOpacity="0.3"
                      />
                      <path
                        d="M7.53547 4.55077C7.8842 4.20204 7.8842 3.64818 7.53547 3.29945C7.18674 2.95072 6.63287 2.95072 6.28414 3.29945C4.0892 5.49439 4.0892 9.06373 6.28414 11.2587C6.44825 11.4228 6.6739 11.5253 6.89955 11.5253C7.1252 11.5253 7.35084 11.4433 7.51495 11.2587C7.86368 10.9099 7.86368 10.3561 7.51495 10.0074C6.03798 8.50987 6.03798 6.04825 7.53547 4.55077Z"
                        fill="#2A5F24"
                      />
                      <path
                        d="M7.53547 4.55077C7.8842 4.20204 7.8842 3.64818 7.53547 3.29945C7.18674 2.95072 6.63287 2.95072 6.28414 3.29945C4.0892 5.49439 4.0892 9.06373 6.28414 11.2587C6.44825 11.4228 6.6739 11.5253 6.89955 11.5253C7.1252 11.5253 7.35084 11.4433 7.51495 11.2587C7.86368 10.9099 7.86368 10.3561 7.51495 10.0074C6.03798 8.50987 6.03798 6.04825 7.53547 4.55077Z"
                        fill="white"
                        fillOpacity="0.3"
                      />
                      <path
                        d="M10.2638 8.77655C11.0909 8.77655 11.7613 8.10611 11.7613 7.27907C11.7613 6.45203 11.0909 5.78159 10.2638 5.78159C9.4368 5.78159 8.76636 6.45203 8.76636 7.27907C8.76636 8.10611 9.4368 8.77655 10.2638 8.77655Z"
                        fill="#2A5F24"
                      />
                      <path
                        d="M10.2638 8.77655C11.0909 8.77655 11.7613 8.10611 11.7613 7.27907C11.7613 6.45203 11.0909 5.78159 10.2638 5.78159C9.4368 5.78159 8.76636 6.45203 8.76636 7.27907C8.76636 8.10611 9.4368 8.77655 10.2638 8.77655Z"
                        fill="white"
                        fillOpacity="0.3"
                      />
                    </svg>
                  ) : state.survey.status === "paused" ? (
                    <Pause />
                  ) : state.survey.status === "archived" ? (
                    <Archive />
                  ) : (
                    <></>
                  )}
                  <Typography>{state.survey.status}</Typography>
                  {(state.survey.status === "live" ||
                    state.survey.status === "paused") &&
                    userRole !== "viewer" && (
                      <svg
                        width="10"
                        height="5"
                        viewBox="0 0 10 5"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0 0L5 5L10 0H0Z"
                          fill="#888888"
                          fillOpacity="0.54"
                        />
                      </svg>
                    )}
                </Button>
                {state.survey.status !== "archived" && userRole !== "viewer" && (
                  <MaterialMenu
                    anchorEl={state.optionMenuAnchor}
                    open={!!state.optionMenuAnchor}
                    onClose={() =>
                      setState({ ...state, optionMenuAnchor: null })
                    }
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                    transformOrigin={{ vertical: "top", horizontal: "left" }}
                    disableScrollLock
                  >
                    {state.survey.status === "live" && (
                      <MenuItem onClick={() => handleStatus("paused")}>
                        Pause
                      </MenuItem>
                    )}
                    {state.survey.status === "paused" && (
                      <MenuItem onClick={() => handleStatus("live")}>
                        Unpause
                      </MenuItem>
                    )}
                    {(state.survey.status === "live" ||
                      state.survey.status === "paused") && (
                      <MenuItem
                        onClick={() =>
                          history.push(
                            `/campaigns/${
                              location.pathname.split("/campaigns/")[1]
                            }/edit`
                          )
                        }
                      >
                        Edit
                      </MenuItem>
                    )}
                    {(state.survey.status === "live" ||
                      state.survey.status === "paused") && (
                      <MenuItem
                        onClick={() =>
                          setState({
                            ...state,
                            archiveConfirmModal: true,
                            optionMenuAnchor: null,
                          })}
                      >
                        Archive
                      </MenuItem>
                    )}
                  </MaterialMenu>
                )}
              </Grid>
              <Grid
                container
                alignItems="center"
                className="Campaign__header__info__description"
              >
                <Typography>
                  {`${state.questions.length} ${
                    state.questions.length === 1 ? "Question" : "Questions"
                  }:`}
                </Typography>
                {state.questions.map((question, id) => (
                  <React.Fragment
                    key={id} // eslint-disable-line
                  >
                    {getQuestionConfig(question.type).img}
                  </React.Fragment>
                ))}
                <Typography>•</Typography>
                <Typography>{state.survey.date}</Typography>
              </Grid>
            </Grid>
            {(state.survey.status === "live" ||
              state.survey.status === "paused") &&
              userRole !== "viewer" && (
                <Link to={`/campaigns/${state.survey.id}/edit`}>
                  <Button variant="contained" color="primary">
                    Edit Campaign
                  </Button>
                </Link>
              )}
          </Grid>
          {state.survey.overlimitied_responses_count > 0 && (
            <Grid container className="Campaign__alert">
              <Speed />
              <Grid>
                <Typography variant="h6">
                  You have reached the response limit
                </Typography>
                <Typography variant="body2">
                  {`In order to get ${state.survey.overlimitied_responses_count} blocked responses you have to upgrade your
                plan`}
                </Typography>
              </Grid>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => setState({ ...state, unlimitedModal: true })}
              >
                Upgrade Plan
              </Button>
            </Grid>
          )}
          <Grid className="Campaign__stats">
            <Grid
              container
              alignItems="center"
              className="Campaign__stats__title"
            >
              <Typography variant="body1">Campaign Stats</Typography>
              <Tooltip
                title="Specific to this individual campaign these stats are used to provide insights to help understand the effectiveness of a campaign."
                arrow
                enterTouchDelay={0}
                interactive
              >
                <ErrorOutline />
              </Tooltip>
            </Grid>
            <Grid
              container
              justify="space-between"
              className="Campaign__stats__main"
            >
              <Grid className="Campaign__stats__main__item">
                <Grid className="Campaign__stats__main__item__count">
                  <Typography>
                    {state.campaignStats.impressions.count
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </Typography>
                  <Typography>
                    {state.campaignStats.impressions.growth
                      ? `+ ${+state.campaignStats.impressions.growth.toFixed(
                          2
                        )}%`
                      : ""}
                  </Typography>
                </Grid>
                <Typography>Total Impressions</Typography>
              </Grid>
              <Divider orientation="vertical" flexItem />
              <Grid className="Campaign__stats__main__item">
                <Grid className="Campaign__stats__main__item__count">
                  <Typography>
                    {state.campaignStats.started.count
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </Typography>
                  <Typography>
                    {state.campaignStats.started.growth
                      ? `+ ${+state.campaignStats.started.growth.toFixed(2)}%`
                      : ""}
                  </Typography>
                </Grid>
                <Typography>Users Started Feedback Campaign</Typography>
              </Grid>
              <Divider orientation="vertical" flexItem />
              <Grid className="Campaign__stats__main__item">
                <Grid className="Campaign__stats__main__item__count">
                  <Typography>
                    {state.campaignStats.finished.count
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </Typography>
                  <Typography>
                    {state.campaignStats.finished.growth
                      ? `+ ${+state.campaignStats.finished.growth.toFixed(2)}%`
                      : ""}
                  </Typography>
                </Grid>
                <Typography>Completed Feedback Campaigns</Typography>
              </Grid>
              <Divider orientation="vertical" flexItem />
              <Grid className="Campaign__stats__main__rate">
                <SentimentVerySatisfied />
                <Grid className="Campaign__stats__main__item">
                  <Grid className="Campaign__stats__main__item__count">
                    <Typography>{`${state.campaignStats.response_rate.percentage}%`}</Typography>
                    <Typography>
                      {state.campaignStats.response_rate.growth
                        ? `+ ${+state.campaignStats.response_rate.growth.toFixed(
                            2
                          )}%`
                        : ""}
                    </Typography>
                  </Grid>
                  <Typography>Campaign Ressponse Rate</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid className="Campaign__tabs">
            <Grid
              container
              justify="space-between"
              className="Campaign__tabs__header"
            >
              <Tabs
                value={state.activeTab}
                TabIndicatorProps={{ children: <div /> }}
                indicatorColor="primary"
                onChange={(event, newValue) =>
                  setState({ ...state, activeTab: newValue })
                }
              >
                <Tab label="Overview" value="overview" disableRipple />
                <Tab label="Responses" value="responses" disableRipple />
              </Tabs>
              <TextField
                select
                value={state.timeFilter}
                variant="outlined"
                onChange={(event) =>
                  setState({ ...state, timeFilter: event.target.value })
                }
                SelectProps={{
                  MenuProps: { disableScrollLock: true },
                }}
              >
                <MenuItem value="allTime">All time</MenuItem>
                <MenuItem value="last_week">Last Week</MenuItem>
                <MenuItem value="30_days">Last 30 Days</MenuItem>
                <MenuItem value="60_days">Last 60 Days</MenuItem>
                <MenuItem value="90_days">Last 90 Days</MenuItem>
              </TextField>
            </Grid>
            {state.activeTab === "overview" && (
              <Grid
                container
                justify="space-between"
                className="Campaign__tabs__overview"
              >
                {state.questions.map((question, id) => (
                  <Grid
                    className="Campaign__tabs__overview__question"
                    key={question.id}
                  >
                    <Grid
                      container
                      justify="space-between"
                      alignItems="center"
                      className="Campaign__tabs__overview__question__header"
                    >
                      <Grid className="Campaign__tabs__overview__question__header__info">
                        <Grid
                          className="Campaign__tabs__overview__question__header__info__logo"
                          style={{
                            background: getQuestionConfig(question.type).color,
                          }}
                        >
                          {getQuestionConfig(question.type).img}
                          {id + 1}
                        </Grid>
                        <Typography className="Campaign__tabs__overview__question__header__info__type">
                          {getQuestionConfig(question.type).title}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Typography className="Campaign__tabs__overview__question__title">
                      {question.text}
                    </Typography>
                    <Grid
                      className={`Campaign__tabs__overview__question__main__${question.type}`}
                    >
                      {question.type === "NPS_Score" && (
                        <>
                          <Grid className="Campaign__tabs__overview__question__main__NPS_Score__info">
                            <Grid className="Campaign__tabs__overview__question__main__NPS_Score__info__score">
                              <Grid>
                                <Typography variant="body1">
                                  NPS Score
                                </Typography>
                                <Tooltip
                                  title="NPS or Net Promoter Score measures customer loyalty for your company with a single question."
                                  arrow
                                  interactive
                                  enterTouchDelay={0}
                                >
                                  <ErrorOutline />
                                </Tooltip>
                              </Grid>
                              <Typography variant="h1">
                                {question.stats.score}
                              </Typography>
                            </Grid>
                            <Grid className="Campaign__tabs__overview__question__main__NPS_Score__info__statistic">
                              {["promoters", "passives", "detractors"].map(
                                (option, optionId) => (
                                  <Grid
                                    key={optionId} //eslint-disable-line
                                  >
                                    <Typography variant="body1">
                                      <span>{option}</span>
                                      <strong>{` (${question.stats[option]})`}</strong>
                                    </Typography>
                                    <Typography variant="h4">
                                      {`${
                                        Math.round(
                                          (100 * question.stats[option]) /
                                            (question.stats.promoters +
                                              question.stats.passives +
                                              question.stats.detractors)
                                        ) || 0
                                      }%`}
                                    </Typography>
                                  </Grid>
                                )
                              )}
                            </Grid>
                          </Grid>
                          <Grid className="Campaign__tabs__overview__question__main__NPS_Score__chart">
                            {["detractors", "passives", "promoters"].map(
                              (option, optionId) => (
                                <Grid
                                  key={optionId} //eslint-disable-line
                                  style={{
                                    width: `${
                                      Number.isNaN(
                                        Math.round(
                                          (100 * question.stats[option]) /
                                            (question.stats.promoters +
                                              question.stats.passives +
                                              question.stats.detractors)
                                        )
                                      )
                                        ? option === "promoters"
                                          ? 100
                                          : 0
                                        : Math.round(
                                            (100 * question.stats[option]) /
                                              (question.stats.promoters +
                                                question.stats.passives +
                                                question.stats.detractors)
                                          )
                                    }%`,
                                  }}
                                />
                              )
                            )}
                          </Grid>
                        </>
                      )}
                      {question.type === "multipleChoice" && (
                        <>
                          {Object.entries(question.stats).map(
                            (response, answerId) => (
                              <Grid
                                key={answerId} // eslint-disable-line
                                className="Campaign__tabs__overview__question__main__multipleChoice__answer"
                              >
                                <Typography variant="body1">
                                  {`${response[0]}`}
                                  <strong>{` (${response[1]} Responses)`}</strong>
                                </Typography>
                                <Grid className="Campaign__tabs__overview__question__main__multipleChoice__answer__chart">
                                  <Grid>
                                    <Grid
                                      style={{
                                        width: `${Math.round(
                                          (response[1] * 100) /
                                            Object.values(
                                              question.stats
                                            ).reduce((a, b) => a + b)
                                        )}%`,
                                      }}
                                    />
                                  </Grid>
                                  <Typography>
                                    {`${
                                      Math.round(
                                        (response[1] * 100) /
                                          Object.values(question.stats).reduce(
                                            (a, b) => a + b
                                          )
                                      ) || 0
                                    }%`}
                                  </Typography>
                                </Grid>
                              </Grid>
                            )
                          )}
                        </>
                      )}
                      {question.type === "rating" && (
                        <>
                          {question.stats.map((option, answerId) => (
                            <Grid
                              key={answerId} // eslint-disable-line
                              className="Campaign__tabs__overview__question__main__rating__answer"
                            >
                              <Typography>
                                {`${
                                  Math.round(
                                    (option * 100) /
                                      question.stats.reduce((a, b) => a + b, 0)
                                  ) || 0
                                }%`}
                              </Typography>
                              <Typography>{`${option} resp.`}</Typography>
                              <Grid>
                                <Grid
                                  style={{
                                    height: `${Math.round(
                                      (option * 100) /
                                        question.stats.reduce(
                                          (a, b) => a + b,
                                          0
                                        )
                                    )}%`,
                                  }}
                                />
                              </Grid>
                              <Typography>{answerId + 1}</Typography>
                            </Grid>
                          ))}
                        </>
                      )}
                      {question.type === "yesNo" && (
                        <>
                          {["yes", "no"].map((option, optionId) => (
                            <Grid
                              key={optionId} // eslint-disable-line
                            >
                              <Typography variant="body1">
                                {option}
                                <strong>{` (${question.stats[option]} Responses)`}</strong>
                              </Typography>
                              <Grid className="Campaign__tabs__overview__question__main__yesNo__chart">
                                <Grid>
                                  <Grid
                                    style={{
                                      width: `${Math.round(
                                        (question.stats[option] * 100) /
                                          (question.stats.yes +
                                            question.stats.no)
                                      )}%`,
                                    }}
                                  />
                                </Grid>
                                <Typography>
                                  {`${
                                    Math.round(
                                      (question.stats[option] * 100) /
                                        (question.stats.yes + question.stats.no)
                                    ) || 0
                                  }%`}
                                </Typography>
                              </Grid>
                            </Grid>
                          ))}
                        </>
                      )}
                      {question.type === "openQuestion" && (
                        <>
                          {question.stats.length ? (
                            <>
                              <AliceCarousel
                                mouseTracking
                                disableDotsControls
                                infinite
                                onSlideChanged={(slide) =>
                                  setState({
                                    ...state,
                                    openQuestionCurrentItem: {
                                      ...state.openQuestionCurrentItem,
                                      [question.id]: slide.item + 1,
                                    },
                                  })
                                }
                              >
                                {question.stats.map((answer, answerId) => (
                                  <Typography
                              key={answerId} //eslint-disable-line
                                    variant="h6"
                                  >
                                    {`“${answer}”`}
                                  </Typography>
                                ))}
                              </AliceCarousel>
                              <Grid className="Campaign__tabs__overview__question__main__openQuestion__label">
                                <Typography>
                                  <span>
                                    {`${
                                      state.openQuestionCurrentItem[
                                        question.id
                                      ] || 1
                                    }`}
                                  </span>
                                  {` Of ${question.stats.length} Responses`}
                                </Typography>
                                <Button
                                  className="Campaign__tabs__overview__question__main__openQuestion__label__view"
                                  disableRipple
                                  onClick={() =>
                                    handleOpenQuestionModal(
                                      question.text,
                                      question.stats
                                    )}
                                >
                                  <Visibility />
                                  <Typography>View All</Typography>
                                </Button>
                              </Grid>
                            </>
                          ) : (
                            <Typography>No answers yet</Typography>
                          )}
                        </>
                      )}
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            )}
            {state.activeTab === "responses" && (
              <Grid className="Campaign__tabs__responses">
                <Grid className="Campaign__tabs__responses__table">
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <Typography>User</Typography>
                        </TableCell>
                        <TableCell>
                          <TableSortLabel
                            direction={state.direction.responseCount}
                            onClick={() => handleSort("responseCount")}
                          >
                            # of Responses
                          </TableSortLabel>
                        </TableCell>
                        <TableCell>
                          <TableSortLabel
                            direction={state.direction.sentiment}
                            onClick={() => handleSort("sentiment")}
                          >
                            Sentiment
                          </TableSortLabel>
                        </TableCell>
                        <TableCell>
                          <TableSortLabel
                            direction={state.direction.date}
                            onClick={() => handleSort("date")}
                          >
                            Date & time
                          </TableSortLabel>
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {state.responses.map((response) => (
                        <TableRow
                          key={response.id}
                          hover
                          className={
                            response.blocked
                              ? "Campaign__tabs__responses__table__unlimited"
                              : ""
                          }
                          onClick={() => {
                            if (!response.blocked) {
                              setState({
                                ...state,
                                responseDetailsModalData: {
                                  date: response.date,
                                  sentiment: response.sentiment,
                                  answers: response.answers,
                                  user: response.user,
                                },
                                responseDetailsModalOpen: true,
                              });
                            } else {
                              setState({ ...state, unlimitedModal: true });
                            }
                          }}
                        >
                          <TableCell>
                            <AccountCircle />
                            {response.user ? (
                              <Grid>
                                <Typography variant="body2">
                                  {response.user.name}
                                </Typography>
                                <Typography>{response.user.email}</Typography>
                              </Grid>
                            ) : (
                              <Typography variant="body2">User</Typography>
                            )}
                          </TableCell>
                          <TableCell>{response.number_of_responses}</TableCell>
                          <TableCell>
                            <Grid
                              className={`Campaign__tabs__responses_${response.sentiment}`}
                            >
                              {response.sentiment === "positive" && (
                                <SentimentVerySatisfied />
                              )}
                              {response.sentiment === "neutral" && (
                                <SentimentSatisfied />
                              )}
                              {response.sentiment === "negative" && (
                                <SentimentVeryDissatisfied />
                              )}
                              {response.sentiment}
                            </Grid>
                          </TableCell>
                          <TableCell>
                            {`${new Date(`${response.date} UTC`)
                              .toUTCString()
                              .slice(5, 16)}, ${new Date(
                              `${response.date} UTC`
                            ).toLocaleString("en-US", {
                              hour: "numeric",
                              minute: "numeric",
                              hour12: true,
                            })}`}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  {state.responses.length === 0 && (
                    <Typography>No responses yet</Typography>
                  )}
                  {state.responsesTolal > 10 && (
                    <Pagination
                      count={Math.ceil(state.responsesTolal / 10)}
                      color="primary"
                      onChange={(event, value) =>
                        setState({ ...state, responsesPage: value })}
                    />
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        {state.unlimitedModal && (
          <ChoosePaymentPlan
            btnText=""
            open={state.unlimitedModal}
            closeModal={() => setState({ ...state, unlimitedModal: false })}
          />
        )}
        <Backdrop open={state.backdrop}>
          <CircularProgress color="primary" />
        </Backdrop>
      </Grid>
      <Dialog
        open={state.isOpenQuestionModal}
        onClose={() => handleOpenQuestionModal(null)}
        disableScrollLock
        className={useStyles().openQuestionModal}
      >
        <IconButton onClick={() => handleOpenQuestionModal(null)}>
          <Close />
        </IconButton>
        <Typography variant="h6">{state.openQuestionModalTitle}</Typography>
        {state.openQuestionModalList.map((answer, id) => (
          <Typography
            key={id} //eslint-disable-line
          >
            {`${id + 1}) ${answer}`}
          </Typography>
        ))}
      </Dialog>
      <Dialog
        onClose={() => setState({ ...state, archiveConfirmModal: false })}
        open={!!state.archiveConfirmModal}
        disableScrollLock
        className={useStyles().archiveConfirmModal}
      >
        <Typography>Archive campaign</Typography>
        <Typography variant="body1">
          After archiving campaign, you will not be able to start it again. Are
          you sure you want to do this?
        </Typography>
        <Grid>
          <Button
            color="primary"
            onClick={() => {
              setState({ ...state, archiveConfirmModal: false });
            }}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={() => {
              handleStatus("archived");
            }}
          >
            Confirm
          </Button>
        </Grid>
      </Dialog>
      <Dialog
        open={state.responseDetailsModalOpen}
        onClose={() => setState({ ...state, responseDetailsModalOpen: false })}
        disableScrollLock
        className={useStyles().responseDetailsModal}
      >
        <Grid>
          <IconButton
            onClick={() =>
              setState({ ...state, responseDetailsModalOpen: false })
            }
          >
            <Close />
          </IconButton>
          <Grid>
            <AccountCircle />
            <Typography variant="body2">
              {state.responseDetailsModalData.user
                ? state.responseDetailsModalData.user.name
                : "User"}
            </Typography>
            <Grid
              className={`ResponseDetailsModal_${state.responseDetailsModalData.sentiment}`}
            >
              {state.responseDetailsModalData.sentiment === "positive" && (
                <SentimentVerySatisfied />
              )}
              {state.responseDetailsModalData.sentiment === "neutral" && (
                <SentimentSatisfied />
              )}
              {state.responseDetailsModalData.sentiment === "negative" && (
                <SentimentVeryDissatisfied />
              )}
              {state.responseDetailsModalData.sentiment}
            </Grid>
            <Typography variant="body2">
              {`${new Date(`${state.responseDetailsModalData.date} UTC`)
                .toUTCString()
                .slice(5, 16)}, ${new Date(
                `${state.responseDetailsModalData.date} UTC`
              ).toLocaleString("en-US", {
                hour: "numeric",
                minute: "numeric",
                hour12: true,
              })}`}
            </Typography>
          </Grid>
          <Grid>
            {state.responseDetailsModalData.answers &&
              state.responseDetailsModalData.answers.map((response, id) => (
                <React.Fragment key={response.id}>
                  <Grid className="Campaign__response-details__title">
                    <Grid
                      style={{
                        background: getQuestionConfig(response.question.type)
                          .color,
                      }}
                    >
                      {getQuestionConfig(response.question.type).img}
                      {id + 1}
                    </Grid>
                    <Typography>
                      {getQuestionConfig(response.question.type).title}
                    </Typography>
                  </Grid>

                  <Typography>{response.question.text}</Typography>

                  {/* Multiple Choice */}
                  {response.question.type === "multipleChoice" &&
                    response.question.options.optionsList[0] && (
                      <Grid
                        container
                        direction="column"
                        className="Campaign__response-details__multipleChoice"
                      >
                        {response.question.options.optionsList.map(
                          (option, id) => (
                            <FormControlLabel
                              key={id}
                              control={
                                <Checkbox
                                  checked={option.value === response.content}
                                />
                              }
                              label={option.value}
                              className={
                                option.value === response.content
                                  ? "Campaign__response-details__multipleChoice__answer"
                                  : ""
                              }
                            />
                          )
                        )}
                      </Grid>
                    )}

                  {/* NPS_Score */}
                  {response.question.type === "NPS_Score" && (
                    <Grid className="Campaign__response-details__NPS_Score">
                      <Grid container justify="space-between">
                        {Array.from(Array(10).keys()).map((number) => (
                          <Button
                            key={number + 1}
                            variant="outlined"
                            className={
                              number + 1 === +response.content
                                ? "Campaign__response-details__NPS_Score__answer"
                                : ""
                            }
                          >
                            <Typography variant="body2">
                              {number + 1}
                            </Typography>
                          </Button>
                        ))}
                      </Grid>
                      <Grid container justify="space-between">
                        <Typography>
                          {response.question.options.lowScoreText}
                        </Typography>
                        <Typography>
                          {response.question.options.highScoreText}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}

                  {/* Yes/No */}
                  {response.question.type === "yesNo" && (
                    <Grid
                      container
                      direction="column"
                      className="Campaign__response-details__multipleChoice"
                    >
                      <FormControlLabel
                        className={
                          response.content === "true"
                            ? "Campaign__response-details__multipleChoice__answer"
                            : ""
                        }
                        control={(
                          <Checkbox
                            color="default"
                            checked={response.content === "true"}
                          />
                        )}
                        label="Yes"
                      />
                      <FormControlLabel
                        className={
                          response.content === "false"
                            ? "Campaign__response-details__multipleChoice__answer"
                            : ""
                        }
                        control={(
                          <Checkbox
                            color="default"
                            checked={response.content === "false"}
                          />
                        )}
                        label="No"
                      />
                    </Grid>
                  )}

                  {/* Open Question */}
                  {response.question.type === "openQuestion" && (
                    <Grid
                      container
                      direction="column"
                      className="Campaign__response-details__openQuestion"
                    >
                      <Typography variant="body1">{`“${response.content}”`}</Typography>
                    </Grid>
                  )}

                  {/* Rating */}
                  {response.question.type === "rating" && (
                    <Grid
                      container
                      direction="column"
                      className="Campaign__response-details__rating"
                    >
                      <ButtonGroup aria-label="outlined primary button group">
                        {Array.from(
                          Array(response.question.options.starsNumber).keys()
                        ).map((button) => (
                          <Button
                            key={button + 1}
                            onClick={() =>
                              setState({ ...state, ratingAnswer: button })
                            }
                            style={{
                              padding: `${
                                10 - response.question.options.starsNumber
                              }px 0`,
                            }}
                            className={
                              +response.content === button + 1
                                ? "Campaign__response-details__rating__answer"
                                : ""
                            }
                          >
                            <Star />
                          </Button>
                        ))}
                      </ButtonGroup>
                      <Grid container justify="space-between">
                        <Typography>
                          {response.question.options.lowScoreText}
                        </Typography>
                        <Typography>
                          {response.question.options.highScoreText}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </React.Fragment>
              ))}
          </Grid>
        </Grid>
      </Dialog>
    </Grid>
  );
};

export default Campaign;
