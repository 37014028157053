import { securedAxiosInstance } from "./api";

const userApi = {
  deactivateUser: (email) =>
    securedAxiosInstance
      .patch("/deactivate", { email })
      .then((response) => response)
      .catch((error) => error),

  activateUser: (email) =>
    securedAxiosInstance
      .patch("/activate", { email })
      .then((response) => response)
      .catch((error) => error),

  setUserRole: (email, role) =>
    securedAxiosInstance
      .patch("/set_role", { email, role })
      .then((response) => response)
      .catch((error) => error),
};

export default userApi;
