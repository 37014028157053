import React from "react";
import {
  makeStyles,
  Grid,
  Typography,
  Divider,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";

import Logo from "../components/Logo";
import FormInput from "../components/FormInput";
import SubmitBtn from "../components/SubmitBtn";
import AlertMessage from "../components/AlertMessage";
import authApi from "../api/auth-api";

const ForgotPassword = () => {
  const [state, setState] = React.useState({
    isSubmitted: false,
    email: "",
    backdrop: false,
    userNotFound: false,
  });

  const history = useHistory();

  const useStyles = makeStyles((theme) => ({
    root: {
      height: "100vh",
      position: "relative",
      minHeight: "600px",

      "& > a": {
        position: "absolute",
        top: "46px",
        left: "361px",

        [theme.breakpoints.down("lg")]: {
          top: "49px",
          left: "115px",
        },

        [theme.breakpoints.down("xs")]: {
          left: "24px",
          top: "30px",
        },
      },
      "& > .MuiGrid-item": {
        maxWidth: "420px",
        width: "100%",

        [theme.breakpoints.down("xs")]: {
          padding: "24px",
          maxWidth: "unset",
        },

        "& h4": {
          fontWeight: "600",
          marginBottom: "7px",
        },

        "& h5": {
          marginBottom: "24px",
        },

        "& form": {
          marginTop: "33px",

          "& .MuiFormHelperText-contained": {
            opacity: 0,
            cursor: "default",
          },

          "& .Mui-error": {
            "& fieldset": {
              borderColor: state.isSubmitted
                ? "#F44336"
                : "rgba(0, 0, 0, 0.23)",
            },

            "& + .MuiFormHelperText-contained": {
              marginLeft: "0",
              opacity: state.isSubmitted ? "1" : "0",
            },

            "&.Mui-focused fieldset, &:hover fieldset": {
              borderColor: state.isSubmitted ? "#F44336" : "#96BD92",
            },

            "&.MuiInputLabel-shrink": {
              color: state.isSubmitted
                ? "#F44336"
                : "rgba(136, 136, 136, 0.54)",

              "&.Mui-focused": {
                color: state.isSubmitted ? "#f44336" : "#96BD92",
              },
            },
          },

          "& button[type='submit']": {
            display: "block",
            marginLeft: "auto",
            marginTop: "20px",

            [theme.breakpoints.down("lg")]: {
              marginTop: "14px",
            },
          },
        },
      },
      "& .MuiBackdrop-root": {
        zIndex: 1,
      },
    },
  }));

  const handleInput = (type, data) => {
    if (data !== state[type]) {
      setState({ ...state, email: data, userNotFound: false });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (state.email) {
      setState({ ...state, backdrop: true });
      authApi.emaillookup(state.email).then((res) => {
        if (
          res.status === 422 &&
          res.data.message === "This email has alredy been used before"
        ) {
          authApi.resetPassword(state.email).then(() => {
            history.push({
              pathname: "/",
              checkEmail: true,
            });
          });
        } else if (res.data.email) {
          setState({ ...state, userNotFound: true, backdrop: false });
        } else {
          console.log("Something went wrong");
        }
      });
    } else {
      setState({ ...state, isSubmitted: true });
    }
  };

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      className={useStyles().root}
    >
      <Logo />
      <Grid item>
        <Typography variant="h4">Reset your password</Typography>
        <Typography variant="h5">
          Enter email below and we’ll email you instructions to change your
          password
        </Typography>
        <Divider />
        <form onSubmit={handleSubmit}>
          <FormInput type="email" onValidate={handleInput} />
          <SubmitBtn btnText="Send Password Reset Email" />
        </form>
      </Grid>
      {state.userNotFound ? (
        <AlertMessage
          type="error"
          message="Account with that email address not found"
        />
      ) : null}
      <Backdrop open={state.backdrop}>
        <CircularProgress color="primary" />
      </Backdrop>
    </Grid>
  );
};

export default ForgotPassword;
