const user = (state = {}, action) => {
  switch (action.type) {
    case "USER":
      return {
        ...state,
        id: action.payload.id,
        name: action.payload.name,
        email: action.payload.email,
        role: action.payload.role,
      };
    default:
      return state;
  }
};

export default user;
