import React from "react";
import { makeStyles, TextField, Typography, Grid } from "@material-ui/core";
import PropTypes from "prop-types";

const FormInput = (props) => {
  const useStyles = makeStyles({
    root: {
      "& p": {
        fontWeight: "600",
        marginBottom: "7px",
      },
      "& div.MuiOutlinedInput-root": {
        "&.Mui-focused fieldset, &:hover fieldset": {
          borderColor: "#96BD92",
        },
        "& input": {
          padding: "10px 12px",
        },
      },
    },
  });

  const { onValidate, type } = props;

  const [value, setValue] = React.useState({
    text: "",
    isValid: false,
  });

  let helperText;
  let validRule;
  let placeholder;
  let label;

  switch (type) {
    case "email":
      validRule = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      helperText = "This is an invalid email address";
      placeholder = "Enter your company email address...";
      label = "Email";
      break;

    case "name":
      validRule = /./;
      placeholder = "Enter your full name";
      label = "Full Name";
      break;

    case "password":
      validRule = /^.{8,}$/;
      placeholder = "Enter a password...";
      helperText = "Password must be at least 8 characters";
      label = "Password";
      break;

    case "confirmPassword":
      validRule = /./;
      placeholder = "Confirm a password...";
      helperText = "The password confirmation does not match";
      label = "Confirm Password";
      break;

    case "organizationName":
      validRule = /./;
      placeholder = "e.g. Thatcompany, Inc";
      label = "Organization name";
      break;

    case "siteURL":
      validRule = new RegExp(
        "^(https?:\\/\\/)?((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|((\\d{1,3}\\.){3}\\d{1,3}))(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*(\\?[;&a-z\\d%_.~+=-]*)?" +
          "(\\#[-a-z\\d_]*)?$",
        "i"
      );
      placeholder = "e.g. www.thatwebsite.com";
      label = "Site URL";
      helperText = "This is an invalid URL";
      break;

    default:
      break;
  }

  const onChange = (event) => {
    setValue({
      text: event.target.value,
      isValid: !!event.target.value.match(validRule),
    });
  };

  React.useEffect(() => {
    onValidate(type, value.isValid ? value.text : false);
  }, [onValidate, value, type]);

  return (
    <Grid className={useStyles().root}>
      <Typography variant="body2">{label}</Typography>
      <TextField
        required
        type={
          type === "password" || type === "confirmPassword"
            ? "password"
            : "text"
        }
        fullWidth
        value={value.text}
        onChange={onChange}
        variant="outlined"
        helperText={helperText}
        label={value.text ? label : ""}
        placeholder={placeholder}
        InputLabelProps={{
          shrink: true,
          required: false,
        }}
        error={value.text ? !value.isValid : false}
      />
    </Grid>
  );
};

FormInput.propTypes = {
  type: PropTypes.string.isRequired,
  onValidate: PropTypes.func.isRequired,
};

export default FormInput;
