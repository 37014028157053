import React from "react";
import {
  makeStyles,
  Grid,
  Typography,
  Button,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  TableHead,
  Chip,
  Dialog,
  IconButton,
  ClickAwayListener,
  Backdrop,
  CircularProgress,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { MoreVert, Close } from "@material-ui/icons";
import { useSelector, useDispatch } from "react-redux";

import campaignsApi from "../api/campaigns-api";
import userApi from "../api/user-api";
import InviteUsers from "./InviteUsers";
import AlertMessage from "./AlertMessage";

const ManageUsers = () => {
  const userEmail = useSelector((store) => store.user.email);
  const userRole = useSelector((store) => store.user.role);
  const company = useSelector((store) => store.company);
  const dispatch = useDispatch();

  const [state, setState] = React.useState({
    users: [],
    direction: {
      name: "desc",
      date: "desc",
      role: "desc",
    },
    activeColumn: 0,
    isInviteModal: false,
    alertMessage: "",
    backdrop: true,
    actionPopUp: {},
    isConfirmModal: false,
    currentUser: "",
  });

  React.useEffect(() => {
    campaignsApi.fetchCampaignMembers().then((response) => {
      setState({ ...state, users: response.data.members, backdrop: false });
    });
  }, []); // eslint-disable-line

  const useStyles = makeStyles((theme) => ({
    root: {
      flex: "1",
      padding: "30px 30px 30px 27px",
      flexDirection: "column",
      overflow: "auto",
      maxHeight: "calc(100vh - 122px)",
      flexWrap: "nowrap",

      [theme.breakpoints.down("xs")]: {
        padding: "15px 10px",
      },

      "&::-webkit-scrollbar-track": {
        webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        backgroundColor: "transparent",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        height: "4px",
        backgroundColor: "transparent",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#96BD92",
        border: "2px solid #96BD92",
      },

      "& .MuiSnackbar-anchorOriginBottomCenter": {
        zIndex: "100",
      },
    },

    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-start",
      width: "100%",
      paddingLeft: "16px",

      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
      },

      "& > button": {
        fontWeight: "600",
        fontSize: "16px",
        lineHeight: "25px",
        letterSpacing: "0.3px",

        [theme.breakpoints.down("xs")]: {
          marginTop: "20px",
        },
      },
    },

    table: {
      [theme.breakpoints.down("xs")]: {
        marginTop: "10px",
      },

      "& thead tr th": {
        border: "none",

        [theme.breakpoints.down("xs")]: {
          display: "none",
        },

        "&:nth-child(2)": {
          minWidth: "137px",
        },

        "&:nth-child(3)": {
          minWidth: "124px",
        },

        [`&:nth-child(${state.activeColumn}) span`]: {
          color: theme.palette.primary.main,

          "& .MuiSvgIcon-root": {
            fill: theme.palette.primary.main,
          },
        },

        "& span": {
          textTransform: "uppercase",
          color: "rgba(136, 136, 136, 0.54)",
          fontWeight: "600",
          fontSize: "10px",
          lineHeight: "11px",
          letterSpacing: "1px",

          "&:hover": {
            color: theme.palette.primary.main,

            "& .MuiSvgIcon-root": {
              opacity: "1",
              fill: theme.palette.primary.main,
            },
          },

          "& .MuiSvgIcon-root": {
            opacity: "1",
            fill: "rgba(136, 136, 136, 0.54)",
            marginLeft: "7px",
          },
        },
      },
      "& tbody": {
        "& > tr": {
          [theme.breakpoints.down("xs")]: {
            display: "flex",
            flexDirection: "column",
            position: "relative",
          },

          "&.ManageUsers__deactivated": {
            opacity: "0.5",
            filter: "grayscale(1)",
          },

          "& > td": {
            border: "none",
            padding: "5px 16px",
            minHeight: "58px",

            [theme.breakpoints.down("xs")]: {
              "&:nth-child(4)": {
                display: "flex",
                justifyContent: "center",
                alignSelf: "center",
                position: "absolute",
                right: "4px",
                top: "50%",
                transform: "translateY(-50%)",
              },

              "&:empty": {
                display: "none",
              },
            },

            "&:first-child": {
              display: "flex",
              alignItems: "center",

              "& .MuiGrid-root:first-child": {
                width: "24px",
                height: "24px",
                marginRight: "11px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textTransform: "capitalize",
                color: "#fff",
                backgroundColor: theme.palette.primary.main,
                borderRadius: "50%",
                fontSize: "12px",
                lineHeight: "24px",
              },

              "& .MuiGrid-root p:last-child": {
                fontSize: "12px",
                lineHeight: "11px",
                color: "rgba(0, 0, 0, 0.38)",
                textTransform: "lowercase",

                [theme.breakpoints.down("xs")]: {
                  wordBreak: "break-all",
                },
              },
            },

            "&:nth-child(2)": {
              [theme.breakpoints.down("xs")]: {
                minHeight: "46px",
              },

              "& > p": {
                [theme.breakpoints.down("xs")]: {
                  fontSize: "13px",
                },

                "&:first-child": {
                  display: "none",

                  [theme.breakpoints.down("xs")]: {
                    display: "block",
                    textTransform: "uppercase",
                    color: "rgba(136, 136, 136, 0.54)",
                    fontWeight: "600",
                    fontSize: "10px",
                    lineHeight: "11px",
                    letterSpacing: "1px",
                    marginBottom: "3px",
                  },
                },
              },
            },

            "&:nth-child(3)": {
              "& .MuiChip-root": {
                height: "auto",
                background: "rgba(136, 136, 136, 0.54)",

                "& span": {
                  padding: "0 8px",
                  lineHeight: "24px",
                  color: "#fff",
                  textTransform: "capitalize",
                },
              },

              "& > p": {
                display: "none",

                [theme.breakpoints.down("xs")]: {
                  display: "block",
                  textTransform: "uppercase",
                  color: "rgba(136, 136, 136, 0.54)",
                  fontWeight: "600",
                  fontSize: "10px",
                  lineHeight: "11px",
                  letterSpacing: "1px",
                  marginBottom: "7px",
                },
              },
            },

            "&:nth-child(4)": {
              width: "48px",
            },
          },
        },
      },
    },

    inviteUsersModal: {
      "& .MuiDialog-paper": {
        maxWidth: "750px",
        overflowY: "auto",
        flexFlow: "wrap",

        "&::-webkit-scrollbar-track": {
          webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
          backgroundColor: "transparent",
        },
        "&::-webkit-scrollbar": {
          width: "4px",
          height: "4px",
          backgroundColor: "transparent",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#96BD92",
          border: "2px solid #96BD92",
        },

        "& > button": {
          position: "absolute",
          padding: "10px",
          right: "4px",
          top: "4px",

          "& svg": {
            fill: "rgba(136, 136, 136, 0.54)",
          },
        },

        "& > .MuiGrid-root:nth-child(2)": {
          [theme.breakpoints.up("md")]: {
            margin: "55px 60px 0 9px",
          },
        },
      },
    },

    userAction: {
      marginTop: "-11px",

      "& .MuiPopover-paper": {
        boxShadow:
          "0px 2px 4px rgba(0, 0, 0, 0.2), 0px 1px 10px rgba(0, 0, 0, 0.12)",
        borderRadius: "5px",
        marginLeft: "13px",
      },
    },

    confirmModal: {
      "& .MuiDialog-paper": {
        padding: state.isConfirmModal ? "16px 24px 14px" : "",

        "& > p": {
          "&:first-child": {
            fontWeight: "500",
            fontSize: "20px",
            lineHeight: "32px",
            letterSpacing: "0.15px",
            color: "rgba(0, 0, 0, 0.87)",
            marginBottom: "24px",
          },

          "&:nth-child(2)": {
            letterSpacing: "0.15px",
            color: "rgba(136, 136, 136, 0.54)",
            marginBottom: "22px",
          },
        },

        "& > .MuiGrid-root": {
          display: "flex",
          justifyContent: "flex-end",

          "& button": {
            fontWeight: "600",
            fontSize: "16px",
            lineHeight: "25px",
            color: "#96BD92",

            "&:first-child": {
              marginRight: "15px",
            },
          },
        },
      },
    },
  }));

  const handleSort = (value) => {
    const sortedList = [...state.users];

    sortedList.sort((a, b) => {
      return state.direction[value] === "asc"
        ? value === "date"
          ? new Date(a[value]) - new Date(b[value])
          : a[value].localeCompare(b[value])
        : value === "date"
        ? new Date(b[value]) - new Date(a[value])
        : b[value].localeCompare(a[value]);
    });

    setState({
      ...state,
      users: sortedList,
      direction: {
        ...state.direction,
        [value]: state.direction[value] === "asc" ? "desc" : "asc",
      },
      activeColumn: value === "name" ? 1 : value === "date" ? 2 : 3,
    });
  };

  const handleInviteUsersModal = () => {
    setState({
      ...state,
      isInviteModal: !state.isInviteModal,
    });
  };

  const handleInviteUsers = () => {
    setState({ ...state, isInviteModal: false });
    campaignsApi.fetchCampaignMembers().then((response) => {
      setState({
        ...state,
        isInviteModal: false,
        alertMessage: "The invitation was successfully sent",
        users: response.data.members,
      });
    });
  };

  const deactivateUser = () => {
    setState({
      ...state,
      backdrop: true,
      isConfirmModal: false,
    });
    userApi.deactivateUser(state.currentUser).then(() => {
      const users = [...state.users].map((user) => {
        if (user.email === state.currentUser) {
          dispatch({
            type: "COMPANY",
            payload: {
              ...company,
              activeUsers: {
                admins:
                  user.role === "admin"
                    ? +company.activeUsers.admins - 1
                    : company.activeUsers.admins,
                managers:
                  user.role === "manager"
                    ? +company.activeUsers.managers - 1
                    : company.activeUsers.managers,
                viewers:
                  user.role === "viewer"
                    ? +company.activeUsers.viewers - 1
                    : company.activeUsers.viewers,
              },
            },
          });

          return { ...user, deactivated: true };
        }
        return user;
      });

      setState({
        ...state,
        isConfirmModal: false,
        backdrop: false,
        users,
        alertMessage: "User has been successfully deactivated",
      });
    });
  };

  const activateUser = (email, role) => {
    const activeUsers = {
      admin: +(role === "admin"),
      manager: +(role === "manager"),
      viewer: +(role === "viewer"),
    };
    state.users.map((el) => {
      activeUsers[el.role] = (activeUsers[el.role] || 0) + +!el.deactivated;
      return null;
    });

    setState({
      ...state,
      actionPopUp: { ...state.actionPopUp, [email]: null },
      backdrop: true,
    });
    userApi.activateUser(email).then(() => {
      const users = [...state.users].map((user) => {
        if (user.email === email) {
          dispatch({
            type: "COMPANY",
            payload: {
              ...company,
              activeUsers: {
                admins:
                  user.role === "admin"
                    ? +company.activeUsers.admins + 1
                    : company.activeUsers.admins,
                managers:
                  user.role === "manager"
                    ? +company.activeUsers.managers + 1
                    : company.activeUsers.managers,
                viewers:
                  user.role === "viewer"
                    ? +company.activeUsers.viewers + 1
                    : company.activeUsers.viewers,
              },
            },
          });

          return { ...user, deactivated: false };
        }
        return user;
      });

      setState({
        ...state,
        users,
        actionPopUp: { ...state.actionPopUp, [email]: null },
        backdrop: false,
        alertMessage: "User has been successfully activated",
      });
    });
  };

  const changeUserRole = () => {
    const role = state.isConfirmModal;

    setState({
      ...state,
      backdrop: true,
      isConfirmModal: false,
    });

    userApi.setUserRole(state.currentUser, role).then(() => {
      const users = [...state.users].map((user) =>
        user.email === state.currentUser ? { ...user, role } : user
      );

      setState({
        ...state,
        isConfirmModal: false,
        backdrop: false,
        users,
        alertMessage: "User type has been successfully changed",
      });
    });
  };

  const handleChangeType = (currentUser, isConfirmModal) => {
    const activeUsers = {
      admin: +(isConfirmModal === "admin"),
      manager: +(isConfirmModal === "manager"),
      viewer: +(isConfirmModal === "viewer"),
    };
    state.users.map((el) => {
      activeUsers[el.role] = (activeUsers[el.role] || 0) + +!el.deactivated;
      return null;
    });

    setState({
      ...state,
      actionPopUp: {},
      isConfirmModal,
      currentUser,
    });
  };

  const UserAction = (userObject) => {
    const { user } = userObject;

    return (
      <Menu
        open={!!state.actionPopUp[user.email]}
        onClose={() =>
          setState({
            ...state,
            actionPopUp: { ...state.actionPopUp, [user.email]: null },
          })}
        disableScrollLock
        anchorEl={state.actionPopUp[user.email]}
        keepMounted
        className={useStyles().userAction}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
      >
        {!user.deactivated &&
          userRole === "admin" &&
          (user.role === "manager" || user.role === "viewer") && (
            <MenuItem onClick={() => handleChangeType(user.email, "admin")}>
              Change to admin
            </MenuItem>
          )}

        {!user.deactivated &&
          ((userRole === "admin" &&
            (user.role === "admin" || user.role === "viewer")) ||
            (userRole === "manager" && user.role === "viewer")) && (
            <MenuItem onClick={() => handleChangeType(user.email, "manager")}>
              Change to manager
            </MenuItem>
          )}

        {!user.deactivated &&
          ((userRole === "admin" &&
            (user.role === "admin" || user.role === "manager")) ||
            (userRole === "manager" && user.role === "manager")) && (
            <MenuItem onClick={() => handleChangeType(user.email, "viewer")}>
              Change to viewer
            </MenuItem>
          )}

        {user.deactivated &&
          (userRole === "admin" ||
            (userRole === "manager" &&
              (user.role === "manager" || user.role === "viewer"))) && (
            <MenuItem onClick={() => activateUser(user.email, user.role)}>
                Activate
            </MenuItem>
          )}

        {!user.deactivated &&
          (userRole === "admin" ||
            (userRole === "manager" &&
              (user.role === "manager" || user.role === "viewer"))) && (
            <MenuItem
                onClick={() => {
                setState({
                  ...state,
                  actionPopUp: {},
                  isConfirmModal: "deactivate",
                  currentUser: user.email,
                });
              }}
              >
              Deactivate
              </MenuItem>
          )}
      </Menu>
    );
  };

  return (
    <Grid container className={useStyles().root}>
      <Grid className={useStyles().header}>
        <Typography variant="body1">{`Users (${state.users.length})`}</Typography>
        {userRole !== "viewer" && (
          <Button
            variant="outlined"
            color="primary"
            onClick={handleInviteUsersModal}
          >
            Invite New Users
          </Button>
        )}
      </Grid>
      <Table className={useStyles().table}>
        <TableHead>
          <TableRow>
            <TableCell>
              <TableSortLabel
                direction={state.direction.name}
                onClick={() => handleSort("name")}
              >
                User (email)
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                direction={state.direction.date}
                onClick={() => handleSort("date")}
              >
                Date added
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                direction={state.direction.role}
                onClick={() => handleSort("role")}
              >
                User type
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {state.users.map((user, id) => (
            <TableRow
              key={id} // eslint-disable-line
              hover
              className={user.deactivated ? "ManageUsers__deactivated" : ""}
            >
              <TableCell>
                <Grid>{user.name.charAt(0)}</Grid>
                <Grid>
                  <Typography variant="body2">{user.name}</Typography>
                  <Typography>{user.email}</Typography>
                </Grid>
              </TableCell>
              <TableCell>
                <Typography>Date added:</Typography>
                <Typography variant="body2">{user.date}</Typography>
              </TableCell>
              <TableCell>
                <Typography>User type:</Typography>
                <Chip label={user.role} />
              </TableCell>
              <TableCell>
                {user.email !== userEmail &&
                  (userRole === "admin" ||
                    (userRole === "manager" &&
                      (user.role === "manager" || user.role === "viewer"))) && (
                      <IconButton
                      onClick={(event) =>
                        setState({
                          ...state,
                          actionPopUp: {
                            ...state.actionPopUp,
                            [user.email]: event.currentTarget,
                          },
                        })}
                    >
                      <MoreVert />
                    </IconButton>
                  )}
                {!!state.actionPopUp[user.email] && <UserAction user={user} />}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Dialog
        onClose={handleInviteUsersModal}
        open={state.isInviteModal}
        className={useStyles().inviteUsersModal}
      >
        <IconButton onClick={handleInviteUsersModal}>
          <Close />
        </IconButton>
        <InviteUsers
          inviteUsersSuccess={handleInviteUsers}
          users={state.users}
        />
      </Dialog>
      <Dialog
        onClose={() => setState({ ...state, isConfirmModal: false })}
        open={!!state.isConfirmModal}
        className={useStyles().confirmModal}
      >
        {state.isConfirmModal && (
          <>
            <Typography>
              {`${
                state.isConfirmModal === "deactivate"
                  ? "Deactivate user"
                  : "Change user type"
              }`}
            </Typography>
            <Typography variant="body1">
              {`${
                state.isConfirmModal === "deactivate"
                  ? "Doing this will deactive this user and will prevent them from being able to sign into the product. Are you sure you want to do this?"
                  : "Doing this will change the specified user type for this user. Are you sure you want to do this?"
              }`}
            </Typography>
            <Grid>
              <Button
                color="primary"
                onClick={() => {
                  setState({ ...state, isConfirmModal: false });
                }}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                onClick={() => {
                  if (state.isConfirmModal === "deactivate") {
                    deactivateUser();
                  } else {
                    changeUserRole();
                  }
                }}
              >
                Confirm
              </Button>
            </Grid>
          </>
        )}
      </Dialog>
      {state.alertMessage && (
        <ClickAwayListener
          onClickAway={() =>
            setState({
              ...state,
              alertMessage: "",
            })
          }
        >
          <Grid>
            <AlertMessage
              type={
                state.alertMessage ===
                "You used all team members limit for your subscription plan."
                  ? "error"
                  : "success"
              }
              message={state.alertMessage}
              upgradePlanMsg={
                state.alertMessage ===
                "You used all team members limit for your subscription plan."
              }
            />
          </Grid>
        </ClickAwayListener>
      )}
      <Backdrop
        open={state.backdrop}
        style={{
          zIndex: 5,
        }}
      >
        <CircularProgress color="primary" />
      </Backdrop>
    </Grid>
  );
};

export default ManageUsers;
