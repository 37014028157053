import { combineReducers } from "redux";
import user from "./user";
import company from "./company";
import paymentPlans from "./paymentPlans";

const rootReducer = combineReducers({
  user,
  company,
  paymentPlans,
});

export default rootReducer;
