import React from "react";
import {
  makeStyles,
  Grid,
  Typography,
  Button,
  TextField,
  MenuItem,
  IconButton,
  Tooltip,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import { AddBox, Add, DeleteOutlineOutlined, Group } from "@material-ui/icons";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";

import authApi from "../api/auth-api";
import campaignsApi from "../api/campaigns-api";

const InviteUsers = (props) => {
  const { inviteUsersSuccess, users } = props;

  const company = useSelector((store) => store.company);
  const userRole = useSelector((store) => store.user.role);
  const dispatch = useDispatch();

  const activeUsers = {
    admin: 0,
    manager: 0,
    viewer: 0,
  };
  users.map((el) => {
    activeUsers[el.role] = (activeUsers[el.role] || 0) + +!el.deactivated;
    return null;
  });

  const [state, setState] = React.useState({
    teamMembers: [],
    newMember: {
      id: 0,
      fullName: "",
      email: "",
      userType: userRole,
    },
    usedEmails: [],
    backdrop: false,
    activeUsers,
    upgradePlan: false,
  });

  const useStyles = makeStyles((theme) => ({
    invite: {
      padding: "0 0 35px 32px",
      background: state.inviteSent ? "#FAFAFA" : "#fff",
      display: "flex",

      [theme.breakpoints.down("sm")]: {
        padding: "27px 72px 35px 32px",
        width: "100%",
      },

      [theme.breakpoints.down("xs")]: {
        padding: "27px 72px 35px 20px",
      },

      "& > svg": {
        fill: "#2196F3",
      },

      "& .MuiOutlinedInput-input": {
        padding: "10px 12px",
      },

      "& .MuiOutlinedInput-root:not(.Mui-disabled):hover fieldset": {
        borderColor: "#96BD92",
      },

      "& .InviteUsers": {
        marginLeft: "5px",
        marginTop: "3px",

        [theme.breakpoints.down("sm")]: {
          width: "100%",
        },

        "& > p": {
          fontSize: "16px",
          lineHeight: "19px",
          letterSpacing: "0.15px",
          color: "#000000",
          marginBottom: "20px",
        },
        "&__member": {
          position: "relative",
          marginBottom: "19px",
          flexDirection: "row",

          [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
          },

          "& > button": {
            position: "absolute",
            right: "-30px",
            bottom: "4px",
            transform: "none",
            padding: "0",

            [theme.breakpoints.down("sm")]: {
              right: "-52px",
              bottom: "50%",
              transform: "translateY(50%)",
            },

            "& svg": {
              width: "32px",
              height: "32px",
              backgroundColor: theme.palette.primary.main,
              borderRadius: "24px",
              cursor: "pointer",
              transition: "background 250ms",

              "&:hover": {
                backgroundColor: theme.palette.primary.dark,
              },

              "& path": {
                transform: "scale(0.6) translate(8px, 8px)",
                fill: "#fff",
              },
            },

            "&:not(.MuiIconButton-colorPrimary)": {
              padding: "12px",
              right: "-38px",
              bottom: "-3px",

              [theme.breakpoints.down("sm")]: {
                right: "-60px",
                bottom: "50%",
              },

              "& svg": {
                background: "none",
                height: "24px",
                width: "24px",

                "&:hover": {
                  background: "none",
                },

                "& path": {
                  fill: "rgba(136, 136, 136, 0.54)",
                  transform: "none",
                },
              },
            },
          },
          "& > div": {
            marginRight: "20px",
            marginBottom: "0",
            position: "relative",

            [theme.breakpoints.down("sm")]: {
              marginRight: "0",
              marginBottom: "20px",
            },

            "&:first-child": {
              "& > .MuiFormControl-root": {
                width: "183px",

                [theme.breakpoints.down("sm")]: {
                  width: "100%",
                },
              },
            },

            "&:nth-child(2)": {
              "& > .MuiFormControl-root": {
                width: "210px",

                [theme.breakpoints.down("sm")]: {
                  width: "100%",
                },
              },
            },

            "&:nth-child(3)": {
              "& > .MuiFormControl-root": {
                width: "150px",

                [theme.breakpoints.down("sm")]: {
                  width: "100%",
                },
              },
            },

            "& p": {
              fontWeight: "600",
              fontSize: "10px",
              lineHeight: "11px",
              letterSpacing: "1px",
              color: "#B3B3B3",
              marginBottom: "12px",

              "&.InviteUsers__member__used": {
                position: "absolute",
                bottom: "-26px",
                left: "1px",
                color: "#F44336",
              },
            },
          },
        },
        "& > .MuiGrid-container:last-child": {
          paddingRight: "20px",
          justifyContent: "flex-end",

          [theme.breakpoints.down("sm")]: {
            justifyContent: "flex-start",
          },

          "& button": {
            marginLeft: "auto",
            display: "block",
            padding: "6px 16px",
            fontWeight: "600",
            fontSize: "16px",
            lineHeight: "25px",
            letterSpacing: "0.3px",

            [theme.breakpoints.down("sm")]: {
              marginLeft: "0",
            },

            "&:disabled": {
              color: "rgba(136, 136, 136, 0.54)",
              borderColor: "rgba(136, 136, 136, 0.54)",
            },
          },
        },
      },
    },
    explain: {
      background: "rgba(0, 0, 0, 0.04)",
      padding: "24px 20px 28px",
      width: "100%",

      [theme.breakpoints.down("sm")]: {
        padding: "24px 65px",
      },

      [theme.breakpoints.down("xs")]: {
        padding: "24px 45px",
      },

      "& > p": {
        fontSize: "16px",
        lineHeight: "19px",
        letterSpacing: "0.15px",
        color: "#000000",
        display: "flex",
        alignItems: "center",
        marginBottom: "16px",
        marginLeft: "20px",

        [theme.breakpoints.down("sm")]: {
          marginLeft: "0",
        },

        "& > svg": {
          fill: theme.palette.primary.main,
          marginRight: "8px",
        },
      },

      "& > .MuiGrid-root": {
        display: "flex",
        justifyContent: "center",

        [theme.breakpoints.down("sm")]: {
          flexDirection: "column",
        },

        "& > .MuiGrid-root": {
          maxWidth: "180px",
          marginRight: "40px",

          [theme.breakpoints.down("sm")]: {
            maxWidth: "unset",
            marginBottom: "25px",
            marginRight: "0",
          },

          "&:last-child": {
            marginRight: "0",
          },

          "& > h6": {
            color: theme.palette.primary.main,
            marginBottom: "11px",

            [theme.breakpoints.down("sm")]: {
              marginBottom: "2px",
            },
          },

          "& > span": {
            lineHeight: "16px",
            letterSpacing: "0.4px",
            color: "#000000",
          },
        },
      },
    },
  }));

  const handleMemberChange = (type, id) => (event) => {
    const editedTeamMembers = state.teamMembers.map((member) => {
      if (member.id === id) {
        return { ...member, [type]: event.target.value };
      }
      return member;
    });

    setState({ ...state, teamMembers: editedTeamMembers });
  };

  const handleRemoveMember = (id) => {
    const updatedMembers = [...state.teamMembers];
    let updatedNewMember = { ...state.newMember };

    if (id === -1) {
      updatedNewMember = updatedMembers[state.teamMembers.length - 1];
    }

    updatedMembers.splice(id, 1);

    if (id !== -1) {
      updatedMembers.forEach((member) => {
        if (member.id >= id) member.id -= 1;
      });
      updatedNewMember.id -= 1;
    }

    setState({
      ...state,
      teamMembers: updatedMembers,
      newMember: updatedNewMember,
    });
  };

  const handleNewMemberChange = (type) => (event) => {
    setState({
      ...state,
      newMember: {
        ...state.newMember,
        [type]: event.target.value,
        id: state.teamMembers.length,
      },
    });
  };

  const handleAddMember = () => {
    setState({
      ...state,
      teamMembers: [...state.teamMembers, state.newMember],
      newMember: {
        fullName: "",
        email: "",
        userType: userRole,
      },
    });
  };

  const handleInviteUsers = async () => {
    const membersData = { members: [...state.teamMembers, state.newMember] };

    const newUsers = {
      admin: 0,
      manager: 0,
      viewer: 0,
    };
    membersData.members.map((user) => {
      newUsers[user.userType] = (newUsers[user.userType] || 0) + 1;
      return null;
    });

    let checkedEmails = [];
    setState({ ...state, backdrop: true });
    await Promise.all(
      membersData.members.map(async (member) => {
        await authApi.emaillookup(member.email).then((res) => {
          if (
            res.status === 422 &&
            res.data.message === "This email has alredy been used before"
          ) {
            checkedEmails = [...state.usedEmails, member.email];
          }
        });
      })
    );
    if (
      membersData.members.some((member) => checkedEmails.includes(member.email))
    ) {
      setState({
        ...state,
        usedEmails: checkedEmails,
        backdrop: false,
      });
    } else {
      campaignsApi
        .inviteMembers(company.id, membersData)
        .then(() => {
          dispatch({
            type: "COMPANY",
            payload: {
              ...company,
              activeUsers: {
                admins: +company.activeUsers.admins + +newUsers.admin,
                managers: +company.activeUsers.managers + +newUsers.manager,
                viewers: +company.activeUsers.viewers + +newUsers.viewer,
              },
            },
          });
          inviteUsersSuccess();
          setState({
            ...state,
            inviteSent: true,
            backdrop: false,
          });
        })
        .catch((error) => {
          console.log(error);
          setState({
            ...state,
            inviteSent: true,
            backdrop: false,
          });
        });
    }
  };

  return (
    <>
      <Grid className={useStyles().invite}>
        <AddBox />
        <Grid className="InviteUsers">
          <Typography>Invite users</Typography>
          {state.teamMembers.map((member) => (
            <Grid key={member.id} container className="InviteUsers__member">
              <Grid>
                <Typography>FULL NAME</Typography>
                <TextField
                  placeholder="Enter full name"
                  variant="outlined"
                  type="text"
                  onChange={handleMemberChange("fullName", member.id)}
                  value={member.fullName}
                  disabled={state.inviteSent}
                />
              </Grid>
              <Grid>
                <Typography>EMAIL ADDRESS</Typography>
                <TextField
                  placeholder="Enter email address..."
                  variant="outlined"
                  onChange={handleMemberChange("email", member.id)}
                  value={member.email}
                  disabled={state.inviteSent}
                />
                {state.usedEmails.includes(member.email) && (
                  <Typography className="InviteUsers__member__used">
                    This email is already registered
                  </Typography>
                )}
              </Grid>
              <Grid>
                <Typography>USER TYPE</Typography>
                <TextField
                  select
                  variant="outlined"
                  value={member.userType}
                  SelectProps={{
                    MenuProps: { disableScrollLock: true },
                  }}
                  onChange={handleMemberChange("userType", member.id)}
                  disabled={state.inviteSent}
                >
                  {userRole === "admin" && (
                    <MenuItem value="admin">Admin</MenuItem>
                  )}
                  <MenuItem value="manager">Manager</MenuItem>
                  <MenuItem value="viewer">Viewer</MenuItem>
                </TextField>
              </Grid>
              {!state.inviteSent && (
                <IconButton onClick={() => handleRemoveMember(member.id)}>
                  <DeleteOutlineOutlined />
                </IconButton>
              )}
            </Grid>
          ))}

          <Grid container className="InviteUsers__member">
            <Grid>
              <Typography>FULL NAME</Typography>
              <TextField
                placeholder="Enter full name"
                variant="outlined"
                type="text"
                onChange={handleNewMemberChange("fullName")}
                value={state.newMember.fullName}
                disabled={state.inviteSent}
              />
            </Grid>
            <Grid>
              <Typography>EMAIL ADDRESS</Typography>
              <TextField
                placeholder="Enter email address..."
                variant="outlined"
                onChange={handleNewMemberChange("email")}
                value={state.newMember.email}
                disabled={state.inviteSent}
              />
              {state.usedEmails.includes(state.newMember.email) && (
                <Typography className="InviteUsers__member__used">
                  This email is already registered
                </Typography>
              )}
            </Grid>
            <Grid>
              <Typography>USER TYPE</Typography>
              <TextField
                select
                variant="outlined"
                value={state.newMember.userType}
                SelectProps={{
                  MenuProps: { disableScrollLock: true },
                }}
                onChange={handleNewMemberChange("userType")}
                disabled={state.inviteSent}
              >
                {userRole === "admin" && (
                  <MenuItem value="admin">Admin</MenuItem>
                )}
                <MenuItem value="manager">Manager</MenuItem>
                <MenuItem value="viewer">Viewer</MenuItem>
              </TextField>
            </Grid>
            {state.newMember.fullName &&
            state.newMember.email &&
            !state.inviteSent ? (
              <IconButton color="primary" onClick={handleAddMember}>
                <Add />
              </IconButton>
            ) : state.teamMembers.length !== 0 && !state.inviteSent ? (
              <IconButton onClick={() => handleRemoveMember(-1)}>
                <DeleteOutlineOutlined />
              </IconButton>
            ) : null}
          </Grid>
          <Grid container>
            {state.teamMembers
              .map((el) => el.fullName === "" || el.email === "")
              .some((el) => el === true) ||
            state.newMember.fullName === "" ||
            state.newMember.email === "" ? (
              <Tooltip title="You need to fill all fields" enterTouchDelay={0}>
                <Typography>
                  <Button variant="outlined" color="primary" disabled>
                    Invite Users
                  </Button>
                </Typography>
              </Tooltip>
            ) : (
              <Button
                variant="outlined"
                color="primary"
                onClick={handleInviteUsers}
                disabled={state.inviteSent}
              >
                Invite Users
              </Button>
            )}
          </Grid>
        </Grid>
        <Backdrop open={state.backdrop} style={{ zIndex: 10 }}>
          <CircularProgress color="primary" />
        </Backdrop>
      </Grid>
      <Grid className={useStyles().explain}>
        <Typography>
          <Group />
          User types explained
        </Typography>
        <Grid>
          <Grid>
            <Typography variant="subtitle2">Admins</Typography>
            <Typography variant="caption">
              Admins can invite other users, create campaigns, change/manage
              billing preferences, create segments, modify company, and setup
              integrations.
            </Typography>
          </Grid>
          <Grid>
            <Typography variant="subtitle2">Managers</Typography>
            <Typography variant="caption">
              Managers can do everything admins can do except for
              changing/managing billing preferences and modifying company
              information.
            </Typography>
          </Grid>
          <Grid>
            <Typography variant="subtitle2">Viewers</Typography>
            <Typography variant="caption">
              Viewers are only able to view campaigns including the campaign
              list as well as the campaign details.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

InviteUsers.propTypes = {
  inviteUsersSuccess: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default InviteUsers;
