import React from "react";
import {
  makeStyles,
  Grid,
  Typography,
  TextField,
  Menu as MaterialMenu,
  MenuItem,
  CircularProgress,
  IconButton,
  FormControlLabel,
  Checkbox,
  Backdrop,
  Tooltip,
} from "@material-ui/core";
import { ErrorOutline, MoreVert } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { Line } from "react-chartjs-2";
import ScrollContainer from "react-indiana-drag-scroll";

import campaignsApi from "../api/campaigns-api";
import Menu from "../components/Menu";
import Header from "../components/Header";

const Dashboard = () => {
  const [state, setState] = React.useState({
    filter: "7",
    campaignsActivity: [],
    chartFilterAnchorEl: null,
    chartFilterData: {},
    chartRedraw: false,
    backdrop: true,
    liveCampaignsTotal: 0,
    npsScore: {
      detractors: 0,
      passives: 0,
      promoters: 0,
      responses_number: 0,
      score: 0,
    },
    responseGrowth: {
      responses_count: 0,
      previous_interval_responses_count: 0,
      impressions_count: 0,
      responses_rate: 0,
    },
  });

  React.useEffect(() => {
    setState((prev) => ({ ...prev, chartRedraw: false }));
  }, [state.filter]);

  React.useEffect(() => {
    setState({ ...state, backdrop: true });
    campaignsApi.campaignDashboardStats("last_week").then((response) => {
      setState({
        ...state,
        campaignsActivity: response.data.campaign_activity,
        npsScore: response.data.nps_score,
        liveCampaignsTotal: response.data.live_campaigns_total,
        chartFilterData: response.data.campaign_activity.reduce(
          (prev, campaign) => ({
            ...prev,
            [campaign.name]: Object.keys(prev).length < 3,
          }),
          {}
        ),
        responseGrowth: response.data.response_growth,
        backdrop: false,
      });
    });
  }, []); //eslint-disable-line

  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: "#F9F9F9",
      minHeight: "100vh",
      width: "calc(100% - 120px)",
      padding: "10px 50px 20px",

      [theme.breakpoints.down("sm")]: {
        width: "100%",
        padding: "10px 20px 50px",
      },

      "& .Dashboard": {
        "&__header": {
          "& > .MuiFormControl-root": {
            marginLeft: "26px",

            [theme.breakpoints.down("xs")]: {
              marginLeft: "7px",
            },

            "& .MuiSelect-select": {
              padding: "10px 15px 10px 12px",
              width: "170px",
              boxSizing: "border-box",
            },

            "& .MuiOutlinedInput-root:hover fieldset": {
              borderColor: "#96BD92",
            },
          },

          "& > h6": {
            letterSpacing: "0.15px",
            color: "#000000",
          },
        },
        "&__statistics": {
          marginTop: "13px",
          overflow: "auto",

          "&::-webkit-scrollbar-track": {
            webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
            backgroundColor: "transparent",
          },
          "&::-webkit-scrollbar": {
            width: "4px",
            height: "4px",
            backgroundColor: "transparent",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#96BD92",
            border: "2px solid #96BD92",
          },

          "& > .MuiGrid-root": {
            flexGrow: "1",
            flexShrink: "1",
            flexBasis: "0",
            background: "#FFFFFF",
            border: "1px solid #F7F7F7",
            borderRadius: "4px",
            marginRight: "30px",
            padding: "20px 30px 20px 25px",
            minWidth: "333px",

            "& .MuiPaper-elevation8": {
              boxShadow:
                "0px 2px 4px rgba(0, 0, 0, 0.2), 0px 1px 10px rgba(0, 0, 0, 0.12)",
            },

            [theme.breakpoints.down("sm")]: {
              flexBasis: "unset",
              marginBottom: "22px",
              marginRight: "0",
              width: "100%",
            },

            "&:first-child": {
              [theme.breakpoints.down("md")]: {
                flexBasis: "unset",
                marginBottom: "22px",
                marginRight: "0",
              },
            },

            "&:last-child": {
              marginRight: "0",
              marginBottom: "0",
            },
          },

          "&__NPS": {
            "&__value": {
              marginRight: "20px",

              "& > p": {
                display: "flex",
                alignItems: "center",

                "&:nth-child(2)": {
                  fontWeight: "500",
                  fontSize: "48px",
                  lineHeight: "56px",
                  color: "#000000",
                  marginBottom: "2px",
                },

                "&:nth-child(3)": {
                  fontWeight: "500",
                  fontSize: "12px",
                  lineHeight: "11px",
                },

                "& > svg": {
                  marginLeft: "6px",
                  fill: "rgba(136, 136, 136, 0.54)",
                },
              },
            },
            "&__details": {
              marginLeft: "5px",
              marginTop: "10px",

              "& > .MuiGrid-root": {
                display: "flex",
                justifyContent: "space-between",

                "&:first-child h6": {
                  color: "#4CAF50",
                },

                "&:nth-child(2) h6": {
                  color: "#FF9800",
                },

                "&:last-child h6": {
                  color: "#E31B0C",
                },

                "& > span": {
                  letterSpacing: "1px",
                  marginRight: "16px",
                },
              },
            },
          },

          "&__responses": {
            "& > .MuiGrid-root": {
              maxWidth: "280px",
              margin: "auto",

              "& > .MuiGrid-root": {
                "&:first-child": {
                  "& > p:nth-child(2)": {
                    fontSize: "13px",
                    lineHeight: "24px",
                    letterSpacing: "0.073125px",
                    color: "#96BD92",
                    marginLeft: "7px",
                    background: "rgba(150, 189, 146, 0.19)",
                    borderRadius: "16px",
                    padding: "4px 14px",
                  },

                  "& > svg": {
                    marginLeft: "9px",
                    fill: "rgba(136, 136, 136, 0.54)",
                  },
                },

                "&:nth-child(2)": {
                  marginBottom: "6px",
                  alignItems: "baseline",

                  "& > p": {
                    "&:first-child": {
                      fontWeight: "500",
                      fontSize: "48px",
                      lineHeight: "56px",
                      marginRight: "5px",
                      width:
                        state.responseGrowth.responses_count > 999
                          ? "100%"
                          : "auto",
                    },

                    "&:nth-child(2)": {
                      fontSize: "12px",
                      lineHeight: "11px",
                      marginRight: "5px",
                      whiteSpace: "pre",
                      color: "rgba(0, 0, 0, 0.38)",
                    },
                  },
                },
              },

              "& > p": {
                display: "inline-block",
                fontWeight: "500",
                fontSize: "12px",
                lineHeight: "11px",
                color: "rgba(0, 0, 0, 0.38)",
              },

              "& > a": {
                display: "inline-block",
                fontWeight: "500",
                fontSize: "12px",
                lineHeight: "11px",
                color: theme.palette.primary.main,
                marginLeft: "4px",
              },
            },
          },

          "&__rate": {
            "& > .MuiGrid-root": {
              maxWidth: "280px",
              margin: "auto",

              "& > .MuiGrid-root": {
                "&:first-child": {
                  "& > p:nth-child(2)": {
                    fontSize: "13px",
                    lineHeight: "24px",
                    letterSpacing: "0.073125px",
                    color: "#D4C27F",
                    marginLeft: "8px",
                    background: "rgba(234, 218, 157, 0.2)",
                    borderRadius: "16px",
                    padding: "4px 14px",
                  },
                },

                "&:nth-child(2)": {
                  alignItems: "center",
                  justifyContent: "space-between",

                  "& > p": {
                    "&:first-child": {
                      fontWeight: "500",
                      fontSize: "48px",
                      lineHeight: "56px",
                    },
                  },

                  "& > .MuiGrid-root": {
                    position: "relative",
                    width: "68px",
                    height: "68px",

                    "& > .MuiCircularProgress-root": {
                      position: "absolute",

                      "&:first-child": {
                        color: "#EEEEEE",
                      },

                      "&:last-child": {
                        color: "#D4C27F",
                        transform: "rotate(-90deg)",
                      },
                    },
                  },
                },
              },

              "& > p": {
                fontWeight: "500",
                fontSize: "12px",
                lineHeight: "11px",
                color: "rgba(0, 0, 0, 0.38)",
                display: "inline-block",

                "&:last-child": {
                  color: "rgba(0, 0, 0, 0.87)",
                  marginLeft: "4px",
                },
              },
            },
          },
        },
        "&__activity": {
          marginTop: "30px",
          display: "flex",
          flexDirection: "column",
          background: "#FFFFFF",
          border: "1px solid #F6F6F6",
          borderRadius: "4px",
          padding: "0 0 10px 15px",

          "& > .MuiGrid-root": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "10px 7px 20px 36px",

            "& > button": {
              color: "rgb(189, 188, 182)",
              padding: "6px",
            },
          },

          "& > div:last-child": {
            marginRight: "30px",
            overflowX: "auto",
            flexDirection: "row-reverse",
            display: "flex",
            cursor:
              state.campaignsActivity[0] &&
              state.campaignsActivity[0].data.length > 60
                ? "grab"
                : "initial",

            "&.indiana-scroll-container--dragging": {
              cursor: "grabbing",
            },

            [theme.breakpoints.down("sm")]: {
              marginRight: "15px",
            },

            "&::-webkit-scrollbar-track": {
              webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
              backgroundColor: "transparent",
            },
            "&::-webkit-scrollbar": {
              width: "4px",
              height: "4px",
              backgroundColor: "transparent",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#96BD92",
              border: "2px solid #96BD92",
            },

            "& > .MuiGrid-root": {
              minHeight: "300px",
              height: "calc(100vh - 450px)",
              width: "100%",
              minWidth:
                state.campaignsActivity[0] &&
                state.campaignsActivity[0].data.length > 60
                  ? `${state.campaignsActivity[0].data.length * 15}px`
                  : "100%",

              [theme.breakpoints.down("md")]: {
                height: "300px",
              },
            },
          },
        },
      },
    },
    campaignChartFilter: {
      "& .MuiPopover-paper": {
        maxHeight: "245px",
        overflowY: "auto",

        "&::-webkit-scrollbar-track": {
          webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
          backgroundColor: "transparent",
        },
        "&::-webkit-scrollbar": {
          width: "4px",
          height: "4px",
          backgroundColor: "transparent",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#96BD92",
          border: "2px solid #96BD92",
        },

        "& li": {
          padding: "0",

          "& > label": {
            padding: "1px 16px",
            marginRight: "0",

            "& > span": {
              padding: "5px",
            },
          },
        },
      },
    },
  }));

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const getColor = (percent) => {
    const canvasElement = document.createElement("CANVAS");
    canvasElement.width = 101;
    canvasElement.height = 1;

    const context = canvasElement.getContext("2d");

    const gradient = context.createLinearGradient(0, 0, 101, 0);

    gradient.addColorStop(0, "#96BD92");
    gradient.addColorStop(1, "#2196F3");

    context.fillStyle = gradient;
    context.fillRect(0, 0, 101, 1);

    const color = context.getImageData(percent, 0, 1, 1);
    const rgba = color.data;

    return `rgb(${rgba[0]}, ${rgba[1]}, ${rgba[2]})`;
  };

  const filterDate = (range) => {
    setState({ ...state, backdrop: true });
    const filterRequest =
      range === "7" ? "last_week" : range === "allTime" ? "" : `${range}_days`;

    campaignsApi.campaignDashboardStats(filterRequest).then((response) => {
      setState({
        ...state,
        filter: range,
        chartRedraw:
          (+range ||
            Math.max(
              ...response.data.campaign_activity.map(
                (survey) => survey.data.length
              ),
              1
            )) > 60 ||
          Math.max(
            ...state.campaignsActivity.map((survey) => survey.data.length),
            1
          ) > 60,
        campaignsActivity: response.data.campaign_activity,
        npsScore: response.data.nps_score,
        liveCampaignsTotal: response.data.live_campaigns_total,
        responseGrowth: response.data.response_growth,
        backdrop: false,
      });
    });
  };

  return (
    <Grid container>
      <Menu activePage="Dashboard" />
      <Grid className={useStyles().root}>
        <Header />
        <Grid className="Dashboard">
          <Grid
            container
            alignItems="center"
            justify="space-between"
            className="Dashboard__header"
          >
            <Typography variant="h6">Dashboard</Typography>
            <TextField
              select
              value={state.filter}
              variant="outlined"
              onChange={(event) => filterDate(event.target.value)}
              SelectProps={{
                MenuProps: { disableScrollLock: true },
              }}
            >
              <MenuItem value="allTime">All Time</MenuItem>
              <MenuItem value="7">Last 7 Days</MenuItem>
              <MenuItem value="30">Last 30 Days</MenuItem>
              <MenuItem value="60">Last 60 Days</MenuItem>
              <MenuItem value="90">Last 90 Days</MenuItem>
            </TextField>
          </Grid>
          <Grid
            container
            alignItems="stretch"
            justify="space-between"
            className="Dashboard__statistics"
          >
            <Grid
              container
              justify="center"
              className="Dashboard__statistics__NPS"
            >
              <Grid className="Dashboard__statistics__NPS__value">
                <Typography variant="body1">
                  NPS Score
                  <Tooltip
                    title={(
                      <>
                        NPS or Net Promoter Score measures customer loyalty for
                        your company with a single question.
                        {/* To learn more about
                        NPS and best practices
                        <a target="_blank" href="/">
                          click here
                        </a> */}
                      </>
                    )}
                    arrow
                    interactive
                    enterTouchDelay={0}
                  >
                    <ErrorOutline />
                  </Tooltip>
                </Typography>
                <Typography>{state.npsScore.score}</Typography>
                <Typography variant="body1">{`${state.npsScore.responses_number} Responses`}</Typography>
              </Grid>
              <Grid className="Dashboard__statistics__NPS__details">
                <Grid>
                  <Typography variant="overline">Promoters</Typography>
                  <Typography variant="h6">
                    {`${Math.round(
                      (state.npsScore.promoters * 100) /
                        state.npsScore.responses_number || 0
                    )}%`}
                  </Typography>
                </Grid>
                <Grid>
                  <Typography variant="overline">Passives</Typography>
                  <Typography variant="h6">
                    {`${Math.round(
                      (state.npsScore.passives * 100) /
                        state.npsScore.responses_number || 0
                    )}%`}
                  </Typography>
                </Grid>
                <Grid>
                  <Typography variant="overline">Detractors</Typography>
                  <Typography variant="h6">
                    {`${Math.round(
                      (state.npsScore.detractors * 100) /
                        state.npsScore.responses_number || 0
                    )}%`}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid className="Dashboard__statistics__responses">
              <Grid>
                <Grid container alignItems="center">
                  <Typography variant="body1">Survey responses</Typography>
                  {state.responseGrowth.responses_growth_percentage && (
                    <Typography>
                      {`${
                        state.responseGrowth.responses_growth_percentage >= 0
                          ? "+"
                          : "-"
                      } ${Math.round(
                        Math.abs(
                          state.responseGrowth.responses_growth_percentage
                        )
                      )}%`}
                    </Typography>
                  )}
                  <Tooltip
                    title="Survey responses are the total number of completed responses you have received per campaign. This calculation is used as the denominator for many of the analytics provided."
                    arrow
                    enterTouchDelay={0}
                    interactive
                  >
                    <ErrorOutline />
                  </Tooltip>
                </Grid>
                <Grid container>
                  <Typography>
                    {state.responseGrowth.responses_count
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </Typography>
                  {state.filter !== "allTime" && (
                    <Typography>
                      {`Prev ${
                        state.filter
                      }: ${state.responseGrowth.previous_interval_responses_count
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                    </Typography>
                  )}
                  <Line
                    data={(canvas) => {
                      const ctx = canvas.getContext("2d");
                      const gradient = ctx.createLinearGradient(0, 0, 0, 22);
                      gradient.addColorStop(0, "rgb(150,189,146)");
                      gradient.addColorStop(1, "rgba(157, 193, 153, 0.08)");

                      return {
                        labels: ["1", "2", "3", "4", "5", "6", "7", "8"],
                        datasets: [
                          {
                            pointRadius: 0,
                            borderWidth: 0,
                            borderColor: gradient,
                            backgroundColor: gradient,
                            fill: "start",
                            data: [0, 10, 15, 10, 15, 20, 18, 30],
                          },
                        ],
                      };
                    }}
                    width={81}
                    height={22}
                    options={{
                      legend: {
                        display: false,
                      },
                      maintainAspectRatio: false,
                      responsive: false,
                      scales: {
                        xAxes: [
                          {
                            display: false,
                          },
                        ],
                        yAxes: [
                          {
                            display: false,
                          },
                        ],
                      },
                    }}
                  />
                </Grid>
                <Typography>{`${state.liveCampaignsTotal} Live Campaigns:`}</Typography>
                <Link to="/campaigns">View Campaigns</Link>
              </Grid>
            </Grid>
            <Grid className="Dashboard__statistics__rate">
              <Grid>
                <Grid container alignItems="center">
                  <Typography variant="body1">Response Rate</Typography>
                  {state.responseGrowth.impressions_growth_percentage && (
                    <Typography>
                      {`${
                        state.responseGrowth.impressions_growth_percentage >= 0
                          ? "+"
                          : "-"
                      } ${Math.round(
                        Math.abs(
                          state.responseGrowth.impressions_growth_percentage
                        )
                      )}%`}
                    </Typography>
                  )}
                </Grid>
                <Grid container>
                  <Typography>
                    {`${Math.round(state.responseGrowth.responses_rate)}%`}
                  </Typography>
                  <Grid>
                    <CircularProgress
                      variant="determinate"
                      size={68}
                      value={100}
                    />
                    <CircularProgress
                      variant="static"
                      size={68}
                      value={state.responseGrowth.responses_rate}
                    />
                  </Grid>
                </Grid>
                <Typography>Out Of</Typography>
                <Typography>
                  {`${state.responseGrowth.impressions_count
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} impressions`}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid className="Dashboard__activity">
            <Grid>
              <Typography variant="body1">Campaigns Activity</Typography>
              <IconButton
                onClick={(event) =>
                  setState({
                    ...state,
                    chartFilterAnchorEl: event.currentTarget,
                  })
                }
              >
                <MoreVert />
              </IconButton>
              <MaterialMenu
                anchorEl={state.chartFilterAnchorEl}
                keepMounted
                open={!!state.chartFilterAnchorEl}
                className={useStyles().campaignChartFilter}
                onClose={() =>
                  setState({ ...state, chartFilterAnchorEl: null })
                }
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "left" }}
                disableScrollLock
              >
                <MenuItem>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        color="primary"
                        checked={Object.keys(state.chartFilterData).every(
                          (survey) => state.chartFilterData[survey] === true
                        )}
                        onClick={() => {
                          const allInclusiveFilters = {
                            ...state.chartFilterData,
                          };
                          Object.keys(allInclusiveFilters).map(
                            (survey) =>
                              (allInclusiveFilters[survey] = !Object.keys(
                                state.chartFilterData
                              ).every(
                                (survey) =>
                                  state.chartFilterData[survey] === true
                              ))
                          );
                          setState({
                            ...state,
                            chartFilterData: allInclusiveFilters,
                          });
                        }}
                      />
                    )}
                    label="All Campaigns"
                  />
                </MenuItem>
                {Object.keys(state.chartFilterData).map((campaign) => (
                  <MenuItem key={campaign}>
                    <FormControlLabel
                      control={(
                        <Checkbox
                          color="primary"
                          checked={state.chartFilterData[campaign]}
                          onClick={() =>
                            setState({
                              ...state,
                              chartFilterData: {
                                ...state.chartFilterData,
                                [campaign]: !state.chartFilterData[campaign],
                              },
                            })}
                        />
                      )}
                      label={campaign}
                    />
                  </MenuItem>
                ))}
              </MaterialMenu>
            </Grid>
            <ScrollContainer hideScrollbars={false}>
              <Grid>
                <Line
                  redraw={state.chartRedraw}
                  data={(canvas) => {
                    const ctx = canvas.getContext("2d");
                    const gradient = ctx.createLinearGradient(
                      0,
                      ctx.canvas.height *
                        (ctx.canvas.height > 300 ? 0.87 : 0.8),
                      0,
                      0
                    );
                    const filteredDataset = [...state.campaignsActivity].map(
                      (campaign) => {
                        return {
                          ...campaign,
                          active: state.chartFilterData[campaign.name],
                        };
                      }
                    );

                    return {
                      labels: [
                        ...Array(
                          +state.filter ||
                            Math.max(
                              ...state.campaignsActivity.map(
                                (survey) => survey.data.length
                              ),
                              1
                            )
                        ),
                      ]
                        .map((date, i) => {
                          const day = new Date();
                          day.setDate(day.getDate() - i);
                          return day.getDate();
                        })
                        .reverse(),
                      datasets: filteredDataset.map((statistics, id) => {
                        gradient.addColorStop(
                          0,
                          getColor((id / state.campaignsActivity.length) * 100)
                            .replace("rgb", "rgba")
                            .replace(")", ",0)")
                        );
                        gradient.addColorStop(
                          1,
                          getColor((id / state.campaignsActivity.length) * 100)
                            .replace("rgb", "rgba")
                            .replace(")", ",1)")
                        );

                        return {
                          pointRadius: 3,
                          borderWidth: 1,
                          pointBorderWidth: 1,
                          pointBorderColor: statistics.active
                            ? getColor(
                                (id / state.campaignsActivity.length) * 100
                              )
                            : "transparent",
                          pointBackgroundColor: statistics.active
                            ? "#fff"
                            : "transparent",
                          borderColor: statistics.active
                            ? getColor(
                                (id / state.campaignsActivity.length) * 100
                              )
                            : "transparent",
                          backgroundColor: statistics.active
                            ? gradient
                            : "transparent",
                          fill: "start",
                          data: statistics.active
                            ? [
                                ...new Array(
                                  state.filter === "allTime"
                                    ? Math.max(
                                        ...state.campaignsActivity.map(
                                          (survey) => survey.data.length
                                        )
                                      ) - statistics.data.length || 0
                                    : +state.filter - statistics.data.length ||
                                      0
                                ),
                                ...statistics.data,
                              ]
                            : Array.from(Array(7)).map(() => 0),
                          lineTension: 0,
                          label: statistics.active
                            ? statistics.name
                            : new Date().getTime(),
                        };
                      }),
                    };
                  }}
                  options={{
                    maintainAspectRatio: false,
                    legend: false,
                    responsive: true,
                    scales: {
                      xAxes: [
                        {
                          ticks: {
                            fontColor: "rgba(190, 190, 190, 0.87)",
                            beginAtZero: true,
                          },
                          gridLines: {
                            display: true,
                            drawBorder: true,
                            drawOnChartArea: false,
                            color: "rgba(190, 190, 190, 0.87)",
                          },
                          scaleLabel: {
                            display: true,
                            labelString: "TIME (DAYS)",
                            fontFamily: "Avenir Next",
                            fontColor: "#9DC199",
                            lineHeight: "20px",
                          },
                        },
                      ],
                      yAxes: [
                        {
                          ticks: {
                            fontColor: "rgba(190, 190, 190, 0.87)",
                            beginAtZero: true,
                            precision: 0,
                          },
                          gridLines: {
                            display: true,
                            drawBorder: true,
                            drawOnChartArea: false,
                            color: "rgba(190, 190, 190, 0.87)",
                          },
                          scaleLabel: {
                            display: true,
                            labelString: "RESPONSES",
                            fontFamily: "Avenir Next",
                            fontColor: "#9DC199",
                            lineHeight: "15px",
                          },
                        },
                      ],
                    },
                    tooltips: {
                      displayColors: false,
                      mode: "index",
                      intersect: false,
                      callbacks: {
                        title(tooltipItem, data) {
                          const date = new Date();

                          date.setDate(
                            date.getDate() -
                              (data.labels.length - tooltipItem[0].index - 1)
                          );

                          return `${monthNames[date.getMonth()]} ${
                            tooltipItem[0].label
                          }, ${date.getFullYear()}`;
                        },
                        label(tooltipItem) {
                          return state.chartFilterData[
                            state.campaignsActivity[tooltipItem.datasetIndex]
                              .name
                          ]
                            ? `${
                                state.campaignsActivity[
                                  tooltipItem.datasetIndex
                                ].name
                              }: ${tooltipItem.value}`
                            : null;
                        },
                      },
                    },
                  }}
                />
              </Grid>
            </ScrollContainer>
          </Grid>
          <Backdrop open={state.backdrop} style={{ zIndex: 10 }}>
            <CircularProgress color="primary" />
          </Backdrop>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Dashboard;
