import { securedAxiosInstance } from "./api";

const billingApi = {
  createCheckoutSession: (priceId, responses_number) =>
    securedAxiosInstance
      .post("/create_checkout_session", {
        priceId,
        responses_number,
      })
      .then((response) => response)
      .catch((error) => error),

  fetchBillingConfig: () =>
    securedAxiosInstance
      .get("/billing_config")
      .then((response) => response)
      .catch((error) => error),

  customerPortal: () =>
    securedAxiosInstance
      .get("/customer_portal")
      .then((response) => response)
      .catch((error) => error),

  setFreemium: () =>
    securedAxiosInstance
      .patch("/set_freemium")
      .then((response) => response)
      .catch((error) => error),
};

export default billingApi;
