import React from "react";
import {
  makeStyles,
  Grid,
  Typography,
  Divider,
  Checkbox,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import authApi from "../api/auth-api";
import FormInput from "../components/FormInput";
import FormSelector from "../components/FormSelector";
import SubmitBtn from "../components/SubmitBtn";
import Logo from "../components/Logo";
import AlertMessage from "../components/AlertMessage";
import GoogleAuth from "../components/GoogleAuth";
import handsImg from "../assets/images/hands.png";

const SignUp = (props) => {
  const { location } = props;

  const [sighUpInfo, setSighUpInfo] = React.useState({
    isSubmitted: false,
    title: location.profileNotCompleted
      ? "We’re almost there"
      : "Sign Up, It’s Free!",
    description: location.profileNotCompleted
      ? "Tell us a little about yourself"
      : "Just a couple steps",
    subbmitBtnText: location.profileNotCompleted ? "Continue" : "Get Started",
    termsAccepted: false,
    emailAlredyUsed: false,
    backdrop: false,
    step: location.profileNotCompleted ? 3 : 1,
    checkEmail: location.checkEmail,
    userDeactivated: location.userDeactivated,
  });

  const [inputValid, setInputValid] = React.useState(false);
  const [newUser, setNewUser] = React.useState({
    email: "",
    name: "",
    password: "",
    role: "",
    aim: "",
    companySize: "",
    organizationName: "",
    siteURL: "",
  });

  const useStyles = makeStyles((theme) => ({
    form: {
      minHeight: "100vh",
      justifyContent: "flex-end",
      display: "flex",

      [theme.breakpoints.down("lg")]: {
        justifyContent: "center",
      },

      [theme.breakpoints.down("sm")]: {
        minHeight: sighUpInfo.step === 3 ? "700px" : "670px",
        height: "100vh",
      },

      [theme.breakpoints.down("xs")]: {
        minHeight: sighUpInfo.step === 1 ? "568px" : "670px",
        padding: "30px",
      },

      [theme.breakpoints.down(371)]: {
        padding: "10px",
      },

      "& .signIn-form-wrap": {
        maxWidth: "420px",
        marginRight: "105px",
        position: "relative",

        [theme.breakpoints.down("lg")]: {
          marginRight: "0",
        },

        "& > .MuiGrid-root:first-child": {
          position: "absolute",
          top: "46px",

          [theme.breakpoints.down("lg")]: {
            top: "49px",
          },

          [theme.breakpoints.down("sm")]: {
            left: "-45px",
          },

          [theme.breakpoints.down("xs")]: {
            left: "0",
            top: "0",
          },
        },

        "& h4": {
          marginBottom: "7px",
          fontWeight: "600",

          [theme.breakpoints.down("xs")]: {
            marginTop: sighUpInfo.step === 2 ? "20px" : "98px",
          },

          [theme.breakpoints.down(600)]: {
            marginTop: "0px",
          },
        },

        "& h5": {
          marginBottom: sighUpInfo.step > 1 ? "26px" : "32px",
        },

        "& form": {
          marginTop: sighUpInfo.step > 1 ? "33px" : "23px",

          "& .MuiFormHelperText-contained": {
            opacity: 0,
            cursor: "default",
          },

          "& .Mui-error": {
            "& fieldset": {
              borderColor: sighUpInfo.isSubmitted
                ? "#F44336"
                : "rgba(0, 0, 0, 0.23)",
            },

            "& + .MuiFormHelperText-contained": {
              marginLeft: "0",
              opacity: sighUpInfo.isSubmitted ? "1" : "0",
            },

            "&.Mui-focused fieldset, &:hover fieldset": {
              borderColor: sighUpInfo.isSubmitted
                ? "#F44336"
                : theme.palette.primary.main,
            },

            "&.MuiInputLabel-shrink": {
              color: sighUpInfo.isSubmitted
                ? "#F44336"
                : "rgba(136, 136, 136, 0.54)",

              "&.Mui-focused": {
                color: sighUpInfo.isSubmitted
                  ? "#f44336"
                  : theme.palette.primary.main,
              },
            },
          },

          "& > .MuiGrid-root:not(:first-child) > p": {
            marginTop: sighUpInfo.step > 1 ? "23px" : "0",
          },

          "& a": {
            textDecoration: "none",
            display: "inline-block",
            letterSpacing: "0.15px",

            "& p": {
              transition: "0.25s color",

              "&:hover": {
                color: theme.palette.primary.dark,
              },
            },
          },

          "& .btn-wrap": {
            marginTop: sighUpInfo.step > 1 ? "38px" : "43px",
            paddingLeft: sighUpInfo.step > 1 ? "97px" : "29px",
            paddingRight: sighUpInfo.step === 2 ? "24px" : "0",
            justifyContent: sighUpInfo.step > 2 ? "flex-end" : "space-between",

            [theme.breakpoints.down("xs")]: {
              paddingLeft: "0",
              paddingRight: "0",
            },

            "& button[type='submit']": {
              backgroundColor:
                sighUpInfo.step === 2 && !sighUpInfo.termsAccepted
                  ? "#E0E0E0"
                  : theme.palette.primary.main,
              color:
                sighUpInfo.step === 2 && !sighUpInfo.termsAccepted
                  ? "rgba(0, 0, 0, 0.87)"
                  : "#fff",
              "&:hover": {
                boxShadow: "none",
                backgroundColor:
                  sighUpInfo.step === 2 && !sighUpInfo.termsAccepted
                    ? "#E0E0E0"
                    : theme.palette.primary.dark,
              },
            },
          },

          "& > p": {
            display: "flex",
            alignItems: "center",
            marginTop: "30px",
            color: "rgba(0, 0, 0, 0.87)",

            [theme.breakpoints.down(500)]: {
              fontSize: "10px",
            },

            [theme.breakpoints.down(380)]: {
              fontSize: "9px",
            },

            "& a": {
              fontWeight: "700",
              color: theme.palette.primary.main,
              letterSpacing: "inherit",
            },

            "& .MuiCheckbox-root": {
              marginLeft: "-12px",

              [theme.breakpoints.down(500)]: {
                paddingRight: "5px",
              },
            },
          },
        },
      },

      "& .MuiBackdrop-root": {
        zIndex: "1",
      },
    },
    intro: {
      backgroundColor: "#EADA9D",
      color: "#fff",
      paddingTop: "191px",
      paddingLeft: "64px",
      paddingBottom: "40px",

      [theme.breakpoints.down("lg")]: {
        paddingRight: "98px",
        paddingLeft: "54px",
        paddingTop: "53px",
        paddingBottom: "40px",
      },

      [theme.breakpoints.down("md")]: {
        paddingRight: "65px",
        paddingLeft: "48px",
        paddingTop: "65px",
        paddingBottom: "40px",
      },

      [theme.breakpoints.down("sm")]: {
        display: "none",
      },

      "& h4": {
        marginBottom: "18px",
        fontWeight: "600",
        maxWidth: "580px",

        [theme.breakpoints.down("lg")]: {
          marginBottom: "30px",
        },

        [theme.breakpoints.down("md")]: {
          marginBottom: "35px",
        },
      },

      "& img": {
        [theme.breakpoints.down("lg")]: {
          width: "100%",
          marginLeft: "-25px",
        },

        [theme.breakpoints.down(1700)]: {
          maxHeight: "530px",
          maxWidth: "560px",
        },

        [theme.breakpoints.down(1500)]: {
          maxHeight: "430px",
          maxWidth: "460px",
        },

        [theme.breakpoints.down("md")]: {
          marginLeft: "-18px",
        },
      },
    },
  }));

  const handleSubmit = (e) => {
    e.preventDefault();
    switch (sighUpInfo.step) {
      case 1:
        if (inputValid) {
          setSighUpInfo({ ...sighUpInfo, backdrop: true });
          authApi.emaillookup(inputValid).then((res) => {
            if (res.data.email) {
              setNewUser({ ...newUser, email: res.data.email });
              setSighUpInfo({
                ...sighUpInfo,
                title: "Welcome!",
                description: "Please complete account details",
                subbmitBtnText: "Continue",
                isSubmitted: false,
                step: 2,
              });
            } else if (
              res.status === 422 &&
              res.data.message === "This email has alredy been used before"
            ) {
              setSighUpInfo({
                ...sighUpInfo,
                emailAlredyUsed: true,
                backdrop: false,
              });
            } else {
              console.log("Something went wrong");
            }
          });
        } else {
          setSighUpInfo({ ...sighUpInfo, isSubmitted: true });
        }
        break;
      case 2:
        if (inputValid) {
          setSighUpInfo({
            ...sighUpInfo,
            title: "We’re almost there",
            description: "Tell us a little about yourself",
            subbmitBtnText: "Continue",
            isSubmitted: false,
            step: 3,
          });
        } else {
          setSighUpInfo({ ...sighUpInfo, isSubmitted: true });
        }
        break;
      case 3:
        setSighUpInfo({
          ...sighUpInfo,
          title: "We’re almost there",
          description: "Tell us a little about your organization",
          subbmitBtnText: "Start Using Prove",
          isSubmitted: false,
          step: 4,
        });
        break;
      case 4:
        if (inputValid) {
          setSighUpInfo({ ...sighUpInfo, backdrop: true });
          if (location.profileNotCompleted) {
            newUser.id = location.userId;
            authApi.updateProfileWithGoogle(newUser).then(() => {
              window.location.replace("/campaigns/new");
            });
          } else {
            authApi
              .signup(newUser)
              .then(() => window.location.replace("/campaigns/new"));
          }
        } else {
          setSighUpInfo({ ...sighUpInfo, isSubmitted: true });
        }
        break;
      default:
        break;
    }
  };

  const handleInput = (type, data) => {
    setInputValid(data);
    if (data && data !== newUser[type]) {
      setNewUser({ ...newUser, [type]: data });
      setSighUpInfo({
        ...sighUpInfo,
        emailAlredyUsed: false,
        checkEmail: false,
        userDeactivated: false,
      });
    }
  };

  const handleCheckBox = () => {
    setSighUpInfo({
      ...sighUpInfo,
      termsAccepted: !sighUpInfo.termsAccepted,
    });
  };

  return (
    <Grid container>
      <Grid item xs={12} md={6} className={useStyles().form}>
        <Grid
          container
          justify="center"
          direction="column"
          className="signIn-form-wrap"
        >
          <Grid
            onClick={() =>
              setSighUpInfo({
                isSubmitted: false,
                title: "Sign Up, It’s Free!",
                description: "Just a couple steps",
                subbmitBtnText: "Get Started",
                termsAccepted: false,
                emailAlredyUsed: false,
                backdrop: false,
                step: 1,
                checkEmail: location.checkEmail,
                userDeactivated: location.userDeactivated,
              })}
          >
            <Logo />
          </Grid>
          <Typography variant="h4">{sighUpInfo.title}</Typography>
          <Typography variant="h5">{sighUpInfo.description}</Typography>
          {sighUpInfo.step === 1 && <GoogleAuth />}
          <Divider />
          <form onSubmit={handleSubmit}>
            {/* 1 step */}
            {sighUpInfo.step === 1 && (
              <>
                <FormInput type="email" onValidate={handleInput} />
                {sighUpInfo.emailAlredyUsed ? (
                  <AlertMessage
                    type="error"
                    message="This email has already been used before"
                  />
                ) : null}
              </>
            )}
            {/* 2 step */}
            {sighUpInfo.step === 2 && (
              <>
                <FormInput type="name" onValidate={handleInput} />
                <FormInput type="password" onValidate={handleInput} />

                <Typography variant="body2">
                  <Checkbox
                    required
                    color="primary"
                    onChange={handleCheckBox}
                  />
                  I’ve read and accepted the&nbsp;
                  <a
                    href="http://tryprove.com/terms-and-conditions"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms of Service
                  </a>
                  <span>&nbsp;&&nbsp;</span>
                  <a
                    href="http://tryprove.com/privacy-policy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </a>
                  <span>.</span>
                </Typography>
              </>
            )}
            {/* 3 step */}
            {sighUpInfo.step === 3 && (
              <>
                <FormSelector
                  title="Whats your role?"
                  list={[
                    "Product Design",
                    "UXR",
                    "Engineering",
                    "Product",
                    "Customer Experience",
                    "Other",
                  ]}
                  handleInput={handleInput}
                  type="role"
                />
                <FormSelector
                  title="What are you looking to achieve with Prove?"
                  list={[
                    "Gain better user insights on our product",
                    "Improve conversion rates",
                    "Provide teams with more data to make better decisions",
                    "Other",
                  ]}
                  handleInput={handleInput}
                  type="aim"
                />
                <FormSelector
                  title="How big is your company?"
                  list={["1-10", "10-20", "20-50", "50-100", "100+"]}
                  handleInput={handleInput}
                  type="companySize"
                />
              </>
            )}
            {/* 4 step */}
            {sighUpInfo.step === 4 && (
              <>
                <FormInput type="organizationName" onValidate={handleInput} />
                <FormInput type="siteURL" onValidate={handleInput} />
              </>
            )}
            <Grid container className="btn-wrap" alignItems="center">
              {sighUpInfo.step < 3 && (
                <Link to="/sign-in">
                  <Typography variant="body2" color="primary">
                    Have an account?
                    <strong> Sign In</strong>
                  </Typography>
                </Link>
              )}
              <SubmitBtn btnText={sighUpInfo.subbmitBtnText} />
            </Grid>
          </form>
        </Grid>
        {sighUpInfo.checkEmail ? (
          <AlertMessage
            type="info"
            message="Please check your email to reset your password"
          />
        ) : null}
        <Backdrop open={sighUpInfo.backdrop}>
          <CircularProgress color="primary" />
        </Backdrop>
      </Grid>
      <Grid item xs={6} className={useStyles().intro}>
        <Typography variant="h4">
          Make it easy for users to provide product feedback — in real time.
        </Typography>
        <img src={handsImg} alt="Hands" />
      </Grid>
      {sighUpInfo.userDeactivated && (
        <AlertMessage
          type="error"
          message="Your account has been disabled. Please contact your administrator"
        />
      )}
    </Grid>
  );
};

SignUp.propTypes = {
  location: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
};

export default SignUp;
