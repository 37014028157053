import { securedAxiosInstance, plainAxiosInstance } from "./api";

const campaignsApi = {
  createCampaign: (campaignPayload) =>
    securedAxiosInstance
      .post("/campaigns", campaignPayload)
      .then((response) => response)
      .catch((error) => error),

  updateCampaign: (campaignPayload) =>
    securedAxiosInstance
      .patch(`/campaigns/${campaignPayload.campaignId}`, campaignPayload)
      .then((response) => response)
      .catch((error) => error),

  listCampaigns: (userId) =>
    plainAxiosInstance
      .get(`/campaigns/campaign_list?user_id=${userId}`)
      .then((response) => response)
      .catch((error) => error),

  fetchCampaign: (campaignId) =>
    securedAxiosInstance
      .get(`/campaigns/${campaignId}`)
      .then((response) => response)
      .catch((error) => error),

  inviteMembers: (companyId, membersData) =>
    plainAxiosInstance
      .post(`companies/${companyId}/invite_members`, membersData)
      .then((response) => response)
      .catch((error) => error),

  fetchCampaignsByUuid: (companyUuid) =>
    plainAxiosInstance
      .get(`/companies/fetch_campaigns_by_uuid?uuid=${companyUuid}`)
      .then((response) => response)
      .catch((error) => error),

  fetchCampaignStatistics: (campaignId, filter) =>
    securedAxiosInstance
      .get(
        `/campaigns/${campaignId}/campaign_stats${
          filter === "allTime" ? "" : `?filter=${filter}`
        }`
      )
      .then((response) => response)
      .catch((error) => error),

  campaignReset: (campaignId) =>
    securedAxiosInstance
      .patch(`/campaigns/${campaignId}/campaign_reset`)
      .then((response) => response)
      .catch((error) => error),

  fetchCampaignMembers: () =>
    securedAxiosInstance
      .get(`/companies/members`)
      .then((response) => response)
      .catch((error) => error),

  fetchResponses: (campaignId, filter, page, order, direction) =>
    securedAxiosInstance
      .get(
        `/responses?campaign_id=${campaignId}${
          filter === "allTime" ? "" : `&filter=${filter}`
        }&page=${page}${
          order === "responseCount" ? "" : `&order=${order}`
        }&direction=${direction}`
      )
      .then((response) => response)
      .catch((error) => error),

  campaignDashboardStats: (filter) =>
    securedAxiosInstance
      .get(`/dashboard_stats${filter ? `?filter=${filter}` : ""}`)
      .then((response) => response)
      .catch((error) => error),

  fetchPaymentPlans: () =>
    securedAxiosInstance
      .get("/payment_plans")
      .then((response) => response)
      .catch((error) => error),

  updateCompanyName: (company_name) =>
    securedAxiosInstance
      .patch(`/update_company_name`, { company_name })
      .then((response) => response)
      .catch((error) => error),

  demoRequest: (requestPayload) =>
    securedAxiosInstance
      .post("/demo_requests", requestPayload)
      .then((response) => response)
      .catch((error) => error),
};

export default campaignsApi;
