import React from "react";
import { makeStyles, Grid, Typography, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import ErrorIcon from "@material-ui/icons/ErrorOutline";

import Logo from "../components/Logo";
import handsImg from "../assets/images/hands.png";

const NotFound = () => {
  const useStyles = makeStyles((theme) => ({
    root: {
      padding: "50px 20%",
      background: "#EADA9D",
      color: "#fff",
      minHeight: "100vh",

      [theme.breakpoints.down("sm")]: {
        padding: "50px",
      },

      "& > .MuiGrid-root": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minHeight: "calc(100vh - 150px)",
        justifyContent: "space-between",

        "& > .MuiGrid-root": {
          textAlign: "center",
          marginBottom: "30px",
          marginTop: "40px",

          "& svg": {
            fill: "#fff",
            width: "40px",
            height: "40px",
          },

          "& p": {
            fontWeight: "600",
            fontSize: "34px",
            lineHeight: "42px",
            textAlign: "center",

            "&:nth-child(2)": {
              fontSize: "30px",
            },
          },
        },

        "& > img": {
          maxWidth: "568px",
          width: "100%",
          maxHeight: "calc(100vh - 455px)",
          objectFit: "contain",
        },

        "& > a button": {
          color: "#fff",
          fontSize: "15px",
          lineHeight: "26px",
          textTransform: "capitalize",
          letterSpacing: "0.3px",
          padding: "8px 22px",
          boxShadow: "none",
          marginTop: "30px",

          "&:hover": {
            boxShadow: "none",
          },
        },
      },
    },
  }));

  return (
    <Grid className={useStyles().root}>
      <Logo />
      <Grid>
        <Grid>
          <ErrorIcon />
          <Typography>Oops!</Typography>
          <Typography>Something went wrong</Typography>
        </Grid>
        <img src={handsImg} alt="Hands" />
        <Link to="/">
          <Button type="submit" variant="contained" color="primary">
            Return to home
          </Button>
        </Link>
      </Grid>
    </Grid>
  );
};

export default NotFound;
