import React from "react";
import {
  makeStyles,
  Grid,
  Typography,
  Divider,
  Backdrop,
  CircularProgress,
  ClickAwayListener,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import Logo from "../components/Logo";
import GoogleAuth from "../components/GoogleAuth";
import FormInput from "../components/FormInput";
import SubmitBtn from "../components/SubmitBtn";
import AlertMessage from "../components/AlertMessage";
import authApi from "../api/auth-api";
import campaignsApi from "../api/campaigns-api";

const SignIn = (props) => {
  const { location } = props;

  const [state, setState] = React.useState({
    formValid: false,
    isSubmitted: false,
    notAuthorized: false,
    userDeactivated: false,
    backdrop: false,
    passwordChanged: location.search.split("?")[1] === "password-changed",
    email: "",
    password: "",
  });

  const useStyles = makeStyles((theme) => ({
    root: {
      height: "100vh",
      position: "relative",
      minHeight: "650px",

      "& > a": {
        position: "absolute",
        top: "46px",
        left: "361px",

        [theme.breakpoints.down("lg")]: {
          top: "49px",
          left: "115px",
        },

        [theme.breakpoints.down("xs")]: {
          left: "30px",
          top: "30px",
        },
      },

      "& > .MuiGrid-item": {
        maxWidth: "420px",
        width: "100%",

        [theme.breakpoints.down("xs")]: {
          padding: "30px",
          maxWidth: "unset",
        },

        "& h4": {
          fontWeight: "600",
          marginBottom: "42px",

          [theme.breakpoints.down("xs")]: {
            maxWidth: "300px",
          },
        },

        "& form": {
          "& > .MuiGrid-root:first-child": {
            marginTop: "23px",
          },

          "& .MuiFormHelperText-contained": {
            opacity: 0,
            cursor: "default",
          },

          "& .Mui-error": {
            "& fieldset": {
              borderColor: state.isSubmitted
                ? "#F44336"
                : "rgba(0, 0, 0, 0.23)",
            },

            "& + .MuiFormHelperText-contained": {
              marginLeft: "0",
              opacity: state.isSubmitted ? "1" : "0",
            },

            "&.Mui-focused fieldset, &:hover fieldset": {
              borderColor: state.isSubmitted
                ? "#F44336"
                : theme.palette.primary.main,
            },

            "&.MuiInputLabel-shrink": {
              color: state.isSubmitted
                ? "#F44336"
                : "rgba(136, 136, 136, 0.54)",

              "&.Mui-focused": {
                color: state.isSubmitted
                  ? "#f44336"
                  : theme.palette.primary.main,
              },
            },
          },

          "& .btn-wrap": {
            marginTop: "24px",

            "& a": {
              textDecoration: "none",
              display: "inline-block",
              letterSpacing: "0.15px",
              marginRight: "40px",

              "& p": {
                transition: "0.25s color",
                fontWeight: "600",

                "&:hover": {
                  color: theme.palette.primary.dark,
                },
              },
            },
          },
        },
      },

      "& > .MuiBackdrop-root": {
        zIndex: "9999",
      },
    },
  }));

  const handleInput = (type, data) => {
    if (data !== state[type]) {
      setState({
        ...state,
        formValid: data,
        [type]: data,
        notAuthorized: false,
        userDeactivated: false,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setState({ ...state, backdrop: true, passwordChanged: false });
    if (state.email && state.password) {
      authApi
        .login({ email: state.email, password: state.password })
        .then((res) => {
          if (
            res &&
            res.status === 404 &&
            res.data.error === "Cannot find such email and password combination"
          ) {
            setState({ ...state, notAuthorized: true, backdrop: false });
          } else if (
            res &&
            res.status === 401 &&
            res.data.notice === "User deactivated"
          ) {
            setState({ ...state, userDeactivated: true, backdrop: false });
          } else {
            authApi.me().then((response) => {
              if (response.data && response.data.id) {
                if (
                  response.data.company.snipped_installed ||
                  response.data.role === "viewer"
                ) {
                  window.location.replace("/");
                } else {
                  campaignsApi
                    .listCampaigns(response.data.id)
                    .then((campaigns) => {
                      if (
                        campaigns.data.some(
                          (survey) => survey.status === "live"
                        )
                      ) {
                        window.location.replace("/install");
                      } else {
                        window.location.replace("/campaigns/new");
                      }
                    });
                }
              } else {
                authApi.logout();
              }
            });
          }
        });
    } else {
      setState({ ...state, isSubmitted: true });
    }
  };

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      className={useStyles().root}
    >
      <Logo />
      <Grid item>
        <Typography variant="h4">Welcome Back, Sign In!</Typography>
        <GoogleAuth />
        <Divider />
        <form onSubmit={handleSubmit}>
          <FormInput type="email" onValidate={handleInput} />
          <FormInput type="password" onValidate={handleInput} />
          <Grid
            container
            className="btn-wrap"
            alignItems="center"
            justify="flex-end"
          >
            <Link to="/forgot-password">
              <Typography variant="body2" color="primary">
                Forgot password?
              </Typography>
            </Link>
            <SubmitBtn btnText="Sign in" />
          </Grid>
        </form>
      </Grid>
      {state.notAuthorized || state.passwordChanged || state.userDeactivated ? (
        <ClickAwayListener
          onClickAway={() =>
            setState({
              ...state,
              notAuthorized: false,
              passwordChanged: false,
              userDeactivated: false,
            })
          }
        >
          <Grid>
            <AlertMessage
              type={
                state.notAuthorized || state.userDeactivated ? "error" : "info"
              }
              message={
                state.notAuthorized
                  ? "Cannot find such email and password combination"
                  : state.passwordChanged
                  ? "Password was changed"
                  : "Your account has been disabled. Please contact your administrator"
              }
            />
          </Grid>
        </ClickAwayListener>
      ) : null}
      <Backdrop open={state.backdrop}>
        <CircularProgress color="primary" />
      </Backdrop>
    </Grid>
  );
};

SignIn.propTypes = {
  location: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
};

export default SignIn;
