import React from "react";
import {
  makeStyles,
  Grid,
  Typography,
  List,
  ListItem,
} from "@material-ui/core";
import {
  AccountCircle,
  Group,
  CreditCard,
  // TODO: Notification
  // Notifications as NotificationsIcon,
} from "@material-ui/icons";
import { Route, Switch, NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

import Menu from "../components/Menu";
import Header from "../components/Header";
import ManageUsers from "../components/ManageUsers";
import Payment from "../components/Payment";
import ManageProfile from "../components/ManageProfile";
import Installation from "../components/Installation";
// TODO: Notification
// import Notifications from "../components/Notifications";

const Settings = () => {
  const userRole = useSelector((store) => store.user.role);

  const useStyles = makeStyles((theme) => ({
    root: {
      "& > .MuiGrid-root": {
        backgroundColor: "#F9F9F9",
        minHeight: "100vh",
        width: "calc(100% - 120px)",
        padding: "7px 53px 0 49px",

        [theme.breakpoints.down("sm")]: {
          width: "100%",
          padding: "7px 20px 0",
        },

        "& > h6": {
          color: "#000000",
          marginBottom: "12px",
        },

        "& > .MuiGrid-root": {
          background: "#FFFFFF",
          border: "1px solid #F7F7F7",
          borderRadius: "4px",
          display: "flex",
          minHeight: "calc(100vh - 120px)",

          "& > .MuiGrid-root:first-child": {
            borderRight: "1px solid rgba(0, 0, 0, 0.12)",
            padding: "32px 0 32px 32px",

            [theme.breakpoints.down("sm")]: {
              paddingLeft: "0",
            },

            "& > ul": {
              padding: "0",
              width: "195px",

              [theme.breakpoints.down("sm")]: {
                width: "auto",
              },

              "& li": {
                padding: "0",
                marginBottom: "19px",
                transition: "color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                color: "rgba(136, 136, 136, 0.54)",
                width: "196px",

                [theme.breakpoints.down("sm")]: {
                  width: "auto",
                },

                "& svg": {
                  width: "20px",
                  fill: "rgba(136, 136, 136, 0.54)",
                  transition: "all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                  marginRight: "14px",
                  stroke: "rgba(136, 136, 136, 0.54)",
                  strokeWidth: 0,

                  [theme.breakpoints.down("sm")]: {
                    marginRight: "0",
                  },

                  "& path": {
                    fill: "inherit",
                    stroke: "inherit",
                  },
                },

                "& a": {
                  width: "100%",
                  color: "inherit",
                  display: "flex",
                  alignItems: "center",

                  [theme.breakpoints.down("sm")]: {
                    padding: "0 20px",
                  },

                  "&.active": {
                    borderRight: "2px solid #96BD92",
                  },

                  "&.active, &:hover": {
                    color: theme.palette.primary.main,

                    "& svg": {
                      fill: theme.palette.primary.main,
                      stroke: theme.palette.primary.main,
                    },
                  },

                  "& h6": {
                    [theme.breakpoints.down("sm")]: {
                      display: "none",
                    },
                  },

                  "&:hover": {
                    background: "none",
                  },
                },
              },
            },
          },
        },
      },
    },
  }));

  return (
    <Grid container className={useStyles().root}>
      <Menu activePage="Team" />
      <Grid>
        <Header />
        <Typography variant="h6">Settings</Typography>
        <Grid>
          <Grid>
            <List>
              <ListItem>
                <NavLink to="/settings/manage-profile">
                  <AccountCircle />
                  <Typography variant="subtitle2">Manage Profile</Typography>
                </NavLink>
              </ListItem>
              <ListItem>
                <NavLink to="/settings/manage-users">
                  <Group />
                  <Typography variant="subtitle2">Manage Users</Typography>
                </NavLink>
              </ListItem>
              {userRole !== "viewer" && (
                <ListItem>
                  <NavLink to="/settings/payment">
                    <CreditCard />
                    <Typography variant="subtitle2">
                      Payment & Billing
                    </Typography>
                  </NavLink>
                </ListItem>
              )}
              {/*  TODO: Notifications */}
              {/* <ListItem>
                <NavLink to="/settings/notifications">
                  <NotificationsIcon />
                  <Typography variant="subtitle2">Notifications</Typography>
                </NavLink>
              </ListItem> */}
              {userRole !== "viewer" && (
                <ListItem>
                  <NavLink to="/settings/installation">
                    <svg
                      width="20"
                      height="16"
                      viewBox="0 0 30 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.6121 22.841L17.3879 2.15902"
                        stroke="inherit"
                        strokeWidth="2"
                      />
                      <path
                        d="M11.75 16.6L6 12L11.75 7.4L10 6L2.5 12L10 18L11.75 16.6ZM18.25 16.6L24 12L18.25 7.4L20 6L27.5 12L20 18L18.25 16.6Z"
                        fill="inherit"
                      />
                    </svg>
                    <Typography variant="subtitle2">Installation</Typography>
                  </NavLink>
                </ListItem>
              )}
            </List>
          </Grid>

          <Switch>
            <Route
              exact
              path="/settings/manage-users"
              component={ManageUsers}
            />
            <Route exact path="/settings/payment" component={Payment} />
            <Route
              exact
              path="/settings/manage-profile"
              component={ManageProfile}
            />
            {/* TODO: Notifications */}
            {/* <Route
              exact
              path="/settings/notifications"
              component={Notifications}
            /> */}
            <Route
              exact
              path="/settings/installation"
              component={Installation}
            />
          </Switch>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Settings;
