import React from "react";
import {
  makeStyles,
  Grid,
  Typography,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import {
  LibraryBooks,
  CheckCircle,
  ExpandMore,
  GroupAdd,
} from "@material-ui/icons";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import campaignsApi from "../api/campaigns-api";
import Menu from "../components/Menu";
import Header from "../components/Header";
import InstallSnippet from "../components/InstallSnippet";
import InviteUsers from "../components/InviteUsers";
// import googleTagManagerImg from "../assets/images/googleTagManager.png"; //TODO - GoogleTagManager

const Install = () => {
  const [state, setState] = React.useState({
    inviteSent: false,
    isInviteTabOpen: false,
    snippetInstalled: useSelector((store) => store.company.snippetInstalled),
    backdrop: false,
    verifyModalOpen: false,
    isInstallTabOpen: false,
    users: [],
    failedVerifyInstall: false,
  });

  const history = useHistory();
  const userRole = useSelector((store) => store.user.role);

  const useStyles = makeStyles((theme) => ({
    root: {
      "& .Install": {
        backgroundColor: "#F9F9F9",
        minHeight: "100vh",
        width: "calc(100% - 120px)",

        [theme.breakpoints.down("sm")]: {
          width: "100%",
        },

        "&__main": {
          maxWidth: "866px",
          padding: "0 50px",
          margin: "33px auto 50px",

          [theme.breakpoints.down("sm")]: {
            marginTop: "11px",
          },

          [theme.breakpoints.down("xs")]: {
            padding: "0 20px",
          },

          "& > h5": {
            color: "#000",
          },

          "& > p:nth-child(2)": {
            lineHeight: "19px",
            letterSpacing: "0.15px",
            color: "#000000",
            marginTop: "9px",
          },

          "&__step": {
            padding: "36px 22px 35px 19px",

            [theme.breakpoints.down("sm")]: {
              flexDirection: "column",
              padding: "15px",
            },

            "&__label": {
              marginLeft: "10px",

              [theme.breakpoints.down("sm")]: {
                marginLeft: "0",
              },

              "& > p": {
                letterSpacing: "0.15px",
                color: "rgba(136, 136, 136, 0.54)",
              },
            },

            "& > svg": {
              marginTop: "6px",

              [theme.breakpoints.down("xs")]: {
                margin: "0 auto 10px",
              },
            },

            "&__status": {
              display: "flex",
              alignItems: "center",
              marginLeft: "auto",

              [theme.breakpoints.down("sm")]: {
                margin: "13px 0 0",
                justifyContent: "center",
              },

              [theme.breakpoints.down("xs")]: {
                margin: "10px auto 0",
              },

              "& > p": {
                fontWeight: "600",
                fontSize: "10px",
                lineHeight: "11px",
                letterSpacing: "1px",
                textTransform: "uppercase",
                color: "#9DC199",

                "&:last-child": {
                  display: "none",
                },
              },
            },

            "& .MuiAccordion-root": {
              width: "100%",
              boxShadow: "none",

              "& .MuiAccordionSummary-root": {
                padding: "0",
                minHeight: "unset",

                "& .MuiAccordionSummary-content": {
                  margin: "0",

                  [theme.breakpoints.down("sm")]: {
                    flexDirection: "column",
                  },
                },

                "& .MuiAccordionSummary-expandIcon": {
                  [theme.breakpoints.down("sm")]: {
                    position: "absolute",
                    bottom: "-18px",
                    right: "50%",
                    transform: "translateX(64px)",
                  },
                },
              },

              "&.Mui-expanded": {
                "& .Install__main__step__status > p": {
                  "&:first-child": {
                    display: "none",
                  },
                  "&:last-child": {
                    display: "block",
                  },
                },
              },
            },

            "&_1": {
              border: "1px solid #E0E0E0",
              borderRadius: "4px 4px 0px 0px",
              marginTop: "22px",
              borderBottom: "none",

              "& > svg": {
                fill: "#BE134D",
              },

              "& .Install__main__step__status p": {
                marginRight: "12px",
              },
            },

            "&_2": {
              border: "1px solid #E0E0E0",
              borderTop: "none",
              borderBottom: "none",
              backgroundColor: state.snippetInstalled ? "#FAFAFA" : "#fff",
              borderRadius: "0px 0px 4px 4px",

              "& .MuiAccordion-root .MuiAccordionSummary-root, & .MuiCollapse-wrapperInner": {
                backgroundColor: state.snippetInstalled ? "#FAFAFA" : "#fff",
              },

              "& .MuiAccordionSummary-expandIcon.Mui-expanded": {
                transform: state.snippetInstalled
                  ? "rotate(0deg)"
                  : "rotate(180deg)",
              },

              "& .MuiAccordionSummary-content > svg": {
                fill: theme.palette.primary.main,
                marginTop: "5px",

                [theme.breakpoints.down("xs")]: {
                  margin: "0 auto 10px",
                },
              },

              "& .MuiAccordionDetails-root": {
                maxWidth: "670px",
                padding: "12px 0 0 16px",
                display: "flex",
                flexDirection: "column",

                [theme.breakpoints.down("sm")]: {
                  marginLeft: "-15px",
                },

                "& .Install__main__step_2__tag-manager": {
                  "& img": {
                    height: "35px",
                    width: "62px",
                    alignSelf: "flex-start",
                  },
                  "&__label": {
                    marginTop: "8px",
                    marginLeft: "-9px",

                    [theme.breakpoints.down("sm")]: {
                      width: "calc(100% - 60px)",
                    },

                    "& p": {
                      fontSize: "16px",
                      lineHeight: "19px",
                      letterSpacing: "0.15px",
                      color: "#000000",
                    },
                    "& h6": {
                      color: "#000000",
                      marginTop: "12px",
                    },
                  },
                  "& button": {
                    height: "37px",
                    marginLeft: "auto",
                    alignSelf: "flex-end",

                    [theme.breakpoints.down("sm")]: {
                      marginLeft: "52px",
                      marginTop: "15px",
                    },

                    "& span": {
                      fontWeight: "600",
                      fontSize: "16px",
                      lineHeight: "25px",
                      letterSpacing: "0.3px",
                    },
                  },
                },

                "& > p": {
                  fontWeight: "600",
                  fontSize: "16px",
                  lineHeight: "19px",
                  letterSpacing: "0.15px",
                  color: "#000000",
                  margin: "18px 0 14px 51px",
                },

                "& .Install__main__step_2__manual": {
                  marginLeft: "18px",
                },
              },

              [theme.breakpoints.down("sm")]: {
                "& .MuiAccordion-root .MuiAccordionSummary-root div.MuiAccordionSummary-expandIcon": {
                  transform: state.snippetInstalled
                    ? "translateX(88px)"
                    : "translateX(64px)",

                  "& .MuiTouchRipple-root": {
                    display: state.snippetInstalled ? "none" : "block",
                  },
                },
              },
            },

            "&_3": {
              border: "1px solid #E0E0E0",
              borderTop: "none",
              backgroundColor: state.inviteSent ? "#FAFAFA" : "#fff",
              borderRadius: "0px 0px 4px 4px",
              padding: "0",

              "& .MuiAccordion-rounded": {
                backgroundColor: state.inviteSent ? "#FAFAFA" : "#fff",

                "& div.MuiAccordionSummary-root": {
                  padding: "36px 22px 32px 19px",

                  [theme.breakpoints.down("sm")]: {
                    paddingBottom: "0",
                    paddingTop: "20px",
                    marginBottom: "20px",
                  },
                },
              },

              "& .MuiAccordionSummary-expandIcon.Mui-expanded": {
                transform: state.inviteSent ? "rotate(0deg)" : "rotate(180deg)",
              },

              "& .MuiAccordionSummary-content > svg": {
                fill: "#2196F3",
                marginTop: "4px",

                [theme.breakpoints.down("xs")]: {
                  margin: "auto",
                },
              },

              "& .MuiAccordionDetails-root": {
                padding: "0",
                flexDirection: "column",
              },

              [theme.breakpoints.down("sm")]: {
                "& .MuiAccordion-root .MuiAccordionSummary-root div.MuiAccordionSummary-expandIcon": {
                  transform: state.inviteSent
                    ? "translateX(88px)"
                    : "translateX(64px)",

                  "& .MuiTouchRipple-root": {
                    display: state.inviteSent ? "none" : "block",
                  },
                },
              },
            },
          },

          "& > button": {
            marginLeft: "auto",
            display: "block",
            marginTop: "30px",
          },
        },
      },
    },
    verify: {
      "& .MuiDialog-paper": {
        padding: "30px 65px 25px 20px",

        [theme.breakpoints.down("sm")]: {
          paddingRight: "20px",
          paddingTop: "40px",

          "& br": {
            display: "none",
          },
        },

        "& > button": {
          "&:first-child": {
            position: "absolute",
            right: "5px",
            top: "5px",
          },
          "&:last-child": {
            color: "#fff",
            marginTop: "15px",
            boxShadow: "none",
            width: "calc(100% + 45px)",
            height: "42px",
            fontWeight: "500",
            fontSize: "15px",
            lineHeight: "26px",
            letterSpacing: "0.3px",

            [theme.breakpoints.down("sm")]: {
              width: "100%",
            },
          },
        },

        "& > h5": {
          marginBottom: "5px",
        },

        "& .MuiCircularProgress-root": {
          marginTop: "20px",
          marginLeft: "50%",
        },
      },
    },
  }));

  const handleInviteUsers = () => {
    setState({
      ...state,
      inviteSent: true,
      isInviteTabOpen: false,
      backdrop: false,
    });
  };

  React.useEffect(() => {
    if (userRole === "viewer") {
      history.push("/");
    }

    campaignsApi.fetchCampaignMembers().then((response) => {
      setState({ ...state, users: response.data.members });
    });
  }, []); // eslint-disable-line

  return (
    <Grid container className={useStyles().root}>
      <Menu activePage="Dashboard" />
      <Grid className="Install">
        <Header />
        <Grid className="Install__main">
          <Typography variant="h5">Hey there, welcome to Prove!</Typography>
          <Typography>
            Just a couple of steps and you’re on your way to gathering product
            changing feedback.
          </Typography>

          <Grid container className="Install__main__step Install__main__step_1">
            <LibraryBooks />
            <Grid className="Install__main__step__label">
              <Typography variant="h6">
                <b>Step 1. </b>
                Create campaign
              </Typography>
              <Typography variant="body2">
                Create your first campaign to start collecting customer feedback
                right away!
              </Typography>
            </Grid>
            <Grid className="Install__main__step__status">
              <Typography>DONE!</Typography>
              <CheckCircle color="primary" />
            </Grid>
          </Grid>

          <Grid container className="Install__main__step Install__main__step_2">
            <Accordion
              expanded={state.isInstallTabOpen}
              onChange={() =>
                setState({
                  ...state,
                  isInstallTabOpen: !state.isInstallTabOpen,
                })
              }
            >
              <AccordionSummary
                expandIcon={
                  state.snippetInstalled ? (
                    <CheckCircle color="primary" />
                  ) : (
                    <ExpandMore color="primary" />
                  )
                }
              >
                <svg
                  width="30"
                  height="24"
                  viewBox="0 0 30 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.6121 22.841L17.3879 2.15902"
                    stroke="#96BD92"
                    strokeWidth="2"
                  />
                  <path
                    d="M11.75 16.6L6 12L11.75 7.4L10 6L2.5 12L10 18L11.75 16.6ZM18.25 16.6L24 12L18.25 7.4L20 6L27.5 12L20 18L18.25 16.6Z"
                    fill="#96BD92"
                  />
                </svg>

                <Grid className="Install__main__step__label">
                  <Typography variant="h6">
                    <b>Step 2. </b>
                    Install code snippet
                  </Typography>
                  <Typography variant="body2">
                    Choose which way you’d like to install Prove on your website
                    or application
                  </Typography>
                </Grid>
                <Grid className="Install__main__step__status">
                  {!state.snippetInstalled ? (
                    <>
                      <Typography>See options</Typography>
                      <Typography>Collapse</Typography>
                    </>
                  ) : (
                    <Typography style={{ display: "block" }}>
                      Install successful!
                    </Typography>
                  )}
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                {/* TODO: Google Tag Manager */}
                {/* <Grid
                  container
                  alignItems="center"
                  className="Install__main__step_2__tag-manager"
                >
                  <img src={googleTagManagerImg} alt="Google Tag Manager" />
                  <Grid className="Install__main__step_2__tag-manager__label">
                    <Typography>Google Tag Manager install</Typography>
                    <Typography variant="subtitle2">
                      In just a few easy steps, you can get Prove up and running
                      with GTM.
                    </Typography>
                  </Grid>
                  <Button color="primary" variant="outlined">
                    Get Started
                  </Button>
                </Grid>
                <Typography>-OR-</Typography> */}
                <Grid className="Install__main__step_2__manual">
                  <InstallSnippet
                    snippetInstalledCallBack={() =>
                      setState({
                        ...state,
                        snippetInstalled: true,
                        isInstallTabOpen: false,
                      })
                    }
                  />
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>

          <Grid container className="Install__main__step Install__main__step_3">
            <Accordion
              expanded={state.isInviteTabOpen}
              onChange={() =>
                setState({ ...state, isInviteTabOpen: !state.isInviteTabOpen })
              }
            >
              <AccordionSummary
                expandIcon={
                  state.inviteSent ? (
                    <CheckCircle color="primary" />
                  ) : (
                    <ExpandMore color="primary" />
                  )
                }
              >
                <GroupAdd />
                <Grid className="Install__main__step__label">
                  <Typography variant="h6">
                    <b>Step 3. </b>
                    Invite some team members
                  </Typography>
                  <Typography variant="body2">
                    Add some members of your team to maximize exposure to
                    customer feedback
                  </Typography>
                </Grid>
                <Grid className="Install__main__step__status">
                  {!state.inviteSent ? (
                    <>
                      <Typography>Invite team</Typography>
                      <Typography>Collapse</Typography>
                    </>
                  ) : (
                    <Typography style={{ display: "block" }}>
                      Invite successful!
                    </Typography>
                  )}
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <InviteUsers
                  inviteUsersSuccess={handleInviteUsers}
                  users={state.users}
                />
              </AccordionDetails>
            </Accordion>
          </Grid>

          <Button
            color="primary"
            variant="outlined"
            onClick={() => history.push("/dashboard")}
          >
            Skip To Dashboard
          </Button>
        </Grid>
      </Grid>
      <Backdrop open={state.backdrop} style={{ zIndex: 10 }}>
        <CircularProgress color="primary" />
      </Backdrop>
    </Grid>
  );
};

export default Install;
