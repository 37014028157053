import React from "react";
import {
  makeStyles,
  Grid,
  Button,
  Backdrop,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import { useSelector } from "react-redux";

import billingApi from "../api/billing-api";
import ChoosePaymentPlan from "./ChoosePaymentPlan";

const Payment = () => {
  const company = useSelector((store) => store.company);
  const userRole = useSelector((store) => store.user.role);
  const paymentPlans = useSelector((store) => store.paymentPlans.config);

  const currentRange = paymentPlans
    .map(
      (responsePackage) =>
        responsePackage.responses_number === company.plan.responses_number
    )
    .indexOf(true);

  const [state, setState] = React.useState({
    backdrop: false,
  });

  const useStyles = makeStyles((theme) => ({
    root: {
      padding: "30px 40px 40px 42px",
      width: "100%",

      [theme.breakpoints.down("xs")]: {
        flex: "1",
        width: "0",
      },

      "& > .MuiGrid-root": {
        "&:first-child": {
          marginBottom: "9px",

          [theme.breakpoints.down("xs")]: {
            flexDirection: "column",
            alignItems: "start",

            "& > p": {
              marginBottom: "15px",
            },
          },

          "& > .MuiGrid-root": {
            "& > button": {
              fontWeight: "600",
              fontSize: "16px",
              lineHeight: "25px",
              letterSpacing: "0.3px",
              padding: "6px 16px",
              border: "1px solid rgba(150, 189, 146, 0.5)",
              transition:
                "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
              textDecoration: "none",

              "&:hover": {
                border: "1px solid #96BD92",
                backgroundColor: "rgba(150, 189, 146, 0.04)",
                color: theme.palette.primary.main,
              },
            },
          },
        },

        "&:nth-child(2)": {
          [theme.breakpoints.down("xs")]: {
            flexDirection: "column",
            justifyContent: "unset",
          },

          "& > .MuiGrid-root": {
            "&:first-child": {
              marginRight: "20px",

              "& > h6": {
                "&:first-child": {
                  color: "#2196F3",
                  marginBottom: "10px",
                  textTransform: "capitalize",
                  letterSpacing: "0.15px",
                },

                "&:not(:first-child)": {
                  lineHeight: "21px",
                  letterSpacing: "0.1px",
                  color: "#000000",
                  marginBottom: "5px",
                },

                "&:nth-child(-n+6):first-letter": {
                  textTransform: "capitalize",
                },
              },

              "& > .MuiGrid-root": {
                display: "inline-block",

                "& > button": {
                  padding: "0",
                  fontWeight: "500",
                  fontSize: "14px",
                  lineHeight: "21px",
                  letterSpacing: "0.1px",
                  textTransform: "none",
                },
              },
            },

            "&:last-child": {
              display: "flex",
              flexDirection: "column",

              [theme.breakpoints.down("xs")]: {
                width: "100%",
              },

              "& > p": {
                "&:first-child": {
                  color: theme.palette.primary.main,
                  fontWeight: "500",
                  fontSize: "48px",
                  lineHeight: "56px",
                  marginTop: "36px",
                  marginBottom: "2px",
                },

                "&:nth-child(2)": {
                  fontWeight: "600",
                  fontSize: "12px",
                  lineHeight: "11px",
                  marginBottom: "20px",
                },
              },

              "& > .MuiGrid-root": {
                width: "100%",
                overflow: "auto",

                "&::-webkit-scrollbar-track": {
                  webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
                  backgroundColor: "transparent",
                },
                "&::-webkit-scrollbar": {
                  width: "4px",
                  height: "4px",
                  backgroundColor: "transparent",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#96BD92",
                  border: "2px solid #96BD92",
                },

                "& > .MuiGrid-root": {
                  minWidth: "420px",
                  border: "1px solid #F2F2F2",

                  [theme.breakpoints.down("1200")]: {
                    minWidth: "370px",
                  },

                  "& > .MuiGrid-root": {
                    display: "flex",
                    fontSize: "16px",
                    lineHeight: "25px",
                    letterSpacing: "0.15px",
                    padding: "10px 0 10px 20px",

                    "&:first-child": {
                      fontWeight: "500",
                      fontSize: "18px",
                      lineHeight: "29px",
                      letterSpacing: "0.15px",
                    },

                    "&:nth-child(even)": {
                      background: "rgba(0, 0, 0, 0.04)",
                      borderRadius: "2px",
                    },

                    [`&:nth-child(${
                      company.plan.type === "enterprise"
                        ? -1
                        : currentRange === -1
                        ? -1
                        : currentRange + 2
                    })`]: {
                      border: "1px solid #96BD92",
                      boxShadow:
                        "1px 1px 3px rgb(150 189 146 / 40%), 1px 1px 6px rgb(150 189 146 / 18%)",
                    },

                    "& > .MuiGrid-root": {
                      "&:first-child": {
                        flex: "1",
                      },
                      "&:last-child": {
                        minWidth: "170px",
                      },
                    },
                  },
                },
              },

              "& > button": {
                marginTop: "20px",
                alignSelf: "flex-end",
                fontWeight: "600",
                fontSize: "16px",
                lineHeight: "25px",
                letterSpacing: "0.3px",
                padding: "6px 16px",
                border: "1px solid rgba(150, 189, 146, 0.5)",
                transition:
                  "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                textDecoration: "none",

                "&:hover": {
                  border: "1px solid #96BD92",
                  backgroundColor: "rgba(150, 189, 146, 0.04)",
                  color: theme.palette.primary.main,
                },

                [theme.breakpoints.down("xs")]: {
                  alignSelf: "flex-start",
                },
              },
            },
          },
        },
      },
    },
  }));

  const handleCustomerPortal = () => {
    setState({ ...state, backdrop: true });
    billingApi.customerPortal().then((response) => {
      window.location.href = response.data.customer_portal_url;
    });
  };

  return (
    <Grid className={useStyles().root}>
      <Grid container justify="space-between" alignItems="center">
        <Typography variant="body1">Your Plan</Typography>
        <ChoosePaymentPlan btnText="Upgrade Plan" />
      </Grid>
      <Grid container justify="space-between">
        <Grid>
          <Typography variant="h6">
            {`${company.plan.type === "paid" ? "Pro" : company.plan.type} Plan`}
          </Typography>
          <Typography variant="subtitle2">
            {`${company.campaigns} Campaign${
              company.campaigns === 1 ? "" : "s"
            }`}
          </Typography>
          <Typography variant="subtitle2">
            {`${company.activeUsers.admins} Admin${
              company.activeUsers.admins === 1 ? "" : "s"
            }`}
          </Typography>
          <Typography variant="subtitle2">
            {`${company.activeUsers.managers} Manager${
              company.activeUsers.managers === 1 ? "" : "s"
            }`}
          </Typography>
          <Typography variant="subtitle2">
            {`${company.activeUsers.viewers} Viewer${
              company.activeUsers.viewers === 1 ? "" : "s"
            }`}
          </Typography>
          <Typography variant="subtitle2">{`${company.plan.responses_number} responses/month`}</Typography>
          <ChoosePaymentPlan btnText="See all features" />
        </Grid>
        <Grid>
          <Typography>{`$${company.plan.price}`}</Typography>
          <Typography>Current Price</Typography>
          <Grid>
            <Grid>
              <Grid>
                <Grid>Feedback Range</Grid>
                <Grid>Monthly Pricing</Grid>
              </Grid>
              {paymentPlans.map((pricePackage, id) => (
                <Grid key={pricePackage.id}>
                  <Grid>
                    {pricePackage.responses_number === "unlimited"
                      ? `${paymentPlans[id - 1].responses_number}+`
                      : `${
                          (paymentPlans[id - 1] &&
                            +paymentPlans[id - 1].responses_number + 1) ||
                          0
                        } - ${pricePackage.responses_number}`}
                  </Grid>
                  <Grid>
                    {pricePackage.price === 0
                      ? "Free"
                      : pricePackage.responses_number === "unlimited"
                      ? "Enterprise"
                      : `$${pricePackage.price}/month`}
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
          {company.plan.type !== "freemium" && userRole === "admin" && (
            <Button
              color="primary"
              variant="outlined"
              onClick={handleCustomerPortal}
            >
              Manage Subscriptions
            </Button>
          )}
        </Grid>
      </Grid>
      <Backdrop open={state.backdrop} style={{ zIndex: 10 }}>
        <CircularProgress color="primary" />
      </Backdrop>
    </Grid>
  );
};

export default Payment;
