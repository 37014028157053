import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Backdrop, CircularProgress } from "@material-ui/core";

import authApi from "./api/auth-api";
import campaignsApi from "./api/campaigns-api";
import SignUp from "./pages/SignUp";
import Enterprise from "./pages/Enterprise";
import SignIn from "./pages/SignIn";
import ForgotPassword from "./pages/ForgotPassword";
import Campaigns from "./pages/Campaigns";
import Campaign from "./pages/Campaign";
import CampaignEdit from "./pages/CampaignEdit";
import ChangePassword from "./pages/ChangePassword";
import GoogleReturnPage from "./pages/GoogleReturnPage";
import Dashboard from "./pages/Dashboard";
import Install from "./pages/Install";
import Settings from "./pages/Settings";
import Spinner from "./pages/Spinner";
import NotFound from "./pages/NotFound";
import WidgetClientDemo from "./client-lib/WidgetClientDemo"; // Just for development and debug will be remove in future

const Routers = () => {
  const [isDataComplete, setIsDataComplete] = React.useState(false);
  const dispatch = useDispatch();

  const userId = useSelector((store) => store.user.id);
  const companyId = useSelector((store) => store.company.id);

  React.useEffect(() => {
    if (localStorage.getItem("jwt") && (!userId || !companyId)) {
      authApi
        .me()
        .then((res) => {
          if (res.data && res.data.id && res.data.company.id) {
            dispatch({
              type: "USER",
              payload: {
                id: res.data.id,
                name: res.data.name,
                email: res.data.email,
                role: res.data.role,
              },
            });
            dispatch({
              type: "COMPANY",
              payload: {
                id: res.data.company.id,
                uuid: res.data.company.uuid,
                name: res.data.company.name,
                url: res.data.company.url,
                snippetInstalled: res.data.company.snipped_installed,
                plan: res.data.company.plan,
                usedResponses: res.data.company.number_of_responses_used,
                campaigns: res.data.company.campaigns,
                activeUsers: {
                  admins: res.data.company.admins_number,
                  managers: res.data.company.managers_number,
                  viewers: res.data.company.viewers_number,
                },
              },
            });
          } else {
            authApi.logout();
          }
        })
        .then(() => {
          campaignsApi.fetchPaymentPlans().then((response) => {
            dispatch({
              type: "PAYMENT_PLANS",
              payload: {
                config: response.data,
              },
            });

            setIsDataComplete(true);
          });
        });
    } else {
      setIsDataComplete(true);
    }
  }, []); //eslint-disable-line

  return (
    <>
      {isDataComplete ? (
        <BrowserRouter>
          {localStorage.getItem("jwt") ? (
            userId && companyId ? (
              <Switch>
                <Route exact path="/dashboard" component={Dashboard} />
                <Route exact path="/install" component={Install} />
                <Route exact path="/campaigns" component={Campaigns} />
                <Route exact path="/campaigns/new" component={CampaignEdit} />
                <Route exact path="/campaigns/:campaign" component={Campaign} />
                <Route path="/enterprise" component={Enterprise} />
                <Route
                  exact
                  path="/campaigns/:campaign/edit"
                  component={CampaignEdit}
                />
                <Route path="/settings" component={Settings} />
                <Route
                  exact
                  path="/password_reset/"
                  component={ChangePassword}
                />
                <Route exact path="/404" component={NotFound} />
                {/* Just for development and debug, will be remove in future */}
                <Route exact path="/client-demo" component={WidgetClientDemo} />
                <Redirect to="/dashboard" />
              </Switch>
            ) : (
              <Switch>
                <Route path="*" component={Spinner} />
              </Switch>
            )
          ) : (
            <Switch>
              <Route exact path="/" component={SignUp} />
              <Route exact path="/sign-in" component={SignIn} />
              <Route exact path="/forgot-password" component={ForgotPassword} />
              <Route exact path="/404" component={NotFound} />
              <Route
                exact
                path="/password_reset/:token"
                component={ChangePassword}
              />
              <Route
                path="/invitation/:token"
                render={() => <ChangePassword invitationMode />}
              />
              <Route
                exact
                path="/callback/google/return"
                component={GoogleReturnPage}
              />
              <Redirect to="/" />
            </Switch>
          )}
        </BrowserRouter>
      ) : (
        <Backdrop open style={{ zIndex: 10 }}>
          <CircularProgress color="primary" />
        </Backdrop>
      )}
    </>
  );
};

export default Routers;
