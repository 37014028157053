import React from "react";
import PropTypes from "prop-types";
import {
  makeStyles,
  Grid,
  Typography,
  Dialog,
  Button,
  IconButton,
  List,
  ListItem,
  Backdrop,
  CircularProgress,
  TextField,
  MenuItem,
  Tooltip,
  ClickAwayListener,
  Slider,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { Close, CheckCircle, ChevronLeft } from "@material-ui/icons";
import { loadStripe } from "@stripe/stripe-js";

import campaignsApi from "../api/campaigns-api";
import billingApi from "../api/billing-api";
import AlertMessage from "./AlertMessage";

const ChoosePaymentPlan = (props) => {
  const { btnText, open, closeModal } = props;

  const company = useSelector((store) => store.company);
  const userRole = useSelector((store) => store.user.role);
  const paymentPlans = useSelector((store) => store.paymentPlans.config);
  const dispatch = useDispatch();
  const modalRef = React.useRef(null);

  const campaignResponses = paymentPlans
    .map(
      (responsePackage) =>
        responsePackage.responses_number === company.plan.responses_number
    )
    .indexOf(true);

  const [state, setState] = React.useState({
    open,
    scheduleDemoOpen: false,
    updadePlanConfirmationOpen: false,
    backdrop: false,
    email: useSelector((store) => store.user.email),
    phone: "",
    preferContact: "email",
    alertTextMsg: "",
    isSliderWasChanged: false,
    campaignResponses:
      campaignResponses === -1 ? paymentPlans.length - 1 : campaignResponses,
  });

  const useStyles = makeStyles((theme) => ({
    root: {
      "& > button": {
        fontWeight: "600",
        fontSize: "12px",
        lineHeight: "16px",
        letterSpacing: "0.15px",
        textDecoration: "underline",
        color: theme.palette.primary.main,
        margin: "auto",
        display: "block",
        transition: "color 0.2s",

        "&:hover": {
          background: "none",
          color: "#6D9D68",
          textDecoration: "underline",
        },
      },
    },
    ChoosePlanModal: {
      "& .MuiDialog-paper": {
        maxWidth: "unset",

        "& > .MuiGrid-root": {
          maxWidth: "1060px",
          padding: "64px 50px 112px",
          overflowX: "auto",

          "&::-webkit-scrollbar-track": {
            webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
            backgroundColor: "transparent",
          },
          "&::-webkit-scrollbar": {
            width: "4px",
            height: "4px",
            backgroundColor: "transparent",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#96BD92",
            border: "2px solid #96BD92",
          },

          "& > button:first-child": {
            position: "absolute",
            padding: "10px",
            right: "10px",
            top: "10px",
            zIndex: "1",

            "& svg": {
              fill: "rgba(136, 136, 136, 0.54)",
            },
          },

          "& h4": {
            fontWeight: "600",
            letterSpacing: "0.25px",
            textAlign: "center",
            maxWidth: "650px",
            margin: "0 auto 12px",
          },

          "& h6": {
            maxWidth: "566px",
            textAlign: "center",
            letterSpacing: "0.15px",
            color: "#403C39",

            "& > button": {
              padding: "0",
              fontSize: "inherit",
              fontWeight: "inherit",
              lineHeight: "inherit",
              textTransform: "none",
              verticalAlign: "baseline",
              margin: "0 5px",
              transition: "color 0.2s",

              "&:hover": {
                backgroundColor: "unset",
              },
            },
          },
        },
      },
    },
    choosePlan: {
      minWidth: "960px",
      display: "inline-block",
      marginBottom: "73px",

      "& > h6": {
        margin: "0 auto 39px",

        "& > .MuiGrid-root > button, & > button": {
          color: theme.palette.primary.main,

          "&:hover": {
            color: theme.palette.primary.dark,
          },
        },
      },

      "& > .MuiGrid-root": {
        display: "flex",
        flexDirection: "column",
        border: "1px solid #F2F2F2",
        borderRadius: "4px",
        padding: "62px 128px 29px",

        "& > .MuiGrid-root": {
          "&:first-child": {
            display: "flex",
            width: "690px",
            margin: "auto",

            "& > .MuiGrid-root": {
              "&:first-child": {
                "& > p": {
                  fontWeight: "600",
                  fontSize: "10px",
                  lineHeight: "11px",
                  letterSpacing: "1px",
                  textTransform: "uppercase",
                  marginBottom: "16px",
                },

                "& > .MuiGrid-root": {
                  "& > .MuiGrid-root": {
                    "&:first-child": {
                      display: "flex",
                      alignItems: "flex-end",
                      marginBottom: "20px",

                      "& > p": {
                        "&:first-child": {
                          fontWeight: "600",
                          fontSize: "34px",
                          lineHeight: "42px",
                          letterSpacing: "0.25px",
                          marginRight: "5px",
                          color: theme.palette.primary.main,
                        },

                        "&:last-child": {
                          fontWeight: "500",
                          fontSize: "14px",
                          lineHeight: "21px",
                          marginBottom: "3px",
                        },
                      },
                    },

                    "&:nth-child(3)": {
                      display: "flex",
                      width: "302px",
                      justifyContent: "space-between",
                      position: "relative",
                      top: "-5px",

                      "& > .MuiGrid-root": {
                        height: "6px",
                        background: "rgba(0, 0, 0, 0.23)",
                        width: "1px",

                        "&:first-child": {
                          background: "none",
                        },
                      },
                    },
                  },

                  "& > span": {
                    width: "300px",
                    display: "flex",
                    alignItems: "center",

                    "& > span:last-child": {
                      background: "#FFFFFF",
                      boxShadow: "0px 0px 10px rgb(0 0 0 / 15%)",
                      borderRadius: "22px",
                      width: "22px",
                      height: "22px",
                      marginLeft: "-11px",
                      zIndex: "1",
                    },
                  },

                  "& > p": {
                    fontSize: "12px",
                    lineHeight: "16px",
                    letterSpacing: "0.4px",
                    color: "#403C39",
                  },
                },
              },

              "&:last-child": {
                marginLeft: "auto",
                visibility:
                  paymentPlans[state.campaignResponses].responses_number ===
                  "unlimited"
                    ? "hidden"
                    : "visible",

                "& > p": {
                  fontWeight: "600",
                  fontSize: "10px",
                  lineHeight: "11px",
                  letterSpacing: "1px",
                  textTransform: "uppercase",
                  marginBottom: "16px",
                },

                "& > h4": {
                  fontWeight: "600",
                  fontSize: "34px",
                  lineHeight: "42px",
                  letterSpacing: "0.25px",
                  color: theme.palette.primary.main,
                  marginBottom: "10px",
                  textAlign: "left",
                },
              },
            },
          },

          "&:nth-child(2)": {
            display: "flex",
            justifyContent: "center",
            margin: "34px 0 49px",

            "& > div > button": {
              height: "100%",
            },

            "& button": {
              padding: "3px 16px",
              fontSize: "16px",
              boxShadow: "none",
              fontWeight: "500",
              lineHeight: "34px",
              letterSpacing: "0.3px",

              "&:first-child": {
                color: "#fff",
                marginRight: "22px",
                transition:
                  "max-width 0.5s, opacity 0.5s, padding-left 0.5s, padding-right 0.5s",
                overflow: "visible",
                maxWidth: "135px",
                height: "42px",
                minWidth: "unset",
                whiteSpace: "nowrap",

                "&.hidden": {
                  padding: "0",
                  maxWidth: "0",
                  overflow: "hidden",
                  height: "0",
                },

                "&.opacity": {
                  opacity: "0.7",
                },
              },
            },
          },

          "&:nth-child(3)": {
            width: "640px",
            margin: "auto",
            display: "flex",
            flexDirection: "column",

            "& > h5": {
              fontSize: "24px",
              lineHeight: "32px",
              textAlign: "center",
              marginBottom: "18px",
            },

            "& > .MuiGrid-root": {
              display: "flex",
              justifyContent: "space-between",

              "& > ul": {
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "column",

                "& > li": {
                  backgroundColor: "unset",
                  justifyContent: "flex-start",

                  "& > svg": {
                    fill: theme.palette.primary.main,
                    marginRight: "6px",
                  },
                },
              },
            },
          },
        },
      },
    },
    comparePlans: {
      minWidth: "960px",
      display: "inline-block",

      "& > .MuiGrid-root": {
        "&:first-child": {
          "& > .MuiGrid-root": {
            marginTop: "0",

            "& > h5": {
              display: "flex",
              alignItems: "center",
              marginRight: "auto",
            },
          },

          "& ul li": {
            "&:last-child": {
              "& svg:first-child": {
                display: "none",
              },
            },
          },
        },

        "&:nth-child(2)": {
          "& ul li": {
            "&:last-child, &:nth-last-child(2)": {
              "& svg:first-child": {
                display: "none",
              },
            },
          },
        },

        "&:nth-child(3)": {
          "& ul li": {
            "&:last-child, &:nth-last-child(2)": {
              "& svg:first-child": {
                display: "none",
              },
            },
          },
        },

        "&:nth-child(4)": {
          "& ul li": {
            "&:last-child": {
              "& svg:first-child": {
                display: "none",
              },
            },
          },
        },

        "& > .MuiGrid-root": {
          marginBottom: "26px",
          marginTop: "61px",

          "& > .MuiGrid-root": {
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            marginRight: "40px",

            "&:first-child": {
              width: "127px",
            },

            "&:nth-child(2)": {
              width: "137px",
            },

            "& > p": {
              fontWeight: "600",
              fontSize: "10px",
              lineHeight: "11px",
              letterSpacing: "1px",
              textTransform: "uppercase",
              color: "rgba(0, 0, 0, 0.87)",
              marginBottom: "24px",
            },

            "& > .MuiGrid-root > button, & > button": {
              color: "#fff",
              boxShadow: "none",
              padding: "3px 16px",
              fontWeight: "500",
              fontSize: "16px",
              lineHeight: "34px",
              letterSpacing: "0.3px",
            },
          },
        },

        "& > h6.MuiTypography-h6": {
          marginBottom: "19px",
          textAlign: "left",
        },

        "& > ul": {
          padding: "0",

          "& > li": {
            padding: "10px 0 10px 30px",
            display: "flex",
            justifyContent: "space-between",

            "&:nth-child(odd)": {
              background: "rgba(0, 0, 0, 0.04)",
              borderRadius: "2px",
            },

            "& p": {
              fontSize: "16px",
              lineHeight: "25px",
              letterSpacing: "0.15px",
              color: "#000000",
            },

            "& > .MuiGrid-root": {
              display: "flex",

              "& > *": {
                marginRight: "40px",
                textAlign: "center",
                fill: theme.palette.primary.main,

                "&:first-letter": {
                  textTransform: "capitalize",
                },

                "&:first-child": {
                  width: "127px",
                },
                "&:nth-child(2)": {
                  width: "137px",
                },
                "&:nth-child(3)": {
                  width: "156px",
                  marginRight: "0",
                },
              },
            },
          },
        },
      },
    },
    scheduleDemoModal: {
      "& .MuiDialog-paper": {
        width: "100%",
        maxWidth: "1060px",

        "& > .MuiGrid-root": {
          padding: "33px 52px 66px",

          [theme.breakpoints.down("xs")]: {
            padding: "20px",
          },

          "& > button:first-child": {
            position: "absolute",
            padding: "10px",
            right: "10px",
            top: "10px",
            zIndex: "1",

            [theme.breakpoints.down("xs")]: {
              top: "5px",
              right: "5px",
            },

            "& svg": {
              fill: "rgba(136, 136, 136, 0.54)",
            },
          },

          "& > button:nth-child(2)": {
            fontWeight: "600",
            fontSize: "16px",
            lineHeight: "25px",
            letterSpacing: "0.3px",

            "& svg": {
              marginRight: "7px",
              width: "19px",
            },
          },

          "& > h4": {
            fontWeight: "600",
            marginTop: "6px",
            marginBottom: "4px",
            letterSpacing: "0.25px",
            textAlign: "center",
          },

          "& > h6": {
            letterSpacing: "0.15px",
            color: "#403C39",
            marginBottom: "10px",
            margin: "0 auto 10px",
            maxWidth: "566px",
            textAlign: "center",
          },

          "& > .MuiGrid-root": {
            maxWidth: "420px",
            margin: "auto",
            display: "flex",
            flexDirection: "column",

            "& > p": {
              fontWeight: "600",
              fontSize: "14px",
              lineHeight: "20px",
              letterSpacing: "0.15px",
              marginBottom: "7px",
            },

            "& .MuiFormControl-root": {
              width: "100%",
              marginBottom: "29px",

              "& div.MuiOutlinedInput-root": {
                "&.Mui-focused fieldset, &:hover fieldset": {
                  borderColor: "#96BD92",
                },
                "& input, & .MuiSelect-root": {
                  padding: "10px 12px",
                },
              },
            },

            "& .MuiGrid-root": {
              marginTop: "15px",
              display: "inline-block",
              alignSelf: "flex-end",

              "& > button": {
                color: "#fff",
                padding: "3px 16px",
                fontSize: "16px",
                lineHeight: "34px",
                boxShadow: "none",
                letterSpacing: "0.3px",
                display: "block",

                "&.Mui-disabled": {
                  backgroundColor: "rgba(0, 0, 0, 0.08)",
                },
              },
            },
          },
        },
      },
    },
    updatePlanToolTip: {
      maxWidth: "unset",
    },
    updadePlanConfirmation: {
      "& .MuiDialog-paper": {
        padding: "15px 20px 20px",
        overflow: "auto",
        alignItems: "flex-start",

        "& > button": {
          "&:first-child": {
            position: "absolute",
            padding: "10px",
            right: "10px",
            top: "10px",
            zIndex: "1",

            [theme.breakpoints.down("xs")]: {
              top: "5px",
              right: "5px",
            },

            "& svg": {
              fill: "rgba(136, 136, 136, 0.54)",
            },
          },

          "&:last-child": {
            color: "#fff",
            boxShadow: "none",
            width: "100%",
          },
        },

        "& > h6": {
          marginRight: "50px",
          marginBottom: "10px",
        },

        "& > p": {
          marginBottom: "20px",
        },
      },
    },
  }))();

  const handleModal = () => {
    if (closeModal) closeModal();
    setState({ ...state, open: !state.open });
  };

  const handleScheduleDemoModal = () => {
    setState({
      ...state,
      scheduleDemoOpen: !state.scheduleDemoOpen,
      open: !state.open,
    });
  };

  const UpgradePlanBtn = () => {
    return (
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          modalRef.current.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }}
      >
        Upgrade Plan
      </Button>
    );
  };

  const ScheduleDemoBtn = () => {
    if (userRole !== "admin") {
      return (
        <Tooltip
          arrow
          title="Only administrator has permission for this option"
          enterTouchDelay={0}
        >
          <Grid>
            <Button variant="contained" color="primary" disabled>
              Schedule Demo
            </Button>
          </Grid>
        </Tooltip>
      );
    }
    return (
      <Button
        variant="outlined"
        color="primary"
        onClick={handleScheduleDemoModal}
      >
        Schedule Demo
      </Button>
    );
  };

  const handleRequestDemo = () => {
    setState({ ...state, backdrop: true });

    campaignsApi
      .demoRequest({
        email: state.email,
        phone: state.phone,
        prefered_contact_way: state.preferContact,
      })
      .then((res) => {
        if (res.data === "Demo request was successfully created") {
          setState({
            ...state,
            backdrop: false,
            alertTextMsg: "Demo was successfully submitted",
            scheduleDemoOpen: false,
            open: false,
          });
        }
      });
  };

  const handleUpdatePlanModal = () => {
    setState({
      ...state,
      updadePlanConfirmationOpen: !state.updadePlanConfirmationOpen,
      open: !state.open,
    });
  };

  const handleUpdatePlan = () => {
    setState({ ...state, backdrop: true });

    if (paymentPlans[state.campaignResponses].price === 0) {
      billingApi.setFreemium().then(() => {
        // TODO: add check 200 here
        dispatch({
          type: "COMPANY",
          payload: {
            ...company,
            plan: {
              ...company.plan,
              responses_number:
                paymentPlans[state.campaignResponses].responses_number,
              price: 0,
            },
          },
        });

        setState({
          ...state,
          backdrop: false,
          alertTextMsg: "Your payment plan was successfully updated",
          open: false,
          updadePlanConfirmationOpen: false,
        });
      });
    } else {
      billingApi.fetchBillingConfig().then((response) => {
        loadStripe(response.data.stripe_publishable_key).then((stripe) => {
          billingApi
            .createCheckoutSession(
              paymentPlans[state.campaignResponses].priceid,
              paymentPlans[state.campaignResponses].responses_number
            )
            .then((session) => {
              stripe.redirectToCheckout({
                sessionId: session.data.sessionId,
              });
            });
        });
      });
    }
  };

  React.useEffect(() => {
    if (state.open === true) {
      setState({
        ...state,
        campaignResponses:
          campaignResponses === -1
            ? paymentPlans.length - 1
            : campaignResponses,
        isSliderWasChanged: false,
      });
    }
  }, [state.open]); //eslint-disable-line

  return (
    <Grid className={useStyles.root}>
      <Button disableRipple onClick={handleModal}>
        {btnText}
      </Button>

      <Dialog
        onClose={handleModal}
        open={state.open}
        disableScrollLock
        className={useStyles.ChoosePlanModal}
      >
        <Grid ref={modalRef}>
          <IconButton onClick={handleModal}>
            <Close />
          </IconButton>
          <Grid className={useStyles.choosePlan}>
            <Typography variant="h4">
              Flexible pricing plans that are ready to scale with your business.
            </Typography>
            <Typography variant="subtitle1">
              There’s a plan for every size and type of company. Pick the one
              that makes the most sense for you. Or
              {userRole === "admin" ? (
                <Button disableRipple onClick={handleScheduleDemoModal}>
                  schedule a demo
                </Button>
              ) : (
                " schedule a demo "
              )}
              to discuss custom pricing.
            </Typography>
            <Grid container>
              <Grid>
                <Grid>
                  <Typography>Campaign Responses/month*</Typography>
                  <Grid>
                    <Grid>
                      <Typography>
                        {paymentPlans[state.campaignResponses]
                          .responses_number === "unlimited"
                          ? `${
                              paymentPlans[state.campaignResponses - 1]
                                .responses_number
                            }+`
                          : paymentPlans[state.campaignResponses]
                              .responses_number}
                      </Typography>
                      <Typography>Current Plan</Typography>
                    </Grid>
                    <Slider
                      value={state.campaignResponses}
                      marks
                      min={0}
                      max={paymentPlans.length - 1}
                      onChange={(event, value) => {
                        if (value !== state.campaignResponses) {
                          setState({
                            ...state,
                            campaignResponses: value,
                          });

                          if (!state.isSliderWasChanged) {
                            setState({ ...state, isSliderWasChanged: true });
                          }
                        }
                      }}
                    />
                    <Grid>
                      {Array.from(Array(paymentPlans.length).keys()).map(
                        (number) => (
                          <Grid key={number} />
                        )
                      )}
                    </Grid>
                    <Typography variant="body1">
                      *Responses are tracked against a full completed campaign
                    </Typography>
                  </Grid>
                </Grid>
                <Grid>
                  <Typography>Total cost per month</Typography>
                  <Typography variant="h4">
                    {`$${paymentPlans[state.campaignResponses].price}/mo`}
                  </Typography>
                  <Typography>Cost per response</Typography>
                  <Typography variant="h4">
                    {`$${(
                      paymentPlans[state.campaignResponses].price /
                        paymentPlans[state.campaignResponses]
                          .responses_number || 0
                    ).toFixed(2)}`}
                  </Typography>
                </Grid>
              </Grid>
              <Grid>
                {userRole !== "admin" ? (
                  <Tooltip
                    arrow
                    title="Only administrator has permission for this option"
                    enterTouchDelay={0}
                  >
                    <Grid>
                      <Button variant="contained" color="primary" disabled>
                        Confirm plan
                      </Button>
                    </Grid>
                  </Tooltip>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    className={`${!state.isSliderWasChanged ? "opacity" : ""} ${
                      paymentPlans[state.campaignResponses].responses_number ===
                      "unlimited"
                        ? "hidden"
                        : ""
                    }`}
                    onClick={() =>
                      setState({
                        ...state,
                        updadePlanConfirmationOpen: true,
                        open: !state.open,
                      })
                    }
                  >
                    Confirm plan
                  </Button>
                )}
                <ScheduleDemoBtn />
              </Grid>

              <Grid>
                <Typography variant="h5">Included:</Typography>
                <Grid>
                  <List>
                    {[
                      "Unlimited campaigns",
                      "Unlimited users",
                      "Unlimited segments (coming soon...)",
                    ].map((option, id) => (
                      <ListItem
                      key={id} //eslint-disable-line
                      >
                        <svg
                          width="12"
                          height="10"
                          viewBox="0 0 12 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M10.6752 0.394562C10.5833 0.401562 10.497 0.453962 10.4348 0.539962C8.30254 3.34376 6.1697 6.13136 4.03623 8.93516L0.972123 5.60796C0.824649 5.42596 0.58607 5.43996 0.452807 5.63836C0.319544 5.83756 0.347333 6.13756 0.513281 6.29236L3.82907 9.89236C3.97149 10.0462 4.18307 10.0338 4.31381 9.86416C6.5237 6.95876 8.73423 4.07116 10.9454 1.16416C11.1773 0.872762 11.0016 0.371962 10.6752 0.395362V0.394562Z"
                          />
                        </svg>
                        <Typography variant="body2">{option}</Typography>
                      </ListItem>
                    ))}
                  </List>
                  <List>
                    {[
                      "Premium campaign packs",
                      "Integrations (coming soon...)",
                      "White labeled surveys",
                    ].map((option, id) => (
                      <ListItem
                    key={id} //eslint-disable-line
                      >
                        <svg
                          width="12"
                          height="10"
                          viewBox="0 0 12 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M10.6752 0.394562C10.5833 0.401562 10.497 0.453962 10.4348 0.539962C8.30254 3.34376 6.1697 6.13136 4.03623 8.93516L0.972123 5.60796C0.824649 5.42596 0.58607 5.43996 0.452807 5.63836C0.319544 5.83756 0.347333 6.13756 0.513281 6.29236L3.82907 9.89236C3.97149 10.0462 4.18307 10.0338 4.31381 9.86416C6.5237 6.95876 8.73423 4.07116 10.9454 1.16416C11.1773 0.872762 11.0016 0.371962 10.6752 0.395362V0.394562Z"
                          />
                        </svg>
                        <Typography variant="body2">{option}</Typography>
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid className={useStyles.comparePlans}>
            <Grid>
              <Grid container justify="flex-end">
                <Typography variant="h5">All Features</Typography>
                <Grid>
                  <Typography>Freemium</Typography>
                  <Button disabled variant="contained">
                    {company.plan.type === "freemium"
                      ? "Current plan"
                      : "Freemium"}
                  </Button>
                </Grid>
                <Grid>
                  <Typography>Paid</Typography>
                  <UpgradePlanBtn />
                </Grid>
              </Grid>
              <Typography variant="h6">
                Campaign creation and management
              </Typography>
              <List>
                <ListItem>
                  <Typography>Active campaigns</Typography>
                  <Grid />
                </ListItem>
                <ListItem>
                  <Typography>Campaign responses/mo.</Typography>
                  <Grid />
                </ListItem>
                {[
                  "NPS",
                  "Ratings",
                  "Open-ended questions",
                  "CTA callouts",
                  "Multiple choice",
                  "Campaign triggers",
                  "Edit live campaigns",
                  "Premium pack templates",
                ].map((option, id) => (
                  <ListItem
                  key={id} //eslint-disable-line
                  >
                    <Typography>{option}</Typography>
                    <Grid>
                      <CheckCircle />
                      <CheckCircle />
                    </Grid>
                  </ListItem>
                ))}
              </List>
            </Grid>
            <Grid>
              <Grid container justify="flex-end">
                <Grid>
                  <Typography>Freemium</Typography>
                  <Button disabled variant="contained">
                    {company.plan.type === "freemium"
                      ? "Current plan"
                      : "Freemium"}
                  </Button>
                </Grid>
                <Grid>
                  <Typography>Paid</Typography>
                  <UpgradePlanBtn />
                </Grid>
              </Grid>
              <Typography variant="h6">Triggers & settings</Typography>
              <List>
                {[
                  "Page visit triggers",
                  "Time based triggers",
                  "Recurring triggers",
                  "Custom survey design",
                  "White-labeled surveys",
                  "User segments",
                ].map((option, id) => (
                  <ListItem
                  key={id} //eslint-disable-line
                  >
                    <Typography>{option}</Typography>
                    <Grid>
                      <CheckCircle />
                      <CheckCircle />
                    </Grid>
                  </ListItem>
                ))}
              </List>
            </Grid>
            <Grid>
              <Grid container justify="flex-end">
                <Grid>
                  <Typography>Freemium</Typography>
                  <Button disabled variant="contained">
                    {company.plan.type === "freemium"
                      ? "Current plan"
                      : "Freemium"}
                  </Button>
                </Grid>
                <Grid>
                  <Typography>Paid</Typography>
                  <UpgradePlanBtn />
                </Grid>
              </Grid>
              <Typography variant="h6">Installation & integrations</Typography>
              <List>
                {[
                  "Google Tag Manager",
                  "Javascript snippet",
                  "Zapier",
                  "REST API",
                ].map((option, id) => (
                  <ListItem
                  key={id} //eslint-disable-line
                  >
                    <Typography>{option}</Typography>
                    <Grid>
                      <CheckCircle />
                      <CheckCircle />
                    </Grid>
                  </ListItem>
                ))}
              </List>
            </Grid>
            <Grid>
              <Grid container justify="flex-end">
                <Grid>
                  <Typography>Freemium</Typography>
                  <Button disabled variant="contained">
                    {company.plan.type === "freemium"
                      ? "Current plan"
                      : "Freemium"}
                  </Button>
                </Grid>
                <Grid>
                  <Typography>Paid</Typography>
                  <UpgradePlanBtn />
                </Grid>
              </Grid>
              <Typography variant="h6">Help & support</Typography>
              <List>
                {["Email support", "Live chat support"].map((option, id) => (
                  <ListItem
                  key={id} //eslint-disable-line
                  >
                    <Typography>{option}</Typography>
                    <Grid>
                      <CheckCircle />
                      <CheckCircle />
                    </Grid>
                  </ListItem>
                ))}
              </List>
            </Grid>
          </Grid>
          <Backdrop open={state.backdrop} style={{ zIndex: 10 }}>
            <CircularProgress color="primary" />
          </Backdrop>
        </Grid>
      </Dialog>

      <Dialog
        open={state.scheduleDemoOpen}
        className={useStyles.scheduleDemoModal}
        onClose={handleScheduleDemoModal}
      >
        <Grid>
          <IconButton onClick={handleScheduleDemoModal}>
            <Close />
          </IconButton>
          <Button onClick={handleScheduleDemoModal}>
            <ChevronLeft />
            Back
          </Button>
          <Typography variant="h4">Schedule Demo</Typography>
          <Typography variant="subtitle1">
            Please fill out the information below and we will reach out to
            schedule time to speak about your needs.
          </Typography>
          <Grid>
            <Typography>Email</Typography>
            <TextField
              variant="outlined"
              value={state.email}
              onChange={(event) =>
                setState({ ...state, email: event.target.value })}
            />
            <Typography>Phone Number</Typography>
            <TextField
              variant="outlined"
              value={state.phone}
              onChange={(event) => {
                setState({ ...state, phone: event.target.value });
              }}
            />
            <Typography>Would you prefer email or phone?</Typography>
            <TextField
              select
              variant="outlined"
              value={state.preferContact}
              onChange={(event) =>
                setState({ ...state, preferContact: event.target.value })}
              SelectProps={{
                MenuProps: {
                  disableScrollLock: true,
                },
              }}
            >
              <MenuItem value="email">Email</MenuItem>
              <MenuItem value="phone">Phone</MenuItem>
            </TextField>
            {state.email === "" && state.phone === "" ? (
              <Tooltip
                arrow
                title="You need to fill in at least one field"
                enterTouchDelay={0}
              >
                <Grid>
                  <Button disabled color="primary" variant="contained">
                    Submit request
                  </Button>
                </Grid>
              </Tooltip>
            ) : (
              <Grid>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleRequestDemo}
                >
                  Submit request
                </Button>
              </Grid>
            )}
          </Grid>
          <Backdrop open={state.backdrop} style={{ zIndex: 10 }}>
            <CircularProgress color="primary" />
          </Backdrop>
        </Grid>
      </Dialog>

      <Dialog
        open={state.updadePlanConfirmationOpen}
        className={useStyles.updadePlanConfirmation}
        onClose={handleUpdatePlanModal}
      >
        <IconButton onClick={handleUpdatePlanModal}>
          <Close />
        </IconButton>
        <Typography variant="h6">
          Are you sure that you want to upgrade your current subscription plan?
        </Typography>
        <Typography>
          The subscription you choose is going to be activated after you press
          &quot;Confirm&quot; button
        </Typography>
        <Button onClick={handleUpdatePlan} variant="contained" color="primary">
          Confirm
        </Button>
      </Dialog>

      {state.alertTextMsg && (
        <ClickAwayListener
          onClickAway={() =>
            setState({
              ...state,
              alertTextMsg: "",
            })}
        >
          <Grid style={{ zIndex: "99999" }}>
            <AlertMessage type="success" message={state.alertTextMsg} />
          </Grid>
        </ClickAwayListener>
      )}
    </Grid>
  );
};

ChoosePaymentPlan.propTypes = {
  btnText: PropTypes.string.isRequired,
  open: PropTypes.bool,
  closeModal: PropTypes.func,
};

ChoosePaymentPlan.defaultProps = {
  open: false,
  closeModal: null,
};

export default ChoosePaymentPlan;
