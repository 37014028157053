import React from "react";
import PropTypes from "prop-types";
import {
  makeStyles,
  Grid,
  Typography,
  IconButton,
  FormControlLabel,
  Checkbox,
  Button,
  ButtonGroup,
  TextField,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { Close, Star } from "@material-ui/icons";

const Widget = (props) => {
  const { question, position, widgetClose, color, branding } = props;

  const [state, setState] = React.useState({
    npsAnswer: -1,
    ratingAnswer: -1,
    yesNoAnswer: -1,
  });

  // Transform Hex to RGBA and add opacity
  const hoverColor = `rgb(${color
    .substr(1)
    .match(/../g)
    .map((chunk) => +`0x${chunk}`)}, 0.09)`;
  const borderColor = hoverColor.replaceAll(", 0.09)", ", 0.47)");

  const useStyles = makeStyles({
    root: {
      position: "absolute",
      width: "336px",
      background: "#fff",
      left: position.includes("left") ? "20px" : "unset",
      right: position.includes("right") ? "20px" : "unset",
      top: position.includes("top") ? "90px" : "unset",
      bottom: position.includes("bottom") ? "20px" : "unset",
      boxShadow: "0px 3px 14px rgba(0, 0, 0, 0.12)",
      borderRadius: "4px",
      padding: "16px 45px 8px 20px",

      "& .Widget": {
        "&__close": {
          position: "absolute",
          right: "5px",
          top: "5px",

          "& svg": {
            width: "18px",
            height: "18px",
            color: "rgba(136, 136, 136, 0.54)",
          },
        },
        "&__label": {
          display: branding ? "flex" : "none",

          "& p": {
            fontSize: "9px",
            lineHeight: "13px",
            color: "rgba(0, 0, 0, 0.38)",
            marginRight: "5px",
          },
        },
        "&__multipleChoice": {
          marginBottom: "19px",

          "& svg": {
            color: "rgba(136, 136, 136, 0.54)",
          },

          "& .Mui-checked": {
            "& svg": {
              fill: color,
            },
            "& + span": {
              color,
            },
          },

          "& label": {
            marginBottom: "-3px",

            "& span": {
              transition: "color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",

              "&.MuiButtonBase-root:first-child:hover": {
                backgroundColor: hoverColor,
              },
            },

            "&:hover span": {
              color,
            },
          },
        },
        "&__NPS_Score": {
          marginTop: "7px",
          marginBottom: "15px",

          "& > .MuiGrid-container": {
            width: "296px",

            "&:first-child": {
              "&:hover button": {
                backgroundColor: hoverColor,
                borderColor,
                color,
              },

              "& button": {
                width: "26px",
                height: "26px",
                minWidth: "unset",
                padding: "0",

                "&:hover": {
                  backgroundColor: hoverColor,
                  borderColor,
                  color,
                },

                "&:focus": {
                  backgroundColor: hoverColor,
                  color,
                },

                "&:last-child": {
                  marginRight: "0",
                },

                "&:after": {
                  content: "''",
                  display: "block",
                  position: "absolute",
                  right: "-6px",
                  left: "0",
                  height: "26px",
                },

                [`&:nth-child(-n+${state.npsAnswer + 1})`]: {
                  backgroundColor: hoverColor,
                  borderColor,
                  color,
                },

                "&:hover button:hover, &:hover ~ button": {
                  backgroundColor: "transparent",
                  border: "1px solid rgba(0, 0, 0, 0.23)",
                  color: "rgba(0, 0, 0, 0.87)",
                },
              },
            },
            "&:last-child": {
              "& > p": {
                fontWeight: "500",
                fontSize: "10px",
                lineHeight: "11px",
                color: "#616161",
                marginTop: "11px",
              },
            },
          },
        },
        "&__openQuestion": {
          marginBottom: "30px",

          "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
            borderBottom: `1px solid ${color}`,
          },

          "& .MuiInput-underline:after": {
            borderBottom: `2px solid ${color}`,
          },

          "& p": {
            letterSpacing: "0.4px",
            color: "rgba(136, 136, 136, 0.54)",
          },

          "& input": {
            marginTop: "6px",
          },
        },
        "&__cta": {
          display: "block",
          margin: "16px 0 22px",
          textAlign: "center",

          "& button": {
            width: "290px",
            boxShadow: "none",
            color: "#fff",
            padding: "8px 0",

            "&:hover:after": {
              filter: "brightness(0.9)",
            },

            "&:after": {
              content: "''",
              backgroundColor: color,
              width: "100%",
              height: "100%",
              transition: "filter 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
              position: "absolute",
              borderRadius: "4px",
            },

            "& span": {
              fontSize: "15px",
              lineHeight: "26px",
              letterSpacing: "0.3px",
              zIndex: "1",
            },
          },
        },
        "&__rating": {
          "& .MuiButtonGroup-root": {
            width: "264px",
            margin: "16px 0 9px",

            "&:hover button": {
              backgroundColor: hoverColor,
              borderColor,
              color,
            },

            "& > button": {
              flex: "1 1 0",
              width: "0",
              minWidth: "unset",
              padding: `${
                question.options ? 10 - question.options.starsNumber : 0
              }px 0`,
              borderColor,

              "&:hover": {
                backgroundColor: hoverColor,
                borderColor,
                color,
              },

              "&:focus": {
                backgroundColor: hoverColor,
                color,
              },

              "&:after": {
                content: "''",
                display: "block",
                position: "absolute",
                right: "-6px",
                left: "0",
                height: "26px",
              },

              [`&:nth-child(-n+${state.ratingAnswer + 1})`]: {
                backgroundColor: hoverColor,
                borderColor,
                color,
              },

              "&:hover button:hover, &:hover ~ button": {
                backgroundColor: "transparent",
              },

              "& svg": {
                width: `${
                  question.options ? 29 - question.options.starsNumber : 0
                }px`,
                fill: color,
              },
            },
          },

          "& > .MuiGrid-container": {
            marginBottom: "19px",
            width: "264px",

            "& p": {
              fontWeight: "500",
              fontSize: "10px",
              lineHeight: "11px",
              color: "#616161",
            },
          },
        },
      },
    },
  });

  return (
    <Grid className={useStyles().root}>
      <IconButton className="Widget__close" onClick={widgetClose}>
        <Close />
      </IconButton>
      <Typography variant="subtitle2">{question.text}</Typography>
      {/* Multiple Choice */}
      {question.type === "multipleChoice" &&
        question.options.optionsList[0] &&
        question.options.optionsList[0].value && (
          <Grid container direction="column" className="Widget__multipleChoice">
            {question.options.optionsList.map((option) =>
              option.value ? (
                <FormControlLabel
                  key={option.position}
                  control={<Checkbox color="default" />}
                  label={option.value}
                />
              ) : null
            )}
          </Grid>
        )}

      {/* NPS_Score */}
      {question.type === "NPS_Score" && (
        <Grid className="Widget__NPS_Score">
          <Grid container justify="space-between">
            {Array.from(Array(10).keys()).map((number) => (
              <Button
                key={number + 1}
                variant="outlined"
                color="default"
                onClick={() => setState({ ...state, npsAnswer: number })}
              >
                <Typography variant="body2">{number + 1}</Typography>
              </Button>
            ))}
          </Grid>
          <Grid container justify="space-between">
            <Typography>{question.options.lowScoreText}</Typography>
            <Typography>{question.options.highScoreText}</Typography>
          </Grid>
        </Grid>
      )}

      {/* Yes/No */}
      {question.type === "yesNo" && (
        <Grid container direction="column" className="Widget__multipleChoice">
          <FormControlLabel
            control={(
              <Checkbox
                color="default"
                checked={state.yesNoAnswer === true}
                onClick={() => setState({ ...state, yesNoAnswer: true })}
              />
            )}
            label="Yes"
          />
          <FormControlLabel
            control={(
              <Checkbox
                color="default"
                checked={state.yesNoAnswer === false}
                onClick={() => setState({ ...state, yesNoAnswer: false })}
              />
            )}
            label="No"
          />
        </Grid>
      )}

      {/* Open Question */}
      {question.type === "openQuestion" && (
        <Grid container direction="column" className="Widget__openQuestion">
          <TextField
            placeholder="Type respons here..."
            helperText="500 character limit"
            fullWidth
          />
        </Grid>
      )}

      {/* CTA */}
      {question.type === "cta" && (
        <a
          href={question.options.linkUrl.replace(
            /^(?:(.*:)?\/\/)?(.*)/i,
            (match, schemma, nonSchemmaUrl) =>
              schemma ? match : `http://${nonSchemmaUrl}`
          )}
          rel="noopener noreferrer"
          target="_blank"
          className="Widget__cta"
        >
          <Button variant="contained" fullWidth>
            {question.options.buttonText}
          </Button>
        </a>
      )}

      {/* Rating */}
      {question.type === "rating" && (
        <Grid container direction="column" className="Widget__rating">
          <ButtonGroup
            color="primary"
            aria-label="outlined primary button group"
          >
            {Array.from(Array(question.options.starsNumber).keys()).map(
              (button) => (
                <Button
                  key={button + 1}
                  onClick={() => setState({ ...state, ratingAnswer: button })}
                >
                  <Star />
                </Button>
              )
            )}
          </ButtonGroup>
          <Grid container justify="space-between">
            <Typography>{question.options.lowScoreText}</Typography>
            <Typography>{question.options.highScoreText}</Typography>
          </Grid>
        </Grid>
      )}

      <Link to="/" target="_blank">
        <Grid
          container
          justify="center"
          alignItems="flex-end"
          className="Widget__label"
        >
          <Typography>powered by</Typography>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="10"
            viewBox="0 0 32 10"
            fill="none"
          >
            <path
              d="M9.23927 1.79735C9.23927 1.6327 9.23494 1.42039 9.22628 1.16041C9.21761 0.900436 9.20461 0.67079 9.18728 0.471474H10.3442C10.3615 0.62746 10.3745 0.809444 10.3832 1.01743C10.3918 1.21674 10.3962 1.38139 10.3962 1.51138H10.4352C10.6085 1.14741 10.8598 0.857106 11.1891 0.640459C11.5271 0.415146 11.904 0.30249 12.32 0.30249C12.5106 0.30249 12.671 0.319822 12.801 0.354485L12.749 1.48538C12.5756 1.44205 12.3893 1.42039 12.19 1.42039C11.8954 1.42039 11.6397 1.47672 11.4231 1.58937C11.2064 1.69336 11.0244 1.83635 10.8771 2.01833C10.7385 2.20032 10.6345 2.41263 10.5652 2.65528C10.4958 2.88926 10.4612 3.13623 10.4612 3.39621V6.63292H9.23927V1.79735Z"
              fill="black"
              fillOpacity="0.5"
            />
            <path
              d="M19.3098 3.5392C19.3098 4.01582 19.2232 4.45345 19.0498 4.85208C18.8852 5.25071 18.6512 5.59735 18.3479 5.89199C18.0533 6.17796 17.7023 6.40327 17.295 6.56792C16.8877 6.72391 16.4501 6.8019 15.9821 6.8019C15.5142 6.8019 15.0765 6.72391 14.6692 6.56792C14.2619 6.40327 13.911 6.17796 13.6163 5.89199C13.3217 5.59735 13.0877 5.25071 12.9144 4.85208C12.7497 4.45345 12.6674 4.01582 12.6674 3.5392C12.6674 3.06257 12.7497 2.62928 12.9144 2.23931C13.0877 1.84068 13.3217 1.49838 13.6163 1.21241C13.911 0.926434 14.2619 0.705454 14.6692 0.549468C15.0765 0.384816 15.5142 0.30249 15.9821 0.30249C16.4501 0.30249 16.8877 0.384816 17.295 0.549468C17.7023 0.705454 18.0533 0.926434 18.3479 1.21241C18.6512 1.49838 18.8852 1.84068 19.0498 2.23931C19.2232 2.62928 19.3098 3.06257 19.3098 3.5392ZM18.0359 3.5392C18.0359 3.24456 17.9883 2.96292 17.8929 2.69427C17.8063 2.42563 17.6763 2.19165 17.503 1.99234C17.3297 1.78436 17.113 1.6197 16.853 1.49838C16.6017 1.37706 16.3114 1.3164 15.9821 1.3164C15.6528 1.3164 15.3582 1.37706 15.0982 1.49838C14.8469 1.6197 14.6346 1.78436 14.4612 1.99234C14.2879 2.19165 14.1536 2.42563 14.0583 2.69427C13.9716 2.96292 13.9283 3.24456 13.9283 3.5392C13.9283 3.83384 13.9716 4.11548 14.0583 4.38412C14.1536 4.65276 14.2879 4.89108 14.4612 5.09906C14.6346 5.30704 14.8469 5.47169 15.0982 5.59301C15.3582 5.71433 15.6528 5.775 15.9821 5.775C16.3114 5.775 16.6017 5.71433 16.853 5.59301C17.113 5.47169 17.3297 5.30704 17.503 5.09906C17.6763 4.89108 17.8063 4.65276 17.8929 4.38412C17.9883 4.11548 18.0359 3.83384 18.0359 3.5392Z"
              fill="black"
              fillOpacity="0.5"
            />
            <path
              d="M23.253 6.63292H21.9271L19.4184 0.471474H20.7703L22.5771 5.32004H22.6031L24.4229 0.471474H25.7228L23.253 6.63292Z"
              fill="black"
              fillOpacity="0.5"
            />
            <path
              d="M30.7651 3.00625C30.7565 2.7636 30.7131 2.53829 30.6351 2.33031C30.5658 2.11366 30.4575 1.92734 30.3102 1.77136C30.1715 1.61537 29.9939 1.49405 29.7772 1.40739C29.5692 1.31207 29.3223 1.2644 29.0363 1.2644C28.7763 1.2644 28.5293 1.31207 28.2954 1.40739C28.07 1.49405 27.8707 1.61537 27.6974 1.77136C27.5328 1.92734 27.3941 2.11366 27.2814 2.33031C27.1775 2.53829 27.1168 2.7636 27.0995 3.00625H30.7651ZM32 3.5002C32 3.56953 32 3.63886 32 3.70818C32 3.77751 31.9957 3.84684 31.987 3.91616H27.0995C27.1081 4.17614 27.1645 4.42312 27.2684 4.6571C27.3811 4.88241 27.5284 5.08172 27.7104 5.25504C27.8924 5.41969 28.1004 5.54968 28.3344 5.64501C28.577 5.74033 28.8326 5.78799 29.1013 5.78799C29.5172 5.78799 29.8769 5.697 30.1802 5.51502C30.4835 5.33304 30.7218 5.11206 30.8951 4.85208L31.753 5.54102C31.4237 5.97431 31.0338 6.29495 30.5831 6.50293C30.1412 6.70225 29.6472 6.8019 29.1013 6.8019C28.6333 6.8019 28.2 6.72391 27.8014 6.56792C27.4028 6.41194 27.0605 6.19529 26.7745 5.91798C26.4885 5.63201 26.2632 5.28971 26.0986 4.89108C25.9339 4.49244 25.8516 4.05049 25.8516 3.5652C25.8516 3.08857 25.9296 2.65094 26.0856 2.25231C26.2502 1.84502 26.4755 1.49838 26.7615 1.21241C27.0475 0.926434 27.3854 0.705454 27.7754 0.549468C28.1654 0.384816 28.5857 0.30249 29.0363 0.30249C29.4869 0.30249 29.8942 0.37615 30.2582 0.52347C30.6308 0.67079 30.9428 0.883104 31.1941 1.16041C31.4541 1.43772 31.6534 1.77569 31.792 2.17432C31.9307 2.56429 32 3.00625 32 3.5002Z"
              fill="black"
              fillOpacity="0.5"
            />
            <path
              d="M1.6507 0.471474H0.752572C0.694234 0.471474 0.68194 0.553758 0.73773 0.570809C1.22618 0.720098 1.57331 1.15336 1.61248 1.66261L1.6507 2.15947V0.471474Z"
              fill="black"
              fillOpacity="0.5"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M2.8466 1.43339C2.8466 0.902137 2.41593 0.471474 1.88468 0.471474H1.6507V2.15947V7.73039H2.87259V5.71H2.91159C3.1369 6.05664 3.44454 6.32528 3.83451 6.51593C4.22447 6.70658 4.64043 6.8019 5.08239 6.8019C5.55902 6.8019 5.98798 6.71958 6.36928 6.55493C6.75058 6.38161 7.07555 6.14763 7.34419 5.85299C7.61283 5.55835 7.82081 5.21171 7.96813 4.81308C8.11545 4.41445 8.18911 3.98982 8.18911 3.5392C8.18911 3.08857 8.11545 2.66828 7.96813 2.27831C7.82081 1.87968 7.6085 1.53305 7.33119 1.23841C7.06255 0.943765 6.73758 0.714119 6.35628 0.549468C5.98364 0.384816 5.56768 0.30249 5.10839 0.30249C4.5971 0.30249 4.14648 0.410814 3.75651 0.627461C3.37521 0.835442 3.08491 1.10408 2.88559 1.43339H2.8466ZM6.79824 2.70727C6.8849 2.97591 6.92823 3.25322 6.92823 3.5392C6.92823 3.82517 6.8849 4.10248 6.79824 4.37112C6.71158 4.63976 6.58159 4.87808 6.40827 5.08606C6.23496 5.29404 6.01831 5.46302 5.75833 5.59301C5.50702 5.71433 5.21238 5.775 4.87441 5.775C4.56244 5.775 4.27647 5.71433 4.01649 5.59301C3.76518 5.47169 3.54853 5.31137 3.36655 5.11206C3.18456 4.90407 3.04158 4.66576 2.93759 4.39712C2.84226 4.12848 2.7946 3.84684 2.7946 3.5522C2.7946 3.25756 2.84226 2.97591 2.93759 2.70727C3.04158 2.43863 3.18456 2.20032 3.36655 1.99234C3.54853 1.78436 3.76518 1.6197 4.01649 1.49838C4.27647 1.37706 4.56244 1.3164 4.87441 1.3164C5.21238 1.3164 5.50702 1.37706 5.75833 1.49838C6.01831 1.6197 6.23496 1.78436 6.40827 1.99234C6.58159 2.20032 6.71158 2.43863 6.79824 2.70727Z"
              fill="black"
              fillOpacity="0.5"
            />
            <path
              d="M2.87259 7.73039H1.6507L1.6587 7.73838C1.99483 8.07452 2.54091 8.07092 2.87259 7.73039Z"
              fill="black"
              fillOpacity="0.5"
            />
            <path
              d="M4.53931 7.93675L4.53931 9.0803L4.21192 9.32485C3.61368 9.77172 2.94922 9.99677 2.28428 10C1.6096 10.0033 0.934423 9.77826 0.327425 9.32485L3.38055e-05 9.0803L3.38554e-05 7.93675C0.670695 8.63039 1.4779 8.97511 2.28429 8.97089C3.08092 8.96673 3.87675 8.62201 4.53931 7.93675Z"
              fill="black"
              fillOpacity="0.5"
            />
          </svg>
        </Grid>
      </Link>
    </Grid>
  );
};

Widget.propTypes = {
  position: PropTypes.string.isRequired,
  question: PropTypes.objectOf(PropTypes.any).isRequired,
  widgetClose: PropTypes.func.isRequired,
  color: PropTypes.string.isRequired,
  branding: PropTypes.bool.isRequired,
};

export default Widget;
