const company = (state = {}, action) => {
  switch (action.type) {
    case "COMPANY":
      return {
        ...state,
        id: action.payload.id,
        uuid: action.payload.uuid,
        name: action.payload.name,
        url: action.payload.url,
        snippetInstalled: action.payload.snippetInstalled,
        plan: action.payload.plan,
        campaigns: action.payload.campaigns,
        activeUsers: {
          admins: action.payload.activeUsers.admins,
          managers: action.payload.activeUsers.managers,
          viewers: action.payload.activeUsers.viewers,
        },
        usedResponses: action.payload.usedResponses,
      };
    default:
      return state;
  }
};

export default company;
