import React from "react";
import PropTypes from "prop-types";
import {
  makeStyles,
  Grid,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  ClickAwayListener,
} from "@material-ui/core";
import {
  TagFaces,
  Visibility,
  VisibilityOff,
  LibraryAddCheck,
  HowToVote,
  MoreVert,
  Stars,
  Tonality,
  FormatAlignLeft,
  NearMe,
} from "@material-ui/icons";

import EditQuestion from "./EditQuestion";
import AddNewQuestion from "./AddNewQuestion";

const QuestionsList = (props) => {
  const { questions, getNewQuestions, demoWidget, getActiveWidget } = props;

  // add an position for each question and options
  questions.forEach((key, id) => {
    key.position = id; // eslint-disable-line
    if (key.type === "multipleChoice") {
      key.options.optionsList.forEach((option, optionId) => {
        if(typeof option === 'string') key.options.optionsList[optionId] = { // eslint-disable-line
            position: optionId,
            value: option,
          };
      });
    }
  });

  const [state, setState] = React.useState({
    questions,
    activeQuestion: -1,
    scrollItem: 0,
  });

  React.useEffect(() => {
    if (JSON.stringify(state.questions) !== JSON.stringify(questions)) {
      setState((prev) => ({ ...prev, questions }));
    }

    const newQuestion = document.querySelector(
      `.QuestionsList:nth-child(${state.scrollItem + 2})`
    );
    if (newQuestion && state.newQuestionAdded) {
      // setTimeout for smooth scroll and focus
      setTimeout(() => {
        setState((prev) => ({
          ...prev,
          newQuestionAdded: false,
          [`${state.scrollItem}activeQuestionEdit`]: false,
          [`${state.scrollItem + 1}activeQuestionEdit`]: true,
        }));
        newQuestion.querySelector("input").focus();
        newQuestion.scrollIntoView({ behavior: "smooth", block: "start" });
      }, 0);
    }
  }, [questions, state.questions, state.scrollItem, state.newQuestionAdded]);

  const useStyles = makeStyles(() => ({
    root: {
      "& .QuestionsList": {
        marginBottom: "20px",
        background: "#FAFAFA",
        padding: "20px 0px 18px 21px",
        position: "relative",
        cursor: demoWidget !== null ? "pointer" : "initial",

        [`&:nth-child(${state.activeQuestion + 1})`]: {
          backgroundColor: "#FAFAFA!important",

          "& .QuestionsList": {
            "&__add": {
              opacity: "1",
            },
          },
        },

        [`&:nth-child(${demoWidget + 1})`]: {
          cursor: "initial",
        },

        "&__logo": {
          color: "#fff",
          borderRadius: "16px",
          paddingRight: "8px",
          paddingLeft: "4px",
          fontSize: "12px",
          lineHeight: "22px",
          display: "flex",
          alignItems: "center",
          fontWeight: "600",
          marginRight: "9px",
          alignSelf: "flex-start",

          "& svg": {
            width: "15px",
            height: "15px",
            marginRight: "8px",
            marginBottom: "1px",
          },
        },

        "&__type": {
          fontSize: "10px",
          lineHeight: "11px",
          fontWeight: "600",
          color: "#B3B3B3",
          letterSpacing: "1px",
          marginTop: "5px",
          marginBottom: "15px",
        },

        "&__title": {
          width: "395px",
          marginRight: "10px",
          marginLeft: "51px",
          fontSize: "16px",
          lineHeight: "19px",
          letterSpacing: "0.15px",
          color: "#000000",
          textOverflow: "ellipsis",
          overflow: "hidden",
        },

        "&__visibility": {
          padding: "10px",
          position: "absolute",
          right: "11px",
          top: "50%",
          transform: "translateY(-50%)",

          "& svg": {
            color: "rgba(136, 136, 136, 0.54)",
          },
        },

        "&__actions": {
          display: "none",
        },

        "&__add": {
          position: "absolute",
          display: "none",
          bottom: "-10px",

          "& h6": {
            display: "none",
          },

          "& > .MuiGrid-root": {
            margin: "0",

            "& button svg": {
              width: "20px",
              height: "20px",
            },
          },
        },
      },
    },
    questionAction: {
      marginTop: "-15px",

      "& .MuiPopover-paper": {
        width: "160px",
        boxShadow:
          "0px 2px 4px rgba(0, 0, 0, 0.2), 0px 1px 10px rgba(0, 0, 0, 0.12)",
        borderRadius: "5px",
      },
    },
  }));

  const questionActionHide = (anchor) => {
    setState({ ...state, [anchor]: null, activeQuestion: -1 });
  };

  const getQuestionConfig = (type) => {
    switch (type) {
      case "NPS_Score": {
        return {
          color: "#BE134D",
          title: "NPS SURVEY",
          img: <TagFaces />,
        };
      }

      case "multipleChoice": {
        return {
          color: "#EADA9D",
          title: "MULTIPLE CHOICE",
          img: <LibraryAddCheck />,
        };
      }

      case "thankYouNote": {
        return {
          color: "#F1DF38",
          title: "THANK YOU NOTE",
          img: <HowToVote />,
        };
      }

      case "rating": {
        return {
          color: "#F06191",
          title: "RATING",
          img: <Stars />,
        };
      }

      case "yesNo": {
        return {
          color: "#6D9D68",
          title: "YES/NO",
          img: <Tonality />,
        };
      }

      case "openQuestion": {
        return {
          color: "#2196F3",
          title: "OPEN QUESTION",
          img: <FormatAlignLeft />,
        };
      }

      case "cta": {
        return {
          color: "#C77700",
          title: "CTA",
          img: <NearMe />,
        };
      }

      default:
        return {};
    }
  };

  const handleHide = (position) => {
    setState({
      ...state,
      [`${position}hideQuestionEdit`]: !state[`${position}hideQuestionEdit`],
    });
  };

  const openQuestionAction = (position) => (event) => {
    setState({
      ...state,
      [`${position}anchorQuestionAction`]: event.currentTarget,
      activeQuestion: position,
    });
  };

  const handleQuestionAction = (type, position) => {
    const sortedQuestions = [...state.questions];
    const current = sortedQuestions[position];
    const newQuestion = { ...sortedQuestions[position] };

    switch (type) {
      case "moveDown":
        sortedQuestions[position] = sortedQuestions[position + 1];
        sortedQuestions[position + 1] = current;
        break;

      case "delete":
        sortedQuestions.splice(position, 1);
        break;

      case "duplicate":
        newQuestion.id = null;
        newQuestion.position =
          sortedQuestions.reduce(
            (maxId, item) => Math.max(maxId, item.position),
            0
          ) + 1;
        sortedQuestions.splice(position, 0, newQuestion);
        break;

      default:
        break;
    }

    setState({
      ...state,
      [`${position}anchorQuestionAction`]: null,
      [`${position}activeQuestionEdit`]: false,
      questions: sortedQuestions,
      activeQuestion: -1,
    });

    getNewQuestions(sortedQuestions);
  };

  const handleQuestionChange = (question) => {
    const newQuestionsList = questions.map((item) => {
      if (item.position === question.position) return question;
      return item;
    });

    getNewQuestions(newQuestionsList);
  };

  const QuestionAction = (question) => {
    return (
      <Menu
        open={!!state[`${question.position}anchorQuestionAction`]}
        onClose={() =>
          questionActionHide(`${question.position}anchorQuestionAction`)}
        anchorEl={state[`${question.position}anchorQuestionAction`]}
        keepMounted
        disableScrollLock
        className={useStyles().questionAction}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <MenuItem
          disabled={state.questions.length === question.position + 1}
          onClick={() => handleQuestionAction("moveDown", question.position)}
        >
          Move down
        </MenuItem>
        <MenuItem
          onClick={() => handleQuestionAction("duplicate", question.position)}
        >
          Duplicate
        </MenuItem>
        <MenuItem
          onClick={() => handleQuestionAction("delete", question.position)}
        >
          Delete
        </MenuItem>
      </Menu>
    );
  };

  const handleActiveQuestion = (position) => {
    setState({ ...state, activeQuestion: position });
  };

  const handleClickAway = (position, isActive) => {
    if (isActive !== state[`${position}activeQuestionEdit`]) {
      setState({
        ...state,
        [`${position}activeQuestionEdit`]: isActive,
      });
    }
  };

  const addOneNewQuestion = (question, position) => {
    const newQuestionsList = [...state.questions];

    newQuestionsList.splice(position + 1, 0, question[0]);

    const sortedList = newQuestionsList.map((newQuestion, i) => {
      if (i >= position) return { ...newQuestion, position: i };
      return newQuestion;
    });

    setState({
      ...state,
      activeQuestion: -1,
      scrollItem: position,
      newQuestionAdded: true,
    });

    getNewQuestions(sortedList, position + 1);
  };

  return (
    <>
      <Grid container className={useStyles().root}>
        {state.questions.map((question, position) => (
          <ClickAwayListener
            key={question.position}
            onClickAway={() => handleClickAway(position, false)}
          >
            <Grid
              container
              className="QuestionsList"
              onClick={() =>
                demoWidget !== null && demoWidget !== position
                  ? getActiveWidget(position)
                  : null}
            >
              <Grid
                className="QuestionsList__logo"
                style={{
                  background: getQuestionConfig(question.type).color,
                }}
              >
                {getQuestionConfig(question.type).img}
                {position + 1}
              </Grid>
              <Typography className="QuestionsList__type">
                {getQuestionConfig(question.type).title}
              </Typography>
              <EditQuestion
                question={question}
                editMode={!!getNewQuestions}
                hideAdditional={!!state[`${position}hideQuestionEdit`]}
                questionChange={handleQuestionChange}
                activeQuestionEdit={
                  state[`${position}activeQuestionEdit`]
                    ? state[`${position}activeQuestionEdit`]
                    : false
                }
                handleClickAway={handleClickAway}
              />
              <IconButton
                className="QuestionsList__visibility"
                onClick={() => handleHide(position)}
              >
                {state[`${position}hideQuestionEdit`] ? (
                  <VisibilityOff />
                ) : (
                  <Visibility />
                )}
              </IconButton>
              <IconButton
                className="QuestionsList__actions"
                onClick={openQuestionAction(question.position)}
              >
                <MoreVert />
              </IconButton>
              <QuestionAction position={question.position} />
              <Grid className="QuestionsList__add">
                <AddNewQuestion
                  getNewQuestions={addOneNewQuestion}
                  activeQuestion={handleActiveQuestion}
                  position={question.position}
                />
              </Grid>
            </Grid>
          </ClickAwayListener>
        ))}
      </Grid>

      {demoWidget !== null && (
        <AddNewQuestion
          getNewQuestions={addOneNewQuestion}
          activeQuestion={handleActiveQuestion}
          position={state.questions.length - 1}
          isFirstQuestion={state.questions.length}
        />
      )}
    </>
  );
};

QuestionsList.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.object).isRequired,
  getNewQuestions: PropTypes.func,
  demoWidget: PropTypes.number,
  getActiveWidget: PropTypes.func,
};

QuestionsList.defaultProps = {
  getNewQuestions: null,
  demoWidget: null,
  getActiveWidget: null,
};

export default QuestionsList;
