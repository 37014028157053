import axios from "axios";

// TODO: made API_URL env specific, not hardcoded
const API_URL =
  process.env.REACT_APP_PROVE_API || "https://prove-backend-stg.herokuapp.com/";

export const securedAxiosInstance = axios.create({
  baseURL: API_URL,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});

export const plainAxiosInstance = axios.create({
  baseURL: API_URL,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});

securedAxiosInstance.interceptors.request.use((config) => {
  config.headers = {
    ...config.headers,
    Authorization: `Bearer ${localStorage.jwt}`,
  };
  return config;
});

securedAxiosInstance.interceptors.response.use(null, (error) => {
  if (
    error.response &&
    error.response.config &&
    error.response.status === 401
  ) {
    // In case 401 is caused by expired access cookie - we'll do refresh request
    return plainAxiosInstance
      .post(
        "/refresh",
        {},
        {
          headers: { Authorization: `Bearer ${localStorage.jwt}` },
        }
      )
      .then((response) => {
        localStorage.jwt = response.data.jwt;
        // And after successful refresh - repeat the original request
        const retryConfig = error.response.config;
        retryConfig.headers.Authorization = `Bearer ${localStorage.csrf}`;
        return plainAxiosInstance.request(retryConfig);
      })
      .catch((error) => {
        delete localStorage.jwt;
        // redirect to signin in case refresh request fails
        window.location.replace("/");
        return Promise.reject(error);
      });
  }
  return Promise.reject(error);
});
