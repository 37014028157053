import React from "react";
import { makeStyles, Button } from "@material-ui/core";
import PropTypes from "prop-types";

const SubmitBtn = (props) => {
  const useStyles = makeStyles({
    root: {
      color: "#fff",
      fontSize: "15px",
      lineHeight: "26px",
      textTransform: "capitalize",
      letterSpacing: "0.3px",
      padding: "8px 22px",
      boxShadow: "none",

      "&:hover": {
        boxShadow: "none",
      },
    },
  });

  const { btnText } = props;

  return (
    <Button
      className={useStyles().root}
      type="submit"
      variant="contained"
      color="primary"
    >
      {btnText}
    </Button>
  );
};

SubmitBtn.propTypes = {
  btnText: PropTypes.string.isRequired,
};

export default SubmitBtn;
