import React from "react";
import PropTypes from "prop-types";
import {
  makeStyles,
  Grid,
  Typography,
  Button,
  IconButton,
  CircularProgress,
  Dialog,
  Tabs,
  Tab,
} from "@material-ui/core";
import {
  Assignment,
  AssignmentTurnedIn,
  Close,
  Build,
} from "@material-ui/icons";
import { useSelector, useDispatch } from "react-redux";

import campaignsApi from "../api/campaigns-api";

const InstallSnippet = (props) => {
  const { snippetInstalledCallBack } = props;

  const dispatch = useDispatch();
  const company = useSelector((store) => store.company);

  const [state, setState] = React.useState({
    snippetCopied: false,
    verifyModalOpen: false,
    failedVerifyInstall: false,
    mode: 0,
    snippetInstalled: useSelector((store) => store.company.snippetInstalled),
  });

  React.useEffect(() => {
    let verifyInstallTimer;
    if (!state.snippetInstalled && !state.failedVerifyInstall) {
      if (!state.verifyModalOpen) {
        clearInterval(verifyInstallTimer);
      } else {
        let timerCount = 0;
        verifyInstallTimer = setInterval(() => {
          if (timerCount === 11) {
            setState({ ...state, failedVerifyInstall: true });
          }
          timerCount += 1;
          campaignsApi.fetchCampaignsByUuid(company.uuid).then((res) => {
            if (res.data.snipped_installed) {
              setState({
                ...state,
                snippetInstalled: true,
                verifyModalOpen: false,
              });
              dispatch({
                type: "COMPANY",
                payload: {
                  ...company,
                  snippetInstalled: true,
                },
              });
              if (snippetInstalledCallBack) {
                snippetInstalledCallBack();
              }
            }
          });
        }, 5000);
      }
      return () => clearInterval(verifyInstallTimer);
    }
  });

  const useStyles = makeStyles((theme) => ({
    root: {
      "& .InstallSnippet": {
        border: "1px solid #E0E0E0",
        borderRadius: "4px",
        marginLeft: "30px",
        flexWrap: "nowrap",
        position: "relative",
        width: "calc(100% - 30px)",

        [theme.breakpoints.down("sm")]: {
          width: "calc(100% - 80px)",
        },

        "&__copy": {
          position: "absolute",
          right: "0",
          top: "0",

          "& button": {
            display: "flex",
            margin: "0",
            background: "#FAFAFA",

            "&:hover": {
              "& svg": {
                fill: theme.palette.primary.dark,
              },
              "& p": {
                color: theme.palette.primary.dark,
              },
            },

            "& .MuiTouchRipple-root": {
              display: "none",
            },

            "& svg": {
              transition: "fill 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
            },

            "& p": {
              fontWeight: "600",
              fontSize: "14px",
              lineHeight: "20px",
              letterSpacing: "0.15px",
              color: theme.palette.primary.main,
              marginLeft: "5px",
              transition: "color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",

              [theme.breakpoints.down(370)]: {
                display: "none",
              },
            },
          },
        },

        "&__rows": {
          display: "flex",
          flexDirection: "column",
          borderRight: "1px solid #E0E0E0",
          borderRadius: "4px",
          minWidth: "33px",
          textAlign: "center",
          padding: "3px 0 6px",

          "& > p": {
            fontSize: "14px",
            lineHeight: "12px",
            letterSpacing: " 0.15px",
            color: "rgba(0, 0, 0, 0.38)",
            margin: "6px 0",
          },
        },

        "&__list": {
          background: "#FAFAFA",
          flex: "1",
          padding: "7px 4px 9px 10px",
          margin: "0",
          lineHeight: "22px",
          fontSize: "12px",
          color: "#000",
          overflowX: "auto",

          "&::-webkit-scrollbar-track": {
            webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
            backgroundColor: "transparent",
          },
          "&::-webkit-scrollbar": {
            width: "4px",
            height: "4px",
            backgroundColor: "transparent",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#96BD92",
            border: "2px solid #96BD92",
          },
        },
      },

      "& > .MuiTabs-root": {
        width: "calc(100% - 30px)",
        marginLeft: "30px",
        minHeight: "unset",

        [theme.breakpoints.down("sm")]: {
          width: "calc(100% - 80px)",
        },

        "& button": {
          flex: "1",
          maxWidth: "unset",
          textTransform: "capitalize",
          minHeight: "unset",
          padding: "9px",
        },
      },

      "& > button": {
        display: "block",
        marginLeft: "auto",
        marginTop: "26px",

        [theme.breakpoints.down("sm")]: {
          marginLeft: "36px",
        },
      },

      "& > .MuiGrid-root:first-child": {
        marginBottom: "16px",

        "& > svg": {
          fill: "#EADA9D",
          marginRight: "10px",
          position: "relative",
          top: "5px",
        },

        "& > p": {
          display: "inline-block",
          fontSize: "16px",
          lineHeight: "19px",
          letterSpacing: "0.15px",
          color: "#000000",

          [theme.breakpoints.down("sm")]: {
            width: "calc(100% - 60px)",
          },
        },

        "& > h6": {
          letterSpacing: "0.1px",
          color: "#000000",
          marginTop: "12px",
          marginLeft: "35px",
        },
      },
    },
    verify: {
      "& .MuiDialog-paper": {
        padding: "30px 65px 25px 20px",

        [theme.breakpoints.down("sm")]: {
          paddingRight: "20px",
          paddingTop: "40px",

          "& br": {
            display: "none",
          },
        },

        "& > button": {
          "&:first-child": {
            position: "absolute",
            right: "5px",
            top: "5px",
          },
          "&:last-child": {
            color: "#fff",
            marginTop: "15px",
            boxShadow: "none",
            width: "calc(100% + 45px)",
            height: "42px",
            fontWeight: "500",
            fontSize: "15px",
            lineHeight: "26px",
            letterSpacing: "0.3px",

            [theme.breakpoints.down("sm")]: {
              width: "100%",
            },
          },
        },

        "& > h5": {
          marginBottom: "5px",
        },

        "& .MuiCircularProgress-root": {
          marginTop: "20px",
          marginLeft: "50%",
        },
      },
    },
  }));

  const handleCopyToClipboard = (event) => {
    const code = event.target
      .closest(".InstallSnippet")
      .querySelector(".InstallSnippet__list");

    window.getSelection().selectAllChildren(code);
    document.execCommand("copy");
    code.blur();

    if (window.getSelection) {
      if (window.getSelection().empty) {
        // Chrome
        window.getSelection().empty();
      } else if (window.getSelection().removeAllRanges) {
        // Firefox
        window.getSelection().removeAllRanges();
      }
    } else if (document.selection) {
      // IE?
      document.selection.empty();
    }

    setState({ ...state, snippetCopied: true });
  };

  const handleVerifyModal = () => {
    setState({
      ...state,
      verifyModalOpen: !state.verifyModalOpen,
    });
  };

  return (
    <Grid className={useStyles().root}>
      <Grid>
        <Build />
        <Typography>Manual code install (javascript)</Typography>
        <Typography variant="subtitle2">
          Paste the following code into the &lt;head&gt; of every page you wish
          to collect feedback on.
        </Typography>
      </Grid>
      <Tabs
        value={state.mode}
        indicatorColor="primary"
        onChange={(event, newValue) => setState({ ...state, mode: newValue })}
      >
        <Tab label="Anonymous mode" />
        <Tab label="Identified users" />
      </Tabs>
      <Grid container className="InstallSnippet">
        <Grid className="InstallSnippet__rows">
          <Typography>1</Typography>
          <Typography>2</Typography>
          <Typography>3</Typography>
        </Grid>
        <pre className="InstallSnippet__list">
          {`<!-- Prove Tracking Code for https://app.tryprove.com -->
<script src="${
            process.env.REACT_APP_PROVE_API ||
            "https://prove-backend-stg.herokuapp.com/"
          }js/library${
            process.env.REACT_APP_PROVE_API ? "" : "-stg"
          }.js"></script>
<script>_prove("${company.uuid}"${
            state.mode === 1
              ? `, {
  name: "Frank White", // Replace with user name
  email: "frank@usertype.com", // Replace with user Email
}`
              : ""
          })</script>`}
        </pre>
        <Grid className="InstallSnippet__copy">
          <Button onClick={handleCopyToClipboard}>
            {state.snippetCopied ? (
              <>
                <AssignmentTurnedIn color="primary" />
                <Typography>Copied</Typography>
              </>
            ) : (
              <>
                <Assignment color="primary" />
                <Typography>Copy to clipboard</Typography>
              </>
            )}
          </Button>
        </Grid>
      </Grid>
      {!state.snippetInstalled && (
        <Button variant="outlined" color="primary" onClick={handleVerifyModal}>
          Verify Manual Install
        </Button>
      )}
      <Dialog
        onClose={handleVerifyModal}
        open={state.verifyModalOpen}
        className={useStyles().verify}
      >
        <IconButton onClick={handleVerifyModal}>
          <Close />
        </IconButton>
        <Typography variant="h5">Check Code Snippet Signal</Typography>
        <Typography variant="body1">
          Please install snippet and refresh the page
          <br />
          to check the widget connection
        </Typography>
        {state.failedVerifyInstall ? (
          <Button
            variant="contained"
            color="primary"
            onClick={() => setState({ ...state, failedVerifyInstall: false })}
          >
            Check Again
          </Button>
        ) : (
          <CircularProgress />
        )}
      </Dialog>
    </Grid>
  );
};

InstallSnippet.propTypes = {
  snippetInstalledCallBack: PropTypes.func,
};

InstallSnippet.defaultProps = {
  snippetInstalledCallBack: null,
};

export default InstallSnippet;
