import { plainAxiosInstance, securedAxiosInstance } from "./api";

const authSuccessful = (response) => {
  if (!response.data.jwt) {
    this.authFailed(response);
    return;
  }

  localStorage.jwt = response.data.jwt;

  return response;
};

const authFailed = (error) => {
  delete localStorage.jwt;

  return error.response;
};

const authApi = {
  signup: (user) =>
    plainAxiosInstance
      .post("/signup", user)
      .then((response) => authSuccessful(response))
      .catch((error) => authFailed(error)),

  login: (user) =>
    plainAxiosInstance
      .post("/signin", user)
      .then((response) => authSuccessful(response))
      .catch((error) => authFailed(error)),

  me: () =>
    securedAxiosInstance
      .get("/me")
      .then((response) => response)
      .catch((error) => authFailed(error)),

  logout: () =>
    securedAxiosInstance.delete("/signin").then(() => {
      delete localStorage.jwt;

      window.location.replace("/");
    }),

  resetPassword: (userEmail) =>
    plainAxiosInstance
      .post("/password_reset", { email: userEmail })
      .catch((error) => {
        console.log(error);
      }),

  changePassword: (passwordPayload) => {
    if (passwordPayload.token) {
      return plainAxiosInstance
        .patch(`/password_reset/${passwordPayload.token}`, passwordPayload)
        .catch((e) => console.log(e));
    }

    return securedAxiosInstance
      .patch("/password_reset", passwordPayload)
      .catch((e) => console.log(e));
  },

  emaillookup: (email) =>
    plainAxiosInstance
      .get(`/email_lookup?email=${email}`)
      .then((response) => response)
      .catch((error) => error.response),

  getGoogleAuthUrl: () =>
    plainAxiosInstance
      .get("/google_auth_url")
      .then((response) => response)
      .catch((error) => console.log(error)),

  authenticateWithGoogle: (userCode) =>
    plainAxiosInstance
      .post("/google_signup", { code: userCode })
      .then((response) => {
        if (response.data.profileCompleted) {
          authSuccessful(response);
        } else {
          return {
            profileNotCompleted: true,
            userId: response.data.userId,
          };
        }
      })
      .catch((error) => error),

  updateProfileWithGoogle: (user) =>
    plainAxiosInstance
      .patch("/update_profile", user)
      .then((response) => authSuccessful(response))
      .catch((error) => authFailed(error)),
};

export default authApi;
