import React from "react";
import PropTypes from "prop-types";
import {
  makeStyles,
  Grid,
  Typography,
  Menu,
  MenuItem,
  IconButton,
} from "@material-ui/core";
import {
  Add,
  TagFaces,
  HowToVote,
  Stars,
  Tonality,
  FormatAlignLeft,
  NearMe,
  LibraryAddCheck,
} from "@material-ui/icons";

const AddNewQuestion = (props) => {
  const { isFirstQuestion, getNewQuestions, activeQuestion, position } = props;

  const [state, setState] = React.useState({
    addQuestionAnchor: null,
  });

  const useStyles = makeStyles((theme) => ({
    root: {
      marginTop: "10px",
      marginBottom: "33px",

      "& button": {
        padding: "0px",

        "& svg": {
          width: "32px",
          height: "32px",
          backgroundColor: theme.palette.primary.main,
          borderRadius: "24px",
          cursor: "pointer",
          transition: "background 250ms",

          "&:hover": {
            backgroundColor: theme.palette.primary.dark,
          },

          "& path": {
            transform: "scale(0.6) translate(8px, 8px)",
            fill: "#fff",
          },
        },
      },

      "& > h6": {
        color: "#000",
        marginLeft: "13px",
      },
    },
    popUp: {
      marginTop: "10px",

      "& .MuiMenu-paper": {
        boxShadow:
          "0px 2px 4px rgba(0, 0, 0, 0.2), 0px 1px 10px rgba(0, 0, 0, 0.12)",
        borderRadius: "5px",
      },

      "& ul": {
        width: "240px",

        "& li": {
          padding: "7px 9px",

          "&:last-child": {
            marginBottom: "7px",
          },

          "& svg": {
            marginRight: "10px",
          },
        },
      },
    },
  }));

  const handleAddQuestion = (event) => {
    if (activeQuestion) activeQuestion(position);
    setState({ ...state, addQuestionAnchor: event.currentTarget });
  };

  const handleAddQuestionClose = () => {
    if (activeQuestion) activeQuestion(-1);
    setState({ ...state, addQuestionAnchor: null });
  };

  const createQuestion = (type) => {
    const newQuestion = {
      type,
      text: "",
    };

    switch (type) {
      case "NPS_Score":
        newQuestion.text =
          "How likely are you to recommend Prove to a friend or colleague?";
        newQuestion.options = {
          lowScoreText: "Not At All Likely",
          highScoreText: "Extremely Likely",
        };
        break;

      case "multipleChoice":
        newQuestion.text =
          "Which Prove feature could not you not live without?";
        newQuestion.options = {
          optionsList: [],
        };
        break;

      case "thankYouNote":
        newQuestion.text = "Thank you so much for your valuable feedback!";
        break;

      case "rating":
        newQuestion.text =
          "Add your question here and then ratings will happen below?";
        newQuestion.options = {
          lowScoreText: "Not So Great",
          highScoreText: "Great",
          starsNumber: 5,
        };
        break;

      case "yesNo":
        newQuestion.text = "The question that person added will show here?";
        break;

      case "openQuestion":
        newQuestion.text = "The question that person added will show here?";
        break;

      case "cta":
        newQuestion.text =
          "This is where you can add a cta for someone to click straight off the modal...";
        newQuestion.options = {
          buttonText: "Click this button",
          linkUrl: "",
        };
        break;

      default:
        break;
    }

    setState({ ...state, addQuestionAnchor: null });
    getNewQuestions([newQuestion], position);
  };

  return (
    <Grid container className={useStyles().root} alignItems="center">
      <IconButton color="primary" onClick={handleAddQuestion}>
        <Add />
      </IconButton>
      <Typography variant="subtitle2">
        {isFirstQuestion === 0
          ? "Add your first feedback question"
          : "Add another question"}
      </Typography>
      <Menu
        open={!!state.addQuestionAnchor}
        onClose={handleAddQuestionClose}
        anchorEl={state.addQuestionAnchor}
        keepMounted
        disableScrollLock
        className={useStyles().popUp}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <MenuItem onClick={() => createQuestion("multipleChoice")}>
          <LibraryAddCheck style={{ color: "#EADA9D" }} />
          Multiple Choice
        </MenuItem>
        <MenuItem onClick={() => createQuestion("rating")}>
          <Stars style={{ color: "#F06191" }} />
          Rating
        </MenuItem>
        <MenuItem onClick={() => createQuestion("yesNo")}>
          <Tonality style={{ color: "#6D9D68" }} />
          Yes/No
        </MenuItem>
        <MenuItem onClick={() => createQuestion("NPS_Score")}>
          <TagFaces style={{ color: "#BE134D" }} />
          NPS Score
        </MenuItem>
        <MenuItem onClick={() => createQuestion("openQuestion")}>
          <FormatAlignLeft style={{ color: "#2196F3" }} />
          Open Question
        </MenuItem>
        <MenuItem onClick={() => createQuestion("cta")}>
          <NearMe style={{ color: "#C77700" }} />
          CTA
        </MenuItem>
        <MenuItem onClick={() => createQuestion("thankYouNote")}>
          <HowToVote style={{ color: "#F1DF38" }} />
          Thank you note
        </MenuItem>
      </Menu>
    </Grid>
  );
};

AddNewQuestion.propTypes = {
  isFirstQuestion: PropTypes.number,
  getNewQuestions: PropTypes.func.isRequired,
  activeQuestion: PropTypes.func,
  position: PropTypes.number,
};

AddNewQuestion.defaultProps = {
  isFirstQuestion: 0,
  activeQuestion: null,
  position: null,
};

export default AddNewQuestion;
