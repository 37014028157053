const paymentPlans = (
  state = {
    config: [
      {
        price: 0,
        responses_number: 0,
      },
    ],
  },
  action
) => {
  switch (action.type) {
    case "PAYMENT_PLANS":
      return {
        ...state,
        config: action.payload.config,
      };
    default:
      return state;
  }
};

export default paymentPlans;
