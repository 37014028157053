import React from "react";
import { Link } from "react-router-dom";
import logoImg from "../assets/images/prove.png";

const Logo = () => {
  return (
    <Link to="/">
      <img src={logoImg} width="120px" height="36px" alt="Prove logo" />
    </Link>
  );
};

export default Logo;
