import React from "react";
import { Backdrop, CircularProgress } from "@material-ui/core";
import { loadStripe } from "@stripe/stripe-js";
import { useLocation } from "react-router-dom";

import billingApi from "../api/billing-api";

const Enterprise = () => {
  const location = useLocation();

  billingApi.fetchBillingConfig().then((response) => {
    loadStripe(response.data.stripe_publishable_key).then((stripe) => {
      stripe
        .redirectToCheckout({
          sessionId: location.search.split("?session_id=")[1],
        })
        .then(() => {
          console.log("successful payment");
        })
        .catch(() => {
          console.log("error while processing");
        });
    });
  });

  return (
    <Backdrop open style={{ backgroundColor: "#fff" }}>
      <CircularProgress color="primary" />
    </Backdrop>
  );
};

export default Enterprise;
