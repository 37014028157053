import React from "react";
import {
  makeStyles,
  Grid,
  Button,
  Typography,
  TextField,
  MenuItem,
  IconButton,
  Menu as MaterialMenu,
  Backdrop,
  CircularProgress,
  Dialog,
  ClickAwayListener,
} from "@material-ui/core";
import {
  MoreVert,
  SentimentVerySatisfied,
  Drafts,
  VisibilityOff,
  Pause,
  Archive,
} from "@material-ui/icons";
import { Link, useLocation, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import campaignsApi from "../api/campaigns-api";
import Menu from "../components/Menu";
import Header from "../components/Header";
import AlertMessage from "../components/AlertMessage";

const Campaigns = () => {
  const [state, setState] = React.useState({
    campaignList: [],
    filter: "allCampaigns",
    campaignsEdited: false,
    backdrop: true,
    archiveConfirmModal: false,
  });

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const userId = useSelector((store) => store.user.id);
  const userRole = useSelector((store) => store.user.role);
  const company = useSelector((store) => store.company);

  React.useEffect(() => {
    campaignsApi.listCampaigns(userId).then((response) => {
      setState((prev) => ({
        ...prev,
        campaignList: response.data,
        campaignsEdited: location.campaignsEdited,
        backdrop: false,
      }));
    });
  }, []); // eslint-disable-line

  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: "#F9F9F9",
      minHeight: "100vh",
      width: "calc(100% - 120px)",

      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },

      "& .Campaigns": {
        margin: "10px 52px 50px 49px",

        [theme.breakpoints.down("sm")]: {
          margin: "10px 20px 50px",
        },

        "&__header": {
          justifyContent: "space-between",

          [theme.breakpoints.down("xs")]: {
            flexDirection: "column",
          },

          "&__intro": {
            display: "flex",
            alignItems: "center",

            [theme.breakpoints.down("xs")]: {
              marginBottom: "20px",
            },

            "& > .MuiFormControl-root": {
              marginLeft: "26px",

              "& .MuiSelect-select": {
                padding: "10px 15px 10px 12px",
                width: "170px",
                boxSizing: "border-box",
              },

              "& .MuiOutlinedInput-root:hover fieldset": {
                borderColor: "#96BD92",
              },
            },
          },

          "& > a button, > .MuiGrid-root:nth-child(2) button": {
            backgroundColor: theme.palette.primary.main,
            display: "inline-flex",
            color: "#fff",
            boxShadow: "none",
            padding: "3px 16px",
            fontSize: "16px",
            letterSpacing: "0.3px",
            lineHeight: "34px",
            textDecoration: "none",
            fontWeight: "normal",
            transition:
              "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",

            "&:hover": {
              boxShadow: "none",
              backgroundColor: "#6D9D68",
            },
          },
        },

        "&__alert": {
          marginTop: "20px",
          background: "rgba(150, 189, 146, 0.1)",
          borderRadius: "4px",
          alignItems: "center",
          justifyContent: "center",
          padding: "11px 30px",

          [theme.breakpoints.down(1150)]: {
            justifyContent: "space-around",
          },

          [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
            alignItems: "flex-start",
            padding: "18px 30px",
          },

          "& > svg": {
            background:
              "linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), #2A5F24",
            borderRadius: "50%",
            padding: "9px",
            width: "35px",
            height: "35px",
            marginRight: "17px",

            [theme.breakpoints.down("sm")]: {
              marginBottom: "10px",
            },
          },

          "& > .MuiGrid-root": {
            color: "#6A8F66",
            marginRight: "25px",

            [theme.breakpoints.down(1150)]: {
              maxWidth: "400px",
            },

            [theme.breakpoints.down("sm")]: {
              marginBottom: "10px",
              maxWidth: "100%",
            },
          },

          "& > button": {
            fontSize: "16px",
            lineHeight: "25px",
            width: "141px",
            height: "37px",
          },
        },

        "&__list": {
          marginTop: "7px",
          display: "flex",
          width: "calc(100% + 30px)",

          [theme.breakpoints.down("sm")]: {
            width: "100%",
          },

          [theme.breakpoints.down("xs")]: {
            width: "242px",
            margin: "7px auto",
          },

          "&:after": {
            height: "0",
            width: "auto",
            content: "''",
          },

          "&__item": {
            width: "242px",
            minHeight: "173px",
            marginRight: "30px",
            marginTop: "30px",
            pointerEvents:
              userRole === "viewer" && !company.snippetInstalled
                ? "none"
                : "unset",

            "& > a": {
              height: "calc(100% - 46px)",
              display: "block",
            },

            [theme.breakpoints.down("xs")]: {
              marginRight: "0",
            },

            "&__header": {
              backgroundColor: "rgba(150, 189, 146, 0.09)",
              padding: "5px 8px 5px 21px",
              height: "46px",

              "& > button": {
                color: "rgb(189, 188, 182)",
                padding: "6px",
              },
            },

            "&__main": {
              textAlign: "center",
              background: "#fff",
              paddingBottom: "15px",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",

              "&:hover": {
                "& > svg": {
                  transform: "scale(1.2)",
                },
              },

              "& > svg": {
                background: "#4CAF50",
                fill: "#fff",
                borderRadius: "4px",
                width: "28px",
                height: "28px",
                padding: "2px",
                marginTop: "-14px",
                transition: "transform 0.225s",
              },

              "&__count": {
                fontWeight: "600",
                fontSize: "8px",
                lineHeight: "11px",
                letterSpacing: "1px",
                color: "#4CAF50",
                marginTop: "6px",
              },

              "&__name": {
                fontSize: "14px",
                lineHeight: "17px",
                letterSpacing: "0.15px",
                maxWidth: "160px",
                display: "block",
                margin: "14px auto 22px",
                color: "rgba(0, 0, 0, 0.87)",
              },

              "&__time": {
                fontSize: "10px",
                lineHeight: "11px",
                color: "rgba(0, 0, 0, 0.38)",
                marginTop: "auto",
                textTransform: "capitalize",
              },
            },

            "&_live": {
              filter: company.snippetInstalled ? "none" : "blur(1.5px)",
            },

            "&_draft": {
              pointerEvents: userRole === "viewer" ? "none" : "unset",

              "& .Campaigns__list__item": {
                "&__header": {
                  backgroundColor: "rgb(231,231,231)",

                  "& > svg": {
                    fill: "rgba(136, 136, 136, 0.54)",
                    width: "20px",
                  },
                },

                "&__main": {
                  "& > svg": {
                    backgroundColor: "#757575",
                    fill: "#fff",
                    padding: "4px",
                  },
                  "&__count": {
                    color: "#757575",
                  },
                },
              },
            },

            "&_paused": {
              "& .Campaigns__list__item": {
                "&__header": {
                  backgroundColor: "rgb(251, 248, 235)",

                  "& > svg": {
                    fill: "#EADA9D",
                    width: "20px",
                  },
                },

                "&__main": {
                  "& > svg": {
                    backgroundColor: "#FF9800",
                    fill: "#fff",
                    padding: "2px",
                  },
                  "&__count": {
                    color: "#FF9800",
                  },
                },
              },
            },

            "&_archived": {
              "& .Campaigns__list__item": {
                "&__header": {
                  backgroundColor: "rgb(231,231,250)",

                  "& > svg": {
                    fill: "rgba(117,117,154,0.53)",
                    width: "20px",
                  },
                },

                "&__main": {
                  "& > svg": {
                    backgroundColor: "#757593",
                    fill: "#fff",
                    padding: "4px",
                  },
                  "&__count": {
                    color: "#75759A",
                  },
                },
              },
            },

            "&__blur": {
              position: "absolute",
              width: "100%",
              height: "100%",
              zIndex: "1",
            },
          },
        },
      },

      "& .MuiSnackbar-anchorOriginBottomCenter": {
        zIndex: "100",
      },
    },
    campaignAction: {
      marginTop: "-11px",

      "& .MuiPopover-paper": {
        width: "160px",
        boxShadow:
          "0px 2px 4px rgba(0, 0, 0, 0.2), 0px 1px 10px rgba(0, 0, 0, 0.12)",
        borderRadius: "5px",
        marginLeft: "13px",
      },
    },
    archiveConfirmModal: {
      "& .MuiDialog-paper": {
        padding: "16px 24px 14px",

        "& > p": {
          "&:first-child": {
            fontWeight: "500",
            fontSize: "20px",
            lineHeight: "32px",
            letterSpacing: "0.15px",
            color: "rgba(0, 0, 0, 0.87)",
            marginBottom: "24px",
          },

          "&:nth-child(2)": {
            letterSpacing: "0.15px",
            color: "rgba(136, 136, 136, 0.54)",
            marginBottom: "22px",
          },
        },

        "& > .MuiGrid-root": {
          display: "flex",
          justifyContent: "flex-end",

          "& button": {
            fontWeight: "600",
            fontSize: "16px",
            lineHeight: "25px",
            color: "#96BD92",

            "&:first-child": {
              marginRight: "15px",
            },
          },
        },
      },
    },
  }));

  const handleStatus = (campaign, status) => {
    setState({
      ...state,
      [`${campaign.id}anchorCampaignAction`]: null,
      backdrop: true,
      archiveConfirmModal: false,
    });
    campaignsApi
      .updateCampaign({
        ...campaign,
        campaignId: campaign.id,
        status,
      })
      .then((response) => {
        const campaignList = [...state.campaignList].map((campaignItem) => {
          if (campaignItem.id === response.data.id) return response.data;
          return campaignItem;
        });

        dispatch({
          type: "COMPANY",
          payload: {
            ...company,
            campaigns:
              company.campaigns +
              (campaign.status === "paused" && status === "live"
                ? 1
                : campaign.status === "live" &&
                  (status === "paused" || status === "archived")
                ? -1
                : 0),
          },
        });

        setState({
          ...state,
          campaignList,
          backdrop: false,
          [`${campaign.id}anchorCampaignAction`]: null,
          archiveConfirmModal: false,
        });
      });
  };

  const CampaignAction = (campaignObject) => {
    const { campaign } = campaignObject;

    return (
      <MaterialMenu
        open={!!state[`${campaign.id}anchorCampaignAction`]}
        onClose={() =>
          setState({ ...state, [`${campaign.id}anchorCampaignAction`]: null })}
        anchorEl={state[`${campaign.id}anchorCampaignAction`]}
        keepMounted
        className={useStyles().campaignAction}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        disableScrollLock
      >
        {campaign.status === "live" && (
          <MenuItem onClick={() => handleStatus(campaign, "paused")}>
            Pause
          </MenuItem>
        )}
        {campaign.status === "paused" && (
          <MenuItem onClick={() => handleStatus(campaign, "live")}>
            Unpause
          </MenuItem>
        )}
        {(campaign.status === "live" || campaign.status === "paused") && (
          <MenuItem
            onClick={() => history.push(`/campaigns/${campaign.id}/edit`)}
          >
            Edit
          </MenuItem>
        )}
        {(campaign.status === "live" || campaign.status === "paused") && (
          <MenuItem
            onClick={() =>
              setState({
                ...state,
                archiveConfirmModal: campaign,
                [`${campaign.id}anchorCampaignAction`]: null,
              })}
          >
            Archive
          </MenuItem>
        )}
      </MaterialMenu>
    );
  };

  return (
    <Grid container>
      <Menu activePage="Campaigns" />
      <Grid className={useStyles().root}>
        <Header />
        <Grid className="Campaigns">
          <Grid container className="Campaigns__header">
            <Grid className="Campaigns__header__intro">
              <Typography variant="h6">Campaigns</Typography>
              <TextField
                select
                variant="outlined"
                value={state.filter}
                onChange={(event) =>
                  setState({ ...state, filter: event.target.value })}
                SelectProps={{
                  MenuProps: {
                    disableScrollLock: true,
                  },
                }}
              >
                <MenuItem value="allCampaigns">All Campaigns</MenuItem>
                <MenuItem value="live">Live</MenuItem>
                <MenuItem value="paused">Paused</MenuItem>
                <MenuItem value="draft">Draft</MenuItem>
                <MenuItem value="archived">Archived</MenuItem>
              </TextField>
            </Grid>
            {userRole !== "viewer" && (
              <Link to="/campaigns/new">
                <Button variant="contained" color="primary">
                  Create Campaign
                </Button>
              </Link>
            )}
          </Grid>
          {!company.snippetInstalled &&
            state.campaignList.some(
              (survey) => survey.status === "live" && userRole !== "viewer"
            ) && (
              <Grid container className="Campaigns__alert">
                <svg
                  width="20"
                  height="15"
                  viewBox="0 0 20 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.1563 0.386547C16.8076 0.0378178 16.2537 0.0378178 15.905 0.386547C15.5563 0.735276 15.5563 1.28914 15.905 1.63787C19.023 4.75592 19.023 9.82274 15.905 12.9408C15.5563 13.2895 15.5563 13.8434 15.905 14.1921C16.0691 14.3562 16.2947 14.4588 16.5204 14.4588C16.746 14.4588 16.9717 14.3767 17.1358 14.1921C20.9513 10.3766 20.9513 4.18154 17.1563 0.386547Z"
                    fill="white"
                  />
                  <path
                    d="M14.2229 3.29946C13.8742 2.95074 13.3203 2.95074 12.9716 3.29946C12.6228 3.64819 12.6228 4.20206 12.9716 4.55079C14.4691 6.04827 14.4896 8.50988 12.9716 10.0074C12.6228 10.3561 12.6228 10.91 12.9716 11.2587C13.1357 11.4228 13.3613 11.5254 13.587 11.5254C13.8126 11.5254 14.0383 11.4433 14.2024 11.2587C16.4383 9.06375 16.4178 5.49441 14.2229 3.29946Z"
                    fill="white"
                  />
                  <path
                    d="M4.62259 1.63787C4.97132 1.28914 4.97132 0.735276 4.62259 0.386547C4.27386 0.0378178 3.72 0.0378178 3.37127 0.386547C-0.423723 4.18154 -0.423723 10.3766 3.37127 14.1716C3.53538 14.3357 3.76102 14.4383 3.98667 14.4383C4.21232 14.4383 4.43797 14.3562 4.60208 14.1716C4.9508 13.8229 4.9508 13.269 4.60208 12.9203C1.50454 9.82274 1.50454 4.75591 4.62259 1.63787Z"
                    fill="white"
                  />
                  <path
                    d="M7.53553 4.55079C7.88426 4.20206 7.88426 3.64819 7.53553 3.29946C7.1868 2.95074 6.63294 2.95074 6.28421 3.29946C4.08927 5.49441 4.08927 9.06375 6.28421 11.2587C6.44831 11.4228 6.67396 11.5254 6.89961 11.5254C7.12526 11.5254 7.35091 11.4433 7.51501 11.2587C7.86374 10.91 7.86374 10.3561 7.51501 10.0074C6.03804 8.50988 6.03804 6.04827 7.53553 4.55079Z"
                    fill="white"
                  />
                  <path
                    d="M10.2638 8.77655C11.0908 8.77655 11.7613 8.10611 11.7613 7.27907C11.7613 6.45203 11.0908 5.78159 10.2638 5.78159C9.43674 5.78159 8.7663 6.45203 8.7663 7.27907C8.7663 8.10611 9.43674 8.77655 10.2638 8.77655Z"
                    fill="white"
                  />
                </svg>
                <Grid>
                  <Typography variant="h6">
                    Your campaigns are almost ready!
                  </Typography>
                  <Typography variant="body2">
                    In order to go live, you or someone on your team will need
                    to install the Prove tracking snippet
                  </Typography>
                </Grid>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => history.push("install")}
                >
                  Install Snippet
                </Button>
              </Grid>
            )}
          <Grid container className="Campaigns__list">
            {state.campaignList &&
              state.campaignList.map(
                (campaign) =>
                  (campaign.status === state.filter ||
                    state.filter === "allCampaigns") && (
                    <Grid
                      key={campaign.id}
                      className={`Campaigns__list__item Campaigns__list__item_${campaign.status}`}
                    >
                      {campaign.status === "live" &&
                        !company.snippetInstalled &&
                        userRole !== "viewer" && (
                          <Link
                            to="/install"
                            className="Campaigns__list__item__blur"
                          />
                        )}
                      <Grid
                        container
                        justify="space-between"
                        alignItems="center"
                        className="Campaigns__list__item__header"
                      >
                        {campaign.status === "live" && (
                          <svg
                            width="20"
                            height="15"
                            viewBox="0 0 20 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M17.1563 0.386547C16.8076 0.0378178 16.2537 0.0378178 15.905 0.386547C15.5563 0.735276 15.5563 1.28914 15.905 1.63787C19.023 4.75592 19.023 9.82274 15.905 12.9408C15.5563 13.2895 15.5563 13.8434 15.905 14.1921C16.0691 14.3562 16.2947 14.4588 16.5204 14.4588C16.746 14.4588 16.9717 14.3767 17.1358 14.1921C20.9513 10.3766 20.9513 4.18154 17.1563 0.386547Z"
                              fill="#2A5F24"
                            />
                            <path
                              d="M17.1563 0.386547C16.8076 0.0378178 16.2537 0.0378178 15.905 0.386547C15.5563 0.735276 15.5563 1.28914 15.905 1.63787C19.023 4.75592 19.023 9.82274 15.905 12.9408C15.5563 13.2895 15.5563 13.8434 15.905 14.1921C16.0691 14.3562 16.2947 14.4588 16.5204 14.4588C16.746 14.4588 16.9717 14.3767 17.1358 14.1921C20.9513 10.3766 20.9513 4.18154 17.1563 0.386547Z"
                              fill="white"
                              fillOpacity="0.3"
                            />
                            <path
                              d="M14.2229 3.29946C13.8742 2.95074 13.3203 2.95074 12.9716 3.29946C12.6228 3.64819 12.6228 4.20206 12.9716 4.55079C14.4691 6.04827 14.4896 8.50988 12.9716 10.0074C12.6228 10.3561 12.6228 10.91 12.9716 11.2587C13.1357 11.4228 13.3613 11.5254 13.587 11.5254C13.8126 11.5254 14.0383 11.4433 14.2024 11.2587C16.4383 9.06375 16.4178 5.49441 14.2229 3.29946Z"
                              fill="#2A5F24"
                            />
                            <path
                              d="M14.2229 3.29946C13.8742 2.95074 13.3203 2.95074 12.9716 3.29946C12.6228 3.64819 12.6228 4.20206 12.9716 4.55079C14.4691 6.04827 14.4896 8.50988 12.9716 10.0074C12.6228 10.3561 12.6228 10.91 12.9716 11.2587C13.1357 11.4228 13.3613 11.5254 13.587 11.5254C13.8126 11.5254 14.0383 11.4433 14.2024 11.2587C16.4383 9.06375 16.4178 5.49441 14.2229 3.29946Z"
                              fill="white"
                              fillOpacity="0.3"
                            />
                            <path
                              d="M4.62259 1.63787C4.97132 1.28914 4.97132 0.735276 4.62259 0.386547C4.27386 0.0378178 3.72 0.0378178 3.37127 0.386547C-0.423723 4.18154 -0.423723 10.3766 3.37127 14.1716C3.53538 14.3357 3.76102 14.4383 3.98667 14.4383C4.21232 14.4383 4.43797 14.3562 4.60208 14.1716C4.9508 13.8229 4.9508 13.269 4.60208 12.9203C1.50454 9.82274 1.50454 4.75591 4.62259 1.63787Z"
                              fill="#2A5F24"
                            />
                            <path
                              d="M4.62259 1.63787C4.97132 1.28914 4.97132 0.735276 4.62259 0.386547C4.27386 0.0378178 3.72 0.0378178 3.37127 0.386547C-0.423723 4.18154 -0.423723 10.3766 3.37127 14.1716C3.53538 14.3357 3.76102 14.4383 3.98667 14.4383C4.21232 14.4383 4.43797 14.3562 4.60208 14.1716C4.9508 13.8229 4.9508 13.269 4.60208 12.9203C1.50454 9.82274 1.50454 4.75591 4.62259 1.63787Z"
                              fill="white"
                              fillOpacity="0.3"
                            />
                            <path
                              d="M7.5355 4.55079C7.88423 4.20206 7.88423 3.64819 7.5355 3.29946C7.18677 2.95074 6.6329 2.95074 6.28418 3.29946C4.08923 5.49441 4.08923 9.06375 6.28418 11.2587C6.44828 11.4228 6.67393 11.5254 6.89958 11.5254C7.12523 11.5254 7.35088 11.4433 7.51498 11.2587C7.86371 10.91 7.86371 10.3561 7.51498 10.0074C6.03801 8.50988 6.03801 6.04827 7.5355 4.55079Z"
                              fill="#2A5F24"
                            />
                            <path
                              d="M7.5355 4.55079C7.88423 4.20206 7.88423 3.64819 7.5355 3.29946C7.18677 2.95074 6.6329 2.95074 6.28418 3.29946C4.08923 5.49441 4.08923 9.06375 6.28418 11.2587C6.44828 11.4228 6.67393 11.5254 6.89958 11.5254C7.12523 11.5254 7.35088 11.4433 7.51498 11.2587C7.86371 10.91 7.86371 10.3561 7.51498 10.0074C6.03801 8.50988 6.03801 6.04827 7.5355 4.55079Z"
                              fill="white"
                              fillOpacity="0.3"
                            />
                            <path
                              d="M10.2638 8.77655C11.0908 8.77655 11.7613 8.10611 11.7613 7.27907C11.7613 6.45203 11.0908 5.78159 10.2638 5.78159C9.43674 5.78159 8.7663 6.45203 8.7663 7.27907C8.7663 8.10611 9.43674 8.77655 10.2638 8.77655Z"
                              fill="#2A5F24"
                            />
                            <path
                              d="M10.2638 8.77655C11.0908 8.77655 11.7613 8.10611 11.7613 7.27907C11.7613 6.45203 11.0908 5.78159 10.2638 5.78159C9.43674 5.78159 8.7663 6.45203 8.7663 7.27907C8.7663 8.10611 9.43674 8.77655 10.2638 8.77655Z"
                              fill="white"
                              fillOpacity="0.3"
                            />
                          </svg>
                        )}
                        {campaign.status === "paused" && <Pause />}
                        {campaign.status === "draft" && <Drafts />}
                        {campaign.status === "archived" && <Archive />}
                        {(campaign.status === "live" ||
                          campaign.status === "paused") &&
                          userRole !== "viewer" && (
                            <IconButton
                              onClick={(event) =>
                                setState({
                                  ...state,
                                  [`${campaign.id}anchorCampaignAction`]: event.currentTarget,
                                })}
                            >
                              <MoreVert />
                            </IconButton>
                          )}
                        {!!state[`${campaign.id}anchorCampaignAction`] && (
                          <CampaignAction campaign={campaign} />
                        )}
                      </Grid>
                      <Link
                        to={`/campaigns/${campaign.id}${
                          campaign.status === "draft" ? "/edit" : ""
                        }`}
                      >
                        <Grid className="Campaigns__list__item__main">
                          {campaign.status === "draft" ||
                          campaign.status === "archived" ? (
                            <VisibilityOff />
                          ) : (
                            <SentimentVerySatisfied />
                          )}
                          <Typography className="Campaigns__list__item__main__count">
                            {`${
                              campaign.finished_count
                                ? campaign.finished_count
                                : "NO"
                            } RESPONSES`}
                          </Typography>
                          <Typography className="Campaigns__list__item__main__name">
                            {campaign.name}
                          </Typography>
                          <Typography className="Campaigns__list__item__main__time">
                            {campaign.date}
                          </Typography>
                        </Grid>
                      </Link>
                    </Grid>
                  )
              )}
          </Grid>
        </Grid>
        {state.campaignsEdited && (
          <ClickAwayListener
            onClickAway={() =>
              setState({
                ...state,
                campaignsEdited: false,
              })}
          >
            <Grid>
              <AlertMessage type="success" message="Campaign was updated" />
            </Grid>
          </ClickAwayListener>
        )}
        <Dialog
          onClose={() => setState({ ...state, archiveConfirmModal: false })}
          open={!!state.archiveConfirmModal}
          className={useStyles().archiveConfirmModal}
        >
          <Typography>Archive campaign</Typography>
          <Typography variant="body1">
            After archiving campaign, you will not be able to start it again.
            Are you sure you want to do this?
          </Typography>
          <Grid>
            <Button
              color="primary"
              onClick={() => {
                setState({ ...state, archiveConfirmModal: false });
              }}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              onClick={() => {
                handleStatus(state.archiveConfirmModal, "archived");
              }}
            >
              Confirm
            </Button>
          </Grid>
        </Dialog>
      </Grid>
      <Backdrop
        open={state.backdrop}
        style={{
          zIndex: 5,
        }}
      >
        <CircularProgress color="primary" />
      </Backdrop>
    </Grid>
  );
};

export default Campaigns;
