import React from "react";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

import Routers from "./Router";
import "./assets/fonts/fonts.css";

function App() {
  const theme = createMuiTheme({
    overrides: {
      MuiCssBaseline: {
        "@global": {
          body: {
            backgroundColor: "#fff",
            overflowY: "auto",

            "&::-webkit-scrollbar-track": {
              webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
              backgroundColor: "transparent",
            },
            "&::-webkit-scrollbar": {
              width: "4px",
              height: "4px",
              backgroundColor: "transparent",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#96BD92",
              border: "2px solid #96BD92",
            },

            "& .MuiPaper-elevation8": {
              boxShadow:
                "0px 2px 4px rgba(0, 0, 0, 0.2), 0px 1px 10px rgba(0, 0, 0, 0.12)",
            },

            "& .MuiTooltip-tooltip": {
              "& a": {
                color: "#96BD92",
                margin: "0 3px",
                transition: "color 0.2s",

                "&:hover": {
                  color: "#6D9D68",
                },
              },
            },
          },
          a: {
            textDecoration: "none",
          },
        },
      },
      MuiButton: {
        root: {
          textTransform: "capitalize",
        },
      },
    },
    typography: {
      fontFamily: "Avenir Next",
    },
    palette: {
      primary: {
        main: "#96BD92",
        dark: "#6D9D68",
      },
    },
  });

  window.onunhandledrejection = () => {
    if (localStorage.getItem("jwt")) {
      window.location.replace("/404");
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routers />
    </ThemeProvider>
  );
}

export default App;
