import React from "react";
import {
  makeStyles,
  Typography,
  Grid,
  Menu,
  MenuItem,
  Button,
  Backdrop,
  CircularProgress,
  Divider,
  LinearProgress,
  Tooltip,
} from "@material-ui/core";
import {
  Settings,
  Group,
  Help,
  ExitToApp,
  ErrorOutline,
} from "@material-ui/icons";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import authApi from "../api/auth-api";
import ChoosePaymentPlan from "./ChoosePaymentPlan";

const Header = () => {
  const company = useSelector((store) => store.company);
  const userName = useSelector((store) => store.user.name);

  const [state, setState] = React.useState({
    menuAnchorEl: null,
    backdrop: false,
  });

  const useStyles = makeStyles((theme) => ({
    header: {
      padding: "20px 0 16px",
      position: "fixed",
      width: "calc(100% - 120px)",
      background: "rgba(249, 249, 249, 0.9)",
      zIndex: "2",
      top: "0",
      right: "0",

      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },

      [theme.breakpoints.down("xs")]: {
        paddingLeft: "60px",
        marginLeft: "0",
      },

      "& > .MuiBackdrop-root": {
        zIndex: 9,
      },
    },
    // search: {
    //   marginRight: "20px",

    //   [theme.breakpoints.down("xs")]: {
    //     flex: "1",
    //   },

    //   "& .MuiInput-underline:before": {
    //     display: "none",
    //   },

    //   "& svg": {
    //     fill: "rgba(136, 136, 136, 0.54)",
    //   },

    //   "& .Mui-focused svg": {
    //     fill: theme.palette.primary.main,
    //   },

    //   "& input:placeholder": {
    //     color: "rgba(0, 0, 0, 0.38)",
    //   },
    // },
    // notification: {
    //   fill: "rgba(136, 136, 136, 0.54)",
    //   marginRight: "25px",
    // },
    responseProgress: {
      display: "flex",
      alignItems: "center",
      marginLeft: "50px",
      marginRight: "auto",

      [theme.breakpoints.down("sm")]: {
        marginLeft: "60px",
      },

      [theme.breakpoints.down("xs")]: {
        display: "none",
      },

      "& > .MuiLinearProgress-root": {
        width: "79px",
        borderRadius: "2px",
        background: "rgba(0, 0, 0, 0.04)",
        marginRight: "11px",
        marginTop: "1px",

        "& > .MuiLinearProgress-bar": {
          backgroundColor:
            (company.usedResponses * 100) / company.plan.responses_number < 59
              ? theme.palette.primary.main
              : (company.usedResponses * 100) / company.plan.responses_number > 95
              ? "#BE134D"
              : "#D4C27F",
        },
      },

      "& > p": {
        fontSize: "12px",
        lineHeight: "16px",
        letterSpacing: "0.15px",
        fontWeight: "500",

        "&:nth-child(2)": {
          fontWeight: "600",
          marginRight: "4px",
        },

        "&:nth-child(3)": {
          color: "#BCBCBC",
          marginRight: "5px",
          fontWeight: "600",
          textTransform: "capitalize",
        },
      },

      "& > svg": {
        width: "17px",
        fill: "rgba(0, 0, 0, 0.38)",
        marginLeft: "5px",
        marginRight: "1px",
      },

      "& > .MuiGrid-root:last-child > button": {
        fontWeight: "500",
      },
    },
    avatar: {
      width: "30px",
      height: "30px",
      lineHeight: "30px",
      marginRight: "15px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textTransform: "capitalize",
      color: "#fff",
      backgroundColor: theme.palette.primary.main,
      borderRadius: "50%",

      [theme.breakpoints.down("xs")]: {
        marginRight: "10px",
      },
    },
    popUp: {
      marginRight: "51px",
      display: "flex",
      alignItems: "center",

      [theme.breakpoints.down("sm")]: {
        marginRight: "20px",
      },

      "& > button": {
        minWidth: "unset",

        "&:hover": {
          backgroundColor: "transparent",
        },
      },

      "& h6": {
        marginRight: "10px",
        textTransform: "none",

        [theme.breakpoints.down("xs")]: {
          display: "none",
        },
      },
    },
    menu: {
      "& .MuiPaper-root": {
        minWidth: "240px",
        marginTop: "6px",

        [theme.breakpoints.down("xs")]: {
          marginTop: "12px",
        },

        "& ul": {
          padding: "0",

          "& > .MuiGrid-root": {
            display: "flex",
            padding: "16px 20px 16px",
            backgroundColor: "rgba(0, 0, 0, 0.04)",
            outline: "none",

            "& > .MuiGrid-root:first-child": {
              marginTop: "4px",
              width: "40px",
              height: "40px",
              marginRight: "0",
            },

            "& > .MuiGrid-root:last-child": {
              marginLeft: "9px",

              "& > p": {
                "&:first-child": {
                  fontWeight: "500",
                  textTransform: "none",
                },

                "&:nth-child(2)": {
                  letterSpacing: "0.15px",
                  color: "rgba(0, 0, 0, 0.38)",
                  marginBottom: "3px",
                  textTransform: "none",
                },
              },

              "& > a": {
                color: theme.palette.primary.main,
                fontWeight: "600",
                fontSize: "12px",
                lineHeight: "16px",
                letterSpacing: "0.15px",
                textDecorationLine: "underline",
                transition: "color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",

                "&:hover": {
                  color: "#6D9D68",
                },
              },
            },
          },

          "& li": {
            padding: "0",

            "&:nth-child(2)": {
              marginTop: "12px",
            },

            "&:nth-child(3)": {
              marginBottom: "9px",
            },

            "&:nth-child(5)": {
              marginTop: "12px",
              marginBottom: "6px",
            },

            "&:nth-child(7)": {
              marginTop: "10px",
              marginBottom: "14px",
              padding: "6px 20px",
              fontSize: "14px",
              lineHeight: "20px",
              letterSpacing: "0.15px",
            },

            "&:nth-child(8)": {
              padding: "12px 20px 18px",

              "&:hover": {
                backgroundColor: "inherit",
                cursor: "initial",
              },

              "& > a": {
                fontSize: "12px",
                lineHeight: "16px",
                letterSpacing: "0.4px",
                color: "rgba(0, 0, 0, 0.38)",
                padding: "0",
                transition: "color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",

                "&:first-child": {
                  marginRight: "25px",
                },

                "&:hover": {
                  color: "#757575",
                },
              },
            },

            "& > a": {
              fontSize: "14px",
              lineHeight: "20px",
              letterSpacing: "0.15px",
              color: "rgba(0, 0, 0, 0.87)",
              display: "flex",
              alignItems: "center",
              width: "100%",
              padding: "6px 20px",
            },

            "& svg": {
              width: "17px",
              marginRight: "14px",
              fill: "rgba(136, 136, 136, 0.54)",
            },
          },

          "& > hr": {
            margin: "0 20px",
          },
        },
      },
    },
  }));

  const handleMenuOpen = (event) => {
    setState({ ...state, menuAnchorEl: event.currentTarget });
  };

  const handleMenuClose = () => {
    setState({ ...state, menuAnchorEl: null });
  };

  return (
    <header style={{ height: "69px" }}>
      <Grid
        container
        alignItems="center"
        justify="flex-end"
        className={useStyles().header}
      >
        <Grid className={useStyles().responseProgress}>
          <LinearProgress
            variant="determinate"
            value={
              ((company.usedResponses > company.plan.responses_number
                ? company.plan.responses_number
                : company.usedResponses) *
                100) /
              company.plan.responses_number
            }
          />
          <Typography>{company.usedResponses}</Typography>
          <Typography>{`/ ${company.plan.responses_number}`}</Typography>
          <Typography>Responses used.</Typography>
          <Tooltip
            interactive
            title="Responses used is the total number of campaign responses allotted for your instance. If your instance of Prove exceeds the amount of responses used the data will be saved. At anytime you can upgrade to increase campaign responses and access those responses."
            arrow
            enterTouchDelay={0}
          >
            <ErrorOutline />
          </Tooltip>
          <ChoosePaymentPlan btnText="Upgrade Now" />
        </Grid>
        {/* TODO: Search, Notification */}
        {/* <TextField
          placeholder="Search..."
          className={useStyles().search}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        /> */}
        {/* <NotificationImportant className={useStyles().notification} /> */}
        <Link to="/settings/manage-profile">
          <Grid className={useStyles().avatar}>
            {company.name.trim().charAt(0)}
          </Grid>
        </Link>
        <Grid className={useStyles().popUp}>
          <Button onClick={handleMenuOpen} disableRipple>
            <Typography variant="subtitle2">{company.name}</Typography>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="5"
              viewBox="0 0 10 5"
              fill="none"
            >
              <path d="M0 0L5 5L10 0H0Z" fill="#888888" fillOpacity="0.54" />
            </svg>
          </Button>
          <Menu
            anchorEl={state.menuAnchorEl}
            keepMounted
            open={!!state.menuAnchorEl}
            onClose={handleMenuClose}
            disableScrollLock
            className={useStyles().menu}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            transformOrigin={{ vertical: "top", horizontal: "left" }}
          >
            <Grid>
              <Grid className={useStyles().avatar}>
                {company.name.trim().charAt(0)}
              </Grid>
              <Grid>
                <Typography>{company.name}</Typography>
                <Typography variant="body2">{userName}</Typography>
                <Link to="/settings/manage-profile" onClick={handleMenuClose}>
                  view profile
                </Link>
              </Grid>
            </Grid>
            <MenuItem>
              <Link to="/settings/manage-profile" onClick={handleMenuClose}>
                <Settings />
                Settings
              </Link>
            </MenuItem>
            <MenuItem>
              <Link to="/settings/manage-users" onClick={handleMenuClose}>
                <Group />
                Manage Team
              </Link>
            </MenuItem>
            <Divider />
            <MenuItem>
              <a href="mailto:support@tryprove.com" onClick={handleMenuClose}>
                <Help />
                Help & Feedback
              </a>
            </MenuItem>
            <Divider />
            <MenuItem
              onClick={() => {
                setState({ ...state, menuAnchorEl: null, backdrop: true });
                authApi.logout();
              }}
            >
              <ExitToApp />
              Log Out
            </MenuItem>
            <MenuItem disableRipple>
              <a
                href="http://tryprove.com/terms-and-conditions"
                target="_blank"
                rel="noopener noreferrer"
                onClick={handleMenuClose}
              >
                Terms of Service
              </a>
              <a
                href="http://tryprove.com/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
                onClick={handleMenuClose}
              >
                Privacy Policy
              </a>
            </MenuItem>
          </Menu>
        </Grid>
        <Backdrop open={state.backdrop}>
          <CircularProgress color="primary" />
        </Backdrop>
      </Grid>
    </header>
  );
};

export default Header;
