import React from "react";
import PropTypes from "prop-types";
import {
  makeStyles,
  Grid,
  TextField,
  List,
  ListItem,
  InputAdornment,
  Typography,
  FormControl,
  MenuItem,
} from "@material-ui/core";
import { FiberManualRecord } from "@material-ui/icons/";

const EditQuestion = (props) => {
  const {
    question,
    questionChange,
    activeQuestionEdit,
    handleClickAway,
    editMode,
    hideAdditional,
  } = props;

  const [updatedQuestion, setUpdatedQuestion] = React.useState({
    position: question.position,
    type: question.type,
    text: question.text,
    options: {
      optionsList: question.optionsList,
      lowScoreText: question.lowScoreText,
      highScoreText: question.highScoreText,
      buttonText: question.buttonText,
      linkUrl: question.linkUrl,
      starsNumber: question.starsNumber,
    },
  });

  React.useEffect(() => {
    setUpdatedQuestion(question);
  }, [question]);

  const useStyles = makeStyles(() => ({
    title: {
      "& .MuiInput-underline:before, & .MuiInput-underline:hover:not(.Mui-disabled):before": {
        border: "0",
      },

      "& .MuiInputBase-input.Mui-disabled": {
        color: "rgba(0, 0, 0, 0.87)",
      },

      "& textarea": {
        maxWidth: editMode ? "unset" : "450px",
      },
    },
    multipleChoice: {
      "& .MuiInput-underline:before, & .MuiInput-underline:after , & .MuiInput-underline:hover:not(.Mui-disabled):before": {
        border: "0",
      },

      "& li": {
        padding: "0",

        "&:last-child": {
          display: editMode ? "flex" : "none",

          "& svg": {
            opacity: "0.4",
          },
          "& .Mui-focused svg": {
            opacity: "1",
          },
        },

        "& > .MuiGrid-root": {
          display: "flex",
          alignItems: "center",

          "& > svg": {
            marginRight: "6px",
          },
        },

        "& svg": {
          width: "8px",
          height: "8px",
        },

        "& .MuiInputAdornment-positionStart": {
          marginRight: "6px",
        },

        "& .MuiFormControl-root": {
          width: "100%",

          "& input": {
            fontSize: "14px",
            lineHeight: "20px",
            letterSpacing: "0.15px",
            padding: "2px 0",
          },
        },
      },

      "& .MuiInputBase-input.Mui-disabled, & .MuiInputBase-root.Mui-disabled": {
        color: "rgba(0, 0, 0, 0.87)",
      },
    },
    inputEdit: {
      "& > .MuiGrid-container": {
        flexWrap: "nowrap",

        "&:first-child": {
          marginTop: "9px",
          marginBottom: "20px",

          "& p": {
            marginRight: "29px",
          },
        },

        "&:last-child": {
          marginBottom: "16px",

          "& p": {
            marginRight: "26px",

            "&.EditQuestion__ctaLink": {
              marginRight: "46px",
            },
          },
        },

        "& p": {
          fontWeight: "500",
          fontSize: "10px",
          lineHeight: "11px",
          color: "#616161",
        },

        "& .MuiFormControl-root": {
          flex: "auto",
          maxWidth: "300px",

          "& input": {
            padding: "10px 12px",
          },
        },
      },

      "& .MuiInputBase-input.Mui-disabled": {
        color: "rgba(0, 0, 0, 0.87)",
      },

      "& .MuiOutlinedInput-root:hover fieldset": {
        borderColor: editMode ? "#96BD92" : "rgba(0, 0, 0, 0.26)",
      },
    },
    selector: {
      flexDirection: "row",
      alignItems: "center",

      "& > p": {
        fontWeight: "500",
        fontSize: "10px",
        lineHeight: "11px",
        color: "#616161",
        marginRight: "20px",
      },

      "& > .MuiFormControl-root": {
        flex: "auto",
        maxWidth: "300px",

        "& .MuiSelect-root": {
          padding: "10px 12px",
        },

        "& .MuiOutlinedInput-root:hover fieldset": {
          borderColor: editMode ? "#96BD92" : "rgba(0, 0, 0, 0.26)",
        },
      },

      "& .Mui-disabled.MuiInputBase-formControl": {
        "& .MuiSelect-root.Mui-disabled": {
          color: "rgba(0, 0, 0, 0.87)",
        },
        "& > svg": {
          display: "none",
        },
      },
    },
  }));

  const styles = useStyles();

  const handleTitleChange = (event) => {
    setUpdatedQuestion({ ...updatedQuestion, text: event.target.value });
  };

  const handleBlur = () => {
    if (
      JSON.stringify(updatedQuestion) !== JSON.stringify(question) &&
      editMode
    ) {
      questionChange(updatedQuestion);
    }
  };

  const handleMultipleChange = (option) => (event) => {
    let optionsList = updatedQuestion.options.optionsList.map((item) => {
      if (option.position === item.position)
        return { ...option, value: event.target.value };
      return item;
    });

    if (optionsList[option.position].value === "") {
      optionsList.splice(option.position, 1);
      optionsList = optionsList.map((item, position) => {
        if (position >= option.position)
          return { ...item, position: item.position - 1 };
        return item;
      });
      questionChange({
        ...updatedQuestion,
        options: { ...updatedQuestion.options, optionsList },
      });
    }

    setUpdatedQuestion({
      ...updatedQuestion,
      options: { ...updatedQuestion.options, optionsList },
    });
  };

  const handleButtonChange = (type) => (event) => {
    setUpdatedQuestion({
      ...updatedQuestion,
      options: {
        ...updatedQuestion.options,
        [type]: event.target.value,
      },
    });
    if (type === "starsNumber")
      questionChange({
        ...updatedQuestion,
        options: {
          ...updatedQuestion.options,
          [type]: event.target.value,
        },
      });
  };

  const addNewMultipleChoiceOption = (event) => {
    if (!event.target.value) return;
    questionChange({
      ...updatedQuestion,
      options: {
        ...updatedQuestion.options,
        optionsList: [
          ...updatedQuestion.options.optionsList,
          {
            position: updatedQuestion.options.optionsList.length,
            value: event.target.value,
          },
        ],
      },
    });
    event.target.value = ""; // eslint-disable-line
  };

  return (
    <Grid container direction="column">
      {editMode ? (
        <TextField
          inputProps={{ "aria-label": "naked" }}
          className={styles.title}
          onChange={handleTitleChange}
          value={updatedQuestion.text}
          onBlur={handleBlur}
          onKeyDown={(event) => {
            if (event.key === "Enter") event.target.blur();
          }}
          onFocus={
            editMode
              ? () => handleClickAway(updatedQuestion.position, true)
              : null
          }
        />
      ) : (
        <Typography className={styles.title}>{updatedQuestion.text}</Typography>
      )}
      {/* Multiple Choice */}
      {updatedQuestion.type === "multipleChoice" &&
        updatedQuestion.options.optionsList &&
        (activeQuestionEdit || !editMode) &&
        (hideAdditional || editMode) && (
          <List className={styles.multipleChoice}>
            {updatedQuestion.options.optionsList[0] &&
              updatedQuestion.options.optionsList.map((option) => (
                <ListItem key={option.position}>
                  {editMode ? (
                    <TextField
                      onChange={handleMultipleChange(option)}
                      value={option.value}
                      onBlur={handleBlur}
                      onKeyDown={(event) => {
                        if (event.key === "Enter") event.target.blur();
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <FiberManualRecord />
                          </InputAdornment>
                        ),
                      }}
                    />
                  ) : (
                    <Grid>
                      <svg
                        className="MuiSvgIcon-root"
                        focusable="false"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                      >
                        <circle cx="12" cy="12" r="8" />
                      </svg>
                      <Typography>{option.value}</Typography>
                    </Grid>
                  )}
                </ListItem>
              ))}
            <ListItem>
              <TextField
                placeholder="|type another choice..."
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    addNewMultipleChoiceOption(event);
                    event.target.value = ""; // eslint-disable-line
                  }
                }}
                onBlur={addNewMultipleChoiceOption}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FiberManualRecord />
                    </InputAdornment>
                  ),
                }}
              />
            </ListItem>
          </List>
        )}
      {/* NPS Survey */}
      {updatedQuestion.type === "NPS_Score" &&
        (activeQuestionEdit || !editMode) &&
        (hideAdditional || editMode) && (
          <Grid container direction="column" className={styles.inputEdit}>
            <Grid container alignItems="center">
              <Typography>Low Score Text</Typography>
              <TextField
                value={updatedQuestion.options.lowScoreText}
                variant="outlined"
                disabled={!editMode}
                onChange={handleButtonChange("lowScoreText")}
                onKeyDown={(event) => {
                  if (event.key === "Enter") event.target.blur();
                }}
                onBlur={handleBlur}
              />
            </Grid>
            <Grid container alignItems="center">
              <Typography>High Score Text</Typography>
              <TextField
                value={updatedQuestion.options.highScoreText}
                variant="outlined"
                disabled={!editMode}
                onChange={handleButtonChange("highScoreText")}
                onKeyDown={(event) => {
                  if (event.key === "Enter") event.target.blur();
                }}
                onBlur={handleBlur}
              />
            </Grid>
          </Grid>
        )}
      {/* Rating */}
      {updatedQuestion.type === "rating" &&
        (activeQuestionEdit || !editMode) &&
        (hideAdditional || editMode) && (
          <>
            <Grid container direction="column" className={styles.inputEdit}>
              <Grid container alignItems="center">
                <Typography>Low Score Text</Typography>
                <TextField
                  value={updatedQuestion.options.lowScoreText}
                  variant="outlined"
                  disabled={!editMode}
                  onChange={handleButtonChange("lowScoreText")}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") event.target.blur();
                  }}
                  onBlur={handleBlur}
                />
              </Grid>
              <Grid container alignItems="center">
                <Typography>High Score Text</Typography>
                <TextField
                  value={updatedQuestion.options.highScoreText}
                  variant="outlined"
                  disabled={!editMode}
                  onChange={handleButtonChange("highScoreText")}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") event.target.blur();
                  }}
                  onBlur={handleBlur}
                />
              </Grid>
            </Grid>
            <FormControl variant="outlined" className={styles.selector}>
              <Typography>Number Of Stars</Typography>
              <TextField
                select
                variant="outlined"
                disabled={!editMode}
                value={updatedQuestion.options.starsNumber}
                SelectProps={{
                  MenuProps: { disablePortal: true },
                }}
                onChange={handleButtonChange("starsNumber")}
                onBlur={handleBlur}
              >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={6}>6</MenuItem>
                <MenuItem value={7}>7</MenuItem>
                <MenuItem value={8}>8</MenuItem>
                <MenuItem value={9}>9</MenuItem>
                <MenuItem value={10}>10</MenuItem>
              </TextField>
            </FormControl>
          </>
        )}
      {/* CTA */}
      {updatedQuestion.type === "cta" &&
        (activeQuestionEdit || !editMode) &&
        (hideAdditional || editMode) && (
          <Grid container direction="column" className={styles.inputEdit}>
            <Grid container alignItems="center">
              <Typography>Button text</Typography>
              <TextField
                value={updatedQuestion.options.buttonText}
                variant="outlined"
                disabled={!editMode}
                onChange={handleButtonChange("buttonText")}
                onBlur={handleBlur}
                onKeyDown={(event) => {
                  if (event.key === "Enter") event.target.blur();
                }}
              />
            </Grid>
            <Grid container alignItems="center">
              <Typography className="EditQuestion__ctaLink">
                Link url
              </Typography>
              <TextField
                value={updatedQuestion.options.linkUrl}
                variant="outlined"
                disabled={!editMode}
                onKeyDown={(event) => {
                  if (event.key === "Enter") event.target.blur();
                }}
                placeholder="e.g. www.thatwebsite.com"
                onChange={handleButtonChange("linkUrl")}
                onBlur={handleBlur}
              />
            </Grid>
          </Grid>
        )}
    </Grid>
  );
};

EditQuestion.propTypes = {
  question: PropTypes.objectOf(PropTypes.any).isRequired,
  questionChange: PropTypes.func.isRequired,
  activeQuestionEdit: PropTypes.bool.isRequired,
  handleClickAway: PropTypes.func.isRequired,
  editMode: PropTypes.bool.isRequired,
  hideAdditional: PropTypes.bool.isRequired,
};

export default EditQuestion;
