import React from "react";
import PropTypes from "prop-types";
import {
  makeStyles,
  Grid,
  Typography,
  Divider,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";

import Logo from "../components/Logo";
import FormInput from "../components/FormInput";
import SubmitBtn from "../components/SubmitBtn";
import authApi from "../api/auth-api";

const ChangePassword = (props) => {
  const { invitationMode } = props;

  const [state, setState] = React.useState({
    isSubmitted: false,
    password: "",
    backdrop: false,
    confirmPassword: "",
  });

  const history = useHistory();
  const useStyles = makeStyles((theme) => ({
    root: {
      height: "100vh",
      position: "relative",
      minHeight: "600px",

      [theme.breakpoints.down(350)]: {
        minHeight: "670px",
      },

      "& > a": {
        position: "absolute",
        top: "46px",
        left: "361px",

        [theme.breakpoints.down("lg")]: {
          top: "49px",
          left: "115px",
        },

        [theme.breakpoints.down("xs")]: {
          left: "24px",
          top: "24px",
        },
      },
      "& > .MuiGrid-item": {
        maxWidth: "420px",
        width: "100%",

        [theme.breakpoints.down("xs")]: {
          padding: "24px",
          maxWidth: "unset",
        },

        "& h4": {
          fontWeight: "600",
          marginBottom: "7px",
        },

        "& h5": {
          marginBottom: "24px",
        },

        "& form": {
          marginTop: "33px",

          "& .MuiFormHelperText-contained": {
            opacity: 0,
            cursor: "default",
          },

          "& > .MuiGrid-root:nth-child(2)": {
            "& .Mui-focused, & .MuiOutlinedInput-root:hover": {
              "&.MuiInputLabel-root": {
                color:
                  state.password === state.confirmPassword || !state.isSubmitted
                    ? theme.palette.primary.main
                    : "#F44336",
              },
              "& fieldset": {
                borderColor:
                  state.password === state.confirmPassword ||
                  !state.password ||
                  !state.isSubmitted
                    ? theme.palette.primary.main
                    : "#F44336",
              },
            },

            "& .MuiInputLabel-outlined": {
              color:
                state.password === state.confirmPassword || !state.isSubmitted
                  ? "rgba(0, 0, 0, 0.54)"
                  : "#F44336",
            },

            "& fieldset": {
              borderColor:
                state.password === state.confirmPassword ||
                !state.password ||
                !state.isSubmitted
                  ? "rgba(0, 0, 0, 0.23)"
                  : "#F44336",
            },

            "& .MuiFormHelperText-contained": {
              opacity:
                state.password === state.confirmPassword ||
                !state.password ||
                !state.isSubmitted
                  ? "0"
                  : "1",
              color: "#F44336",
              marginLeft: "0",
            },
          },

          "& .Mui-error": {
            "& fieldset": {
              borderColor:
                state.isSubmitted && !state.password
                  ? "#F44336"
                  : "rgba(0, 0, 0, 0.23)",
            },

            "& + .MuiFormHelperText-contained": {
              marginLeft: "0",
              opacity: state.isSubmitted && !state.password ? "1" : "0",
            },

            "&.Mui-focused fieldset, &:hover fieldset": {
              borderColor:
                state.isSubmitted && !state.password ? "#F44336" : "#96BD92",
            },

            "&.MuiInputLabel-shrink": {
              color:
                state.isSubmitted && !state.password
                  ? "#F44336"
                  : "rgba(136, 136, 136, 0.54)",

              "&.Mui-focused": {
                color:
                  state.isSubmitted && !state.password ? "#f44336" : "#96BD92",
              },
            },
          },

          "& button[type='submit']": {
            display: "block",
            marginLeft: "auto",
            marginTop: "20px",

            [theme.breakpoints.down("lg")]: {
              marginTop: "14px",
            },
          },
        },
      },
      "& .MuiBackdrop-root": {
        zIndex: 1,
      },
    },
  }));

  const handleInput = (type, data) => {
    if (state[type] !== data) {
      setState({ ...state, [type]: data });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      state.password &&
      state.confirmPassword &&
      state.password === state.confirmPassword
    ) {
      setState({ ...state, backdrop: true });

      authApi
        .changePassword({
          password: state.password,
          password_confirmation: state.confirmPassword,
          token: history.location.pathname.includes("/invitation/")
            ? history.location.pathname.split("/invitation/")[1]
            : history.location.pathname
                .split("/password_reset")
                .slice(-1)[0]
                .substring(1),
        })
        .then(() => {
          delete localStorage.jwt;

          window.location.replace("/sign-in?password-changed");
        });
    } else {
      setState({ ...state, isSubmitted: true });
    }
  };
  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      className={useStyles().root}
    >
      <Logo />
      <Grid item>
        <Typography variant="h4">
          {`${invitationMode ? "Create" : "Reset"} your password`}
        </Typography>
        <Typography variant="h5">
          {`Please enter a ${
            invitationMode ? "" : "new "
          }password and confirm it to continue`}
        </Typography>
        <Divider />
        <form onSubmit={handleSubmit}>
          <FormInput type="password" onValidate={handleInput} />
          <FormInput type="confirmPassword" onValidate={handleInput} />
          <SubmitBtn
            btnText={`Create ${invitationMode ? "" : "New "}Password`}
          />
        </form>
        <Backdrop open={state.backdrop}>
          <CircularProgress color="primary" />
        </Backdrop>
      </Grid>
    </Grid>
  );
};

ChangePassword.propTypes = {
  invitationMode: PropTypes.bool,
};

ChangePassword.defaultProps = {
  invitationMode: false,
};

export default ChangePassword;
