import React from "react";
import {
  makeStyles,
  Grid,
  Typography,
  Button,
  TextField,
  Tooltip,
  Backdrop,
  CircularProgress,
  ClickAwayListener,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import campaignsApi from "../api/campaigns-api";
import AlertMessage from "./AlertMessage";

const ManageProfile = () => {
  const company = useSelector((store) => store.company);
  const userRole = useSelector((store) => store.user.role);
  const userEmail = useSelector((store) => store.user.email);
  const dispatch = useDispatch();

  const [state, setState] = React.useState({
    newCompanyName: company.name,
    saveChangesDisabled: false,
    backdrop: false,
    successMessage: false,
  });

  const useStyles = makeStyles((theme) => ({
    root: {
      padding: "30px 40px 40px 42px",
      width: "100%",

      "& > .MuiGrid-root": {
        "&:first-child": {
          marginBottom: "14px",

          [theme.breakpoints.down("xs")]: {
            flexDirection: "column",
            alignItems: "start",

            "& > p": {
              marginBottom: "15px",
            },
          },

          "& button": {
            fontWeight: "600",
            fontSize: "16px",
            lineHeight: "25px",
            letterSpacing: "0.3px",
            padding: "6px 16px",
          },
        },

        // TODO: Avatars
        // "&:nth-child(2)": {
        //   marginBottom: "40px",

        //   [theme.breakpoints.down("xs")]: {
        //     flexDirection: "column",
        //     alignItems: "start",
        //     marginBottom: "20px",

        //     "& > *": {
        //       marginBottom: "10px",
        //     },
        //   },

        //   "& > p": {
        //     fontWeight: "600",
        //     fontSize: "10px",
        //     lineHeight: "11px",
        //     letterSpacing: "1px",
        //     textTransform: "uppercase",
        //     color: "#B3B3B3",
        //     marginRight: "21px",
        //   },

        //   "& > .MuiGrid-root": {
        //     width: "60px",
        //     height: "60px",
        //     marginRight: "25px",
        //     display: "flex",
        //     justifyContent: "center",
        //     alignItems: "center",
        //     textTransform: "capitalize",
        //     color: "#fff",
        //     backgroundColor: theme.palette.primary.main,
        //     borderRadius: "50%",
        //   },

        //   "& > button": {
        //     padding: "0",
        //     fontWeight: "500",
        //     fontSize: "16px",
        //     lineHeight: "25px",
        //     letterSpacing: "0.3px",
        //     transition: "color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",

        //     "&:hover": {
        //       backgroundColor: "transparent",
        //       color: "#6D9D68",
        //     },
        //   },
        // },

        "&:nth-child(2)": {
          "& > p": {
            fontWeight: "600",
            fontSize: "10px",
            lineHeight: "11px",
            letterSpacing: "1px",
            textTransform: "uppercase",
            color: "#B3B3B3",
            marginBottom: "12px",
          },

          "& > .MuiFormControl-root": {
            marginBottom: "34px",
            maxWidth: "363px",
            width: "100%",

            "& .Mui-disabled": {
              "& .Mui-focused fieldset, &:hover fieldset": {
                borderColor: "rgba(0, 0, 0, 0.26)",
              },

              "& input": {
                color: "rgba(0, 0, 0, 0.77)",
                backgroundColor: "rgba(0, 0, 0, 0.03)",
              },

              "& fieldset": {
                borderColor: "rgba(0, 0, 0, 0.15)",
              },
            },

            "&.Mui-focused fieldset, &:hover fieldset": {
              borderColor: "#96BD92",
            },

            "& input": {
              padding: "10px 12px",
              height: "auto",
            },
          },
        },
      },

      "& > button": {
        marginTop: "15px",
        color: "#fff",
        boxShadow: "none",
        transition: "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",

        "&:hover": {
          boxShadow: "none",
        },

        "& a": {
          color: "inherit",
        },
      },
    },
  }));

  const handleSaveChanges = () => {
    setState({ ...state, backdrop: true });

    campaignsApi.updateCompanyName(state.newCompanyName).then((res) => {
      if (res.data.message === "Changes were applied") {
        dispatch({
          type: "COMPANY",
          payload: {
            ...company,
            name: state.newCompanyName,
          },
        });
        setState({ ...state, backdrop: false, successMessage: true });
      }
    });
  };

  return (
    <Grid className={useStyles().root}>
      <Grid container justify="space-between" alignItems="center">
        <Typography variant="body1">Your Profile</Typography>
        {userRole !== "viewer" &&
          (state.saveChangesDisabled ? (
            <Tooltip
              title="You need to fill all fields"
              enterTouchDelay={0}
              arrow
            >
              <Typography>
                <Button color="primary" variant="outlined" disabled>
                  Save Changes
                </Button>
              </Typography>
            </Tooltip>
          ) : (
            <Button
              color="primary"
              variant="outlined"
              onClick={handleSaveChanges}
            >
              Save Changes
            </Button>
          ))}
      </Grid>
      {/* TODO: Avatars */}
      {false && (
        <Grid container justify="flex-start" alignItems="center">
          <Typography>Company logo</Typography>
          <Grid>{company.name.charAt(0)}</Grid>
          {userRole !== "viewer" && (
            <Button color="primary" disableRipple>
              Choose image
            </Button>
          )}
        </Grid>
      )}
      <Grid>
        <Typography>Organization name</Typography>
        <TextField
          disabled={userRole === "viewer"}
          variant="outlined"
          defaultValue={company.name}
          onChange={(event) => {
            if (
              (state.saveChangesDisabled && event.target.value) ||
              (!state.saveChangesDisabled && !event.target.value)
            ) {
              setState({
                ...state,
                saveChangesDisabled: event.target.value === "",
              });
            }
          }}
          onBlur={(event) =>
            setState({ ...state, newCompanyName: event.target.value })
          }
        />
        <Typography>Email address</Typography>
        <TextField disabled variant="outlined" value={userEmail} />
      </Grid>
      <Typography>
        If you want to reset your password, click on the button below
      </Typography>
      <Button variant="contained" color="primary">
        <Link to="/password_reset">Change password</Link>
      </Button>
      {state.successMessage && (
        <ClickAwayListener
          onClickAway={() =>
            setState({
              ...state,
              successMessage: false,
            })
          }
        >
          <Grid>
            <AlertMessage type="success" message="Сhanges successfully saved" />
          </Grid>
        </ClickAwayListener>
      )}
      <Backdrop open={state.backdrop} style={{ zIndex: 10 }}>
        <CircularProgress color="primary" />
      </Backdrop>
    </Grid>
  );
};

export default ManageProfile;
